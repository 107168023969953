import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function None({ show, children }) {

  return (
    show && (
      <Fragment>{children}</Fragment>
    )
  );
}

None.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
};

export default None;
