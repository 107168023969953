import React from 'react';
import styled from 'styled-components';
import { CARD_LOGOS } from 'core/api/constants';

const CardLogoImg = styled((p) => (
  <img
    alt="card logo"
    src={CARD_LOGOS[p.brand.toLowerCase()]}
    {...p}
  />
))`
  position: absolute;
  height: 1.5em;
  right: 1em;
  top: 0.7em;
`;

export default CardLogoImg;
