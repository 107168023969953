import React, { PureComponent } from 'react';

import { goTo } from 'core/api/actions/navigation';
import { Text } from 'core/ui/atoms';
import CutoffModal from 'core/ui/components/Cutoff/Modal';

class Modal extends PureComponent {
  render() {
    return (
      <CutoffModal onClose={() => goTo('/sign-up')}>
        <Text>We updated our menu while you were signing up.</Text>

        <Text>Please start the signup process again.</Text>
      </CutoffModal>
    );
  }
}

export default Modal;
