import React from 'react';
import styled from 'styled-components';

import { Button } from 'core/ui/atoms';

const ContentButton = styled((p) => <Button btn-secondary btn-lg btn-rounded {...p} />)`
  display: inline-block;
  font-weight: 500;
  color: ${(p) => p.theme.colors.primary};

  margin-top: auto;
  margin-right: auto;

&:disabled {
  color: ${(p) => p.theme.colors.primaryV2};

}

  ${(p) => p.theme.max('sm')`
    margin-right: inherit;
    margin-left: auto;
  `}
`;

export default ContentButton;
