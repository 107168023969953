import qs from 'qs';

import { session } from 'core/api/storage';
import { readFragment } from 'core/api/cache/helpers';
import { SplitTestAssignmentFragment } from 'core/api/apollo/fragments';
import { GET_PROMOCODE_INFO } from 'core/api/apollo/queries';
import { graphqlHost, clientTimestamp } from 'reactAppConstants';

export const storeSessionTestAssignments = (location) => {
  const overrides = qs.parse(location.search, { ignoreQueryPrefix: true }).ab_test;
  if (!overrides) {
    return;
  }

  const current = JSON.parse(session.getItem('ab_test') || '{}');
  const updated = { ...current, ...overrides };
  session.setItem('ab_test', JSON.stringify(updated));
};

export const storePromocode = async (location) => {
  const overrides = qs.parse(location.search, { ignoreQueryPrefix: true }).referral_code;
  if (!overrides) {
    return;
  }

  return await getReferralCodeInfo(overrides);
};

export const getPartnerLink = (location) => {
  const overrides = qs.parse(location.search, { ignoreQueryPrefix: true }).partner_link;
  if (!overrides) {
    return;
  }

  return overrides;
};

export const isReorder = (location) => {
  const overrides = qs.parse(location.search, { ignoreQueryPrefix: true }).reorder;

  return !!overrides;
};

export const sessionTestAssignment = (id) => {
  const assignments = JSON.parse(session.getItem('ab_test') || '{}');
  return assignments[id];
};

const cachedTestAssignment = (cache, id) => {
  const data = readFragment(cache, {
    fragment: SplitTestAssignmentFragment,
    fragmentName: 'SplitTestAssignmentFragment',
    typename: 'SplitTestAssignment',
    id,
  }) || {};

  return data.cohort;
};

const getReferralCodeInfo = async (overrides) => {
  const referralCode = overrides;
  const res = await fetch(`${graphqlHost}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'client-timestamp': clientTimestamp,
    },
    body: JSON.stringify({
      query: GET_PROMOCODE_INFO,
      variables: {
        promocode: referralCode,
      },
    }),
  });
  const result = await res.json();

  if (result && result.data.getPromocodeInfo) {
    return result.data.getPromocodeInfo;
  }

  return null;
};

export const getSplitTestAssignment = (cache, id) => (sessionTestAssignment(id) || cachedTestAssignment(cache, id));
