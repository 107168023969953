
import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from 'core/ui/components';
import { Text, Button, Link } from 'core/ui/atoms';
import { EXTERNAL_LINKS } from 'core/api/constants';

class NotDeliverable extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
  }
  
  render() {
    const { onClick } = this.props;

    return (
      <Fragment>
        <CustomModal>
          <ModalContentContainer>
            <ModalContent>
              <CustomText text-italic>Sorry, it looks like we don’t deliver to your neck of the woods yet. Try another zip code.</CustomText>
              <Line></Line>
              <CustomButton onClick={onClick} btn-primary btn-lg>
                TRY ANOTHER ZIP CODE
              </CustomButton>
              <CustomText>The good news is you can shop our pantry items and other artisanal goods from our Methodology Shop.</CustomText>
              <CustomLink href={EXTERNAL_LINKS.shop} target='_blank' rel='noopener noreferrer' aria-label="Visit Methodology Shop. Opens in new window">SHOP NOW</CustomLink>
              <CustomText text-italic text-size='smaller'>We deliver anywhere in the US except Alaska and Hawaii (our delivery guy can't walk that far).</CustomText>
            </ModalContent>
            
          </ModalContentContainer>
        </CustomModal>
      </Fragment>
    );
  }
}

const CustomModal = styled(Modal)`
  width: 80%;
  ${(p) => p.theme.max('sm')`
    width: 100%;
  `}
`;

const CustomText = styled(Text)`
  margin: 1.5rem 0;
`;

const ModalContentContainer = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #C1D3C1;
  padding: 3vh;
  ${(p) => p.theme.max('sm')`
    height: 100vh;
  `}
`;

const Line = styled.div`
  border-left: 2px solid #36443A;
  height: 100px;
  width: 2px;
  margin: 0 auto;
  ${(p) => p.theme.max('sm')`
  `}
`;

const ModalContent = styled.div`
  text-align: center;
  ${(p) => p.theme.max('sm')`
    
  `}
`;

const CustomButton = styled(Button)`
  margin-top: 1.5rem;
  border-radius: 5px;
  border: 1px solid #36443a;
  background: #;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
  color: white;
  font-family: ${(p) => p.theme.typography.familyPoppins};
`;

const CustomLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  background: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.typography.colors.inverse};
  border-color: ${(p) => p.theme.colors.primary};
  &:hover {
    box-shadow: ${(p) => p.theme.shadows.level1};
  }
  padding: 1rem 2rem;
  border-radius: 5px;
`;


export default NotDeliverable;
