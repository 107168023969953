import React from 'react';

import Notices from 'dashboard/ui/components/DashboardPage/Notices';

function News() {
  return (
    <Notices priority='LOW' />
  );
}

export default News;
