import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import _isEmpty from 'lodash/isEmpty';
import _sumBy from 'lodash/sumBy';
import { useClickAway } from 'react-use';

import { IconButton } from 'core/ui/atoms';
import Fade from 'core/ui/animations/Fade';
import useInventoryStatus from 'dashboard/hooks/useInventoryStatus';
import VariantOptions from 'dashboard/ui/components/VariantOptions';

function CartControls(props) {
  const { enabled, lineItems, product, deliveryDay, prodName } = props;
  const quantity = _sumBy(lineItems, 'quantity');
  const inCart = quantity > 0;
  const { soldOut } = useInventoryStatus(deliveryDay.id, product.id);
  const disableAdd = !enabled || soldOut;
  const disableRemove = !enabled;
  const [opened, setOpened] = useState(false);
  const controlsRef = useRef(null);

  const openControls = () => {
    if (!disableAdd || !disableRemove) {
      setOpened(true);
    }
  };

  const closeControls = () => {
    setOpened(false);
  };

  useClickAway(controlsRef, closeControls);

  if (!product || _isEmpty(product.variants)) {
    return null;
  }

  return (
    <Fragment>
      { !inCart && !disableAdd && <AddTrigger onClick={openControls} prodName={prodName} /> }
      { inCart && <InCartTrigger quantity={quantity} onClick={openControls} prodName={prodName} /> }
      { soldOut && <SoldOutIndicator data-product-sold-out>Sold Out</SoldOutIndicator>}
      <Fade show={opened}>
        <Controls ref={controlsRef}>
          <StyledVariantOptions
            deliveryDay={deliveryDay}
            product={product}
            lineItems={lineItems}
            autoAdd={!inCart}
            disableAdd={disableAdd}
            disableRemove={disableRemove}
            animate
            except={['weight', 'price']}
          />
        </Controls>
      </Fade>
    </Fragment>
  );
}

CartControls.propTypes = {
  enabled: PropTypes.bool.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  product: PropTypes.object.isRequired,
  deliveryDay: PropTypes.object.isRequired,
  prodName: PropTypes.string,
};

function AddTrigger(props) {
  const { prodName, ...rest } = props;

  return (
    <Trigger btn-primary data-product-add-trigger aria-label={'Add ' + prodName + ' to cart'} {...rest} >
      <IconButton.Icon icon='plus' />
    </Trigger>
  );
}

AddTrigger.propTypes = {
  prodName: PropTypes.string,
};

function InCartTrigger(props) {
  const { prodName, quantity, ...rest } = props;

  return (
    <Trigger btn-secondary data-product-cart-trigger {...rest} >
      <IconButton.Text>
        <span className="sr-only">Quantity for {prodName}:&nbsp;</span>
        {quantity}
      </IconButton.Text>
    </Trigger>
  );
}

InCartTrigger.propTypes = {
  quantity: PropTypes.number.isRequired,
  prodName: PropTypes.string,
};

const Controls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  font-size: 1em;

  background: ${(p) => p.theme.modals.inverseBackdropColor};
  backdrop-filter: blur(4px);
`;

const StyledVariantOptions = styled(VariantOptions)`
  border: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.borders.color};
`;

const SoldOutIndicator = styled.span`
  position: absolute;
  bottom: 5%;
  left: 5%;
  font-size: 0.75em;
  padding: 0.25em 0.5em;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: ${(p) => p.theme.borders.radius};
  background-color: ${(p) => p.theme.colors.grays.light};
  color: ${(p) => p.theme.typography.colors.muted};
`;

const Trigger = styled(IconButton)`
  position: absolute;
  bottom: 5%;
  right: 5%;
  font-size: 2.5em;
  ${(p) => p['btn-secondary'] && css`
    color: ${(p) => p.theme.typography.colors.primary};
  `}
`;

export default CartControls;
