import React, { useState } from 'react';
import styled from 'styled-components';

import { EXTERNAL_LINKS } from 'core/api/constants';
import { Form, Icon, Input, Link, Text } from 'core/ui/atoms';
import Panel from 'settings/ui/components/Panel';

function NeedHelp() {
  const [searchTerm, setSearchTerm] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    window.open(`${EXTERNAL_LINKS.helpCenter}?q=${searchTerm}`, '_blank');
  };

  const onChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Panel title='Need Help?' data-need-help>
      <Form onSubmit={onSubmit}>
        <SearchCont>
          <Input
            type="text"
            placeholder={'Search'}
            value={searchTerm}
            onChange={onChange}
            name="searchTerm"
          />
          <SearchIcon />
        </SearchCont>
      </Form>

      <Text>
        Visit our <Link href={EXTERNAL_LINKS.helpCenter}>Help Center</Link>{' '}
        for general questions
      </Text>
    </Panel>
  );
}

const SearchCont = styled.div`
  position: relative;
  ${(p) => p.theme.max('md')`
    width: 100%;
  `}
`;

const SearchIcon = styled((p) => <Icon icon="search" {...p} />)`
  font-size: 1em;
  position: absolute;
  top: 1em;
  right: 1em;
  color: #858C94; // FIXME: add to theme
`;

export default NeedHelp;
