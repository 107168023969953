import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Icon } from 'core/ui/atoms';

class Tag extends PureComponent {
  static propTypes = {
    onRemove: PropTypes.func.isRequired,
    tagId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  onRemove = () => this.props.onRemove(this.props.tagId);

  render() {
    return (
      <TagCont data-tag-id={this.props.tagId}>
        <Icon icon="check" />
        <Title>{this.props.title}</Title>
        <RemoveButton btn-outline onClick={this.onRemove} data-tag-remove-button>
          <RemoveIcon icon="close" />
        </RemoveButton>
      </TagCont>
    );
  }
}

const TagCont = styled.li`
  display: flex;
  align-items: center;
  border: 1px solid ${(p) => p.theme.colors.primary};
  border-radius: 3em;
  padding: 0.5em 1em;
  margin: 0 0.5em 0.5em 0;
`;

const Title = styled.span`
  margin: 0 0.5em;
`;

const RemoveButton = styled(Button)`
  position: relative;
  padding: 0;
  border-radius: 3em;
  height: 1em;
  width: 1em;
`;

const RemoveIcon = styled(Icon)`
  position: absolute;
  font-size: 0.4em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default Tag;
