import React from 'react';
import PropTypes from 'prop-types';

import { onrampNext } from 'onramp/api/navigation';
import OrderCreator from 'onramp/ui/components/V2/CustomizePlanPage/OrderCreator';

export function StepForm(props) {

  return (
      <OrderCreator
        currentUser={props.currentUser}
        onSuccess={props.onSuccess}
      />
  );
}

StepForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
  frequencyRadioVisible: PropTypes.bool,
  onSuccess: PropTypes.func,
};

function BuildYourOwnBoxPage(props) {
  const onSuccess = () => onrampNext(props.currentUser, props.cache, '?isSubscribed=true' );

  return (
      <StepForm
        currentUser={props.currentUser}
        cache={props.cache}
        onSuccess={onSuccess}
      />
  );
}

BuildYourOwnBoxPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
};

export default BuildYourOwnBoxPage;
