import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-form-validator-core';
import { useMutation } from '@apollo/client';

import { SET_PRIMARY_ZIP_CODE } from 'core/api/apollo/mutations';
import { Button, FormGroup, ValidatedInput } from 'core/ui/atoms';
import { ERROR_MESSAGES, ZIP_CODE_REGEXP } from 'core/api/constants';

function ZipCodeForm(props) {
  const { zipCode: initialValue, ...rest } = props;
  const [zipCode, setZipCode] = useState(initialValue);
  const [updateZipCode, { loading: isProcessing }] = useMutation(SET_PRIMARY_ZIP_CODE, {
    variables: { input: { zipCode: zipCode } },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    updateZipCode();
  };

  return(
    <ValidatorForm
      noValidate
      instantValidate={false}
      onSubmit={onSubmit}
      {...rest}
    >
      <FormGroup>
        <ValidatedInput
          name="zipCode"
          placeholder="Try another zip code"
          maxLength={5}
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          validators={['required', 'isNumber', `matchRegexp:${ZIP_CODE_REGEXP}`]}
          errorMessages={[
            ERROR_MESSAGES.required,
            ERROR_MESSAGES.numeric,
            ERROR_MESSAGES.zip,
          ]}
        />
      </FormGroup>
      <FormGroup>
        <Button btn-secondary btn-rounded btn-lg type="submit" disabled={isProcessing}>Update</Button>
      </FormGroup>
    </ValidatorForm>
  );
}

ZipCodeForm.propTypes = {
  zipCode: PropTypes.string,
};

export default ZipCodeForm;
