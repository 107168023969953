import React from 'react';
import PropTypes from 'prop-types';

import Message from './Message';

function EmptyOrderMessage(props) {
  const title = 'Your order is empty.';

  return <Message title={title} {...props} />;
}

EmptyOrderMessage.propTypes = {
  deliveryDayId: PropTypes.string,
};

export default EmptyOrderMessage;
