import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import Loading from 'core/ui/components/Loading';
import { LIST_MARKETING_VIDEOS } from 'core/api/apollo/queries';
import { VimeoEmbed } from 'dashboard/ui/atoms';

function Discover() {
  const { loading, data: { listMarketingVideos = [] } = {} } = useQuery(LIST_MARKETING_VIDEOS);

  if (loading) { return <Loading />; }

  const videos = sortBy(listMarketingVideos, 'position');

  return (
    <VideoGrid>
      {videos.map((video) => <DiscoverVideo key={video.id} video={video} />)}
    </VideoGrid>
  );
}

function DiscoverVideo(props) {
  const { video: { videoId } } = props;

  return (
    <VideoBox>
      <VimeoEmbed srcKey={videoId} />
    </VideoBox>
  );
}

DiscoverVideo.propTypes = {
  video: PropTypes.object.isRequired,
};


const VideoGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: calc(${(p) => p.theme.layout.gridGap} * 2);

  grid-template-columns: repeat(auto-fill, minmax(30vw, 1fr));
  ${(p) => p.theme.max('sm')`
    grid-template-columns: 1fr;
  `};

  margin-bottom: 20px;
`;

const VideoBox = styled.div`
  width: 100%;
`;

export default Discover;
