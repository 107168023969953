import Rollbar from 'rollbar';
import { codeVersion, rollbarAccessToken, hostEnv } from 'reactAppConstants';

const IGNORED_MESSAGES = [
  "undefined is not an object (evaluating 'document.getElementsByClassName(\"docs-homescreen-gb-container\")[0].style')",
  'ClipperError',
  'Extension context invalidated',
];

export const init = () => {
  window.Rollbar = window.Rollbar || new Rollbar({
    accessToken: rollbarAccessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: !!rollbarAccessToken,
    ignoredMessages: IGNORED_MESSAGES,
    payload: {
      environment: hostEnv,
      client: {
        javascript: {
          source_map_enabled: true,
          guess_uncaught_frames: true,
          code_version: codeVersion,
        },
      },
    },
  });
};

export const updatePersonInfo = (user) => {
  const person = user ? { id: user.id, username: user.email, email: user.email } : {};
  window.Rollbar.configure({ payload: { person } });
};

export default window.Rollbar;
