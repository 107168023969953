import React from 'react';
import PropTypes from 'prop-types';

import Message from './Message';
import UnskipOrderButton from 'dashboard/ui/components/UnskipOrderButton';

function SkippedMessage(props) {
  const { orderId, actionable, ...rest } = props;

  return (
    <Message title='You skipped this order.' {...rest}>
      {actionable && <UnskipOrderButton orderId={orderId} btn-primary btn-lg text="Unskip order" />}
    </Message>
  );
}

SkippedMessage.propTypes = {
  orderId: PropTypes.string.isRequired,
  actionable: PropTypes.bool,
};

export default SkippedMessage;
