import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { NavLink, useParams } from 'react-router-dom';

import useUser from 'core/hooks/useUser';
import useProductFilters from 'dashboard/hooks/useProductFilters';
import useProductTags from 'dashboard/hooks/useProductTags';

import { Button, Checkbox, H6, Icon, List, ListItem } from 'core/ui/atoms';
import CloseButton from 'core/ui/components/CloseButton';
import { menuFilterPath, menuReorderPath } from 'core/api/actions/navigation';

function FilterNav() {
  const [trayOpen, setTrayOpen] = useState(false);
  const { userCan } = useUser();
  const { deliveryDayId } = useParams();
  const { loading, productTags: listProductTags = [] } = useProductTags(deliveryDayId);
  const productFilters = useProductFilters();

  const toggleTray = () => setTrayOpen(!trayOpen);

  if (loading) {
    return null;
  }

  const tags = listProductTags.filter((tag) => !tag.promo);
  const tagsByGroup = groupBy(tags, 'tagGroup.id');
  const tagGroups = sortBy(uniqBy(tags.map((tag) => tag.tagGroup), 'id'), 'position');

  const filterCount = productFilters.active.length;
  const reorderGroup = tagGroups.find((group) => group.featured);

  return (
    <Wrapper>
      <Button unstyled onClick={toggleTray} hiddenMD hiddenLG >
        {filterCount > 0 ? filterCount : ''} Filter{filterCount !== 1 ? 's' : ''}
        {' '}
        <Icon icon='filter' />
      </Button>
      <Tray open={trayOpen}>
        <CloseButton onClose={toggleTray} hiddenMD hiddenLG />
        {
          tagGroups.map((tagGroup) => (
            <TagGroup
              key={tagGroup.name}
              tagGroup={{ ...tagGroup, tags: tagsByGroup[tagGroup.id] }}
              deliveryDayId={deliveryDayId}
              productFilters={productFilters}
              addReorderLink={userCan.placeOrder() && tagGroup === reorderGroup}
            />
          ))
        }
      </Tray>
    </Wrapper>
  );
}

function TagGroup(props) {
  const { tagGroup, deliveryDayId, productFilters, addReorderLink } = props;

  return (
    <TagGroupWrapper>
      {!tagGroup.featured && <TagHeading>{tagGroup.name}</TagHeading>}
      <TagList unstyled>
        {
          sortBy(tagGroup.tags, 'position').map((tag) => (
            <ListItem key={`filter-${tag.id}}`}>
              {
                tagGroup.featured ? (
                  <NavLink to={menuFilterPath(deliveryDayId, tag.id)}>{tag.name}</NavLink>
                ) : (
                  <Fragment>
                    <Checkbox
                      checked={productFilters.isActive(tag.id)}
                      onChange={() => productFilters.toggleFilter(tag.id)}
                      value={tag.id}
                      label={tag.name}
                    />
                  </Fragment>
                )
              }
            </ListItem>
          ))
        }
        {
          addReorderLink && (
            <ListItem key='filter-reorder'>
              <NavLink to={menuReorderPath(deliveryDayId)}>Reorder</NavLink>
            </ListItem>
          )
        }
      </TagList>
    </TagGroupWrapper>
  );
}

TagGroup.propTypes = {
  tagGroup: PropTypes.object.isRequired,
  deliveryDayId: PropTypes.string.isRequired,
  productFilters: PropTypes.object.isRequired,
  addReorderLink: PropTypes.bool.isRequired,
};

const Wrapper = styled.div`
  position: relative;
`;

const Tray = styled.div`
  ${(p) => p.theme.max('md')`
    position: absolute;
    background: ${(p) => p.theme.colors.white};
    z-index: ${(p) => p.theme.zIndex.header - 1};
    padding: 5vh 5vw;
    left: 0;
    top: -3vh;
    width: 75vw;
    min-height: 100vh;
    border: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.black};
    border-top: none;
    border-left: none;
    transition: transform 0.5s ease-in-out;
    transform: translateX(-100vw);

    ${(p) => p.open && css`
      transform: translateX(-5vw);
    `}
  `}
`;

const TagGroupWrapper = styled.div`
  margin-right: 5vw;
  margin-top: 2vh;
  padding-bottom: 2vh;
  border-bottom: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.grays.light};

  &:last-child {
    border: none;
  }
`;

const TagList = styled(List)`
  li {
    display: flex;
    align-items: center;
    margin: 0 0 0.5em 0;
  }

  a {
    font-weight: bold;
  }

  span.checkboxLabel {
    font-size: 0.875em;
    font-weight: 500;
  }
`;

const TagHeading = styled(H6)`
  margin: 0 0 0.5em 0;
  text-transform: uppercase;
`;

export default FilterNav;
