import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, H1, H3, RouterLink } from 'core/ui/atoms';

import FRIENDSGIVING_BG from 'dashboard/assets/images/menu/friendsgiving.jpg';
import THANKSGIVING_BG from 'dashboard/assets/images/menu/thanksgiving.jpg';
import IMMUNITY_BG from 'dashboard/assets/images/menu/immunity.jpg';
import NEW_BG from 'dashboard/assets/images/menu/new.jpg';
import POPULAR_BG from 'dashboard/assets/images/menu/popular.jpg';
import PROMO_BG from 'dashboard/assets/images/menu/promo.svg';
import REORDER_BG from 'dashboard/assets/images/menu/reorder.jpg';
import GET_STARTED_BG from 'dashboard/assets/images/menu/getStarted.jpg';

export function FriendsgivingHeroComponent(props) {
  const { path, ...rest } = props;

  return (
    <FriendsgivingHero {...rest} data-zone='friendsgiving'>
      <HeroLink to={path}>
        <HeroContent>
          <HeroTitle>Friendsgiving Menu</HeroTitle>
          <HeroSubtitle>Nourishing versions of Thanksgiving classics</HeroSubtitle>
        </HeroContent>
      </HeroLink>
    </FriendsgivingHero>
  );
}

FriendsgivingHeroComponent.propTypes = {
  path: PropTypes.string.isRequired,
};

export function ThanksgivingHeroComponent(props) {
  const { path, ...rest } = props;

  return (
    <ThanksgivingHero {...rest} data-zone='thanksgiving'>
      <HeroLink to={path}>
        <HeroContent>
          <HeroTitle>Thanksgiving Menu</HeroTitle>
          <HeroSubtitle>Nourishing versions of Thanksgiving classics</HeroSubtitle>
        </HeroContent>
      </HeroLink>
    </ThanksgivingHero>
  );
}

ThanksgivingHeroComponent.propTypes = {
  path: PropTypes.string.isRequired,
};

export function ImmunityHeroComponent(props) {
  const { path, ...rest } = props;

  return (
    <ImmunityHero {...rest} data-zone='immunity'>
      <HeroLink to={path}>
        <HeroContent>
          <HeroTitle>Immunity</HeroTitle>
          <HeroSubtitle>Menu items with the most immune-boosting ingredients</HeroSubtitle>
        </HeroContent>
      </HeroLink>
    </ImmunityHero>
  );
}

ImmunityHeroComponent.propTypes = {
  path: PropTypes.string.isRequired,
};

export function NewHeroComponent(props) {
  const { path, ...rest } = props;

  return (
    <NewHero {...rest} data-zone='new'>
      <HeroLink to={path}>
        <HeroContent>
          <HeroTitle><em>New</em> in the <em>Kitchen</em></HeroTitle>
          <HeroSubtitle>All new items available this week!</HeroSubtitle>
        </HeroContent>
      </HeroLink>
    </NewHero>
  );
}

NewHeroComponent.propTypes = {
  path: PropTypes.string.isRequired,
};

export function PopularHeroComponent(props) {
  const { path, ...rest } = props;

  return (
    <PopularHero {...rest} data-zone='popular'>
      <HeroLink to={path}>
        <HeroContent>
          <HeroTitle>Popular</HeroTitle>
          <HeroSubtitle>Everyone's favorites</HeroSubtitle>
        </HeroContent>
      </HeroLink>
    </PopularHero>
  );
}

PopularHeroComponent.propTypes = {
  path: PropTypes.string.isRequired,
};

export function PromoHeroComponent(props) {
  return (
    <PromoHero {...props} data-zone='promo'>
      <HeroContent>
        <HeroSubtitle>Feel your absolute best</HeroSubtitle>
      </HeroContent>
    </PromoHero>
  );
}

export function ReorderHeroComponent(props) {
  const { path, ...rest } = props;

  return (
    <ReorderHero {...rest} data-zone='reorder'>
      <HeroLink to={path}>
        <HeroContent>
          <HeroTitle>Reorder</HeroTitle>
          <HeroSubtitle>Reorder your favorite past items</HeroSubtitle>
        </HeroContent>
      </HeroLink>
    </ReorderHero>
  );
}

ReorderHeroComponent.propTypes = {
  path: PropTypes.string.isRequired,
};

export function GetStartedHeroComponent(props) {
  const { path, ...rest } = props;

  return (
    <GetStartedHero {...rest} data-zone='reorder'>
      <HeroLink to={path}>
        <HeroTitle>Actually healthy.</HeroTitle>
        <HeroTitle>Insanely delicious.</HeroTitle>
        <HeroTitle>Fully cooked.</HeroTitle>
        <GetStartedButton btn-secondary btn-lg>Get Started</GetStartedButton>
      </HeroLink>
    </GetStartedHero>
  );
}

GetStartedHeroComponent.propTypes = {
  path: PropTypes.string.isRequired,
};

const Hero = styled.div`
  display: block;
  background-size: cover;
  background-position: center center;
  height: 100%;
  transition: background-position 0.25s ease-out, background-size 0.25s ease-out;
`;

const HeroLink = styled(RouterLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(29, 29, 29, 29%);

  padding: 8vh 5vw;
`;

const HeroContent = styled.div``;

const HeroTitle = styled(H1)`
  color: ${(p) => p.theme.colors.white} !important;
  font-style: italic;
  font-family: Albra;
  font-weight: normal;
  font-size: 4em;
  margin: 0 0 0.25em 0;
  line-height: 0.9;
  text-align: center;

  em {
    font-style: normal;
    text-transform: uppercase;
  }

  ${(p) => p.theme.max('xs')`
    font-size: 3em;
  `}
`;

const HeroSubtitle = styled(H3)`
  color: ${(p) => p.theme.colors.white} !important;
  font-weight: normal;
  text-align: center;
  margin: 0;
`;

const FriendsgivingHero = styled(Hero)`
  background-image: url(${FRIENDSGIVING_BG});
  background-size: 100%;

  ${HeroContent} {
    max-width: inherit;

    h1 {
      font-style: normal;
    }
  }

  a {
    justify-content: center;
  }

  ${(p) => p.theme.max('md')`
    background-size: cover;
  `}
`;

const ThanksgivingHero = styled(FriendsgivingHero)`
  background-image: url(${THANKSGIVING_BG});

  ${(p) => p.theme.max('md')`
    background-position: 85% 10%;
  `}
`;

const ImmunityHero = styled(Hero)`
  background-image: url(${IMMUNITY_BG});

  ${HeroContent} {
    max-width: 350px;
  }

  ${(p) => p.theme.min('sm')`
    a {
      align-items: start;
    }
  `}

  ${(p) => p.theme.max('md')`
    background-size: 200%;
    background-position: 20% 40%;
  `}
`;

const NewHero = styled(Hero)`
  background-image: url(${NEW_BG});
  background-size: 150%;
  background-position: 60% 10%;

  ${(p) => p.theme.max('md')`
    background-size: 200%;
    background-position: 25% 0%;
  `}

  a {
    justify-content: flex-start;
  }
`;

const PopularHero = styled(Hero)`
  background-image: url(${POPULAR_BG});
`;

const PromoHero = styled(Hero)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${PROMO_BG});

  h3 {
    color: ${(p) => p.theme.typography.colors.primary} !important;
  }
`;

const ReorderHero = styled(Hero)`
  background-image: url(${REORDER_BG});
`;

const GetStartedHero = styled(Hero)`
  background-image: url(${GET_STARTED_BG});
  background-size: 125%;
  background-position: 0% 25%;

  a {
    padding: 4vh 4vw;
    justify-content: flex-start;
    align-items: flex-start;
  }

  h1 {
    font-style: normal;
    text-align: left;
    max-width: 100% !important;
  }
`;

const GetStartedButton = styled(Button)`
  margin-top: 3vh;

  ${(p) => p.theme.max('sm')`
    width: 100%;
    padding-right: 2em;
    padding-left: 2em;
  `}
`;
