import React from 'react';

import useUser from 'core/hooks/useUser';

import { Text } from 'core/ui/atoms';
import AlertModal from 'core/ui/components/AlertModal';
import { goToBilling } from 'core/api/actions/navigation';

function InvalidBillingCheck() {
  const { account, loading, userCan } = useUser();
  const isCheckable = userCan.placeOrder();
  const billingIsValid = account.billingAccount.isActive();

  if (loading || !isCheckable || billingIsValid) {
    return null;
  }

  return (
    <AlertModal
      heading='Houston, we have a problem!'
      buttonText='My Account'
      onClick={goToBilling}
    >
      <Text>
        There seems to be an issue with your billing information.
        Go to account settings to update.
      </Text>
    </AlertModal>
  );
}

export default InvalidBillingCheck;
