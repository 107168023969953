import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Flex,
} from 'core/ui/atoms';

class Style extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    caption: PropTypes.string,
    children: PropTypes.node,
  };

  render() {
    const { name, caption, children, ...rest } = this.props;

    return (
      <Wrapper>
        <Tag name={name}>
          {name}
          {caption && <small> ({caption})</small>}
        </Tag>
        <Content {...rest}>
          {children}
        </Content>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 5px;
`;

const Tag = styled.a`
  position: relative;
  top: -1px;
  left: -1px;
  background: black;
  border-radius: 3px;
  color: white;
  padding: 0.4em 1em;
  display: inline-block;
`;

const Content = styled(Flex)`
  padding: 1vh 1vw;
`;

export default Style;
