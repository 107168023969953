import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ValidatorForm } from 'react-form-validator-core';

import useUser from 'core/hooks/useUser';
import useAlerts from 'core/hooks/useAlerts';
import useForm from 'core/hooks/useForm';
import useTastePreferences from 'settings/hooks/useTastePreferences';

import { SET_SPICY_PREFERENCE } from 'core/api/apollo/mutations';

import { Radio  } from 'core/ui/atoms';

import { RadioFormGroup, SubmitButton } from 'settings/ui/atoms';
import Panel from 'settings/ui/components/Panel';

function SpicyPreference() {
  const { loading, spicyPreference } = useTastePreferences();

  return (
    <Panel title='Spicy Foods' loading={loading} data-spicy-preference>
      <Form initialData={{ spicyPreference: spicyPreference  }} />
    </Panel>
  );
}

function Form(props) {
  const { initialData } = props;
  const { account } = useUser();
  const alerts = useAlerts();
  const [setSpicyPreference] = useMutation(SET_SPICY_PREFERENCE);

  const onSuccess = () => {
    let message = 'Your changes have been saved!';
    if (account.subscription.isActive()) {
      message += ' Note: this will not apply to existing orders';
    }
    alerts.show(message, { autoClose: true });
  };

  const { formData, isDirty, isProcessing, handleChange, handleSubmit } = useForm('setSpicyPreference', {
    initialData,
    onSubmit: setSpicyPreference,
    onSuccess,
  });

  const onChange = (e) => handleChange({ spicyPreference: e.target.value === '1' });

  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <RadioFormGroup>
        <Radio
          name='spicyPreference'
          value='1'
          label='Yesssss!'
          checked={formData.spicyPreference === true}
          onChange={onChange}
        />
        <Radio
          name='spicyPreference'
          value='0'
          label='Nah'
          checked={formData.spicyPreference === false}
          onChange={onChange}
        />
      </RadioFormGroup>

      <Panel.Footer>
        <SubmitButton disabled={!isDirty || isProcessing}>Apply Changes</SubmitButton>
      </Panel.Footer>
    </ValidatorForm>
  );
}

Form.propTypes = {
  initialData: PropTypes.object.isRequired,
};

export default SpicyPreference;
