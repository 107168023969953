import { useQuery } from '@apollo/client';

import { GET_SUBSCRIPTION_OPTIONS } from 'core/api/apollo/queries';

function useSubscriptionOptions() {
  const { loading, data: { getSubscriptionOptions: subscriptionOptions } = {} } = useQuery(GET_SUBSCRIPTION_OPTIONS);

  return { loading, subscriptionOptions };
}

export default useSubscriptionOptions;
