import styled from 'styled-components';

import PageBox from 'onramp/ui/components/Page/Box';

const Box = styled(PageBox)`
  text-align: left;

  padding-right: ${(p) => p.theme.layout.onramp.padding.horiz};
  padding-left: ${(p) => p.theme.layout.onramp.padding.horiz};
`;

export default Box;
