import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ValidatorForm } from 'react-form-validator-core';

import useErrorHandlers from 'core/hooks/useErrorHandlers';

import SplitPageContainer from 'core/ui/components/SplitPageContainer';
import {
  Box,
  Button,
  Container,
  Flex,
  FormGroup,
  Label,
  Header,
  Hint,
  SubHeader,
  ValidatedInput,
} from 'core/ui/atoms';
import { RESET_PASSWORD } from 'core/api/apollo/mutations';
import { ERROR_MESSAGES } from 'core/api/constants';
import { CURRENT_USER } from 'core/api/apollo/queries';
import cacheUpdater from 'core/api/cache/updaters';

const minPwdLength = 8;
const maxPwdLength = 128;

function ResetPasswordPage() {
  const { token } = useParams();
  const formDefaults = { password: '', passwordConfirmation: '' };
  const [formData, setFormData] = useState(formDefaults);
  const [isProcessing, setIsProcessing] = useState(false);
  const { displayApolloResponseErrors, handleGenericApolloError } = useErrorHandlers();

  ValidatorForm.addValidationRule('isPasswordMatch', (value) => value === formData.password);

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const resetForm = () => {
    setIsProcessing(false);
    setFormData(formDefaults);
  };

  const onError = (error) => {
    setIsProcessing(false);
    handleGenericApolloError(error);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    resetPassword().catch(resetForm);
  };

  const onComplete = ({ resetPassword: { errors } }) => {
    setIsProcessing(false);

    if (errors && errors.length) {
      displayApolloResponseErrors(errors);
      resetForm();
      return;
    }
  };

  const updateCache = cacheUpdater('resetPassword', (cache, { user }) => {
    const { currentUser } = cache.readQuery({ query: CURRENT_USER });
    cache.writeQuery({
      query: CURRENT_USER,
      data: { currentUser: { ...currentUser, ...user } },
    });
  });

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    variables: { input: { token, ...formData } },
    onError: onError,
    onCompleted: onComplete,
    update: updateCache,
  });

  return (
    <SplitPageContainer>
      <Container>
        <Flex>
          <Box md={4} mdOffset={1}>
            <WelcomeHeader>Almost there.</WelcomeHeader>
            <SubHeader>Now please enter your new password.</SubHeader>

            <Form
              onSubmit={onSubmit}
              noValidate
              instantValidate={false}
            >
              <FormGroup>
                <Label required>Password</Label>
                <ValidatedInput
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={formData.password}
                  validators={['required',
                    `minStringLength:${minPwdLength}`,
                    `maxStringLength:${maxPwdLength}`,
                  ]}
                  errorMessages={[
                    ERROR_MESSAGES.required,
                    ERROR_MESSAGES.passwordLength,
                    ERROR_MESSAGES.passwordLength,
                  ]}
                />
                <Hint>At least 8 characters</Hint>
              </FormGroup>
              <FormGroup>
                <Label required>Repeat Password</Label>
                <ValidatedInput
                  type="password"
                  name="passwordConfirmation"
                  onChange={handleChange}
                  value={formData.passwordConfirmation}
                  validators={['required', 'isPasswordMatch']}
                  errorMessages={[ERROR_MESSAGES.required, ERROR_MESSAGES.passwordMatch]}
                />
              </FormGroup>
              <ButtonCont>
                <FormGroup>
                  <Button type="submit" btn-primary btn-lg disabled={isProcessing}>
                    {isProcessing ? 'Resetting...' : 'Reset password'}
                  </Button>
                </FormGroup>
              </ButtonCont>
            </Form>
          </Box>
        </Flex>
      </Container>
    </SplitPageContainer>
  );
}

const WelcomeHeader = styled(Header)`
  margin-top: 5vh;
`;

const Form = styled(ValidatorForm)`
  display: block;
  width: 320px;
  margin: 7vh auto 3vh auto;
  > div:nth-child(1) {
    margin-bottom: 5vh;
  }
`;

const ButtonCont = styled.div`
  text-align: center;
  margin-top: 3vh;
  ${(p) => p.theme.max('sm')`
    button {
      width: 100%;
    }
  `}
`;

export default ResetPasswordPage;
