import { useQuery } from '@apollo/client';

import { GET_PRODUCT } from 'core/api/apollo/queries';

function useProduct(deliveryDayId, productId, zipcode) {
  const { loading, data: { getProduct: product } = {} } = useQuery(GET_PRODUCT, {
    variables: { productId, deliveryDayId, zipcode },
  });

  return { loading, product };
}

export default useProduct;
