import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { Button } from 'core/ui/atoms';

import { UNSKIP_ORDER } from 'core/api/apollo/mutations';
import { updateOrderCache } from 'core/api/cache/updaters';

function UnskipOrderButton(props) {
  const [unskipOrder] = useMutation(UNSKIP_ORDER, {
    update: (cache, { data: { unskipOrder: { order, errors } } }) => {
      updateOrderCache(cache, order, errors);
    },
  });
  const [isUnskipping, setIsUnskipping] = useState(false);
  const { orderId, text, ...rest } = props;

  const onClick = (e) => {
    e.preventDefault();
    setIsUnskipping(true);
    unskipOrder({ variables: { input: { orderId: orderId } } });
  };

  return (
    <Button onClick={onClick} disabled={isUnskipping} {...rest}>
      {text || 'Unskip'}
    </Button>
  );
}

UnskipOrderButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default UnskipOrderButton;
