import React from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import styled from 'styled-components';

import { isLoading } from 'core/api/loadingHelpers';
import { compareDates } from 'core/ui/dateHelpers';

import { Container } from 'core/ui/atoms';
import Loading from 'core/ui/components/Loading';
import DeliveryPeriod from 'dashboard/ui/components/OrdersPage/DeliveryPeriod';

import { LIST_DELIVERY_PERIODS } from 'core/api/apollo/queries';

function DeliveryPeriods() {
  const variables = {
    startDate: moment().startOf('isoWeek'),
    endDate: moment().add(3, 'weeks').endOf('isoWeek'),
  };
  const {
    loading, error, data: { listDeliveryPeriods = [] } = {},
  } = useQuery(LIST_DELIVERY_PERIODS, { variables });

  if (isLoading(loading, listDeliveryPeriods, error)) {
    return <Loading />;
  }

  const periods = [...listDeliveryPeriods].sort((a,b) => compareDates(a.startDate, b.startDate));

  const currentPeriods = periods.filter((period) => period.locked);
  const upcomingPeriods = periods.filter((period) => !period.locked);

  return (
    <div>
      <AtHomePeriods data-at-home>
        <Container>
          {
            currentPeriods.map((period) =>
              <PeriodWrapper key={period.id}>
                <DeliveryPeriod deliveryPeriod={period} />
              </PeriodWrapper>,
            )
          }
        </Container>
      </AtHomePeriods>

      <UpcomingPeriods data-upcoming>
        <Container>
          {
            upcomingPeriods.map((period) =>
              <PeriodWrapper key={period.id}>
                <DeliveryPeriod deliveryPeriod={period} />
              </PeriodWrapper>,
            )
          }
        </Container>
      </UpcomingPeriods>
    </div>
  );
}

const PeriodsSection = styled.section`
  position: relative;
  padding-top: 30px;
`;

const AtHomePeriods = styled(PeriodsSection)`
  background-color: ${(p) => p.theme.colors.white};

  ${(p) => p.theme.max('xs')`
    padding-top: 0px;
  `}
`;

const UpcomingPeriods = styled(PeriodsSection)`
  padding-bottom: 30px;
  background-color: #F3F3F3; // FIXME: move to theme
  border-top: ${(p) => p.theme.borders.width} solid #979797;
`;

const PeriodWrapper = styled.div`
  margin-bottom: 60px;
`;

export default DeliveryPeriods;
