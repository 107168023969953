import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { ValidatorForm } from 'react-form-validator-core';
import _pick from 'lodash/pick';

import useAlerts from 'core/hooks/useAlerts';
import useForm from 'core/hooks/useForm';

import { Checkbox as CheckboxAtom, Table, Text } from 'core/ui/atoms';
import { SettingsHeading, SubmitButton } from 'settings/ui/atoms';
import Panel from 'settings/ui/components/Panel';

import { GET_NOTIFICATION_PREFERENCES } from 'core/api/apollo/queries';
import { SET_NOTIFICATION_PREFERENCES } from 'core/api/apollo/mutations';

const FIELDS = [
  'receiveNewMenuReadyEmails',
  'receiveNewMenuReadySms',
  'receiveMenuReminderEmails',
  'receiveMenuReminderSms',
  'receiveRateFoodEmails',
  'receiveWellnessNewsletterEmails',
  'receiveWellnessNewsletterSms',
  'receiveDeliverySms',
];

function NotificationSettings(props) {
  const alerts = useAlerts();
  const { loading, data: { getNotificationPreferences } = {} } = useQuery(GET_NOTIFICATION_PREFERENCES);

  const onSuccess = () => alerts.show('Your changes have been saved!', { autoClose: true });

  return (
    <Panel title='Notifications' loading={loading} data-notifications {...props}>
      <Text no-margin>
        Beyond the notifications below, we may still email or text you about your billing, order or delivery
      </Text>

      <NotificationSettingsForm
        initialData={_pick(getNotificationPreferences, FIELDS)}
        onSuccess={onSuccess}
      />
    </Panel>
  );
}

function NotificationSettingsForm(props) {
  const { initialData, onSuccess } = props;
  const [setNotificationPreferences] = useMutation(SET_NOTIFICATION_PREFERENCES);
  const { handleSubmit, formData, handleChange, isDirty, isProcessing } = useForm('setNotificationPreferences', {
    initialData,
    onSubmit: setNotificationPreferences,
    onSuccess,
  });

  const onChange = (e) => handleChange({ [e.target.name]: e.target.checked });

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      noValidate
      instantValidate={false}
    >
      <Table>
        <thead>
          <Table.Row>
            <Table.Header/>
            <Table.Header text-align='center'>
              <SettingsHeading no-margin>Email</SettingsHeading>
            </Table.Header>
            <Table.Header text-align='center'>
             <SettingsHeading no-margin>SMS/Text</SettingsHeading>
            </Table.Header>
          </Table.Row>
        </thead>
        <tbody>
          <Table.Row>
            <Table.Cell>New menu is ready</Table.Cell>
            <Table.Cell text-align='center'>
              <Checkbox
                name="receiveNewMenuReadyEmails"
                checked={formData.receiveNewMenuReadyEmails}
                onChange={onChange}
              />
            </Table.Cell>
            <Table.Cell text-align='center'>
              <Checkbox
                name="receiveNewMenuReadySms"
                checked={formData.receiveNewMenuReadySms}
                onChange={onChange}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Last day to edit or pause</Table.Cell>
            <Table.Cell text-align='center'>
              <Checkbox
                name="receiveMenuReminderEmails"
                checked={formData.receiveMenuReminderEmails}
                onChange={onChange}
              />
            </Table.Cell>
            <Table.Cell text-align='center'>
              <Checkbox
                name="receiveMenuReminderSms"
                checked={formData.receiveMenuReminderSms}
                onChange={onChange}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Cooking and product tips</Table.Cell>
            <Table.Cell text-align='center'>
              <Checkbox
                name="receiveWellnessNewsletterEmails"
                checked={formData.receiveWellnessNewsletterEmails}
                onChange={onChange}
              />
            </Table.Cell>
            <Table.Cell text-align='center'>
              <Checkbox
                name="receiveWellnessNewsletterSms"
                checked={formData.receiveWellnessNewsletterSms}
                onChange={onChange}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Delivery notification</Table.Cell>
            <Table.Cell/>
            <Table.Cell text-align='center'>
              <Checkbox
                name="receiveDeliverySms"
                checked={formData.receiveDeliverySms}
                onChange={onChange}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Rate food</Table.Cell>
            <Table.Cell text-align='center'>
              <Checkbox
                name="receiveRateFoodEmails"
                checked={formData.receiveRateFoodEmails}
                onChange={onChange}
              />
            </Table.Cell>
            <Table.Cell/>
          </Table.Row>
        </tbody>
      </Table>

      <Panel.Footer>
        <SubmitButton disabled={!isDirty || isProcessing} >Apply Changes</SubmitButton>
      </Panel.Footer>
    </ValidatorForm>
  );
}

NotificationSettingsForm.propTypes = {
  initialData: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

const Checkbox = styled(CheckboxAtom)`
  justify-content: center;
`;

export default NotificationSettings;
