import React from 'react';
import { useQuery } from '@apollo/client';
import { Redirect, useLocation } from 'react-router-dom';
import moment from 'moment';
import firstItem from 'lodash/first';

import LoadingScreen from 'core/ui/components/LoadingScreen';
import { compareDates, displayDate } from 'core/ui/dateHelpers';
import { menuPath } from 'core/api/actions/navigation';
import { LIST_DELIVERY_PERIODS } from 'core/api/apollo/queries';

function NextMenuPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isShipping = searchParams.get('delivery');
  const zipcode = searchParams.get('zipcode');

  const queryParams = isShipping ? { delivery: isShipping, zipcode  } : {};


  const { loading, data: { listDeliveryPeriods } = {} } = useQuery(LIST_DELIVERY_PERIODS, {
    variables: {
      startDate: displayDate(moment().startOf('isoWeek'), 'isoShort'),
      endDate: displayDate(moment().add(2, 'weeks'), 'isoShort'),
    },
  });

  if (loading) {
    return <LoadingScreen />;
  }

  const days = listDeliveryPeriods.map((period) => period.days).flatten().sort((a,b) => compareDates(a.date, b.date));
  const day = firstItem(days.filter((day) => !day.locked && !day.closed));

  if (!day) {
    return null;
  }

  return <Redirect to={menuPath(day.id, queryParams)} />;
}

export default NextMenuPage;
