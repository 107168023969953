import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ValidatorForm } from 'react-form-validator-core';

import useAlerts from 'core/hooks/useAlerts';
import useForm from 'core/hooks/useForm';
import useUser from 'core/hooks/useUser';

import { formatPhoneNumber } from 'core/api/utils';
import { PHONE_REGEXP, ERROR_MESSAGES } from 'core/api/constants';
import { SET_ACCOUNT_INFO } from 'core/api/apollo/mutations';

import { Flex, FormGroup, Label, ValidatedInput } from 'core/ui/atoms';
import { EditButton, SubmitButton } from 'settings/ui/atoms';
import Modal from 'settings/ui/components/Modal';
import Panel from 'settings/ui/components/Panel';

function Account(props) {
  const { currentUser } = useUser();
  const alerts = useAlerts();
  const [modalOpen, setModalOpen] = useState(false);

  const { firstName, lastName, email, phoneNumber } = currentUser;

  const openModal = () => setModalOpen(true);

  const closeModal = () => setModalOpen(false);

  const onSuccess = () => {
    closeModal();
    alerts.show('Your changes have been saved!', { autoClose: true });
  };

  return (
    <Flex data-account {...props}>
      {firstName} {lastName}
      <br/>
      {email}
      <br/>
      {formatPhoneNumber(phoneNumber)}
      <br/>

      <EditButton onClick={openModal} />
      { modalOpen && (
        <Modal title='Account Info' data-account-info-modal onClose={closeModal}>
          <AccountInfoForm
            initialData={{ firstName, lastName, email, phoneNumber }}
            onSuccess={onSuccess}
          />
        </Modal>
      )}
    </Flex>
  );
}

function AccountInfoForm(props) {
  const { initialData, onSuccess } = props;
  const [setAccountInfo] = useMutation(SET_ACCOUNT_INFO);
  const { handleSubmit, formData, onChange, isDirty, isProcessing } = useForm('setAccountInfo', {
    initialData,
    onSubmit: setAccountInfo,
    onSuccess,
    validator: ({ firstName, lastName, email, phoneNumber }) => (firstName && lastName && email && phoneNumber),
  });

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      noValidate
      instantValidate={false}
    >
      <FormGroup>
        <Label required>First Name</Label>
        <ValidatedInput
          type="text"
          name="firstName"
          placeholder="Type first name"
          value={formData.firstName}
          onChange={onChange}
          validators={['required']}
          errorMessages={[ERROR_MESSAGES.required]}
        />
      </FormGroup>

      <FormGroup>
        <Label required>Last Name</Label>
        <ValidatedInput
          type="text"
          name="lastName"
          placeholder="Type last name"
          value={formData.lastName}
          onChange={onChange}
          validators={['required']}
          errorMessages={[ERROR_MESSAGES.required]}
        />
      </FormGroup>

      <FormGroup>
        <Label required>Email Address</Label>
        <ValidatedInput
          type="email"
          placeholder="Type email address"
          name="email"
          value={formData.email}
          onChange={onChange}
          validators={['required', 'isEmail']}
          errorMessages={[ERROR_MESSAGES.required, ERROR_MESSAGES.email]}
        />
      </FormGroup>

      <FormGroup>
        <Label required>Delivery Phone</Label>
        <ValidatedInput
          name="phoneNumber"
          onChange={onChange}
          value={formatPhoneNumber(formData.phoneNumber) || formData.phoneNumber}
          type="tel"
          placeholder="ex. (123) 456-7890"
          validators={['required', `matchRegexp:${PHONE_REGEXP}`]}
          errorMessages={[ERROR_MESSAGES.required, ERROR_MESSAGES.phone]}
        />
      </FormGroup>

      <Panel.Footer>
        <SubmitButton disabled={!isDirty || isProcessing}>
          Apply Changes
        </SubmitButton>
      </Panel.Footer>
    </ValidatorForm>
  );
}

AccountInfoForm.propTypes = {
  initialData: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

export default Account;
