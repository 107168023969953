import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ValidatorForm } from 'react-form-validator-core';

import useAlerts from 'core/hooks/useAlerts';
import useForm from 'core/hooks/useForm';
import useTastePreferences from 'settings/hooks/useTastePreferences';
import useUser from 'core/hooks/useUser';

import { SET_FOODS_TO_AVOID } from 'core/api/apollo/mutations';

import { Text  } from 'core/ui/atoms';
import FoodsToAvoidInput from 'core/ui/components/FoodsToAvoidInput';

import { SubmitButton } from 'settings/ui/atoms';
import Panel from 'settings/ui/components/Panel';

function FoodsToAvoid() {
  const { account } = useUser();
  const alerts = useAlerts();
  const { loading, foodsToAvoid } = useTastePreferences();

  const onSuccess = () => {
    let message = 'Your changes have been saved!';
    if (account.subscription.isActive()) {
      message += ' Note: this will not apply to existing orders';
    }
    alerts.show(message, { autoClose: true });
  };

  return (
    <Panel title='Foods to avoid' loading={loading} data-foods-to-avoid>
      <FoodstoAvoidForm
        initialData={{ foodsToAvoidIds: foodsToAvoid.map((tag) => tag.id).sort() }}
        onSuccess={onSuccess}
      />
    </Panel>
  );
}

function FoodstoAvoidForm(props) {
  const { initialData, onSuccess } = props;
  const [setFoodsToAvoid] = useMutation(SET_FOODS_TO_AVOID);
  const { handleSubmit, formData, handleChange, isDirty, isProcessing } = useForm('setFoodsToAvoid', {
    initialData,
    onSubmit: setFoodsToAvoid,
    onSuccess,
  });

  const onChange = (tagIds) => handleChange({ foodsToAvoidIds: tagIds.sort() });

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      noValidate
      instantValidate={false}
    >
      <Text>We won't assign any items that contain your Foods to Avoid.</Text>

      <FoodsToAvoidInput
        initialFoodsToAvoidIds={formData.foodsToAvoidIds}
        placeholder='Search Ingredients...'
        onChange={onChange}
      />

      <Panel.Footer>
        <SubmitButton disabled={!isDirty || isProcessing} >Apply Changes</SubmitButton>
      </Panel.Footer>
    </ValidatorForm>
  );
}

FoodstoAvoidForm.propTypes = {
  initialData: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

export default FoodsToAvoid;
