import React from 'react';

import { SettingsGrid } from 'settings/ui/atoms';
import SettingsPage from 'settings/ui/components/SettingsPage';
import { PaymentInfo, AvailableCredits, InvoiceList } from 'settings/ui/components/BillingInfo';

function BillingPage() {
  return (
    <SettingsPage scrollTop>
      <SettingsGrid template='1.5fr 1fr 2fr'>
        <PaymentInfo />
        <AvailableCredits />
        <InvoiceList />
      </SettingsGrid>
    </SettingsPage>
  );
}

export default BillingPage;
