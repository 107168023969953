import { useContext } from 'react';
import { ReferralCodeContext } from 'core/api/referralCodeContext';


function useReferralCode() {
  const context = useContext(ReferralCodeContext);

  if (context === undefined) {
    throw new Error('useReferralCode was used outside of its Provider');
  }

  return context;
}

export default useReferralCode;
