import React from 'react';
import styled from 'styled-components';

import {
  Flex,
  H1,
  H4,
} from 'core/ui/atoms';

import BACKGROUND_IMAGE from 'dashboard/assets/images/deliveriesBackground.jpg';

function Hero() {
  return (
    <Wrapper direction='column' alignItems='center'>
      <MainHeader>Orders</MainHeader>
      <SubHeader>Your current and upcoming orders</SubHeader>
    </Wrapper>
  );
}

const Wrapper = styled(Flex)`
  padding: 50px;
  text-align: center;
  color: white;
  background-image: url(${BACKGROUND_IMAGE});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${(p) => p.theme.max('xs')`
    background-image: none;
    text-align: left;
    align-items: flex-start;
    padding: 20px;
  `}
`;

const MainHeader = styled(H1)`
  font-size: 1.125em;
  ${(p) => p.theme.min('xs')`
    font-size: 2.625em;
    color: ${(p) => p.theme.colors.white} !important;
  `}
  margin: 0;
`;

const SubHeader = styled(H4)`
  font-weight: normal;
  font-size: 0.75em;
  ${(p) => p.theme.min('xs')`
    font-size: 1.5em;
    color: ${(p) => p.theme.colors.white} !important;
  `}
  margin: 0;
`;

export default Hero;
