import styled from 'styled-components';
import NavItem from 'core/ui/atoms/NavItem';

const MobileNavItem = styled(NavItem)`
  display: block;
  text-decoration: none;
  padding: 0.1em 0.5em !important;
  margin-right: 2em;
  font-size: ${(p) => p.theme.typography.sizes.h1};
  color: rgba(255, 255, 255, 0.8);
  &.active {
    color: rgba(255, 255, 255, 0.8);
    border-bottom: none;
  }
`;

export default MobileNavItem;
