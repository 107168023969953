import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Money from 'core/api/Money';
import { GET_PREPAID_PURCHASE } from 'core/api/apollo/queries';

import { compareDates, displayDate } from 'core/ui/dateHelpers';
import { displayDiscount } from 'core/ui/discountHelpers';
import { H4 } from 'core/ui/atoms';
import { Loading } from 'core/ui/components';

import PromoCodeInput from 'onramp/ui/components/PromoCodeInput';

// function PromoLululemon(coupon,discount) {
//   return (<TableRow>
//     <td>
//       Promo <strong>"LULULEMON"</strong> ({promoMessage(coupon)})
//     </td>
//     <td data-prepaid-discount>
//       <strong>-{Money(discount).toFormat()}</strong>
//     </td>
//   </TableRow>);
// }

function promoMessage(coupon) {
  if (coupon.durationType === 'LIMITED_TO_WEEK')
    return `${displayDiscount(coupon)} off first ${
      coupon.applicableOnWeek <= 1 ? '1 week' : coupon.applicableOnWeek + ' weeks'
    } `;

  return ` ${displayDiscount(coupon)} off `;
}

function PrepaidPurchaseSummary(props) {
  const { enablePromoCodeForm } = props;
  const { loading, data: { getPrepaidPurchase: purchase = {} } = {}, refetch } = useQuery(GET_PREPAID_PURCHASE, { fetchPolicy: 'network-only' });

  if (loading) {
    return <Loading />;
  }

  if (!purchase) {
    return null;
  }

  const { deliveryPeriods, bundleReservations, deliveryReservations, coupon, discount, total } = purchase;

  const startDates = [...deliveryPeriods].sort((a, b) => compareDates(a.startDate, b.startDate)).map((p) => p.startDate);
  const weeks = startDates.map((date) => {
    const bundle = bundleReservations.find((r) => r.deliveryPeriod.startDate === date) || {};
    const delivery = deliveryReservations.find((r) => r.deliveryPeriod.startDate === date) || {};
    const { deliveryFee, serviceFee } = delivery;
    return { date, bundle, deliveryFee, serviceFee };
  });

  const promoCodeApplied = () => refetch();

  return (
    <div data-purchase>
      <H4 text-center>Purchase Summary</H4>

      <Table>
        <tbody>
          {weeks.map((week, i) => (
            <Fragment key={`week-${i}`}>
              <TableRow>
                <td data-prepaid-date={i}>
                  <strong>Week of {displayDate(week.date, 'dddd, M/D')}</strong>
                </td>
                <td></td>
              </TableRow>
              <TableRow detail={true}>
                <td data-prepaid-bundle-name={i}>{week.bundle.description}</td>
                <td data-prepaid-bundle-price={i}>{Money(week.bundle.price).toFormat()}</td>
                <td></td>
              </TableRow>
              <TableRow detail={true}>
                <td>Delivery Fee</td>
                <td data-prepaid-delivery-fee={i}>
                  {Money(week.deliveryFee).toFormat()} per delivery
                </td>
                <td></td>
              </TableRow>
              <TableRow detail={true} serviceFee={true}>
                <td>Service Fee</td>
                <td data-prepaid-service-fee={i}>
                  {Money(week.serviceFee).toFormat()} per delivery
                </td>
                <td></td>
              </TableRow>
            </Fragment>
          ))}
          <Fragment>
            {coupon &&
              (<TableRow>
                <td>
                  Promo <strong>{coupon.code}</strong> ({promoMessage(coupon)})
                </td>
                <td data-prepaid-discount>
                  <strong>-{Money(discount).toFormat()}</strong>
                </td>
              </TableRow>)
              }
            <TableRow total={true}>
              <td>
                <strong>Total Charge</strong>
              </td>
              <td data-prepaid-total>
                <strong>{Money(total).toFormat()}</strong>
              </td>
            </TableRow>
          </Fragment>
        </tbody>
      </Table>
      {enablePromoCodeForm && (
        <PromoCodeForm>
          <PromoCodeInput onSuccess={promoCodeApplied} />
        </PromoCodeForm>
      )}
    </div>
  );
}

PrepaidPurchaseSummary.propTypes = {
  enablePromoCodeForm: PropTypes.bool.isRequired,
};

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;

const TableRow = styled.tr`
  td {
    font-size: ${(p) => (p.detail ? p.theme.typography.sizes.small : 'inherit')};
    text-align: left;
  }

  ${(p) => p.serviceFee && css`
      td {
        padding-bottom: 1em;
      }
    `}

  ${(p) => p.total && css`
      td {
        border-top: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.borders.color};
        padding-top: 0.75em;
      }
    `}
`;

const PromoCodeForm = styled.div`
  margin-top: 3vh;
`;

export default PrepaidPurchaseSummary;
