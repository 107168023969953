import styled, { css } from 'styled-components';

const TagsList = styled.ul`
  display: flex;
  padding: 20px;
  min-height: 12vh;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  border: 2px dashed transparent;
  border-color: ${(p) => p.theme.colors.grays.light};
  border-radius: 0.5em;
  align-items: flex-start;
  text-transform: capitalize;

  ${(p) => p.empty && css`
    position: relative;
    :after {
      content: 'None Selected';
      color: ${(p) => p.theme.typography.colors.muted};
      position: absolute;
      top: 1vh;
      left: 1vh;
    }
  `}
`;

export default TagsList;
