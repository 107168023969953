import React, { useMemo } from 'react';
import styled from 'styled-components';
import _compact from 'lodash/compact';

import { HELP_EMAIL, ADDRESS_CHANGE_EMAIL_BODY, ADDRESS_CHANGE_EMAIL_SUBJECT } from 'core/api/constants';

import useUser from 'core/hooks/useUser';

import { Address, H6, Link, Text } from 'core/ui/atoms';
import Panel from 'settings/ui/components/Panel';

function ShippingAddress(props) {
  const { currentUser } = useUser();

  return useMemo(() => {
    const { primaryAddress: { street1, street2, city, state, zipCode, deliveryOption } } = currentUser;
    const emailLink = `mailto:${HELP_EMAIL}?body=${ADDRESS_CHANGE_EMAIL_BODY}&subject=${ADDRESS_CHANGE_EMAIL_SUBJECT}`;

    return (
      <Panel title='Shipping Address' data-shipping-address {...props}>
        <Address>
          <Text no-margin>{street1}</Text>
          {street2 && <Text no-margin>{street2}</Text>}
          <Text no-margin>{[_compact([city, state]).join(', '), zipCode].join(' ')}</Text>
        </Address>

        { deliveryOption && (
          <DeliveryWindow>
            <H6 text-color='muted' no-margin>Delivery Window</H6>
            <Text>{deliveryOption.name}</Text>
          </DeliveryWindow>
        )}

        <Caveat text-color='muted' text-size='small'>
          To change shipping address, email <Link href={emailLink} target='_blank'>{HELP_EMAIL}</Link>
        </Caveat>
      </Panel>
    );
  }, [currentUser, props]);
}

const DeliveryWindow = styled.div`
  margin-top: 2em;
`;

const Caveat = styled(Text)`
  margin-top: auto;
  margin-bottom: 0;
`;

export default ShippingAddress;
