import { css } from 'styled-components';

import BOLD from 'core/assets/fonts/Larken/Larken-ExtraBold.otf';
import BOLD_ITALIC from 'core/assets/fonts/Larken/Larken-ExtraBoldItalic.otf';
import REGULAR from 'core/assets/fonts/Larken/Larken.otf';
import REGULAR_ITALIC from 'core/assets/fonts/Larken/Larken-Italic.otf';
import LIGHT from 'core/assets/fonts/Larken/Larken-Light.otf';
import LIGHT_ITALIC from 'core/assets/fonts/Larken/Larken-LightItalic.otf';

export default css`
  @font-face {
    font-family: 'Larken';
    src: url(${BOLD});
    font-weight: bold 700 800 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Larken';
    src: url(${BOLD_ITALIC});
    font-weight: bold 700 800 900;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Larken';
    src: url(${REGULAR});
    font-weight: normal 400 500 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Larken';
    src: url(${REGULAR_ITALIC});
    font-weight: normal 400 500 600;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Larken';
    src: url(${LIGHT});
    font-weight: 300 200 100;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Larken';
    src: url(${LIGHT_ITALIC});
    font-weight: 300 200 100;
    font-style: italic;
    font-display: swap;
  }
`;
