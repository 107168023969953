import React, { PureComponent } from 'react';

import { Style, StyleGrid, StyleSection } from 'admin/ui/atoms';
import { Icon } from 'core/ui/atoms';
import { BoxIcon, BoxCrossedIcon, BoxOpenedIcon } from 'dashboard/ui/atoms';

const ICONS = [
  'star',
  'arrow_l',
  'arrow_r',
  'caret_down',
  'caret_l',
  'caret_r',
  'check',
  'close',
  'error',
  'instagram',
  'largecaret_r',
  'largecaret_l',
  'minus',
  'pencil',
  'plus',
  'alert',
  'locked',
  'calendar',
  'play3',
  'filter',
  'search',
  'gift',
];

class Icons extends PureComponent {
  render() {
    return (
      <StyleSection title='Icons'>
       <StyleGrid cols='repeat(6, 1fr)'>
          {
            ICONS.map((icon) => (
              <Style name={icon} key={icon}>
                <Icon icon={icon} style={{ fontSize: '2em' }} />
              </Style>
            ))
          }
        </StyleGrid>

        <StyleGrid cols='repeat(6, 1fr)'>
          <Style name='Box' caption='open'>
            <BoxOpenedIcon />
          </Style>

          <Style name='Box' caption='locked'>
            <BoxIcon />
          </Style>

          <Style name='Box' caption='closed'>
            <BoxCrossedIcon />
          </Style>
        </StyleGrid>
      </StyleSection>
    );
  }
}

export default Icons;
