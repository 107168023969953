import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from 'onramp/ui/components/Page/Content/Box';

class PageContent extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <CustomBox {...this.props}>
        {this.props.children}
      </CustomBox>
    );
  }
}

const CustomBox = styled(Box)`
  background-color: ${(p) => p.theme.colors.white};

  ${(p) => p.theme.max('md')`
    background-color: transparent;
  `};

  ${(p) => p.theme.max('sm')`
    padding-top: 0;
  `};
`;

export default PageContent;
