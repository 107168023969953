import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Flex as FlexAtom,
  Text as TextAtom,
 } from 'core/ui/atoms';

function DeliveryDayStatus(props) {
  const { title, children, ...rest } = props;

  return (
    <Flex alignItems="center" justifyContent="center" direction='column' {...rest}>
      <Text>{title}</Text>
      {children}
    </Flex>
  );
}

DeliveryDayStatus.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const Flex = styled(FlexAtom)`
  min-height: 300px;
`;

const Text = styled(TextAtom)`
  text-align: center;
  font-weight: bold;
  margin-top: 0;
  font-size: ${(p) => p.theme.typography.sizes.h1};
  width: 450px;
  ${(p) => p.theme.max('sm')`
    width: 90%;
  `}
`;

export default DeliveryDayStatus;
