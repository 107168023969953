import _merge from 'lodash/merge';
import { reportError } from 'core/api/errors';

export const readFragment = (cache, { fragment, fragmentName, id, typename }) => {
  try {
    return cache.readFragment({
      id: cache.identify({ __typename: typename, id }),
      fragment,
      fragmentName,
      variables: { id },
    });
  } catch(ex) {
    reportError(ex);
  }
};

export const updateFragment = (cache, { fragment, fragmentName, id, typename, data }) => {
  try {
    const existingData = readFragment(cache, { fragment, fragmentName, id, typename });
    cache.writeFragment({
      fragment,
      fragmentName,
      id: cache.identify({ __typename: typename, id }),
      data: _merge({}, existingData, data),
    });
  } catch(ex) {
    reportError(ex);
  }
};

export const updateQueryCache = (cache, { query, variables, data }, errors = null) => {
  if (errors && errors.length) { return; }
  try {
    const existingData = cache.readQuery({ query, variables });
    cache.writeQuery({
      query,
      variables,
      data: _merge({}, existingData, data),
    });
  } catch(ex) {
    reportError(ex);
  }
};
