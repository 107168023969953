import { useQuery } from '@apollo/client';

import { GET_DELIVERY_DAY } from 'core/api/apollo/queries';

function useDeliveryDay(deliveryDayId) {
  const { loading, data: { getDeliveryDay: deliveryDay } = {} } = useQuery(GET_DELIVERY_DAY, {
    variables: { deliveryDayId },
    skip: !deliveryDayId,
  });

  return { loading, deliveryDay };
}

export default useDeliveryDay;
