import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Container,
  H1,
} from 'core/ui/atoms';

class StyleSection extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
  };

  render() {
    const { title, children, ...rest } = this.props;

    return (
      <Container style={{ marginBottom: '5vh' }} {...rest}>
        <StyleHeader>{title}</StyleHeader>
        {children}
      </Container>
    );
  }
}

const StyleHeader = styled(H1)`
  border-bottom: 1px solid ${(p) => p.theme.colors.grays.regular};
`;

export default StyleSection;
