import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import useUser from 'core/hooks/useUser';

import { GET_REFERRAL_MODAL_OPENED } from 'core/api/apollo/queries';
import Money from 'core/api/Money';
import { displayDiscount } from 'core/ui/discountHelpers';
import { isReferralModalOpenVar } from 'core/api/apollo/cache';

import { H2, Text as TextAtom } from 'core/ui/atoms';
import SplitPanelModal from 'core/ui/components/SplitPanelModal';
import ReferralActions from 'dashboard/ui/components/InviteFriends/Actions';

import MODAL_IMAGE from 'dashboard/assets/images/RateFood_Intro.png';

function InviteFriendsModal() {
  const { data: { isReferralModalOpen } } = useQuery(GET_REFERRAL_MODAL_OPENED);

  const onClose = () => isReferralModalOpenVar(false);

  if (!isReferralModalOpen) {
    return null;
  }

  return (
    <SplitPanelModal onClose={onClose} image={MODAL_IMAGE}>
      <Content />
    </SplitPanelModal>
  );
}

function Content() {
  const { currentUser: { referralCoupon } = {} } = useUser();

  if (!referralCoupon) { return null; }

  return (
    <ContentContainer>
      <ModalTitle>
        Gift your friends {displayDiscount(referralCoupon)} off their first purchase
      </ModalTitle>
      <Text huge>
        They'll love you for it, and you'll get a {Money(referralCoupon.creditAmount).toFormat('$0,0')} credit each time your personal referral code is redeemed.
      </Text>
      <ReferralActions referralCoupon={referralCoupon} />
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  padding: 50px;
  ${(p) => p.theme.max('sm')`
    padding: 20px;
  `}
`;

const ModalTitle = styled(H2)`
  margin-top: 10px;
  margin-right: 25%;
  ${(p) => p.theme.max('sm')`
    margin-top: 0;
    font-size: 1.375em;
  `}
`;

const Text = styled(TextAtom)`
  margin-top: 0;
`;

export default InviteFriendsModal;
