import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from 'core/ui/theme';

function BoxCrossedIcon(props) {
  const { colors: { secondary: fill, black: stroke } } = theme;

  return (
    <SVG
      className={props.className}
      height="2.5em"
      viewBox="0 0 39 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="LoggedIn-XP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Menus" transform="translate(-839.000000, -556.000000)">
          <g id="Menu-2" transform="translate(-1.000000, 545.000000)">
            <g id="Box-Full">
              <g transform="translate(841.000000, 11.000000)">
                <polygon id="Stroke-5" fill={fill} points="16.4805443 23.2306985 32.9612226 17.5596674 32.9612226 10.3941181 16.4805443 15.930326"></polygon>
                <polygon id="Stroke-11" fill={fill} points="16.4805443 23.2306985 -0.00013391304 17.5596674 -0.00013391304 10.2285907 16.4805443 15.8440129"></polygon>
                <polygon id="Stroke-8" fill={fill} points="8.02527478 13.0127319 23.9944052 7.51928511 16.506422 5.07481971 0.689017918 10.1005859"></polygon>
                <polygon id="Stroke-8" fill={fill} points="8.02527478 13.0127319 23.9944052 7.51928511 32.6817043 10.2660085 16.5481063 15.8089694"></polygon>
                <polygon id="Stroke-1" fill={fill} stroke={stroke} strokeWidth="1.67999987" strokeLinecap="round" strokeLinejoin="round" points="32.9613565 29.2549538 16.4806783 34.9259849 16.4806783 15.9283548 32.9613565 10.2573237"></polygon>
                <path d="M8.02527478,13.0127319 L23.9944052,7.51928511" id="Stroke-8" stroke={stroke} strokeWidth="1.67999987" strokeLinecap="round" strokeLinejoin="round"></path>
                <polygon id="Stroke-9" fill={fill} stroke={stroke} strokeWidth="1.67999987" strokeLinecap="round" strokeLinejoin="round" points="16.4805443 15.927966 -0.00013391304 10.2569349 -0.00013391304 29.254565 16.4805443 34.9255961"></polygon>
                <polyline id="Stroke-13" stroke={stroke} strokeWidth="1.67999987" strokeLinecap="round" strokeLinejoin="round" points="0.000267826083 10.2569349 16.4809461 5.18606088 32.9616243 10.2569349"></polyline>
                <path d="M21.9416522,29.6055856 L29.7621739,26.9146003" id="Stroke-16" stroke={stroke} strokeWidth="1.67999987" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M23.9941374,25.7978933 L29.7631113,23.8133565" id="Stroke-17" stroke={stroke} strokeWidth="1.67999987" strokeLinecap="round" strokeLinejoin="round"></path>
                <rect id="Rectangle-11" fill={stroke} transform="translate(18.853553, 18.853553) rotate(-45.000000) translate(-18.853553, -18.853553) " x="17.8535534" y="-6.80939751" width="2" height="51.3259018" rx="1"></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SVG>
  );
}

BoxCrossedIcon.propTypes = {
  className: PropTypes.string,
};

const SVG = styled.svg`
  margin-top: 0.15em;
`;

export default BoxCrossedIcon;
