import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AspectRatio, Text } from 'core/ui/atoms';

function PaymentCard(props) {
  const { billingInfo = {} } = props;
  const { last4, expMonth, expYear } = (billingInfo || {});

  if (!last4) {
    return <Text data-card-none>None</Text>;
  }

  return (
    <CardWrapper aspectHeight={54} aspectWidth={86}>
      <Card>
        <Title>Your Current Card</Title>
        <CardNumber data-card-number>**** **** **** {last4}</CardNumber>
        <CardExpiry>
          <label>Exp. Date</label> <span data-card-expiry>{expMonth}/{expYear}</span>
        </CardExpiry>
      </Card>
    </CardWrapper>
  );
}

PaymentCard.propTypes = {
  billingInfo: PropTypes.object,
};

const CardWrapper = styled(AspectRatio)`
  max-width: 86mm;
  max-height: 54mm;
  margin-bottom: 3vh;
`;

const Card = styled.div`
  width: 100%;
  background: linear-gradient(83.52deg, #36443A -26.57%, #636F7A 102.71%);
  border-radius: 1em;
  color: ${(p) => p.theme.typography.colors.inverse} !important;
  padding: 1em 2em 2em 1em;
`;

const CardText = styled(Text)`
  color: ${(p) => p.theme.typography.colors.inverse};
`;

const Title = styled(CardText)`
  font-size: ${(p) => p.theme.typography.sizes.smallish};
  text-transform: uppercase;
  margin-top: 0;
`;

const CardNumber = styled(CardText)`
  font-size: ${(p) => p.theme.typography.sizes.h4};
  text-align: right;
  letter-spacing: 2px;
  margin: 1.5em 0;
`;

const CardExpiry = styled(CardText)`
  margin-bottom: 0;
  text-align: right;

  label {
    font-size: ${(p) => p.theme.typography.sizes.smallish};
    font-weight: normal;
    display: inline-block;
    margin-right: 1em;
  }

  span {
    font-size: ${(p) => p.theme.typography.sizes.smaller};
  }
`;

export default PaymentCard;
