import { css } from 'styled-components';

import Larken from './Larken';
import Poppins from './Poppins';
import Albra from './Albra';
import HelveticaNeue from './HelveticaNeue';
import icomoon from './icomoon';

export default css`
  ${Larken}
  ${Poppins}
  ${Albra}
  ${HelveticaNeue}
  ${icomoon}
`;
