import React from 'react';
import styled from 'styled-components';

import { Text } from 'core/ui/atoms';
import Image from 'core/ui/atoms/Image';
import IMAGE from 'dashboard/assets/images/noResults.svg';

function NoResults(props) {
  return (
    <Wrapper {...props}>
      <Text text-bold align='center' no-margin>There are no products in this category that meet your filter criteria.</Text>
      <NoResultsImage src={IMAGE} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 5vh;
`;

const NoResultsImage = styled(Image)`
  ${(p) => p.theme.min('sm')`
    margin: auto;
    width: 50%;
  `}
  margin-top: -5vh !important;
`;

export default NoResults;
