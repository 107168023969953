import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Loading from 'core/ui/components/Loading';

function LoadingScreen() {
  return (
    ReactDOM.createPortal(
      <LoadingScreenCont>
        <Loading text-size='large' encircled />
      </LoadingScreenCont>,
      document.getElementById('modal-root'),
    )
  );
}

const LoadingScreenCont = styled.div`
  position: fixed;
  z-index: ${(p) => p.theme.zIndex.loadingModal};
  background-color: ${(p) => p.theme.colors.white};
  top: ${(p) => p.theme.layout.headerHeight};
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default LoadingScreen;
