import styled from 'styled-components';

import { textAlignMixin } from 'core/ui/mixins/text';

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1em;
`;

Table.Row = styled.tr`
  td {
    transition: border-color 0.25s ease-in-out;
  }

  &:hover, &:active {
    td {
      border-bottom-color: ${(p) => p.theme.borders.color};
    }
  }
`;

Table.Cell = styled.td`
  padding: 1em 0;
  border-bottom: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.grays.light};
  ${textAlignMixin}
`;

Table.Header = styled.th`
  padding: 1em 0;
  font-weight: bold;
  ${textAlignMixin}
`;

export default Table;
