import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useMutation } from '@apollo/client';
import client from 'core/api/apollo/apolloClient';
import { SIGN_OUT } from 'core/api/apollo/mutations';
import { goToSignIn, goToInitOrder } from 'core/api/actions/navigation';
import { session } from 'core/api/storage';
import { Button } from 'core/ui/atoms';
import useReferralCode from 'core/hooks/useReferralCode';

function LogoutButton(props) {
  const { clearReferralCode } = useReferralCode();
  const { children, reload = false, ...rest } = props;
  const onComplete = () => {
    session.clear();
    return client.resetStore().then(()=>{
      clearReferralCode();
      if (reload){
        goToInitOrder();
      } else {
        goToSignIn();
      }
    });
  };

  const [logout] = useMutation(SIGN_OUT, {
    variables: { input: {} },
    onCompleted: onComplete,
    onError: onComplete,
  });

  return (
    <SignoutButton onClick={logout} {...rest}>{children || 'Log out'}</SignoutButton>
  );
}

LogoutButton.propTypes = {
  children: PropTypes.node,
  reload: PropTypes.bool,
};

const SignoutButton = styled(Button)`
  color: ${(p) => p.theme.typography.colors.inverse};
  background: ${(p) => p.theme.colors.primary};
  padding: 10px 10px;
  font-size: 13px;
`;

export default LogoutButton;
