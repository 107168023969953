import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { ValidatorForm } from 'react-form-validator-core';

import useAlerts from 'core/hooks/useAlerts';
import useForm from 'core/hooks/useForm';
import useProductSizes from 'core/hooks/useProductSizes';
import useUser from 'core/hooks/useUser';

import { SET_PRODUCT_SIZE } from 'core/api/apollo/mutations';

import { Radio } from 'core/ui/atoms';
import { RadioFormGroup, SubmitButton } from 'settings/ui/atoms';
import Panel from 'settings/ui/components/Panel';

function ProductSize() {
  const { loading, currentUser: { subscription } = {} } = useUser();
  const { loading: sizesLoading, productSizes } = useProductSizes();
  const { productSize = {} } = subscription || {};

  return (
    <Panel title='Default Size' loading={loading || sizesLoading || !subscription} data-default-size>
      <Form
        initialData={{ productSizeId: productSize.id }}
        productSizes={productSizes}
      />
    </Panel>
  );
}

function Form({ initialData, productSizes }) {
  const { account } = useUser();
  const alerts = useAlerts();
  const [setProductSize] = useMutation(SET_PRODUCT_SIZE);

  const onSuccess = () => {
    let message = 'Your changes have been saved!';
    if (account.subscription.isActive()) {
      message += ' Note: this will not apply to existing orders';
    }
    alerts.show(message, { autoClose: true });
  };

  const { formData, isDirty, isProcessing, onChange, handleSubmit } = useForm('setProductSize', {
    initialData,
    onSubmit: setProductSize,
    onSuccess,
    validator: ({ productSizeId }) => !!productSizeId,
  });

  return (
    <ValidatorForm onSubmit={handleSubmit} >
      <RadioFormGroup>
        {productSizes.map((size) => (
          <Radio
            key={size.id}
            name="productSizeId"
            label={productSizeLabel(size)}
            value={size.id}
            checked={formData.productSizeId === size.id}
            onChange={onChange}
          />
        ))}
      </RadioFormGroup>

      <Panel.Footer>
        <SubmitButton disabled={!isDirty || isProcessing}>Apply Changes</SubmitButton>
      </Panel.Footer>
    </ValidatorForm>
  );
}

Form.propTypes = {
  initialData: PropTypes.object.isRequired,
  productSizes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const productSizeLabel = (productSize) => (
  <SizeLabel>
    {productSize.name}
    <small>{productSize.description}</small>
  </SizeLabel>
);

const SizeLabel = styled.div`
  font-weight: bold;
  small {
    display: block;
    font-weight: normal;
    color: ${(p) => p.theme.typography.colors.muted};
  }
`;

export default ProductSize;
