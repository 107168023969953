import styled from 'styled-components';

const SettingsGrid = styled.div`
  margin-top: 5vh;
  display: grid;
  grid-template-columns: ${(p) => p.template || '1fr 1fr 2fr'};
  grid-gap: 3vh 2vw;

  ${(p) => p.theme.max('md')`
    grid-template-columns: repeat(auto-fit, 100%);
  `}
`;

export default SettingsGrid;
