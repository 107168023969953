import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Text } from 'core/ui/atoms';

function Loading(props) {
  return (
    <LoadingWrapper text-color='grays.light' no-margin {...props}>
      Loading{!props.encircled && '...'}
    </LoadingWrapper>
  );
}

Loading.propTypes = {
  encircled: PropTypes.bool,
};

const LoadingWrapper = styled(Text)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  ${(p) => p.encircled && css`
    &:before {
      content: '';
      display: block;
      position: absolute;
      border: 2px solid ${(p) => p.theme.colors.grays.light};
      border-radius: 50%;
      height: 6em;
      width: 6em;
    }
  `};
`;

export default Loading;
