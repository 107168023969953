import SettingsLayout from 'settings/ui/layouts/SettingsLayout';

import {
  userAuthenticated,
  canManageBillingInfo,
  canManageSubscription,
} from 'core/api/routeAccessCheck';

import AccountInfoPage from 'settings/ui/pages/AccountInfoPage';
import BillingPage from 'settings/ui/pages/BillingPage';
import ContactUsPage from 'settings/ui/pages/ContactUsPage';
import SubscriptionPage from 'settings/ui/pages/SubscriptionPage';
import FoodPreferencesPage from 'settings/ui/pages/FoodPreferencesPage';

export const ROUTES = [
  {
    name: 'accountInfo',
    path: '/settings/account-info',
    component: AccountInfoPage,
    layout: SettingsLayout,
    accessChecks: [
      userAuthenticated,
    ],
  },
  {
    name: 'billing',
    path: '/settings/billing',
    component: BillingPage,
    layout: SettingsLayout,
    accessChecks: [
      userAuthenticated,
      canManageBillingInfo,
    ],
  },
  {
    name: 'contact',
    path: '/settings/contact',
    component: ContactUsPage,
    layout: SettingsLayout,
    accessChecks: [
      userAuthenticated,
    ],
  },
  {
    name: 'subscription',
    path: '/settings/subscription',
    component: SubscriptionPage,
    layout: SettingsLayout,
    accessChecks: [
      userAuthenticated,
      canManageSubscription,
    ],
    requireValidBilling: true,
  },
  {
    name: 'foodPreferences',
    path: '/settings/food-preferences',
    component: FoodPreferencesPage,
    layout: SettingsLayout,
    accessChecks: [
      userAuthenticated,
    ],
    requireValidBilling: true,
  },
];

const ROUTE_MAP = ROUTES.reduce((obj, route) => ({ ...obj, [route.name]: route }), {});

export default ROUTE_MAP;
