import React from 'react';
import styled from 'styled-components';

import { SettingsGrid } from 'settings/ui/atoms';
import SettingsPage from 'settings/ui/components/SettingsPage';
import SubscriptionSettingsComp from 'settings/ui/components/SubscriptionSettings';

function SubscriptionPage() {
  return (
    <SettingsPage scrollTop>
      <SettingsGrid>
        <SubscriptionSettings />
      </SettingsGrid>
    </SettingsPage>
  );
}

const SubscriptionSettings = styled(SubscriptionSettingsComp)`
  grid-column: -1 / 1;
`;

export default SubscriptionPage;
