import Account from 'core/api/Account';

class AccessControl {
  constructor(user) {
    this.user = user;
    this.account = new Account(this.user);
  }

  placeOrder = () => (this.account.subscription.isActive() || this.account.subscription.isCancelled()) && this.account.hasSupportedAddress();

  manageSubscription = () => this.account.subscription.isActive();

  manageBillingInfo = () => !this.account.billingAccount.isDoNotCharge();

  inviteFriends = () => !!(this.user && this.user.referralCoupon);

  viewWelcome = () => this.account.subscription.isFuture();

  logOut = () => !!this.user;
}

export default AccessControl;
