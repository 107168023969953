import { css } from 'styled-components';

const getFontColor = (props) => (
  props.inverse ? props.theme.typography.colors.inverse : props.theme.typography.colors.primary
);

const getBorderColor = (props) => {
  if (props.success) {
    return props.theme.typography.colors.success;
  } else if (props.error) {
    return props.theme.typography.colors.danger;
  } else if (props.inverse) {
    return props.theme.typography.colors.inverse;
  }

  return props.theme.typography.colors.primary;
};

export const formInputMixin = (props) => css`
  display: block;
  color: ${getFontColor};
  font-size: ${(p) => p.theme.typography.sizes.regular};
  width: 100%;
  border-radius: 0.5em;
  border: ${props.theme.borders.width} solid ${getBorderColor};
  padding: 0.85em 1em;
  background: transparent;
  box-shadow: none;

  &input:read-only, &select:read-only, &textarea:read-only, &[readonly] {
    border-color: ${(p) => p.theme.colors.grays.light};
    color: ${(p) => p.theme.typography.colors.muted};
  }

  :disabled {
    cursor: not-allowed;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${(p) => p.theme.typography.colors.muted};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${(p) => p.theme.typography.colors.muted};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${(p) => p.theme.typography.colors.muted};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${(p) => p.theme.typography.colors.muted};
  }
`;
