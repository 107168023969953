import styled, { css } from 'styled-components';

const Hint = styled.span`
  display: inline-block;
  font-size: ${(p) => p.theme.typography.sizes.smaller};
  color: ${(p) => p.theme.typography.colors.muted};
  margin-left: 0.75em;

  ${(p) => p.success && css`
  color: ${(p) => p.theme.typography.colors.success};
  `}
`;

export default Hint;
