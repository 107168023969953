import { combineMixins } from './utils';

export const btnSizeMixin = (props) => {
  const { 'btn-sm': small, 'btn-lg': large } = props;

  if (small) {
    return `
      font-size: ${props.theme.typography.sizes.small};
    `;
  } else if (large) {
    return `
      padding: 1em 5.5em;
      font-weight: normal;
      font-size: ${props.theme.typography.sizes.regular};
    `;
  }

  return `
    font-size: ${props.theme.typography.sizes.regular};
  `;
};

export const defaultBtnMixin = (props) => {
  return `
    background: transparent;
    border-width: ${props.theme.borders.width};
    border-color: transparent;
    border-style: solid;
    &:hover {
      box-shadow: none;
    }
    color: ${props.theme.typography.colors.primary};
    width: ${props.fullWidth ? '100%' : 'auto'};
    padding: 0.4em 1.7em;
    font-weight: 500;
    cursor: pointer;
    will-change: box-shadow;
    transition: box-shadow ${props.theme.transitions.fast};
    &:disabled {
      ${disabledBtnMixin({ ...props, 'btn-disabled': true })}
    }
  `;
};

export const primaryBtnMixin = (props) => {
  const { 'btn-primary': primary } = props;
  if (!primary) {
    return null;
  }

  return `
    background: ${props.theme.colors.primary};
    color: ${props.theme.typography.colors.inverse};
    border-color: ${props.theme.colors.primary};
    &:hover {
      box-shadow: ${props.theme.shadows.level1};
    }
  `;
};

export const secondaryBtnMixin = (props) => {
  const { 'btn-secondary': secondary } = props;
  if (!secondary) {
    return null;
  }

  return `
    background: ${props.theme.colors.secondary};
    color: ${props.theme.typography.colors.inverse};
    border-color: ${props.theme.colors.secondary};
    &:hover {
      box-shadow: ${props.theme.shadows.level1};
    }
  `;
};

export const unstyledBtnMixin = (props) => {
  const { 'unstyled': isUnstyled, 'btn-unstyled': unstyled } = props;
  if (!isUnstyled && !unstyled) {
    return null;
  }

  return `
    border: 0;
    margin: 0;
    padding: 0;
    &:disabled {
      color: ${props.theme.typography.colors.muted};
      background-color: transparent;
    }
  `;
};

export const linkBtnMixin = (props) => {
  const { 'link': isLink, 'btn-link': link } = props;
  if (!isLink && !link) {
    return null;
  }

  return `
    ${unstyledBtnMixin({ ...props, 'btn-unstyled': true })}
    font-weight: normal;
    &:hover {
      text-decoration: underline;
    }
  `;
};

export const outlineBtnMixin = (props) => {
  const { 'btn-outline': outline } = props;
  if (!outline) {
    return null;
  }

  return `
    background: transparent;
    color: ${props.theme.typography.colors.primary};
    border-color: ${props.theme.colors.primary};
    transition: background ${props.theme.transitions.fast}, color ${props.theme.transitions.fast};
    &:hover {
      background: ${props.theme.colors.primary};
      color: ${props.theme.colors.white};
    }
  `;
};

export const disabledBtnMixin = (props) => {
  const { 'btn-disabled': disabled } = props;
  if (!disabled) {
    return null;
  }

  return `
    background: ${props.theme.colors.grays.light};
    border-color: ${props.theme.colors.grays.light};
    color: ${props.theme.typography.colors.primary};
    &:hover {
      box-shadow: none;
      cursor: not-allowed;
    }
  `;
};

export const roundedBtnMixin = (props) => {
  const { 'btn-rounded': rounded } = props;
  if (!rounded) {
    return null;
  }

  return `
    border-radius: 3em;
    padding-right: 1.5em;
    padding-left: 1.5em;
  `;
};

export const buttonMixin = combineMixins([
  defaultBtnMixin,
  btnSizeMixin,
  primaryBtnMixin,
  secondaryBtnMixin,
  unstyledBtnMixin,
  linkBtnMixin,
  outlineBtnMixin,
  disabledBtnMixin,
  roundedBtnMixin,
]);
