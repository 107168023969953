import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { ValidatorForm } from 'react-form-validator-core';

import useAlerts from 'core/hooks/useAlerts';
import useErrorHandlers from 'core/hooks/useErrorHandlers';

import SplitPageContainer from 'core/ui/components/SplitPageContainer';
import {
  Box,
  Button,
  Container,
  H4,
  Header,
  Flex,
  FormGroup,
  Label,
  SubHeader,
  Text,
  ValidatedInput,
} from 'core/ui/atoms';
import { SEND_RESET_PASSWORD_EMAIL } from 'core/api/apollo/mutations';
import { ERROR_MESSAGES } from 'core/api/constants';

function ForgotPasswordPage() {
  const [isSent, setIsSent] = useState(false);

  return (
    <SplitPageContainer>
      <Container>
        <Flex>
          <Box md={4} mdOffset={1}>
            <WelcomeHeader>Don't worry we got you.</WelcomeHeader>
            <SubHeader>Enter the email address you used when you created your account. We'll email you a link you can use to reset your password.</SubHeader>
            {isSent
              ? (
                <EmailSent>
                  <H4>Email Sent!</H4>
                  <Text>Please follow the instructions in the email</Text>
                </EmailSent>
              ) : (
                <ForgotPasswordForm
                  onSuccess={() => setIsSent(true)}
                />
              )}
          </Box>
        </Flex>
      </Container>
    </SplitPageContainer>
  );
}

function ForgotPasswordForm(props) {
  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const alerts = useAlerts();
  const [sendResetPasswordEmail] = useMutation(SEND_RESET_PASSWORD_EMAIL, {
    variables: { input: { email } },
  });
  const { displayApolloResponseErrors } = useErrorHandlers();

  const onChange = (e) => setEmail(e.target.value);

  const onError = (errors) => {
    setIsProcessing(false);
    displayApolloResponseErrors([errors].flatten());
  };

  const onComplete = ({ data: { sendResetPasswordEmail: result } }) => {
    setIsProcessing(false);

    if (result.errors && result.errors.length) {
      return onError(result.errors);
    }

    props.onSuccess && props.onSuccess();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    alerts.clear();
    setIsProcessing(true);
    sendResetPasswordEmail().then(onComplete).catch(onError);
  };

  return (
    <Form
      onSubmit={onSubmit}
      noValidate
      instantValidate={false}
    >
      <FormGroup>
        <Label required>Email</Label>
        <ValidatedInput
          type="email"
          name="email"
          onChange={onChange}
          value={email}
          validators={['required', 'isEmail']}
          errorMessages={[ERROR_MESSAGES.required, ERROR_MESSAGES.email]}
        />
      </FormGroup>
      <ButtonCont>
        <FormGroup>
          <Button type="submit" btn-primary btn-lg disabled={isProcessing}>
            {isProcessing ? 'Sending...' : 'Send email'}
          </Button>
        </FormGroup>
      </ButtonCont>
    </Form>
  );
}

ForgotPasswordForm.propTypes = {
  onSuccess: PropTypes.func,
};

const WelcomeHeader = styled(Header)`
  margin-top: 5vh;
`;

const Form = styled(ValidatorForm)`
  display: block;
  width: 320px;
  margin: 7vh auto 3vh auto;
  > div:nth-child(1) {
    margin-bottom: 5vh;
  }
`;

const ButtonCont = styled.div`
  text-align: center;
  margin-top: 3vh;
  ${(p) => p.theme.max('sm')`
    button {
      width: 100%;
    }
  `}
`;

const EmailSent = styled.div`
  text-align: center;
  margin: 20px 0;
  padding: 30px;
`;

export default ForgotPasswordPage;
