import React from 'react';
import PropTypes from 'prop-types';

import { normalizeRoute } from 'core/api/utils';
import { AspectRatio } from 'core/ui/atoms';

const VIDEO_HOST_URL = 'https://player.vimeo.com';

function VimeoEmbed(props) {
  const { srcKey, autoplay, fullscreen } = props;

  const options = {};
  if (autoplay) {
    options.autoplay = 1;
  }
  if (fullscreen) {
    options.playsinline = 0; // see https://vimeo.zendesk.com/hc/en-us/articles/360000636368-Inline-Playback-on-Mobile
  }
  const url = [VIDEO_HOST_URL, normalizeRoute(`/video/${srcKey}`, options)].join('');

  return (
    <AspectRatio className='vimeo-aspect-ratio' aspectWidth='560' aspectHeight='316'>
      <iframe
        width='100%'
        height='100%'
        title={srcKey}
        src={url}
        frameBorder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        webkitallowfullscreen='true'
        mozallowfullscreen='true'
        allowFullScreen
      />
    </AspectRatio>
  );
}

VimeoEmbed.propTypes = {
  srcKey: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  fullscreen: PropTypes.bool,
};

export default VimeoEmbed;
