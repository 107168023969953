/* eslint-disable no-console */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Money from 'core/api/Money';
import { compareDates, displayDate } from 'core/ui/dateHelpers';
import { H5 } from 'core/ui/atoms';
import PromoCodeInput from 'onramp/ui/components/PromoCodeInput';
import { displayDiscount } from 'core/ui/discountHelpers';

const PurchaseSummary = ({ enablePromoCodeForm, isShipping, data, refetch }) => {


  if (!data) {
    return null;
  }

  const purchase = isShipping ? data.getPrepaidPurchase : data.getSubscriptionPurchase;

  const promoCodeApplied = () => refetch();

  const displayNumItems = () => {
    const { numItems } = purchase;
    return `${numItems} Item${numItems === 1 ? '' : 's'}`;
  };

  const promoMessage = (coupon) => {
    if (coupon.durationType === 'LIMITED_TO_WEEK') {
      return `${displayDiscount(coupon)} off first ${
        coupon.applicableOnWeek <= 1 ? '1 week' : coupon.applicableOnWeek + ' weeks'
      } `;
    }
    return ` ${displayDiscount(coupon)} off `;
  };

  const renderOrders = () => {
    const orders = [...purchase.orders].sort((a, b) => compareDates(a.date, b.date));
    return (
      <Fragment>
        {orders.map((order) => (
          <TableRow key={`order-${order.id}`}>
            <td>First delivery</td>
            <td><strong>{displayDate(order.date, 'dddd, M/D')}</strong></td>
          </TableRow>
        ))}
        <TableRow>
          <td>{displayNumItems()}</td>
          <td>{Money(purchase.subtotal).toFormat()}</td>
        </TableRow>
        <TableRow>
          <td>Delivery fee</td>
          <td>{Money(purchase.deliveryFee).toFormat()} per delivery</td>
        </TableRow>
        <TableRow>
          <td>Service fee</td>
          <td>{Money(purchase.serviceFee).toFormat()} per delivery</td>
        </TableRow>
      </Fragment>
    );
  };

  const renderWeeks = () => {
    const startDates = [...purchase.deliveryPeriods].sort((a, b) => compareDates(a.startDate, b.startDate)).map((p) => p.startDate);
    const weeks = startDates.map((date) => {
      const bundle = purchase.bundleReservations.find((r) => r.deliveryPeriod.startDate === date) || {};
      const delivery = purchase.deliveryReservations.find((r) => r.deliveryPeriod.startDate === date) || {};
      return { date, bundle, deliveryFee: delivery.deliveryFee, serviceFee: delivery.serviceFee };
    });
    const digitalItems = [...purchase.billableItems];

    return (
      <Fragment>
        {weeks.map((week, i) => (
          <Fragment key={`week-${i}`}>
            <TableRow>
              <td><strong>Week of {displayDate(week.date, 'dddd, M/D')}</strong></td>
              <td></td>
            </TableRow>
            <TableRow detail>
              <td>{week.bundle.description}</td>
              <td>{Money(week.bundle.price).toFormat()}</td>
              <td></td>
            </TableRow>
            <TableRow detail>
              <td>Delivery Fee</td>
              <td>{Money(week.deliveryFee).toFormat()} per delivery</td>
              <td></td>
            </TableRow>
            <TableRow detail serviceFee>
              <td>Service Fee</td>
              <td>{Money(week.serviceFee).toFormat()} per delivery</td>
              <td></td>
            </TableRow>
          </Fragment>
        ))}
        {digitalItems.map((item, i) => (
          <Fragment key={i}>
            <TableRow>
              <td><strong>{item.name}</strong></td>
              <td>{Money(item.price).toFormat()}</td>
            </TableRow>
          </Fragment>
        ))}
      </Fragment>
    );
  };

  return (
    <div data-purchase>
      <H5>{isShipping ? 'Purchase Summary' : 'First Week Summary'}</H5>
      <Table>
        <tbody>
          {isShipping ? renderWeeks() : renderOrders()}
          {purchase.coupon && (
            <TableRow>
              <td>Promo <strong>{purchase.coupon.code}</strong> ({promoMessage(purchase.coupon)})</td>
              <td><strong>-{Money(purchase.discount).toFormat()}</strong></td>
            </TableRow>
          )}
          <TableRow total>
            <td><strong>Total Charge</strong></td>
            <td><strong>{Money(purchase.total).toFormat()}</strong></td>
          </TableRow>
        </tbody>
      </Table>
      {(
        <PromoCodeForm>
          <PromoCodeInput onSuccess={promoCodeApplied} enablePromoCodeForm={enablePromoCodeForm}/>
        </PromoCodeForm>
      )}
    </div>
  );
};

PurchaseSummary.propTypes = {
  enablePromoCodeForm: PropTypes.bool.isRequired,
  isShipping: PropTypes.bool.isRequired,
  data: PropTypes.object,
  refetch: PropTypes.func,
};

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;

const TableRow = styled.tr`
  td {
    font-size: ${(p) => (p.detail ? p.theme.typography.sizes.small : 'inherit')};
    text-align: left;
    width: 70%;
    ${(p) => p.theme.max('sm')`
      font-size: 12px;
    `}
  }

  ${(p) => p.serviceFee && css`
    td {
      padding-bottom: 1em;
    }
  `}

  ${(p) => p.total && css`
    td {
      border-top: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.borders.color};
      padding-top: 0.75em;
    }
  `}
`;

const PromoCodeForm = styled.div`
  margin-top: 3vh;
`;

export default PurchaseSummary;
