import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import styled, { css } from 'styled-components';

import { ReferralCodeContext } from 'core/api/referralCodeContext';
import Money from 'core/api/Money';
import { compareDates, displayDate } from 'core/ui/dateHelpers';
import { GET_SUBSCRIPTION_PURCHASE } from 'core/api/apollo/queries';
import { H4 } from 'core/ui/atoms';
import { Loading } from 'core/ui/components';
import PromoCodeInput from 'onramp/ui/components/PromoCodeInput';

import { displayDiscount } from 'core/ui/discountHelpers';

@graphql(GET_SUBSCRIPTION_PURCHASE, { options: { fetchPolicy: 'network-only' } })
class FirstSummary extends PureComponent {
  static contextType = ReferralCodeContext

  static propTypes = {
    enablePromoCodeForm: PropTypes.bool,
    data: PropTypes.object.isRequired,
  };

  componentWillUnmount() {
    this.context.clearReferralCode();
  }

  promoCodeApplied = () => this.props.data.refetch();

  displayNumItems = () => {
    const { data: { getSubscriptionPurchase = {} } } = this.props;
    const { numItems } = getSubscriptionPurchase;

    return `${numItems} Item${numItems === 1 ? '' : 's'}`;
  }

  render() {
    const { enablePromoCodeForm, data: { getSubscriptionPurchase = { orders:[] }, loading } } = this.props;
    const { deliveryFee, serviceFee, subtotal, coupon, discount, total } = getSubscriptionPurchase;

    if (loading) {
      return <Loading />;
    }

    const orders = [...getSubscriptionPurchase.orders].sort((a, b) => compareDates(a.date, b.date));

    return (
      <div data-purchase>
        <H4 text-center>First Week Summary</H4>

        <Table>
          <tbody>
            {orders.map((order, i) => (
              <TableRow key={`order-${order.id}`}>
                <td>{i === 0 ? 'First' : 'Second'} delivery</td>
                <td data-purchase-order={i}><strong>{displayDate(order.date, 'dddd, M/D')}</strong></td>
              </TableRow>
            ))}
          </tbody>
        </Table>

        <PriceTable>
          <tbody>
            <TableRow>
              <td data-purchase-num-items>{this.displayNumItems()}</td>
              <td data-purchase-subtotal>{Money(subtotal).toFormat()}</td>
            </TableRow>
            <TableRow>
              <td>Delivery fee</td>
              <td data-purchase-delivery-fee>{Money(deliveryFee).toFormat()} per delivery</td>
            </TableRow>
            <TableRow>
              <td>Service fee</td>
              <td data-purchase-service-fee>{Money(serviceFee).toFormat()} per delivery</td>
            </TableRow>
            {coupon &&
              <TableRow>
                <td>Promo <strong>{coupon.code}</strong> ({displayDiscount(coupon)} off)</td>
                <td data-purchase-discount><strong>-{Money(discount).toFormat()}</strong></td>
              </TableRow>
            }
            <TableRow total={true}>
              <td><strong>Total Charge Today</strong></td>
              <td data-purchase-total><strong>{Money(total).toFormat()}</strong></td>
            </TableRow>
          </tbody>
        </PriceTable>
        {enablePromoCodeForm && (
          <PromoCodeForm>
            <PromoCodeInput onSuccess={this.promoCodeApplied} />
        </PromoCodeForm>
        )}
      </div>
    );
  }
}

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;

const TableRow = styled.tr`
  td:first-child {
    text-align: left;
  }

  td:last-child {
    text-align: right;
  }

  ${(p) => p.total && css`
    td {
      border-top: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.borders.color};
      padding-top: 0.75em;
    }
  `}
`;

const PriceTable = styled(Table)`
  margin-top: 2em;
`;

const PromoCodeForm = styled.div`
  margin-top: 3vh;
`;

export default FirstSummary;
