import React from 'react';
import styled from 'styled-components';

import Panel from 'settings/ui/components/Panel';
import Account from 'settings/ui/components/AccountInfo/Account';
import Password from 'settings/ui/components/AccountInfo/Password';

function AccountInfo(props) {
  return (
    <Panel title='Account Info' data-account-info {...props}>
      <AccountSection />
      <Password />
    </Panel>
  );
}

const AccountSection = styled(Account)`
  margin-bottom: 2em;
`;

export default AccountInfo;
