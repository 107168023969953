import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Page } from 'core/ui/atoms';

class OnrampPage extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <CustomPage scrollTop {...this.props}>
        {this.props.children}
      </CustomPage>
    );
  }
}

const CustomPage = styled(Page)`
  overflow: hidden;
  position: relative;
  min-height: 90vh;
  background-color: #FFFFFF;
  display: grid;
  grid-template-columns: 55% 45%;

  ${(p) => p.theme.max('md')`
    grid-template-columns: 50% 50%;
  `};

  ${(p) => p.theme.max('sm')`
    grid-template-columns: 1fr;
  `}
`;

export default OnrampPage;
