import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CardNumberElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import stripePromise from 'core/api/stripe';

import LoadingError from 'core/ui/components/Stripe/LoadingError';

// TODO: this could consolidate more Stripe functionality and just expose a simple API like `createToken`
//       and `hasStripeError`
class Context extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children, ...rest } = this.props;

    if (!stripePromise) {
      return (<LoadingError />);
    }

    return (
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {
            ({ stripe, elements }) => {
              if (!stripe) {
                return <LoadingError />;
              }

              const getCardElement = () => elements.getElement(CardNumberElement);

              return React.cloneElement(children, { stripe, elements, getCardElement, ...rest });
            }
          }
        </ElementsConsumer>
      </Elements>
    );
  }
}

export default Context;
