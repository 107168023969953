import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

import { Flex, H2, Image } from 'core/ui/atoms';
import { BodyScrollManager } from 'core/ui/components';
import { Burst } from 'onramp/ui/atoms';
import PageBox from 'onramp/ui/components/Page/Box';
import {
  Header,
  InfoDescription,
  InfoHeader,
} from 'onramp/ui/components';

import { ReactComponent as FillingBox } from 'onramp/assets/images/CustomizePlan/FillingBox.svg';
import Slide1Image from 'onramp/assets/images/CustomizePlan/V2/asparagus_porkchop.png';
import Slide2Image from 'onramp/assets/images/CustomizePlan/V2/apple_cranberry_toast.png';
import Slide3Image from 'onramp/assets/images/CustomizePlan/V2/artichokes_petrale_sole.png';
import Slide4Image from 'onramp/assets/images/CustomizePlan/V2/bangkok_salmon_bowl.png';

class CreatingOrderOverlay extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
  };

  render() {
    const { open } = this.props;

    return (
      ReactDOM.createPortal(
        <Background open={open} >
          <BodyScrollManager disabled={open} />
          <OverlayHeader disableProgress={true} />
          <ContentBox>
            <StyledInfoHeader>Hold tight, we're building your order!</StyledInfoHeader>
            <InfoDescription>Get excited. You're about to...</InfoDescription>
            <StyledFillingBox />
          </ContentBox>
          <SlideshowContentBox>
            {open && (
              <SlideshowWrapper>
                <Slideshow>
                  <Slide1>
                    <H2>Invest in yourself</H2>
                    <Image src={Slide1Image} />
                  </Slide1>

                  <Slide2>
                    <H2>Stress less and save time</H2>
                    <Image src={Slide2Image} />
                  </Slide2>

                  <Slide3>
                    <H2>Eat 100+ different plants each week</H2>
                    <Image src={Slide3Image} />
                  </Slide3>

                  <Slide4>
                    <H2>Look and feel light and vibrant</H2>
                    <Image src={Slide4Image} />
                  </Slide4>
                </Slideshow>
              </SlideshowWrapper>
            )}
          </SlideshowContentBox>
          <StyledBurst />
        </Background>,
        document.getElementById('modal-root'),
      )
    );
  }
}

const Background = styled(Flex)`
  position: fixed;
  z-index: ${(p) => p.theme.zIndex.loadingModal};
  background-color: #EFECE9;
  opacity: 0;
  top: -100vh;
  left: 0;
  width: 100vw;
  height: 100vh;

  flex-direction: row;
  align-items: center;
  align-items: flex-start;
  justify-content: flex-start;

  ${(p) => p.theme.max('sm')`
    flex-direction: column;
  `}

  transition: opacity 0.25s ease-in-out;

  ${(p) => p.open && css`
    top: 0;
    opacity: 1;
  `};
`;

const OverlayHeader = styled(Header)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(p) => p.theme.layout.onramp.headerHeight};
  z-index: ${(p) => p.theme.zIndex.headerFixed};
  overflow: hidden;
`;

const ContentBox = styled(PageBox)`
  width: 50%;

  ${(p) => p.theme.max('sm')`
    width: 100%;
  `}
`;

const SlideshowContentBox = styled(ContentBox)`
  ${(p) => p.theme.max('sm')`
    padding-top: 0;
  `}
`;

const SlideshowWrapper = styled.div`
  position: relative;
`;

const StyledInfoHeader = styled(InfoHeader)`
  font-size: 3em;
`;

const StyledFillingBox = styled(FillingBox)`
  margin-top: 3vh;
  ${(p) => p.theme.max('sm')`
    display: none;
  `}
`;

const StyledBurst = styled(Burst)`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateX(25%);
`;

const Slideshow = styled.div`
  display: grid;
  grid-template-areas: ". slide1 ." "slide4 . slide2" ". slide3 .";
  position: absolute;

  grid-template-columns: repeat(3, 50vw);
  grid-template-rows: repeat(3, 50vw);
  top: 0;
  left: calc(50vw * -1);

  ${(p) => p.theme.max('md')`
    grid-template-columns: repeat(3, 75vw);
    grid-template-rows: repeat(3, 75vw);
    left: calc(75vw * -1.2);
  `}

  ${(p) => p.theme.max('sm')`
    grid-template-columns: repeat(3, 150vw);
    grid-template-rows: repeat(3, 150vw);
    left: calc(150vw * -1.175);
  `}

  transform-origin: center center;

  animation: Rotate 10s infinite;

  @keyframes Rotate {
    0% {
      transform: rotate(0deg);
    }
    15% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-90deg);
    }
    40% {
      transform: rotate(-90deg);
    }
    50% {
      transform: rotate(-180deg);
    }
    65% {
      transform: rotate(-180deg);
    }
    75% {
      transform: rotate(-270deg);
    }
    90% {
      transform: rotate(-270deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`;

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;

  width: 100%;
  height: 100%;
  padding: 0 10%;

  h2 {
    text-align: center;
    max-width: 90vw;
    padding: 0 5vw;
  }

  img {
    width: 100%;
    backface-visibility: hidden;
  }
`;

const Slide1 = styled(Slide)`
  grid-area: slide1;
`;

const Slide2 = styled(Slide)`
  grid-area: slide2;
  transform: rotate(90deg);
`;

const Slide3 = styled(Slide)`
  grid-area: slide3;
  transform: rotate(180deg);
`;

const Slide4 = styled(Slide)`
  grid-area: slide4;
  transform: rotate(270deg);
`;

export default CreatingOrderOverlay;
