import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class BodyScrollManager extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
  };

  toggleBodyScrolling = (disabled) => {
    if (disabled) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }

  componentDidMount() {
    this.toggleBodyScrolling(this.props.disabled);
  }

  componentDidUpdate() {
    this.toggleBodyScrolling(this.props.disabled);
  }

  componentWillUnmount() {
    this.toggleBodyScrolling(false);
  }

  render() {
    return null;
  }
}

export default BodyScrollManager;
