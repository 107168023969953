import React from 'react';

import { SettingsGrid } from 'settings/ui/atoms';
import SettingsPage from 'settings/ui/components/SettingsPage';
import ProductSize from 'settings/ui/components/ProductSize';
import FoodsToAvoid from 'settings/ui/components/FoodsToAvoid';
import SpicyPreference from 'settings/ui/components/SpicyPreference';

function FoodPreferencesPage() {
  return (
    <SettingsPage scrollTop>
      <SettingsGrid template='repeat(3, 1fr)'>
        <ProductSize />
        <FoodsToAvoid />
        <SpicyPreference />
      </SettingsGrid>
    </SettingsPage>
  );
}

export default FoodPreferencesPage;
