/* eslint-disable no-console */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ValidatorForm } from 'react-form-validator-core';

import { graphql } from '@apollo/client/react/hoc';
import { validateEmail } from '../../../../helpers';
import { getQueryParams, formatPhoneNumber } from 'core/api/utils';
import {
  ControlsGrid,
  FormGroup,
  Label,
  Checkbox,
  Select,
  Text,
  ValidatedInput,
  ValidatedTextarea,
  Hint,
  H2,
  InputErrorMessage,
} from 'core/ui/atoms';
import cacheUpdater, { updateUserQuery } from 'core/api/cache/updaters';
import Tracker from 'core/api/tracking/tracker';
import { ContentForm } from 'onramp/ui/components/V2';
import { ERROR_MESSAGES, US_STATES, AMERICAN_PHONE_REGEXP } from 'core/api/constants';
import { userHasCourierAddress } from 'core/api/Account';
import { scrollTop } from 'core/api/actions/scrolling';
import { VALIDATE_CUSTOMER } from 'core/api/apollo/mutations';
const states = US_STATES.map((s) => ({ title: s.name, value: s.abbreviation }));

// @graphql(VALIDATE_CUSTOMER, {
//   options: {
//     update: (data, data1) => {
//       cacheUpdater('signUp', updateUserQuery)(data, data1);
//     },
//   },
// })
// class UserDetailsForm extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       emailError: '',
//     };
//   }

//   componentDidMount() {
//     const { form } = this.props;
//     this.verifyGuestFields(form);
//   }

//   static propTypes = {
//     currentUser: PropTypes.object.isRequired,
//     form: PropTypes.object.isRequired,
//     isValid: PropTypes.func,
//     mutate: PropTypes.func,
//     setUserValidateEmail: PropTypes.func,
//     userValidateEmail: PropTypes.string,
//     refetchUser: PropTypes.func,
//   };

//   toggleCheckbox = (e) => {
//     const {
//       form: { formData, handleChange },
//     } = this.props;
//     handleChange({ [e.target.name]: !formData[e.target.name] });
//   };

//   verifyGuestFields = (form) => {
//     const email = form.formData.email;
//     if (email.includes('@guest.com')) {
//       form.handleChange({ email: '' });
//     } else {
//       if (validateEmail(email)) {
//         const { isSubscribed } = getQueryParams();
//         this.props
//           .mutate({ variables: { input: { email, isSubscribed: !!isSubscribed } } })
//           .then(({ data }) => {
//             const {
//               validateCustomer: { errors },
//             } = data;
//             if (errors && errors[0] && errors[0].message) {
//               // alert(errors[0].message);
//               this.setState({ emailError: errors[0].message });
//             } else {
//               this.setState({ emailError: '' });
//               this.props.setUserValidateEmail(email);
//             }
//           })
//           .catch((error) => {
//             this.setState({ emailError: error.message });
//           });
//       }
//     }
//     if ([form.formData.firstName, form.formData.lastName].includes('guest')) {
//       form.handleChange({ firstName: '', lastName: '' });
//     }
//   };

//   validateUser = (e) => { //
//     const email = e.target.value;
//     if (email.includes('@guest.com')) return;

//     if (!validateEmail(email)) {
//       this.setState({ emailError: 'Invalid Email' });
//       return;
//     }

//     if (this.props.userValidateEmail === email) return;
//     const { isSubscribed } = getQueryParams();

//     this.props
//       .mutate({ variables: { input: { email, isSubscribed: !!isSubscribed } } })
//       .then(({ data }) => {
//         const {
//           validateCustomer: { errors },
//         } = data;
//         if (errors && errors[0] && errors[0].message) {
//           this.setState({ emailError: errors[0].message });
//         } else {
//           const { validateCustomer } = data;
//           if (validateCustomer.newUser) {
//             Tracker.trackRegistration();
//           } else {
//             this.props.refetchUser();
//           }
//           this.setState({ emailError: '' });
//           this.props.setUserValidateEmail(email);
//         }
//       })
//       .catch((error) => {
//         // alert(error);
//         this.setState({ emailError: error.message });
//       });
//     };

//   onSubmit = (e) => {
//     e.preventDefault();
//     const {
//       form: { handleSubmit },
//     } = this.props;
//     handleSubmit(this.props.isValid);
//   };

//   render() {
//     const { isSubscribed } = getQueryParams();

//     const {
//       currentUser: {
//         primaryAddress: { deliveryOption },
//       },
//       form: { formData, onChange },
//     } = this.props;

//     const displayInstructionsInput = userHasCourierAddress(this.props.currentUser);

//     return (
//       <ContentForm onError={scrollTop} onSubmit={this.onSubmit} noValidate instantValidate={false}>
//         <H2 no-margin>Contact Details</H2>
//         <Hint>Fields marked with * are required.</Hint>
//         <FormGroup>
//           <Label required>Email Address</Label>
//           <ValidatedInput
//             type="email"
//             placeholder="ex. hi@gomethodology.com"
//             name="email"
//             value={formData.email}
//             onChange={onChange}
//             // onBlur={this.validateUser}
//             disabled={this.props.userValidateEmail}
//             disable={this.props.userValidateEmail}
//             readOnly={this.props.userValidateEmail}
//             validators={['required', 'isEmail']}
//             errorMessages={[ERROR_MESSAGES.required, ERROR_MESSAGES.email]}
//           />
//           {/* {this.state.emailError && <InputErrorMessage>{this.state.emailError}</InputErrorMessage>} */}
//         </FormGroup>
//         {isSubscribed && (
//           <FormGroup>
//             <Label required>Password</Label>
//             <ValidatedInput
//               name="password"
//               onChange={onChange}
//               value={formData.password}
//               type="password"
//               placeholder="ex. ********"
//               validators={[
//                 'required',
//                 `minStringLength:${this.minPwdLength}`,
//                 `maxStringLength:${this.maxPwdLength}`,
//               ]}
//               errorMessages={[
//                 ERROR_MESSAGES.required,
//                 ERROR_MESSAGES.passwordLength,
//                 ERROR_MESSAGES.passwordLength,
//               ]}
//             />
//             <Hint>At least 8 characters</Hint>
//           </FormGroup>
//         )}
//         <CustomControlsGrid>
//           <FormGroup>
//             <Label required htmlFor="firstName">
//               First Name
//             </Label>
//             <ValidatedInput
//               type="text"
//               name="firstName"
//               id="firstName"
//               placeholder="John"
//               onChange={onChange}
//               value={formData.firstName || ''}
//             />
//           </FormGroup>
//           <FormGroup>
//             <Label required htmlFor="lastName">
//               Last Name
//             </Label>
//             <ValidatedInput
//               type="text"
//               name="lastName"
//               id="lastName"
//               placeholder="Smith"
//               onChange={onChange}
//               value={formData.lastName || ''}
//             />
//           </FormGroup>
//         </CustomControlsGrid>
//         <FormGroup>
//           <Label required htmlFor="street1">
//             Address
//           </Label>
//           <ValidatedInput
//             type="text"
//             name="street1"
//             id="street1"
//             placeholder="ex. 123 W. Main St"
//             value={formData.street1 || ''}
//             onChange={onChange}
//             validators={['required']}
//             errorMessages={[ERROR_MESSAGES.required]}
//             readOnly={!this.props.userValidateEmail}
//           />
//         </FormGroup>
//         <FormGroup>
//           <Label htmlFor="street2">Apartment or Suite</Label>
//           <ValidatedInput
//             type="text"
//             name="street2"
//             id="street2"
//             placeholder="ex. Apt 007"
//             value={formData.street2 || ''}
//             onChange={onChange}
//             readOnly={!this.props.userValidateEmail}
//           />
//         </FormGroup>
//         <FormGroup>
//           <Label required htmlFor="city">
//             City
//           </Label>
//           <ValidatedInput
//             type="text"
//             name="city"
//             id="city"
//             placeholder="ex. San Francisco"
//             value={formData.city || ''}
//             onChange={onChange}
//             validators={['required']}
//             errorMessages={[ERROR_MESSAGES.required]}
//             readOnly={!this.props.userValidateEmail}
//           />
//         </FormGroup>
//         <CustomControlsGrid>
//           <FormGroup>
//             <Label required htmlFor="states">
//               State
//             </Label>
//             <CustomSelect id="states" options={states} selected={formData.state} readOnly />
//           </FormGroup>
//           <FormGroup>
//             <Label required htmlFor="zipCode">
//               Zip
//             </Label>
//             <ValidatedInput
//               type="text"
//               name="zipCode"
//               id="zipCode"
//               placeholder="ex. 12345"
//               maxLength={5}
//               value={formData.zipCode || ''}
//               readOnly
//             />
//           </FormGroup>
//         </CustomControlsGrid>
//         {displayInstructionsInput && (
//           <FormGroup>
//             <Label required>
//               {deliveryOption.isOvernight ? 'Overnight ' : ''}Courier Delivery Instructions
//             </Label>
//             <InstructionsHintText text-size="small">
//               Help couriers find your home in the dark and place your box in the right place. If
//               possible, include a backup option (eg, if the lobby is locked or the gate code doesn’t
//               work).
//             </InstructionsHintText>
//             <InstructionsTextarea
//               name="instructions"
//               value={formData.instructions || ''}
//               onChange={onChange}
//               rows={5}
//               validators={['required']}
//               placeholder="ex. The gate code is: 123#. You’re at the right house if you see lawn gnomes. Leave box on porch behind potted plant by front door. If gate code doesn’t work, call neighborhood office at 415-245-2231"
//             />
//           </FormGroup>
//         )}
//         <FormGroup>
//           <Label required>Delivery Phone</Label>
//           <ValidatedInput
//             name="phoneNumber"
//             onChange={onChange}
//             value={formatPhoneNumber(formData.phoneNumber) || formData.phoneNumber || ''}
//             type="tel"
//             placeholder="ex. (123) 456-7890"
//             validators={['required', `matchRegexp:${PHONE_REGEXP}`]}
//             errorMessages={[ERROR_MESSAGES.required, ERROR_MESSAGES.phone]}
//             readOnly={!this.props.userValidateEmail}
//           />
//         </FormGroup>
//         <FormGroup>
//           <CustomCheckbox
//             label="Please send me SMS messages to notify me about order deadlines and delivery."
//             name="receiveReminders"
//             value="receiveReminders"
//             onChange={this.toggleCheckbox}
//             checked={formData.receiveReminders}
//           />
//         </FormGroup>
//         {/* <ContentButton type="submit" disabled={!this.isValid() || isProcessing}>
//           Continue
//         </ContentButton> */}
//       </ContentForm>
//     );
//   }
// }

const CustomControlsGrid = styled(ControlsGrid)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 28px;
`;

const CustomSelect = styled(Select)`
  padding: 0;
  .select-selected {
    margin: 0;
    padding: 0.7em 1em;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  font-size: ${(p) => p.theme.typography.sizes.regular};
  margin-bottom: 15px;
  .checkbox {
    margin-right: 1.5em;
    font-size: ${(p) => p.theme.typography.sizes.big};
  }
`;

const InstructionsHintText = styled(Text)`
  margin-top: -0.1em;
  padding: 0 0 0.5em 1em;
`;

const InstructionsTextarea = styled(ValidatedTextarea)`
  margin: 0;
  width: 100%;
  background-color: transparent;
`;


ValidatorForm.addValidationRule('isPasswordLengthValid', (value) => {
  return (value.length >= 8 && value.length <= 16);
});

ValidatorForm.addValidationRule('isPhoneValid', (value) => {
  const cleaned = value.replace(/(?!^\+)\D/g, '');
  return cleaned.match(AMERICAN_PHONE_REGEXP);
});

ValidatorForm.addValidationRule('isNotGuestEmail', (value) => {
  return !value.includes('guest');
});

@graphql(VALIDATE_CUSTOMER, {
  options: {
    update: (data, data1) => {
      cacheUpdater('signUp', updateUserQuery)(data, data1);
    },
  },
})


class UserDetailsForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      emailError: '',
    };
  }

  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    isValid: PropTypes.func,
    mutate: PropTypes.func,
    setUserValidateEmail: PropTypes.func,
    userValidateEmail: PropTypes.string,
    refetchUser: PropTypes.func,
  };

  componentDidMount() {
    const { form } = this.props;
    this.verifyGuestFields(form);
  }

  verifyGuestFields = (form) => {
    const email = form.formData.email;
    if (email.includes('@guest.com')) {
      form.handleChange({ email: '' });
    } else {
      if (validateEmail(email)) {
        const { isSubscribed } = getQueryParams();
        this.props
          .mutate({ variables: { input: { email, isSubscribed: !!isSubscribed } } })
          .then(({ data }) => {
            const {
              validateCustomer: { errors },
            } = data;
            if (errors && errors[0] && errors[0].message) {
              // alert(errors[0].message);
              this.setState({ emailError: errors[0].message });
            } else {
              this.setState({ emailError: '' });
              this.props.setUserValidateEmail(email);
            }
          })
          .catch((error) => {
            this.setState({ emailError: error.message });
          });
      }
    }
    if ([form.formData.firstName, form.formData.lastName].includes('guest')) {
      form.handleChange({ firstName: '', lastName: '' });
    }
  };

  validateEmailOnBlur = (e) => {
    const email = e.target.value;
    if (email.includes('guest')) return;
    if (!validateEmail(email)) return;
    if (this.props.userValidateEmail === email) return;

    const { isSubscribed } = getQueryParams();

    this.props
      .mutate({ variables: { input: { email, isSubscribed: !!isSubscribed } } })
      .then(({ data }) => {
        const {
          validateCustomer: { errors },
        } = data;
        if (errors && errors[0] && errors[0].message) {
          this.setState({ emailError: errors[0].message });
        } else {
          const { validateCustomer } = data;
          if (validateCustomer.newUser) {
            Tracker.trackRegistration();
          } else {
            this.props.refetchUser();
          }
          this.setState({ emailError: '' });
          this.props.setUserValidateEmail(email);
        }
      })
      .catch((error) => {
        this.setState({ emailError: error.message });
      });
  }

  toggleCheckbox = (e) => {
    const {
      form: { formData, handleChange },
    } = this.props;
    handleChange({ [e.target.name]: !formData[e.target.name] });
  };
  
  onSubmit = (e) => {
    e.preventDefault();
    const {
      form: { handleSubmit },
    } = this.props;
    handleSubmit(this.props.isValid);
  };

  render() {
    const { isSubscribed } = getQueryParams();
    const displayInstructionsInput = userHasCourierAddress(this.props.currentUser);
    const {
      currentUser: {
        primaryAddress: { deliveryOption },
      },
      form: { formData, onChange },
    } = this.props;
    return (
      <ContentForm
        noValidate
        onSubmit={this.onSubmit}
        instantValidate={true}
        onError={scrollTop}
        ref={(r) => { this.form = r; }}
        >
          <H2 no-margin>Contact Details</H2>
          <Hint>Fields marked with * are required.</Hint>
          <FormGroup>
            <Label required>Email Address</Label>
            <ValidatedInput
              type="email"
              placeholder="ex. hi@gomethodology.com"
              onChange={onChange}
              name="email"
              value={formData.email}
              onBlur={this.validateEmailOnBlur}
              withRequiredValidator={true}
              disabled={this.props.userValidateEmail}
              readOnly={this.props.userValidateEmail}
              validators={[
                'required', 
                'isEmail', 
                'isNotGuestEmail',
              ]}
              errorMessages={[
                ERROR_MESSAGES.required, 
                ERROR_MESSAGES.email, 
                "Email cannot contain 'guest'",
              ]}
            />
            {this.state.emailError && <InputErrorMessage>{this.state.emailError}</InputErrorMessage>}
          </FormGroup>

          {isSubscribed && (
            <FormGroup>
              <Label required>Password</Label>
              <ValidatedInput
                name="password"
                onChange={onChange}
                value={formData.password || ''}
                type="password"
                placeholder="ex. ********"
                validators={[
                  'required',
                  'isPasswordLengthValid',
                ]}
                errorMessages={[
                  ERROR_MESSAGES.required,
                  'Password must be between 8 and 16 characters',
                ]}
              />
              <Hint>At least 8 characters</Hint>
            </FormGroup>
          )}

          <CustomControlsGrid>
            <FormGroup>
              <Label required htmlFor="firstName">
                First Name
              </Label>
              <ValidatedInput
                type="text"
                name="firstName"
                id="firstName"
                placeholder="John"
                withRequiredValidator={true}
                onChange={onChange}
                value={formData.firstName || ''}
                validators={[
                  'required',
                ]}
                errorMessages={[
                  ERROR_MESSAGES.required,
                ]}
              />
            </FormGroup>
            <FormGroup>
              <Label required htmlFor="lastName">
                Last Name
              </Label>
              <ValidatedInput
                type="text"
                name="lastName"
                withRequiredValidator={true}
                id="lastName"
                placeholder="Smith"
                onChange={onChange}
                value={formData.lastName || ''}
                validators={[
                  'required',
                ]}
                errorMessages={[
                  ERROR_MESSAGES.required,
                ]}
              />
            </FormGroup>
          </CustomControlsGrid>

          <FormGroup>
            <Label required htmlFor="street1">
              Address
            </Label>
            <ValidatedInput
              type="text"
              name="street1"
              id="street1"
              placeholder="ex. 123 W. Main St"
              value={formData.street1 || ''}
              onChange={onChange}
              validators={['required']}
              withRequiredValidator={true}
              errorMessages={[ERROR_MESSAGES.required]}
              readOnly={!this.props.userValidateEmail}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="street2">Apartment or Suite</Label>
            <ValidatedInput
              type="text"
              name="street2"
              id="street2"
              placeholder="ex. Apt 007"
              value={formData.street2 || ''}
              onChange={onChange}
              readOnly={!this.props.userValidateEmail}
            />
          </FormGroup>

          <FormGroup>
            <Label required htmlFor="city">
              City
            </Label>
            <ValidatedInput
              type="text"
              name="city"
              id="city"
              placeholder="ex. San Francisco"
              value={formData.city || ''}
              onChange={onChange}
              validators={['required']}
              withRequiredValidator={true}
              errorMessages={[ERROR_MESSAGES.required]}
              readOnly={!this.props.userValidateEmail}
            />
          </FormGroup>

          <CustomControlsGrid>
            <FormGroup>
              <Label required htmlFor="states">
                State
              </Label>
              <CustomSelect id="states" options={states} selected={formData.state} readOnly />
            </FormGroup>
            <FormGroup>
              <Label required htmlFor="zipCode">
                Zip
              </Label>
              <ValidatedInput
                type="text"
                name="zipCode"
                id="zipCode"
                placeholder="ex. 12345"
                maxLength={5}
                value={formData.zipCode || ''}
                readOnly
              />
            </FormGroup>
          </CustomControlsGrid>

          {displayInstructionsInput && (
            <FormGroup>
              <Label required>
                {deliveryOption.isOvernight ? 'Overnight ' : ''}Courier Delivery Instructions
              </Label>
              <InstructionsHintText text-size="small">
                Help couriers find your home in the dark and place your box in the right place. If
                possible, include a backup option (eg, if the lobby is locked or the gate code doesn’t
                work).
              </InstructionsHintText>
              <InstructionsTextarea
                name="instructions"
                value={formData.instructions || ''}
                onChange={onChange}
                rows={5}
                validators={['required']}
                placeholder="ex. The gate code is: 123#. You’re at the right house if you see lawn gnomes. Leave box on porch behind potted plant by front door. If gate code doesn’t work, call neighborhood office at 415-245-2231"
                errorMessages={[ERROR_MESSAGES.required]}
                withRequiredValidator={true}
              />
            </FormGroup>
          )}

          <FormGroup>
            <Label required>Delivery Phone</Label>
            <ValidatedInput
              name="phoneNumber"
              onChange={onChange}
              value={formatPhoneNumber(formData.phoneNumber) || formData.phoneNumber || ''}
              type="tel"
              placeholder="ex. (123) 456-7890"
              validators={['required', 'isPhoneValid']}
              withRequiredValidator={true}
              errorMessages={[ERROR_MESSAGES.required, ERROR_MESSAGES.phone]}
              readOnly={!this.props.userValidateEmail}
            />
          </FormGroup>

          <FormGroup>
            <CustomCheckbox
              label="Please send me SMS messages to notify me about order deadlines and delivery."
              name="receiveReminders"
              value="receiveReminders"
              onChange={this.toggleCheckbox}
              checked={formData.receiveReminders}
            />
          </FormGroup>
      </ContentForm>
    );
  }
}

export default UserDetailsForm;
