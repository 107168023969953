import React from 'react';

import { EXTERNAL_LINKS, HELP_EMAIL } from 'core/api/constants';
import Panel from 'settings/ui/components/Panel';
import { Text, Link } from 'core/ui/atoms';

function ContactUs() {
  return (
    <Panel title='Contact Us' data-contact-us>
      <Text no-margin>
        Email us at{' '}
        <Link href={EXTERNAL_LINKS.contactMail} target='_blank'>{HELP_EMAIL}</Link>
      </Text>
    </Panel>
  );
}

export default ContactUs;
