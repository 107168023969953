import React, { PureComponent } from 'react';
import styled from 'styled-components';

import theme from 'core/ui/theme';
import { Style, StyleGrid, StyleSection } from 'admin/ui/atoms';

class Colors extends PureComponent {
  render() {
    return (
      <StyleSection title='Colors'>
        <StyleGrid cols='repeat(4, 1fr)'>
          <Style name='Primary' caption='green'>
            <ColorSwatch color={theme.colors.primary} />
          </Style>
          <Style name='Secondary' caption='salmon/pink'>
            <ColorSwatch color={theme.colors.secondary} />
          </Style>
          <Style name='Off White' caption='accent background'>
            <ColorSwatch color={theme.colors.offWhite} />
          </Style>
          <Style name='Yellow' caption='rating stars'>
            <ColorSwatch color={theme.colors.yellow} />
          </Style>
        </StyleGrid>

        <StyleGrid cols='repeat(4, 1fr)'>
          <Style name='Black'>
            <ColorSwatch color={theme.colors.black} />
          </Style>
          <Style name='Gray' caption='darker'>
            <ColorSwatch color={theme.colors.grays.darker} />
          </Style>
          <Style name='Gray' caption='dark'>
            <ColorSwatch color={theme.colors.grays.dark} />
          </Style>
          <Style name='Gray' caption='regular'>
            <ColorSwatch color={theme.colors.grays.regular} />
          </Style>
          <Style name='Gray' caption='light'>
            <ColorSwatch color={theme.colors.grays.light} />
          </Style>
          <Style name='Gray' caption='lighter'>
            <ColorSwatch color={theme.colors.grays.lighter} />
          </Style>
          <Style name='Gray' caption='lightest'>
            <ColorSwatch color={theme.colors.grays.lightest} />
          </Style>
          <Style name='White'>
            <ColorSwatch color={theme.colors.white} />
          </Style>
        </StyleGrid>

        <StyleGrid cols='repeat(4, 1fr)'>
          <Style name='Danger'>
            <ColorSwatch color={theme.colors.danger} />
          </Style>
          <Style name='Success'>
            <ColorSwatch color={theme.colors.success} />
          </Style>
        </StyleGrid>
      </StyleSection>
    );
  }
}

const ColorSwatch = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: ${(p) => p.color};
  position: relative;

  :after {
    content: '${(p) => p.color}';
    position: absolute;
    bottom: 0;
    right: -50%;
    color: black;
    background: rgba(0,0,0,0.1);
    font-size: 0.75em;
    padding: 0.5em;
  }
`;

export default Colors;
