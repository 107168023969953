/* eslint-disable no-console */

import * as log from 'loglevel';
import { hostEnv } from 'reactAppConstants';
import { queryStringToObj } from 'core/api/utils';

// logging will automagically configure based on the .env file or override on the url e.g. level=trace
log.setDefaultLevel(log.levels.DEBUG);
if (hostEnv === 'development') {
  log.setLevel(log.levels.DEBUG);
}

const logger = () => {
  const queryParams = queryStringToObj(window.location.search);
  const level = queryParams.level;
  if (level) {
    // temporary url override (not persisted)
    log.setLevel(level, false);
  }
  return log;
};

log.debugGroupCollapsed = (...args) => {
  if (log.getLevel() >= log.levels.DEBUG) {
    console.groupCollapsed(...args);
  }
};

log.debugGroupEnd = () => {
  if (log.getLevel() >= log.levels.DEBUG) {
    console.groupEnd();
  }
};

export default logger;
