import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { generateRandomId } from 'core/ui/helpers';

class Radio extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func,
    label: PropTypes.node,
    subLabel: PropTypes.node,
    hint: PropTypes.string,
    className: PropTypes.string,
    disable: PropTypes.bool,
    hidden: PropTypes.bool,
  };

  render() {
    const { checked = false, hidden, disable, label, subLabel, hint = '', className = '', ...rest } = this.props;

    if(hidden) return null;

    const id = `radio-${generateRandomId()}`;

    return (
      <RadioCont htmlFor={id} className={className}>
        <Input id={id} type='radio' disabled={disable} checked={checked} {...rest} aria-label={label} />
        <Facade className='radio'>
            <Heading id='heading'>{label}</Heading>
            {subLabel && <SubHeading>{subLabel}</SubHeading>}
            {hint && <Hint>{hint}</Hint>}
        </Facade>
      </RadioCont>
    );
  }
}

const RadioCont = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-family: ${(p) => p.theme.typography.familyPoppins};
  margin-bottom: 0 !important;


  &.span_2 {
    grid-column: span 2;
    }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  height: 1em;
  width: 1em;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;

  :checked ~ .radio {
    border-color: ${(p) => p.theme.colors.primary};
    background: ${(p) => p.theme.colors.secondaryV2};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

    :disabled ~ .radio {
    border-color: #36443A;
    color: #36443A;
    opacity: 45%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  :focus-visible ~ .radio {
    border: 2px solid blue !important;
  }
`;

const Facade = styled.span`
  display: flex;
  position:relative;
  flex-direction: column;
  background-color: transparent;
  transition: border-color 0.15s ease-in-out;
  width: 100%;
  min-height: 97px;
  height: 100%;
  padding: 20px 0;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 5px;
  justify-content: center;
  border: 1px solid #36443A;
  align-items: center;
  text-align: center;

  p {
    margin: 0;
    display: inline-block;
  }
`;

const Heading = styled.div`
  font-weight: 500;
  font-size: 17px;
  margin: 0 1rem;
`;

const SubHeading = styled.p`
  font-weight: 400;
  font-size: 13px;
  font-style: italic;
  width: 90%;
`;

const Hint = styled.span`
background: #36443A;
color: #FFFFFF;
position: absolute;
top: 0;
right: 0;
padding: .1em .8em;
border-radius: 4px;
font-size: 12px;
`;

export default Radio;
