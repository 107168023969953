import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { List as ListAtom, ListItem as ListItemAtom } from 'core/ui/atoms';

class Nav extends PureComponent {
  static propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.object),
  }

  render() {
    const { navItems } = this.props;

    return (
      <NavContainer>
        <List unstyled horizontal>
          {(navItems || []).map((item, index) => (
            <ListItem key={`nav-${index}-${Math.random()}`}>
              {item}
            </ListItem>
          ))}
        </List>
      </NavContainer>
    );
  }
}

const NavContainer = styled.nav`
  height: 100%;
`;

const List = styled(ListAtom)`
  height: ${(p) => p.theme.layout.headerHeight};
  align-items: center;
`;

const ListItem = styled(ListItemAtom)`
  margin-top: -0.3em;
  font-size: ${(p) => p.theme.typography.sizes.small};
  margin-right: 2em;
  &:last-child {
    margin-right: 0;
  }
`;

export default Nav;
