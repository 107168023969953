import { useQuery } from '@apollo/client';

import { GET_TASTE_PREFERENCES } from 'core/api/apollo/queries';

function useTastePreferences() {
  const { loading, data: { currentUser } = {} } = useQuery(GET_TASTE_PREFERENCES);
  const { foodsToAvoid = [], spicyPreference } = currentUser || {};

  return { loading, foodsToAvoid, spicyPreference };
}

export default useTastePreferences;
