import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button as ButtonAtom, Icon } from 'core/ui/atoms';

class EditButton extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  }

  render() {
    const { onClick, ...rest } = this.props;

    return (
      <Button unstyled onClick={onClick} {...rest} >
        <EditIcon />
        <span>Edit</span>
      </Button>
    );
  }
}

const Button = styled(ButtonAtom)`
  position: relative;
  color: ${(p) => p.theme.typography.colors.muted};
`;

const EditIcon = styled((p) => <Icon icon='pencil' {...p} />)`
  position: relative;
  top: 0.1em;
  display: inline-block;
  font-size: 1.2em;
  margin-right: 0.5em;
`;

export default EditButton;
