import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button as ButtonAtom,
  Textarea,
} from 'core/ui/atoms';

const MAX_FOOD_RATING_COMMENT_LENGTH = 1000;

function ComponentCommentInput(props) {
  const [showComment, setShowComment] = useState(false);

  const onClick = () => setShowComment(!showComment);

  const { onBlur, disabled } = props;

  return (
    <CommentContainer>
      {showComment ?
        <CommentTextarea
          name="comment"
          placeholder="Please share any comments you have about this item."
          autoFocus
          onBlur={(e) => onBlur(e.target.value)}
          rows={5}
          maxlength={MAX_FOOD_RATING_COMMENT_LENGTH}
        />
      :
        <Button unstyled disabled={disabled} onClick={onClick}>Add Comments</Button>
      }
    </CommentContainer>
  );
}

ComponentCommentInput.propTypes = {
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const CommentContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
`;

const CommentTextarea = styled(Textarea)`
  margin-top: 20px;
`;

const Button = styled(ButtonAtom)`
  font-size: 0.75em;
`;

export default ComponentCommentInput;
