import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

import { Link, useLocation } from 'react-router-dom';
import useUser from 'core/hooks/useUser';
import { AspectRatio, Image, Text } from 'core/ui/atoms';
import FoodsToAvoidWarning from 'dashboard/ui/components/ProductPage/FoodsToAvoidWarning';
import PLACEHOLDER_IMG from 'core/assets/images/food_no_img.jpg';
import { productPath } from 'core/api/actions/navigation';
import CartControls from 'dashboard/ui/components/CatalogPage/ProductList/Item/CartControls';

function ProductItem(props) {
  const { product, addEnabled, lineItems, deliveryDay, ...rest } = props;
  const { userCan } = useUser();
  const enabled = addEnabled && userCan.placeOrder();

  const location = useLocation();

  return useMemo(() => {
  const searchParams = new URLSearchParams(location.search);
  const isShipping = searchParams.get('delivery');
  const zipcode = searchParams.get('zipcode');
  const queryParams = isShipping ? { delivery: isShipping, zipcode  }: {};
    const { primaryImage, name, subtitle } = product;

    const itemPath = productPath(deliveryDay.id, product.id, queryParams);

    return (
      <ItemCont data-product-item-id={product.id} {...rest}>
        <LazyLoad once>
          <ImageCont aspectHeight={100} aspectWidth={100}>
            <Image src={primaryImage ? primaryImage.smallUrl : PLACEHOLDER_IMG} alt={name} />
            <ClickOverlay to={itemPath} tabIndex={-1} aria-hidden={true} />
            <CartControls
              enabled={enabled}
              lineItems={lineItems}
              product={product}
              deliveryDay={deliveryDay}
              prodName={name}
            />
          </ImageCont>
        </LazyLoad>

        <Button to={itemPath}>
          <Heading>{name}</Heading>
          <Subtitle>{subtitle}</Subtitle>
          <FoodsToAvoidWarning product={product} />
        </Button>
      </ItemCont>
    );
  }, [product, enabled, lineItems, deliveryDay, rest, location]);
}

ProductItem.propTypes = {
  product: PropTypes.object.isRequired,
  deliveryDay: PropTypes.object.isRequired,
  order: PropTypes.object,
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  addEnabled: PropTypes.bool,
};

const ItemCont = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;
`;

const ImageCont = styled(AspectRatio)`
  position: relative;
  width: 100%;
  max-width: 100%;
`;

const ClickOverlay = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const Subtitle = styled(Text)`
  font-size: 0.86em;
  font-style: italic;
  color: ${(p) => p.theme.typography.colors.muted};
  margin: 0;
`;

const Heading = styled.h5`
  text-align: left;
  font-size: ${(p) => p.theme.typography.sizes.h6};
  text-transform: capitalize;
  margin-top: 12px;
  margin-bottom: 5px;
`;

const Button = styled(Link)`
  display: block;
  width: 100%;
  text-align: left;
`;

export default ProductItem;
