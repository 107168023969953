import React, { Fragment, PureComponent } from 'react';

import {
  Logos,
  Texts,
  Headings,
  Colors,
  Buttons,
  Forms,
  Icons,
  Modals,
  Alerts,
} from 'admin/ui/components/sections';

class StyleGuide extends PureComponent {
  render() {
    return (
      <Fragment>
        <Logos />
        <Texts />
        <Headings />
        <Colors />
        <Buttons />
        <Forms />
        <Icons />
        <Modals />
        <Alerts />
      </Fragment>
    );
  }
}

export default StyleGuide;
