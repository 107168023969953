import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  H2,
} from 'core/ui/atoms';
import ModalComponent from 'core/ui/components/Modal';
import { CANCEL_ORDER } from 'core/api/apollo/mutations';
import { goToRoot } from 'core/api/actions/navigation';
import BACKGROUND_IMG from 'dashboard/assets/images/skipModalBackground.png';
import { updateOrderCache, updateDeliveryDayCache } from 'core/api/cache/updaters';
import useErrorHandlers from 'core/hooks/useErrorHandlers';

function CancelOrderButton(props) {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const { text, orderId, ...rest } = props;

  if (!orderId) { return null; }

  return (
    <Fragment>
      <Button onClick={openModal} {...rest}>
        {text || 'Cancel this order'}
      </Button>
      {isOpen && (
        <CancelOrderModal
          orderId={orderId}
          onSuccess={goToRoot}
          onClose={closeModal}
        />
      )}
    </Fragment>
  );
}

CancelOrderButton.propTypes = {
  orderId: PropTypes.string,
  text: PropTypes.string,
};

function CancelOrderModal(props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const { handleGenericApolloError } = useErrorHandlers();
  const [cancelOrder] = useMutation(CANCEL_ORDER, {
    update: (cache, { data: { cancelOrder: { order, errors } } }) => {
      updateOrderCache(cache, order, errors);
      const deliveryDay = { id: order.deliveryDay.id, order: null };
      updateDeliveryDayCache(cache, deliveryDay, errors);
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const { onSuccess, orderId } = props;
    setIsProcessing(true);
    cancelOrder({ variables: { input: { orderId: orderId } } }).then(onSuccess).catch(onError);
  };

  const onClose = () => {
    setIsProcessing(false);
    props.onClose();
  };

  const onError = (error) => {
    setIsProcessing(false);
    handleGenericApolloError(error);
  };

  return (
    <Modal onClose={onClose}>
      <ModalContentContainer>
        <ModalImage image={BACKGROUND_IMG} />
        <ModalContent md={6}>
          <H2>Are you sure you want to cancel this order?</H2>
          <NoButton btn-primary btn-lg onClick={onClose}>No thanks</NoButton>
          <YesButton default onClick={onSubmit} disabled={isProcessing}>Yes, cancel this order</YesButton>
        </ModalContent>
      </ModalContentContainer>
    </Modal>
  );
}

CancelOrderModal.propTypes = {
  orderId: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const Modal = styled(ModalComponent)`
  width: 80%;
  max-width: 900px;
  ${(p) => p.theme.max('xs')`
    width: 100%;
  `}
`;

const ModalContentContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
`;

const ModalImage = styled.section`
  position: absolute;
  height: 100%;
  width: 55%;
  top: 0;
  left: 0;
  background-image: url(${(p) => p.image});
  background-size: cover;
  background-position: center center;
  ${(p) => p.theme.max('sm')`
    display: none;
  `}
`;

const ModalContent = styled.div`
  padding: 125px 35px;
  margin-left: 55%;
  ${(p) => p.theme.max('sm')`
    margin-left: 0;
  `}
`;

const NoButton = styled(Button)`
  margin-bottom: 30px;
`;

const YesButton = styled(Button)`
  display: block;
  padding: 0;
  font-size: 1em;
  text-align: left;
`;

export default CancelOrderButton;
