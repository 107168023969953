import React from 'react';
import styled from 'styled-components';

import Icon from 'core/ui/atoms/Icon';

const ArrowIcon = styled((p) => <Icon icon="arrow_r" {...p} />)`
  position: absolute;
  top: 50%;
  transform: translate(15px, -52%);
`;

export default ArrowIcon;
