import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ErrorOccurred from 'core/ui/components/ErrorOccurred';
import { reportError } from 'core/api/errors';

@withRouter
class ErrorBoundary extends Component {
  state = {
    error: null,
    info: null,
    pathname: this.props.location.pathname,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname !== prevState.pathname) {
      return { pathname: nextProps.location.pathname, error: null, info: null };
    }
    return null;
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
    reportError(error, info);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error) return <ErrorOccurred error={error} />;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  location: PropTypes.object,
};

export default ErrorBoundary;
