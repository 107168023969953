import React from 'react';
import styled from 'styled-components';
import { ValidatorComponent } from 'react-form-validator-core';

import Input from './Input';
import { InputErrorMessage } from './InputMessage';

class ValidatedInput extends ValidatorComponent {
  render() {
    const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
    const invalid = !this.isValid();

    return (
      <InputCont>
        <Input error={invalid} {...rest} />
        {invalid && <InputErrorMessage data-test-error={rest.name}>{this.getErrorMessage()}</InputErrorMessage>}
      </InputCont>
    );
  }
}

const InputCont = styled.div`
  position: relative;
`;

export default ValidatedInput;
