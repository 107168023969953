import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text } from 'core/ui/atoms';
import { displayDate } from 'core/ui/dateHelpers';

function Status(props) {
  const getStatusText = () => {
    const { status } = props;

    if (status.closed) {
      return 'No orders available';
    } else if (status.locked) {
      return 'The deadline to edit this order has passed';
    } else {
      return `Deadline to edit: ${displayDate(status.cutoff, 'deliveryCutoffDate')}`;
    }
  };

  return (
    <OrderStatusText>{getStatusText()}</OrderStatusText>
  );
}

Status.propTypes = {
  status: PropTypes.object.isRequired,
};

const OrderStatusText = styled(Text)`
  color: ${(p) => p.theme.typography.colors.muted};
  font-size: 14px;
  width: 100%;
  text-align: center;
  ${(p) => p.theme.max('md')`
    margin-left: ${(p) => p.theme.layout.mobilePadding};
  `}
`;

export default Status;
