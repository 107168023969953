import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';
import { useMount } from 'react-use';

import { scrollTop } from 'core/api/actions/scrolling';

import useProductFilters from 'dashboard/hooks/useProductFilters';

import { Box, Container, Flex } from 'core/ui/atoms';
import Loading from 'core/ui/components/Loading';
import FilterNav from 'dashboard/ui/components/CatalogPage/ProductList/FilterNav';
import ProductSection from 'dashboard/ui/components/CatalogPage/ProductList/ProductSection';
import NoResults from 'dashboard/ui/components/CatalogPage/ProductList/NoResults';

function ProductList(props) {
  const { productGroups } = props;
  const productFilters = useProductFilters();

  useMount(scrollTop);

  const count = productGroups.reduce((sum, group) => (
    sum += productFilters.filterProducts(group.products).length
  ), 0);

  return (
    <ProductsContainer relative>
      <ProductsFlex>
        <ResultBox hidden={productFilters.active.length <= 0}>
          {count} Result{count !== 1 ? 's' : ''}
        </ResultBox>
        <FilterNavBox md={2}>
          <FilterNav />
        </FilterNavBox>
        <ProductsBox md={10}>
          <ProductsContent
            {...props}
            productFilters={productFilters}
            count={count}
          />
        </ProductsBox>
      </ProductsFlex>
    </ProductsContainer>
  );
}

ProductList.propTypes = {
  loading: PropTypes.bool.isRequired,
  productGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  deliveryDay: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  order: PropTypes.object,
};

function ProductsContent(props) {
  const { productGroups, productFilters, loading, count, ...rest } = props;

  if (loading) {
    return <Loading text-size='regular' />;
  }

  if (count < 1) {
    return <NoResults />;
  }

  return (
    <Fragment>
      {
        _sortBy(productGroups, 'position').map((group) => (
          <ProductSection
            key={group.name}
            title={group.name}
            products={productFilters.filterProducts(group.products)}
            addEnabled={true}
            {...rest}
          />
        ))
      }
    </Fragment>
  );
}

ProductsContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  productGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  productFilters: PropTypes.object.isRequired,
  deliveryDay: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  order: PropTypes.object,
  count: PropTypes.number.isRequired,
};

const ProductsContainer = styled(Container)`
  margin-top: 3vh;
  margin-bottom: 5vh;
`;

const ProductsFlex = styled(Flex)`
  flex-wrap: wrap;
  flex-direction: row;
`;

const ResultBox = styled(Box)`
  font-weight: 500;
  order: -1;
  flex: 1 0 100%;
  text-align: right;

  ${(p) => p.theme.max('md')`
    order: 2;
    flex: initial;
    flex: 1 0 50%;
  `}

  ${(p) => p.hidden && css`
    visibility: hidden;
  `}
`;

const FilterNavBox = styled(Box)`
  order: 1;

  ${(p) => p.theme.max('md')`
    flex: 1 0 50%;
  `}
`;

const ProductsBox = styled(Box)`
  order: 2;

  ${(p) => p.theme.max('md')`
    flex: 1 0 100%;
  `}
`;

export default ProductList;
