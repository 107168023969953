import React, { Component } from 'react';

import { alertsVar } from 'core/api/apollo/cache';

function withAlerts(WrappedComponent) {
  return class extends Component {
    static displayName = 'showAlertHOC';

    showAlert = (message, { autoClose = false } = {}) => {
      const id = `alert-${Math.random()}`;
      alertsVar([...alertsVar(), { id, message, autoClose }]);
    };

    hideAlert = (alert) => {
      alertsVar(alertsVar().filter((i) => i.id !== alert.id));
    };

    clearAlerts = () => alertsVar([]);

    render() {
      const alerts = {
        show: this.showAlert,
        hide: this.hideAlert,
        clear: this.clearAlerts,
      };

      return (
        <WrappedComponent {...this.props} alerts={alerts} />
      );
    }
  };
}

export default withAlerts;
