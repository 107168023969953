import { css } from 'styled-components';

import ICOMOON_EOT from 'core/assets/fonts/icomoon/icomoon.eot';
import ICOMOON_TTF from 'core/assets/fonts/icomoon/icomoon.ttf';
import ICOMOON_WOFF from 'core/assets/fonts/icomoon/icomoon.woff';
import ICOMOON_SVG from 'core/assets/fonts/icomoon/icomoon.svg';

export default css`
  @font-face {
    font-family: 'icomoon';
    src:  url('${ICOMOON_EOT}?l0j2rn');
    src:  url('${ICOMOON_EOT}?l0j2rn#iefix') format('embedded-opentype'),
      url('${ICOMOON_TTF}?l0j2rn') format('truetype'),
      url('${ICOMOON_WOFF}?l0j2rn') format('woff'),
      url('${ICOMOON_SVG}?l0j2rn#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-gift:before {
    content: "\\e900";
  }
  .icon-star:before {
    content: "\\e901";
  }
  .icon-arrow_l:before {
    content: "\\e902";
  }
  .icon-arrow_r:before {
    content: "\\e903";
  }
  .icon-filter:before {
    content: "\\e904";
  }
  .icon-caret_down:before {
    content: "\\e905";
  }
  .icon-caret_l:before {
    content: "\\e906";
  }
  .icon-caret_r:before {
    content: "\\e907";
  }
  .icon-check:before {
    content: "\\e908";
  }
  .icon-close:before {
    content: "\\e909";
  }
  .icon-error:before {
    content: "\\e90a";
  }
  .icon-largecaret_r:before {
    content: "\\e90b";
  }
  .icon-largecaret_l:before {
    content: "\\e90c";
  }
  .icon-minus:before {
    content: "\\e90f";
  }
  .icon-plus:before {
    content: "\\e911";
  }
  .icon-pencil:before {
    content: "\\e913";
  }
  .icon-alert:before {
    content: "\\e914";
  }
  .icon-locked:before {
    content: "\\e915";
  }
  .icon-calendar:before {
    content: "\\e916";
  }
  .icon-search:before {
    content: "\\e986";
  }
  .icon-play3:before {
    content: "\\ea1c";
  }
  .icon-instagram:before {
    content: "\\ea92";
  }
`;
