import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Header from 'core/ui/components/Header/Header';
import LayoutBody from 'core/ui/components/LayoutBody';

class ErrorLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <main>
        <Header />
        <LayoutBody>
          {this.props.children}
        </LayoutBody>
      </main>
    );
  }
}

export default ErrorLayout;
