import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import _isEqual from 'lodash/isEqual';
import { ERROR_MESSAGE } from 'core/api/constants';

import handlesErrors from 'core/ui/components/HandlesErrors';

function withForm(mutationNames, { deriveVariablesFromFormData } = {}) {
  return (WrappedComponent) => {

      // eslint-disable-next-line react/display-name
    return compose(handlesErrors)(class extends Component {
      static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onError: PropTypes.func,
        onSuccess: PropTypes.func,
        initialData: PropTypes.object,
        displayApolloResponseErrors: PropTypes.func.isRequired,
      };

      constructor(props) {
        super(props);
        const initialData = props.initialData || {};

        // set default state
        this.state = {
          isProcessing: false,
          isSubmitted: false,
          formData: { ...initialData },
        };
      }

      setIsProcessing = () => this.setState({ isProcessing: true });
      unsetIsProcessing = () => this.setState({ isProcessing: false });
      setIsSubmitted = () => this.setState({ isSubmitted: true });
      unsetIsSubmitted = () => this.setState({ isSubmitted: false });

      handleChange = (update) => this.setState((st) => ({ formData: { ...st.formData, ...update } }));

      onChange = (e) => this.handleChange({ [e.target.name]: e.target.value });

      handleSubmit = (validator) => {
        this.setState({ isSubmitted: true, isProcessing: true });

        if (!validator || validator()) {

          const variables = deriveVariablesFromFormData ? deriveVariablesFromFormData(this.state.formData, this.props) : { input: this.state.formData };

          this.props.onSubmit({ variables }).then((data) => {
            const { data: response } = data;
            const errors = [];
            const mutations = (mutationNames && mutationNames.split(',')) || [];
            mutations.forEach((m) => response[m] && errors.push.apply(errors, response[m].errors));
            if (errors.length) {
              this.handleError(errors);
            } else {
              this.handleSuccess(data);
            }
          }, () => {
            this.handleError([{
              message: ERROR_MESSAGE,
            }], true);
          });
        } else {
          this.unsetIsProcessing();
        }
      };

      handleError = (errors, displayOnly = false) => {
        this.unsetIsProcessing();
        (this.props.onError || this.props.displayApolloResponseErrors)(errors, displayOnly);
      };

      handleSuccess = (data) => {
        this.unsetIsProcessing();
        (this.props.onSuccess && this.props.onSuccess(data));
      }

      // FIXME: This requires arrays to be in the same order
      isDirty = () => {
        return !_isEqual(this.state.formData, this.props.initialData);
      }

      render() {
        const { isProcessing, isSubmitted, formData } = this.state;

        const form = {
          handleChange: this.handleChange,
          handleSubmit: this.handleSubmit,
          onChange: this.onChange,
          isDirty: this.isDirty,
          isProcessing: isProcessing,
          setIsProcessing: this.setIsProcessing,
          unsetIsProcessing: this.unsetIsProcessing,
          isSubmitted: isSubmitted,
          setIsSubmitted: this.setIsSubmitted,
          unsetIsSubmitted: this.unsetIsSubmitted,
          formData: formData,
        };

        return (
          <WrappedComponent {...this.props} form={form} />
        );
      }
    });
  };
}

export default withForm;
