/* eslint-disable react/display-name */

import React from 'react';
import useReferralCode from '../../../core/hooks/useReferralCode';
import { DEFAULT_PROMO_CODE } from 'onramp/constants';

const withHookHoc = (Component) => (props) => {
  let { code } = DEFAULT_PROMO_CODE;
  let partnerLink = null;
  const { referralCode, partnerCode } = useReferralCode();

  if (referralCode) {
    code = referralCode.code;
    partnerLink = partnerCode;
  }



  return <Component code={code} partnerLink={partnerLink} {...props} />;
};

export default withHookHoc;
