import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { generateRandomId } from 'core/ui/helpers';


class Checkbox extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.node,
    subLabel: PropTypes.node,
    className: PropTypes.string,
  };

  render() {
    const { checked = false, label, subLabel, className = '', ...rest } = this.props;

    const id = `checkbox-${generateRandomId()}`;

    return (
      <CheckboxCont htmlFor={id} className={className} >
        <Input id={id} type="checkbox" checked={checked} {...rest} aria-label={label + ' ' + subLabel} />
        <Facade className='checkbox'>
            <Heading>{label}</Heading>
            {subLabel && <SubHeading>{subLabel}</SubHeading>}
        </Facade>
      </CheckboxCont>
    );
  }
}

const CheckboxCont = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: ${(p) => p.theme.typography.familyPoppins};
  margin-bottom: 0 !important;

  &.span_2 {
    grid-column: span 2;
    }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  height: 1em;
  width: 1em;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;

  :checked ~ .checkbox {
    color: ${(p) => p.theme.typography.colors.inverse};
    background: ${(p) => p.theme.colors.secondaryV2};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  :focus-visible ~ .checkbox {
    border: 2px solid blue !important;
  }
`;

const Facade = styled.span`
display: flex;
flex-direction: column;
  position: relative;
  color: transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out;
  background-color: transparent;
  width: 100%;
  min-height: 97px;
  padding: 20px 11px;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 5px;
  justify-content: center;
  border: 1px solid #36443A;
  align-items: center;
  text-align: center;
  p {
    margin: 0;
    display: inline-block;
  }
`;

const Heading = styled.p`
  font-weight: 500;
  font-size: 17px;
  margin: 0 2em;

`;

const SubHeading = styled.p`
  font-weight: 400;
  font-size: 13px;
  font-style: italic;
  width: 60%;
`;

export default Checkbox;
