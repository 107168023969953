import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ModalComponent from './Modal';

class SplitPanelModal extends PureComponent {
  static propTypes = {
    image: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  }

  render() {
    const { onClose, children, image } = this.props;

    return (
      <Modal onClose={onClose}>
        <ModalContentContainer>
          <ModalImage image={image} />
          <ModalContent>
            {children}
          </ModalContent>
        </ModalContentContainer>
      </Modal>
    );
  }
}

const Modal = styled(ModalComponent)`
  width: 80%;
  max-width: 900px;
  ${(p) => p.theme.max('sm')`
    width: 100%;
  `}
`;

const ModalContentContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
`;

const ModalContent = styled.div`
  margin-left: 55%;
  ${(p) => p.theme.max('sm')`
    margin-left: 0;
    margin-top: 50vh;
  `}
`;

const ModalImage = styled.section`
  position: absolute;
  height: 100%;
  width: 55%;
  top: 0;
  left: 0;
  background-image: url(${(p) => p.image});
  background-size: cover;
  background-position: center center;
  ${(p) => p.theme.max('sm')`
    height: 50vh;
    width: 100%;
  `}
`;

export default SplitPanelModal;
