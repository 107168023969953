import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import useUser from 'core/hooks/useUser';

import { Flex, H1 } from 'core/ui/atoms';
import NoticesComponent from 'dashboard/ui/components/DashboardPage/Notices';
import ReferralAdComponent from 'dashboard/ui/components/DashboardPage/ReferralAd';

const greeting = () => {
  const now = moment().hour();
  if (now >= 5 && now < 12) {
    return 'Good morning';
  } else if (now < 18) {
    return 'Good afternoon';
  }
  return 'Good evening';
};

function Hero() {
  const { currentUser } = useUser();

  return (
    <Flex direction='column'>
      <Heading>
        {greeting()}, {currentUser.firstName}
      </Heading>
      <Notices priority='HIGH' />
      <ReferralAd />
    </Flex>
  );
}

const Heading = styled(H1)`
  margin: 0;
  font-weight: 500;
  font-size: 2.6em;
  ${(p) => p.theme.max('sm')`
    font-size: 2em;
  `}
`;

const Notices = styled(NoticesComponent)`
  ${(p) => p.theme.max('sm')`
    width: 100%;
  `};
`;

const ReferralAd = styled(ReferralAdComponent)`
  background-color: transparent;
  color: #9CA3BC;
  border: none;
  button {
    color: #9CA3BC;
  }
  ${(p) => p.theme.max('sm')`
    section {
      padding-left: 5px;
    }
  `}
`;

export default Hero;
