import { useQuery } from '@apollo/client';
import moment from 'moment';

import { LIST_PRODUCT_INVENTORY_STATUSES } from 'core/api/apollo/queries';

const POLL_INTERVAL = moment.duration(5, 'minutes').asMilliseconds();

// NOTE: this polls all the inventory statuses for the given delivery day
function useInventoryStatus(deliveryDayId, productId) {
  const { data: { listProductInventoryStatuses: statuses = [] } = {} } = useQuery(LIST_PRODUCT_INVENTORY_STATUSES, {
    variables: { deliveryDayId },
    pollInterval: POLL_INTERVAL,
  });
  const status = statuses.find((status) => status.product.id === productId);

  return status || { soldOut: false };
}

export default useInventoryStatus;
