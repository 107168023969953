import styled from 'styled-components';

import { ReactComponent as BurstSVG } from 'onramp/assets/images/Burst.svg';

const Burst = styled(BurstSVG)`
  height: 10em;
  path {
    fill: ${(p) => p.$inverse ? p.theme.colors.white : p.theme.colors.primary };
  }
`;

export default Burst;
