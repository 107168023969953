import { gql } from '@apollo/client';

import {
  AddressFragment,
  UserFragment,
  BillingAccountFullFragment,
  ErrorFragment,
  OrderFragment,
  OneTimeProgramFragment,
  TransformationTribeFragment,
  NotificationPreferencesFragment,
  PrepaidPurchaseFragment,
  RateableOrderFragment,
  SubscriptionFragment,
  SubscriptionOptionsFragment,
  SubscriptionQuantityFragment,
  TastePreferencesFragment,
} from './fragments';

export const ACTIVATE_SUBSCRIPTION = gql`
  mutation ActivateSubscription($input: ActivateSubscriptionInput!) {
    activateSubscription(input: $input) {
      backgroundJob {
        id
      }
      purchase {
        startDay {
          id
          date
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const APPLY_PROMO_CODE = gql`
  mutation ApplyPromoCode($input: ApplyPromoCodeInput!) {
    applyPromoCode(input: $input) {
      user {
        id
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const VALIDATE_CUSTOMER = gql`
  mutation ValidateCustomer($input: ValidateCustomerInput!) {
    validateCustomer(input: $input) {
      user {
        ...UserFragment
      }
      newUser
      errors {
        ...ErrorFragment
      }
    }
  }
  ${UserFragment}
  ${ErrorFragment}
`;

export const CANCEL_ORDER = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      order {
        ...OrderFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${OrderFragment}
  ${ErrorFragment}
`;

export const COMPLETE_PREPAID_PURCHASE = gql`
  mutation CompletePrepaidPurchase($input: CompletePrepaidPurchaseInput!) {
    completePrepaidPurchase(input: $input) {
      backgroundJob {
        id
      }
      purchase {
        ...PrepaidPurchaseFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${PrepaidPurchaseFragment}
  ${ErrorFragment}
`;

export const CREATE_INITIAL_ORDERS = gql`
  mutation CreateInitialOrders($input: CreateInitialOrdersInput!) {
    createInitialOrders(input: $input) {
      backgroundJob {
        id
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const CREATE_ONE_TIME_PROGRAM = gql`
  mutation CreateOneTimeProgram($input: CreateOneTimeProgramInput!) {
    createOneTimeProgram(input: $input) {
      oneTimeProgram {
        ...OneTimeProgramFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${OneTimeProgramFragment}
  ${ErrorFragment}
`;

export const CREATE_TRANSFORMATION_TRIBE = gql`
  mutation CreateTransformationTribe($input: CreateTransformationTribeInput!) {
    createTransformationTribe(input: $input) {
      oneTimeProgram {
        ...TransformationTribeFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${TransformationTribeFragment}
  ${ErrorFragment}
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      order {
        ...OrderFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${OrderFragment}
  ${ErrorFragment}
`;

export const SET_DIET_AND_ALLERGIES_AND_CREATE_SUBSCRIPTION_QUANTITIES = gql`
  mutation SetDietAndAllergiesAndCreateSubscriptionQuantities(
    $setDietInput: SetDietInput!
    $setFoodsToAvoidInput: SetFoodsToAvoidInput!
    $createSubscriptionQuantitiesInput: CreateSubscriptionQuantitiesInput!
  ) {
    setDiet(input: $setDietInput) {
      user {
        id
        diet {
          id
        }
      }
      errors {
        ...ErrorFragment
      }
    }
    setFoodsToAvoid(input: $setFoodsToAvoidInput) {
      user {
        id
        ...TastePreferencesFragment
      }
      errors {
        ...ErrorFragment
      }
    }
    createSubscriptionQuantities(input: $createSubscriptionQuantitiesInput) {
      subscription {
        ...SubscriptionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${SubscriptionFragment}
  ${TastePreferencesFragment}
  ${ErrorFragment}
`;

export const CREATE_SUBSCRIPTION_QUANTITIES = gql`
  mutation CreateSubscriptionQuantities($input: CreateSubscriptionQuantitiesInput!) {
    createSubscriptionQuantities(input: $input) {
      subscription {
        ...SubscriptionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${SubscriptionFragment}
  ${ErrorFragment}
`;

export const RATE_PRODUCT_COMPONENT = gql`
  mutation RateProductComponent($input: RateProductComponentInput!) {
    rateProductComponent(input: $input) {
      order {
        ...RateableOrderFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${RateableOrderFragment}
  ${ErrorFragment}
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      signedIn
      user {
        ...UserFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${UserFragment}
  ${ErrorFragment}
`;

export const SEND_RESET_PASSWORD_EMAIL = gql`
  mutation SendResetPasswordEmail($input: SendResetPasswordEmailInput!) {
    sendResetPasswordEmail(input: $input) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const SET_ACCOUNT_CREDENTIALS = gql`
  mutation SetAccountCredentials($input: SetAccountCredentialsInput!) {
    setAccountCredentials(input: $input) {
      user {
        id
        email
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const SET_ACCOUNT_INFO = gql`
  mutation SetAccountInfo($input: SetAccountInfoInput!) {
    setAccountInfo(input: $input) {
      user {
        id
        firstName
        lastName
        email
        phoneNumber
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const SET_BILLING_INFO = gql`
  mutation SetBillingInfo($input: SetBillingInfoInput!) {
    setBillingInfo(input: $input) {
      billingAccount {
        ...BillingAccountFullFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${BillingAccountFullFragment}
  ${ErrorFragment}
`;

export const SET_DELIVERY_INSTRUCTIONS = gql`
  mutation SetDeliveryInstructions($input: SetDeliveryInstructionsInput!) {
    setDeliveryInstructions(input: $input) {
      user {
        id
        primaryAddress {
          ...AddressFragment
        }
      }
    }
  }
  ${AddressFragment}
`;

export const SET_DIET_AND_ALLERGIES = gql`
  mutation SetDietAndAllergies(
    $setDietInput: SetDietInput!
    $setFoodsToAvoidInput: SetFoodsToAvoidInput!
  ) {
    setDiet(input: $setDietInput) {
      user {
        id
        diet {
          id
        }
      }
      errors {
        ...ErrorFragment
      }
    }
    setFoodsToAvoid(input: $setFoodsToAvoidInput) {
      user {
        id
        ...TastePreferencesFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${TastePreferencesFragment}
  ${ErrorFragment}
`;

export const SET_FOODS_TO_AVOID = gql`
  mutation SetFoodsToAvoid($input: SetFoodsToAvoidInput!) {
    setFoodsToAvoid(input: $input) {
      user {
        id
        ...TastePreferencesFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${TastePreferencesFragment}
  ${ErrorFragment}
`;

export const SET_GENDER_AND_MOTIVATION = gql`
  mutation SetGenderAndMotivation(
    $setGenderInput: SetGenderInput!
    $setMotivationInput: SetMotivationInput!
  ) {
    setGender(input: $setGenderInput) {
      user {
        id
        gender
      }
      errors {
        ...ErrorFragment
      }
    }
    setMotivation(input: $setMotivationInput) {
      user {
        id
        motivation {
          id
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const SET_PRODUCT_SIZE = gql`
  mutation SetProductSize($input: SetProductSizeInput!) {
    setProductSize(input: $input) {
      subscription {
        ...SubscriptionFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${SubscriptionFragment}
  ${ErrorFragment}
`;

export const SET_NOTIFICATION_PREFERENCES = gql`
  mutation SetNotificationPreferences($input: SetNotificationPreferencesInput!) {
    setNotificationPreferences(input: $input) {
      notificationPreferences {
        ...NotificationPreferencesFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${NotificationPreferencesFragment}
  ${ErrorFragment}
`;

export const SET_DELIVERY_INFO_AND_SMS_PREFERENCES = gql`
  mutation SetDeliveryInfoAndSmsPreferences(
    $setDeliveryInfoInput: SetDeliveryInfoInput!
    $setSmsPreferencesInput: SetSmsPreferencesInput!
  ) {
    setDeliveryInfo(input: $setDeliveryInfoInput) {
      user {
        id
        phoneNumber
        primaryAddress {
          ...AddressFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
    setSmsPreferences(input: $setSmsPreferencesInput) {
      user {
        id
        notificationSettings {
          ...NotificationPreferencesFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${AddressFragment}
  ${ErrorFragment}
  ${NotificationPreferencesFragment}
`;

export const SET_DELIVERY_INFO_AND_SMS_AND_ACCOUNT_TYPE_PREFERENCES = gql`
  mutation SetDeliveryInfoAndSmsPreferences(
    $setUserAccountInput: SetUserAccountInput!
    $setDeliveryInfoInput: SetDeliveryInfoInput!
    $setSmsPreferencesInput: SetSmsPreferencesInput!
  ) {
    setUserAccount(input: $setUserAccountInput) {
      user {
        ...UserFragment
      }
    }
    setDeliveryInfo(input: $setDeliveryInfoInput) {
      user {
        id
        phoneNumber
        primaryAddress {
          ...AddressFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
    setSmsPreferences(input: $setSmsPreferencesInput) {
      user {
        id
        notificationSettings {
          ...NotificationPreferencesFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${AddressFragment}
  ${ErrorFragment}
  ${NotificationPreferencesFragment}
  ${UserFragment}
`;

export const SET_DELIVERY_INFO_AND_SMS_AND_ACCOUNT_CREDIENTIALS = gql`
  mutation SetDeliveryInfoAndSmsPreferences(
    $setAccountCredentialsInput: SetAccountCredentialsInput!
    $setDeliveryInfoInput: SetDeliveryInfoInput!
    $setSmsPreferencesInput: SetSmsPreferencesInput!
  ) {
    setAccountCredentials(input: $setAccountCredentialsInput) {
      user {
        id
        email
      }
      errors {
        ...ErrorFragment
      }
    }
    setDeliveryInfo(input: $setDeliveryInfoInput) {
      user {
        id
        phoneNumber
        primaryAddress {
          ...AddressFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
    setSmsPreferences(input: $setSmsPreferencesInput) {
      user {
        id
        notificationSettings {
          ...NotificationPreferencesFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${AddressFragment}
  ${ErrorFragment}
  ${NotificationPreferencesFragment}
`;

export const SET_PRIMARY_ZIP_CODE = gql`
  mutation SetPrimaryZipCode($input: SetPrimaryZipCodeInput!) {
    setPrimaryZipCode(input: $input) {
      user {
        id
        primaryAddress {
          ...AddressFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${AddressFragment}
  ${ErrorFragment}
`;

export const SET_SMS_PREFERENCES = gql`
  mutation SetSmsPreferences($input: SetSmsPreferencesInput!) {
    setSmsPreferences(input: $input) {
      user {
        id
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const SET_PASSWORD = gql`
  mutation SetPassword($input: SetPasswordInput!) {
    setPassword(input: $input) {
      user {
        ...UserFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${UserFragment}
  ${ErrorFragment}
`;

export const SET_SPICY_PREFERENCE = gql`
  mutation SetSpicyPreference($input: SetSpicyPreferenceInput!) {
    setSpicyPreference(input: $input) {
      user {
        id
        ...TastePreferencesFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${TastePreferencesFragment}
  ${ErrorFragment}
`;

export const SET_SUBSCRIPTION_OPTIONS = gql`
  mutation SetSubscriptionOptions($input: SetSubscriptionOptionsInput!) {
    setSubscriptionOptions(input: $input) {
      subscriptionOptions {
        ...SubscriptionOptionsFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${SubscriptionOptionsFragment}
  ${ErrorFragment}
`;

export const SET_SUBSCRIPTION_QUANTITIES = gql`
  mutation SetSubscriptionQuantities($input: SetSubscriptionQuantitiesInput!) {
    setSubscriptionQuantities(input: $input) {
      subscription {
        id
        subscriptionQuantities {
          ...SubscriptionQuantityFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${SubscriptionQuantityFragment}
  ${ErrorFragment}
`;

export const SIGN_IN = gql`
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      signedIn
      errors {
        ...ErrorFragment
      }
      user {
        ...UserFragment
      }
    }
  }
  ${ErrorFragment}
  ${UserFragment}
`;

export const SIGN_OUT = gql`
  mutation SignOut($input: SignOutInput!) {
    signOut(input: $input) {
      signedIn
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const SIGN_UP = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      signedIn
      user {
        ...UserFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${UserFragment}
  ${ErrorFragment}
`;

export const SKIP_ORDER = gql`
  mutation SkipOrder($input: SkipOrderInput!) {
    skipOrder(input: $input) {
      order {
        ...OrderFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${OrderFragment}
  ${ErrorFragment}
`;

export const UNSKIP_ORDER = gql`
  mutation UnskipOrder($input: UnskipOrderInput!) {
    unskipOrder(input: $input) {
      order {
        ...OrderFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${OrderFragment}
  ${ErrorFragment}
`;

export const UPDATE_LINE_ITEM = gql`
  mutation UpdateLineItem($input: UpdateLineItemInput!) {
    updateLineItem(input: $input) {
      order {
        ...OrderFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${OrderFragment}
  ${ErrorFragment}
`;

export const UPSERT_ITEM_ON_ORDER = gql`
  mutation UpsertItemOnOrder($input: UpsertItemOnOrderInput!) {
    upsertItemOnOrder(input: $input) {
      order {
        ...OrderFragment
      }
      deliveryDay {
        id
        order {
          id
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${OrderFragment}
  ${ErrorFragment}
`;

export const SET_ZIPCODE_BY_EMAIL = gql`
  mutation SetZipcodeByEmail($input: SetZipcodeByEmailInput!) {
    setZipcodeByEmail(input: $input) {
      signedIn
      user {
        ...UserFragment
      }
      zipcodeType
      errors {
        path
        message
      }
    }
  }
  ${UserFragment}
`;

export const SET_USER_ACCOUNT_TYPE = gql`
  mutation SetUserAccount($input: SetUserAccountInput!) {
    setUserAccount(input: $input) {
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
`;
