import styled, { css } from 'styled-components';
import { hiddenMixin } from 'core/ui/mixins';

const Container = styled.section`
  height: 100%;
  width: 100%;
  padding-right: 2vw;
  padding-left: 5vw;
  ${(p) => p.theme.min('md')`
    padding-right: 3vw;
    padding-left: 3vw;
  `}
  ${(p) => p.relative && css`position: relative;`}
  ${hiddenMixin}
`;

export default Container;
