import React from 'react';
import styled from 'styled-components';

import useUser from 'core/hooks/useUser';
import Header from 'core/ui/components/Header/Header';
import LogoutButton from 'core/ui/components/LogoutButton';
import { EXTERNAL_LINKS } from 'core/api/constants';
import { NavItem , MobileNavItem } from 'core/ui/atoms/V2';
import { isGuestEmail } from 'onramp/helpers';

import { ReactComponent as UserSVG } from 'core/assets/images/V2/icons/User.svg';

function OnrampHeader() {
  const { currentUser } = useUser();

  const LoginButton = (key) => {
    if (currentUser && !isGuestEmail(currentUser.email)) {
      return (
        <LogoutButton reload={true} key={key}>Change Your Zipcode</LogoutButton>
      );
    } else {
      return (
        <NavItem to="/sign-in" activeClassName='hidden' key={key}>
          Log-In <SVGIcon />
        </NavItem>
      );
    }
  };

  const rightNavItems = () => {
    return [
      <NavItem key="nav-programs"
        subMenus={[{ label: 'Signature Program', href: EXTERNAL_LINKS.signatureProgram },
                   { label: 'Post Partum Haute Mom', href: EXTERNAL_LINKS.postPartumHauteMomProgram },
                   { label: 'Transformation Tribe', href: EXTERNAL_LINKS.transformationTribeProgram },
                  ]}>
        Our Programs
      </NavItem>,
      <NavItem href={EXTERNAL_LINKS.ourStory} key="nav-our-story">Our Story</NavItem>,
      <LoginButton key="nav-sign-in" />,
    ];
  };

  const mobileNavItems = () => {
    return [
      <MobileNavItem href={EXTERNAL_LINKS.home} key="nav-home">Home</MobileNavItem>,
      <MobileNavItem key="nav-programs"
        subMenus={[{ label: 'Signature Program', href: EXTERNAL_LINKS.signatureProgram },
                   { label: 'Post Partum Haute Mom', href: EXTERNAL_LINKS.postPartumHauteMomProgram },
                   { label: 'Transformation Tribe', href: EXTERNAL_LINKS.transformationTribeProgram },
                  ]}>
        Our Programs
      </MobileNavItem>,
      <MobileNavItem href={EXTERNAL_LINKS.ourStory} key="nav-our-story">Our Story</MobileNavItem>,
      <MobileNavItem to="/sign-in" key="nav-sign-in">Log In</MobileNavItem>,
    ];
  };

  return (
    <Header rightNavItems={rightNavItems} mobileNavItems={mobileNavItems} />
  );
}

const SVGIcon = styled(UserSVG)`
  vertical-align: middle;
  margin-left: 0.3em;
`;

export default OnrampHeader;
