import { render } from 'react-dom';
import React from 'react';
import { onError } from '@apollo/client/link/error';
import ErrorOccurred from 'core/ui/components/ErrorOccurred';
import { ignorableError, reportError } from 'core/api/errors';
import Tracker from 'core/api/tracking/tracker';

const errorLink = onError(({ response, operation, graphQLErrors, networkError }) => {
  // report GraphQL errors if any
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      Tracker.trackError(error.message);
      reportError(error);
    });
  }

  // there are some errors that we don't want taking the site down!
  if (ignorableError(operation)) {
    // clear response errors if any
    if (response) {
      response.errors = null;
    }

    if (networkError) {
      // make sure we know it's happening
      reportError(networkError, { operation: operation.operationName });
    }

    // exit stage right, allow the page to render
    return;
  }

  if (networkError) {
    console.error(`[apolloClient] Caught a network error: ${networkError}`);
    render(<ErrorOccurred error={{ networkError }} />, document.getElementById('root'));
  }
});

export default errorLink;
