import { useQuery } from '@apollo/client';

import { LIST_PRODUCT_PREVIEWS, LIST_PRODUCT_DETAILS } from 'core/api/apollo/queries';

function useProductList(queryOptions = {}) {
  const { loading, data: { listProducts: previews = [] } = {} } = useQuery(LIST_PRODUCT_PREVIEWS, queryOptions);
  const { data: { listProducts: details = [] } = {} } = useQuery(LIST_PRODUCT_DETAILS, queryOptions);

  const products = previews.map((preview) => {
    const detail = details.find((d) => d.id === preview.id) || {};
    return { ...preview, ...detail };
  });

  return { products, loading };
}

export default useProductList;
