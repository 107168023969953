import React from 'react';
import styled from 'styled-components';

import {
  Container,
  Flex,
  H3,
  Icon,
  Page,
  RouterLink,
} from 'core/ui/atoms';

import BACKGROUND_IMAGE from 'dashboard/assets/images/dashboardBackground.jpg';

import Hero from 'dashboard/ui/components/DashboardPage/Hero';
import Orders from 'dashboard/ui/components/DashboardPage/Orders';
import RateFood from 'dashboard/ui/components/DashboardPage/RateFood';
import Discover from 'dashboard/ui/components/DashboardPage/Discover';
import News from 'dashboard/ui/components/DashboardPage/News';

function DashboardPage() {
  return (
    <Page scrollTop>
      <WelcomeSection>
        <Hero />
      </WelcomeSection>

      <MainContainer>
        <OrdersSection>
          <OrdersHeading>Edit orders</OrdersHeading>
          <Orders />
          <OrdersButtonFlex>
            <OrdersButton to='/orders'>
              See more orders
              <ArrowIcon />
            </OrdersButton>
          </OrdersButtonFlex>
        </OrdersSection>

        <SubContainer>
          <DashboardSection>
            <SectionHeading>Rate food</SectionHeading>
            <RateFood />
          </DashboardSection>

          <DashboardSection>
            <SectionHeading>Discover</SectionHeading>
            <Discover />
          </DashboardSection>

          <NewsSection>
            <SectionHeading>News</SectionHeading>
            <News />
          </NewsSection>
        </SubContainer>
      </MainContainer>
    </Page>
  );
}

const DashboardSection = styled(Container)`
  position: relative;
  padding: 30px 40px 0 40px;
  ${(p) => p.theme.max('sm')`
    padding: 20px 20px 0 20px;
  `}
`;

const WelcomeSection = styled(DashboardSection)`
  padding-bottom: 40px;
  ${(p) => p.theme.max('sm')`
    padding-bottom: 20px;
  `}
`;

const MainContainer= styled.div`
  background-image: url(${BACKGROUND_IMAGE});
  background-size: 100%;
  background-position: center -26vw;
  background-repeat: no-repeat;

  ${(p) => p.theme.max('md')`
    border-radius: 40px 40px 0 0;
  `};
`;

const SubContainer= styled.div`
  background: ${(p) => p.theme.colors.white};
  padding-top: 10px;
  ${(p) => p.theme.max('md')`
    border-radius: 40px 40px 0 0;
  `};
`;

const SectionHeading = styled(H3)`
  font-size: 1.25em;
  font-weight: 500;
  margin-top: 0;
  text-transform: capitalize;

  ${(p) => p.theme.max('sm')`
    font-size: 0.875em;
  `}
`;

const OrdersSection = styled(DashboardSection)`
  padding-bottom: 15px !important;
`;

const OrdersHeading = styled(SectionHeading)`
  position: absolute;
  top: 30px;
  left: 40px;
  color: ${(p) => p.theme.typography.colors.inverse} !important;

  ${(p) => p.theme.max('sm')`
    position: static;
  `}
`;

const OrdersButtonFlex = styled(Flex)`
  justify-content: center;

  margin-top: 15px !important;
`;

const OrdersButton = styled((props) => <RouterLink btn-secondary='true' {...props} />)`
  padding: 1em;
`;

const ArrowIcon = styled((props) => <Icon icon='arrow_r' {...props} />)`
  margin-left: 0.5em;
  position: relative;
  top: 1px;
`;

const NewsSection = styled(DashboardSection)`
  padding-bottom: 30px;
`;

export default DashboardPage;
