import React from 'react';
import styled from 'styled-components';
import Button from 'core/ui/atoms/Button';
import Icon from 'core/ui/atoms/Icon';

// FIXME: this could use a better name
const IconButton = styled(({ label, ...rest }) => <Button {...rest} />)`
  display: inline-flex;
  width: 1em;
  height: 1em;
  padding: 0;
  border-radius: 50%;
  font-weight: normal;
  align-items: center;
  justify-content: center;
`;

IconButton.Icon = styled(Icon)`
  font-size: 0.4em;
`;

IconButton.Text = styled.span`
  font-size: 0.4em;
`;

export default IconButton;
