import styled from 'styled-components';

import { H1 } from 'core/ui/atoms';

const Header = styled(H1)`
  margin: 0;
  position: relative;
  z-index: 2;
`;

export default Header;
