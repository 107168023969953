import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H4 } from 'core/ui/atoms';

function InfoSection(props) {
  const { title, ...rest } = props;

  return (
    <InfoSectionContainer {...rest}>
      {title && <InfoHeading>{title}</InfoHeading>}
      {props.children}
    </InfoSectionContainer>
  );
}

InfoSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};


const InfoSectionContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
`;

const InfoHeading = styled(H4)`
  margin-top: 0;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.black};
`;

export default InfoSection;
