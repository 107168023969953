import React from 'react';
import styled from 'styled-components';
import { EditButton } from 'core/ui/components';

const Edit = styled((props) => <EditButton {...props} /> )`
  color: ${(p) => p.theme.typography.colors.muted};
  padding: 0;
  margin: auto 0 0 auto;
  font-size: ${(p) => p.theme.typography.sizes.smaller};
`;

export default Edit;
