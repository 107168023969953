import React from 'react';

import { SettingsGrid } from 'settings/ui/atoms';
import ContactUs from 'settings/ui/components/ContactUs';
import NeedHelp from 'settings/ui/components/NeedHelp';
import SettingsPage from 'settings/ui/components/SettingsPage';

function ContactUsPage() {
  return (
    <SettingsPage scrollTop>
      <SettingsGrid template='repeat(3, 1fr)'>
        <NeedHelp />
        <ContactUs />
      </SettingsGrid>
    </SettingsPage>
  );
}

export default ContactUsPage;
