import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import useDeliveryDayStatus from 'dashboard/hooks/useDeliveryDayStatus';

import { Page, Container } from 'core/ui/atoms';
import LoadingScreen from 'core/ui/components/LoadingScreen';
import { ClosedMessage, NotPublishedMessage } from 'dashboard/ui/components/DeliveryDayStatus';

function CatalogPage(props) {
  const { children } = props;
  const { deliveryDayId } = useParams();
  const status = useDeliveryDayStatus(deliveryDayId);

  if (status.loading) {
    return (
      <LoadingScreen />
    );
  }

  return (
    <Page scrollTop>
      <CatalogView status={status}>
        {children}
      </CatalogView>
    </Page>
  );
}

CatalogPage.propTypes = {
  children: PropTypes.node,
};

function CatalogView(props) {
  const { status, children } = props;

  const { deliveryDay, order } = status;

  if (status.closed) {
    return (
      <Container>
        <ClosedMessage reason={status.closureReason} />
      </Container>
    );
  }

  if (!status.menuPublished) {
    return (
      <Container>
        <NotPublishedMessage />
      </Container>
    );
  }

  return React.cloneElement(children, { deliveryDay, order, status });
}

CatalogView.propTypes = {
  status: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default CatalogPage;
