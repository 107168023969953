import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function StatusHeader(props) {
  const { status, ...rest } = props;
  const { closed, skipped, locked, menuPublished } = status;

  return useMemo(() => {
    let statusText = '';

    if (closed) {
      statusText = 'Business closed';
    } else if (skipped) {
      statusText = 'Skipped';
    } else if (locked) {
      statusText = 'Locked';
    } else if (!menuPublished) {
      statusText = 'Menu not published';
    }

    return (
      <Wrapper danger={closed || skipped || (!locked && !menuPublished)} {...rest}>
        {statusText}
      </Wrapper>
    );
  }, [closed, skipped, locked, menuPublished, rest]);
}

StatusHeader.propTypes = {
  status: PropTypes.object.isRequired,
};

const Wrapper = styled.div`
  color: ${(p) => p.danger ? '#FF3232' : p.theme.typography.colors.primary};
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
`;

export default StatusHeader;
