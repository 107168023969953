import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { displayDate } from 'core/ui/dateHelpers';

import { Text } from 'core/ui/atoms';

function CheckoutWarning(props) {
  const { status, ...rest } = props;
  const cutoff = displayDate(status.cutoff, 'deliveryCutoffDate');

  return (
    <CheckoutWarningContainer {...rest}>
      Items added to your order will automatically be processed at {cutoff}. There is no checkout.
      If you don't want to order, clear your cart before {cutoff}.
    </CheckoutWarningContainer>
  );
}

CheckoutWarning.propTypes = {
  status: PropTypes.object.isRequired,
};

const CheckoutWarningContainer = styled(Text)`
  font-size: 0.75em;
`;

export default CheckoutWarning;
