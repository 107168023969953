import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex, H5, Icon, Image, RouterLink, Text } from 'core/ui/atoms';
import Money from 'core/api/Money';
import { displayWeight } from 'dashboard/ui/helpers';
import { productPath } from 'core/api/actions/navigation';
import PLACEHOLDER_IMG from 'core/assets/images/food_no_img.jpg';
import LineItemQuantity from 'dashboard/ui/components/Cart/LineItemQuantity';
import RemoveFromOrderButton from 'dashboard/ui/components/RemoveFromOrderButton';

function LineItem(props) {
  const { order, lineItem, editable, ...rest } = props;
  const { product, variant, quantity } = lineItem;
  const { name, primaryImage } = product;

  const productPathForItem = () => productPath(props.order.deliveryDay.id, props.lineItem.product.id);

  return (
    <Wrapper data-line-item-id={lineItem.id} {...rest}>
      {(lineItem.gratis || lineItem.message) && (
        <LineItemHeadline>
          {lineItem.gratis && <GiftTag><GiftIcon /> Free Gift</GiftTag>}
          {lineItem.message && <Text text-size='smaller' no-margin>{lineItem.message}</Text>}
        </LineItemHeadline>
      )}
      <LineItemFlex alignItems="center">
        <Box shrink={0}>
          <RouterLink btn-unstyled to={productPathForItem()}>
            <ProductImage data-product-image src={primaryImage ? primaryImage.smallUrl : PLACEHOLDER_IMG} />
          </RouterLink>
        </Box>
        <DetailsBox md={6}>
          <RouterLink to={productPathForItem()} btn-unstyled>
            <ProductName data-product-heading>{name}</ProductName>
            <Text text-small no-margin weight='normal'>
              {variant.size.name} {displayWeight(variant.weightOunces)}
            </Text>
          </RouterLink>
          {editable && (
            <SecondaryButton unstyled order={order} lineItem={lineItem}>
              Remove
              <span className="sr-only">&nbsp;{name} {variant.size.name} {displayWeight(variant.weightOunces)} from order</span>
            </SecondaryButton>
          )}
        </DetailsBox>

        <ControlBox xs={3} sm={2} md={1}>
          <LineItemQuantity
            order={order}
            lineItem={lineItem}
            quantity={quantity}
            editable={editable}
          />
          <Price>{Money(lineItem.unitPrice).toFormat()}</Price>
        </ControlBox>
      </LineItemFlex>
    </Wrapper>
  );
}

LineItem.propTypes = {
  lineItem: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  editable: PropTypes.bool,
};

const Wrapper = styled.div`
`;

const LineItemFlex = styled(Flex)`
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
`;

const LineItemHeadline = styled.div`
  margin-bottom: 0.5em;
  display: flex;
  align-items: flex-start;
`;

const GiftTag = styled(H5)`
  display: inline-flex;
  align-items: center;
  margin: 0 1em 1em 0;
  padding: 0.5em 1em;
  color: ${(p) => p.theme.typography.colors.inverse};
  background-color: ${(p) => p.theme.colors.black};
  border-radius: ${(p) => p.theme.borders.radius};
  font-weight: normal;
  text-transform: uppercase;
  font-size: ${(p) => p.theme.typography.sizes.smallest};
`;

const GiftIcon = styled((props) => <Icon icon='gift' {...props} />)`
  font-size: 2em;
  margin-right: 0.5em;
`;

const ProductImage = styled(Image)`
  height: 90px;
  width: 90px;
  cursor: pointer;
`;

const ProductName = styled.h6`
  margin: 0;
  font-size: 1em;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 1em;
  cursor: pointer;
`;

const SecondaryButton = styled(RemoveFromOrderButton)`
  font-size: ${(p) => p.theme.typography.sizes.small};
  font-weight: normal;
  color: ${(p) => p.theme.typography.colors.muted};
  margin: auto;
  &:hover {
    color: ${(p) => p.theme.colors.grays.dark};
  }
`;

const DetailsBox = styled(Box)`
  flex-grow: 1;
  padding: 0 1em;
  min-width: 0;
  align-items: flex-start;
`;

const Price = styled(Text)`
  text-align: right;
  font-weight: 500;
  font-size: 1em;
  margin: 1.5em 0 0 0;
`;

const ControlBox = styled(Box)`
  margin-left: auto;
`;

export default LineItem;
