import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment/moment';

import client from 'core/api/apollo/apolloClient';
import { GET_NEXT_CUTOFF_TIME } from 'core/api/apollo/queries';
import AlertModal from 'core/ui/components/AlertModal';

@graphql(GET_NEXT_CUTOFF_TIME)
class CutoffModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    data: PropTypes.object.isRequired,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      timer: null,
      open: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.trackCutoffTime();
  }

  componentDidUpdate() {
    this.trackCutoffTime();
  }

  componentWillUnmount() {
    this.stopTimer();
    this.mounted = false;
  }

  setStateSafely = (...args) => this.mounted && this.setState(...args);

  startTimer = () => {
    const { data: { getNextCutoffTime: cutoffTime } } = this.props;
    const timeout = moment(cutoffTime).diff(moment());

    this.stopTimer();

    const timer = setTimeout(() => {
      if (moment().isSameOrAfter(cutoffTime)) {
        this.openModal();
      } else {
        this.startTimer();
      }
    }, timeout);

    this.setStateSafely({ timer });
  };

  stopTimer = () => {
    clearTimeout(this.state.timer);
    this.setStateSafely({ timer: null });
  };

  trackCutoffTime = () => {
    const { data: { getNextCutoffTime: cutoffTime } } = this.props;
    const { timer, open } = this.state;

    if (timer || open || !cutoffTime) {
      return;
    }

    this.startTimer();
  };

  openModal = () => {
    this.setStateSafely({ open: true, timer: null });
    client.resetStore();
  };

  closeModal = () => {
    this.setStateSafely({ open: false });
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { open } = this.state;

    if (!open) {
      return null;
    }

    return (
      <AlertModal
        heading='Hey there!'
        buttonText='Gotcha'
        onClick={this.closeModal}
      >
          {this.props.children}
      </AlertModal>
    );
  }
}

export default CutoffModal;
