import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Image from 'core/ui/atoms/Image';

class Video extends PureComponent {
  static propTypes = {
    fallback: PropTypes.string.isRequired,
    children: PropTypes.node,
  };

  render() {
    const { fallback, children, ...rest } = this.props;

    // check if the device supports HTML5 video
    if (!document.createElement('video').canPlayType) {
      // not supported
      return <FallbackImage src={fallback} {...rest} />;
    }

    return (
      <VideoTag autoPlay={true} loop={true} muted={true} fullscreen={false} {...rest}>
        {children}
      </VideoTag>
    );
  }
}

const FallbackImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const VideoTag = styled.video`
  max-width: 100%;
`;

export default Video;
