import styled from 'styled-components';

import { Box } from 'core/ui/atoms';

const PageBox = styled(Box)`
  display: flex;
  flex-direction: column;

  width: 100%;
  // background-color: #FFFFFF;
`;

export default PageBox;
