import { debugTrackingEnabled, googleTagManagerEnabled } from 'reactAppConstants';

window.dataLayer = window.dataLayer || [];

const logEvent = (data = {}) => {
  try {
    if (debugTrackingEnabled()) {
      console.debug(Tracker.name, Tracker.enabled ? 'enabled' : 'disabled', data);
    }
  } catch(e) {
    console.error(Tracker.name, 'Error while logging', e, data);
  }
};

const pushGTMData = (data) => {
  Tracker.enabled && window.dataLayer.push(data);
  logEvent(data);
};

const Tracker = {
  name: 'Google Tag Manager',
  enabled: !!googleTagManagerEnabled,
  events: {
    pageView: () => {},
    lead: () => pushGTMData({ event: 'lead' }),
    registration: () => pushGTMData({ event: 'registration' }),
    addToCart: ({ name, variant, price }) => {
      pushGTMData({ ecommerce: null });
      pushGTMData({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'USD',
          add: {
            products: [{
              name,
              product: 'Onramp Plan',
              variant,
              price,
              quantity: 1,
            }],
          },
        },
      });
    },
    applyPromoCode: ({ promoCode }) => {
      pushGTMData({ ecommerce: null });
      pushGTMData({
        event: 'addPromo',
        ecommerce: {
          currencyCode: 'USD',
          promoClick: {
            promotions: [{
              id: promoCode,
            }],
          },
        },
      });
    },
    checkoutProgress: ({ name, variant, price, step }) => {
      pushGTMData({ ecommerce: null });
      pushGTMData({
        event: 'checkoutProgress',
        ecommerce: {
          currencyCode: 'USD',
          checkout: {
            actionField: { step },
            products: [{
              name,
              product: 'Onramp Plan',
              variant,
              price,
              quantity: 1,
            }],
          },
        },
      });
    },
    purchase: ({ transactionId, purchaseAmount, name, variant }) => {
      pushGTMData({ ecommerce: null });
      pushGTMData({
        event: 'purchase',
        eventCategory: 'UX',
        eventAction: 'Click',
        eventLabel: 'Purchase',
        ecommerce: {
          currencyCode: 'USD',
          purchase: {
            actionField: {
              id: transactionId,
              revenue: purchaseAmount.toRoundedUnit(2),
            },
            products: [{
              name,
              product: 'Onramp Plan',
              variant,
              price: purchaseAmount.toRoundedUnit(2),
              quantity: 1,
            }],
          },
        },
      });

      pushGTMData({
        event: 'conversion',
        conversionValue: purchaseAmount.toRoundedUnit(2),
        conversionCurrency: 'USD',
        transactionId: transactionId,
      });
    },
    error: () => {},
  },
};

export default Tracker;
