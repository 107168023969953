import React from 'react';
import styled from 'styled-components';

import { SettingsGrid } from 'settings/ui/atoms';
import SettingsPage from 'settings/ui/components/SettingsPage';
import AccountInfo from 'settings/ui/components/AccountInfo';
import ShippingAddress from 'settings/ui/components/ShippingAddress';
import DeliveryInstructions from 'settings/ui/components/DeliveryInstructions';
import NotificationSettingsComp from 'settings/ui/components/NotificationSettings';

function AccountInfoPage() {
  return (
    <SettingsPage scrollTop>
      <SettingsGrid>
        <AccountInfo />
        <ShippingAddress />
        <DeliveryInstructions />
        <NotificationSettings />
      </SettingsGrid>
    </SettingsPage>
  );
}

const NotificationSettings = styled(NotificationSettingsComp)`
  grid-column: 1 / 3;

  ${(p) => p.theme.max('md')`
    grid-column: 1;
  `}
`;

export default AccountInfoPage;
