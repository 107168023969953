import _get from 'lodash/get';

import RouteAccessCheck, {
  canOrder,
  supportedAddress,
  userAuthenticated,
  userUnauthenticated,
  unsupportedAddress,
} from 'core/api/routeAccessCheck';
import Tracker from 'core/api/tracking/tracker';

import { onrampSequenceForUser, ROUTE_MAP as SEQUENCE_MAP } from 'onramp/api/routeSequence';

import SuccessLayout from 'onramp/ui/layouts/V2/SuccessLayout';
import OnrampLayout from 'onramp/ui/layouts/OnrampLayout';
import OnrampLayoutV2 from 'onramp/ui/layouts/V2/OnrampLayout';
import SignInExistingUserPage from 'onramp/ui/pages/SignInExistingUserPage';
import SignUpPage from 'onramp/ui/pages/SignUpPage';
import InitOrderPage from 'onramp/ui/pages/V2/InitOrderPage';
import NewBillingInfoPage from 'onramp/ui/pages/V2/NewBillingInfoPage';
import ProgramConfirmationPage from 'onramp/ui/pages/V2/ProgramConfirmationPage';
import OutOfZonePage from 'onramp/ui/pages/OutOfZonePage';

export const canNotOrder = new RouteAccessCheck({
  check: (user, cache) => !canOrder.check(user, cache),
  pathname: '/',
  name: 'canNotOrder',
});


const onrampUserUnauthenticated = new RouteAccessCheck({
  check: userUnauthenticated.check,
  pathname: '/onramp/init-order',
  name: 'onrampUserUnauthenticated',
});

const onrampSupportedAddress = new RouteAccessCheck({
  check: (user, cache) => {
    if (_get(user, 'primaryAddress.deliveryOption.adminOnly')) {
      return false;
    }

    return supportedAddress.check(user, cache);
  },
  pathname: SEQUENCE_MAP.outOfDeliveryZone.path,
  name: 'onrampSupportedAddress',
});

const onrampUnsupportedAddress = new RouteAccessCheck({
  check: (user, cache) => !onrampSupportedAddress.check(user, cache),
  pathname: unsupportedAddress.pathname,
  name: 'onrampUnsupportedAddress',
});

// FIXME: the redirect pathname should be the first authenticated onramp route (not hardcoded to /onramp/about-you)
// Onramp route exists for user
export const onrampAccessCheck = (route) => (new RouteAccessCheck({
  check: (user, cache) => {
    const sequence = onrampSequenceForUser(user, cache);
    const currentIndex = sequence.findIndex((step) => step.name === route.name);
    /* eslint-disable-next-line no-console */
    console.log('check', sequence, currentIndex);
    if (currentIndex < 0) {
      return false; // route is not valid for the user
    }

    const prevSteps = sequence.slice(0, currentIndex);

    return prevSteps.every((step) => step.isComplete(user, cache));
  },
  pathname: SEQUENCE_MAP.initOrder.path,
  name: 'onrampAccessCheck',
}));
export const ROUTES = [
  {
    ...SEQUENCE_MAP.signIn, //This is used to sign in users who have not created any orders or passwords.
    component: SignInExistingUserPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
      inverse: true,
      disableProgress: true,
    },
    accessChecks: [
      onrampUserUnauthenticated,
    ],
  },
  {
    ...SEQUENCE_MAP.signUp,
    component: SignUpPage,
    layout: OnrampLayout,
    layoutProps: {
      promoBannerEnabled: true,
      inverse: true,
      disableProgress: true,
    },
    accessChecks: [
      onrampUserUnauthenticated,
    ],
  },
  {
    ...SEQUENCE_MAP.initOrder,
    component: InitOrderPage,
    layout: OnrampLayoutV2,
    layoutProps: {
      promoBannerEnabled: true,
    },
    accessChecks: [
      canNotOrder,
      onrampAccessCheck(SEQUENCE_MAP.initOrder),
    ],
  },
  {
    ...SEQUENCE_MAP.newBillingInfo,
    component: NewBillingInfoPage,
    layout: OnrampLayoutV2,
    layoutProps: {
      promoBannerEnabled: true,
    },
    onLoad: () => Tracker.trackCheckoutProgress({ step: 2 }),
    accessChecks: [
      userAuthenticated,
      canNotOrder,
      onrampSupportedAddress,
      onrampAccessCheck(SEQUENCE_MAP.newBillingInfo),
    ],
  },
  {
    ...SEQUENCE_MAP.prepaidConfirmation,
      component: ProgramConfirmationPage,
      layout: SuccessLayout,
      layoutProps: {
        promoBannerEnabled: true,
      },
      accessChecks: [
        userAuthenticated,
      ],
    },
  {
    ...SEQUENCE_MAP.outOfDeliveryZone,
    component: OutOfZonePage,
    layout: OnrampLayout,
    layoutProps: {
      disableProgress: true,
    },
    accessChecks: [
      userAuthenticated,
      onrampUnsupportedAddress,
    ],
  },
];

const ROUTE_MAP = ROUTES.reduce((obj, route) => ({ ...obj, [route.name]: route }), {});

export default ROUTE_MAP;
