import React, { Fragment, PureComponent } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';

import { CREATE_INITIAL_ORDERS } from 'core/api/apollo/mutations';
import { BackgroundJobStatusCheck, handlesErrors } from 'core/ui/components';
import CustomizePlanForm from 'onramp/ui/components/V2/CustomizePlanPage/CustomizePlanForm';
import CreatingOrderOverlay from 'onramp/ui/components/V2/CustomizePlanPage/CreatingOrderOverlay';
import { ERROR_MESSAGE } from 'core/api/constants';

@handlesErrors
@graphql(CREATE_INITIAL_ORDERS)
class OrderCreator extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
    displayApolloResponseErrors: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    // set default state
    this.state = {
      backgroundJobId: null,
      overlayOpen: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setStateSafely = (...args) => this.mounted && this.setState(...args);

  onSubmit = () => {
    this.setStateSafely({ overlayOpen: true });
  }

  onSuccess = () => {
    const { mutate } = this.props;
    mutate({ variables: { input: {} } }).then(({ data }) => {
      const { createInitialOrders: { backgroundJob, errors } } = data;
      if (errors.length) {
        this.handleErrors(errors);
      } else {
        this.setStateSafely({ backgroundJobId: backgroundJob.id });
      }
    }).catch(() => {
      this.handleErrors([{ message: ERROR_MESSAGE }]);
    });
  }

  handleErrors = (errors) => {
    this.setStateSafely({ backgroundJobId: null, overlayOpen: false });
    this.props.displayApolloResponseErrors(errors);
  }

  render() {
    const { currentUser, onSuccess } = this.props;
    const { overlayOpen, backgroundJobId } = this.state;

    return (
      <Fragment>
        <CustomizePlanForm
          currentUser={currentUser}
          onSubmit={this.onSubmit}
          onSuccess={this.onSuccess}
          disabled={overlayOpen}
        />
        <CreatingOrderOverlay open={overlayOpen} />
        { backgroundJobId && (
          <BackgroundJobStatusCheck
            backgroundJobId={backgroundJobId}
            onSuccess={onSuccess}
            onError={this.handleErrors}
          />
        )}
      </Fragment>
    );
  }
}

export default OrderCreator;
