import styled from 'styled-components';

const SubHeader = styled.h3`
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
  ${(p) => p.theme.max('md')`
    margin-bottom: calc(${p.theme.layout.mobilePadding} * 2);
    padding: 0 calc(${p.theme.layout.mobilePadding} * 2);
  `}
  ${(p) => p.theme.max('sm')`
    padding: 0 ${p.theme.layout.mobilePadding};
  `}
`;

export default SubHeader;
