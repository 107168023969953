import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Icon } from 'core/ui/atoms';

class MobileNavItem extends PureComponent {
  static propTypes = {
    to: PropTypes.string,
    subMenus: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        href: PropTypes.string,
      }),
    ),
    children: PropTypes.node,
  };

  state = {
    isSubMenuOpen: false,
  };

  toggleSubMenu = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({
      isSubMenuOpen: !prevState.isSubMenuOpen,
    }));
  };

  render() {
    const { to, subMenus, children } = this.props;
    const { isSubMenuOpen } = this.state;
    const Comp = to ? CustomLink : Anchor;

    return (
      <div>
        <MainItemContainer>
          <MainItem {...(subMenus && { onClick: this.toggleSubMenu })}>
            <Comp {...this.props}>{children}</Comp>
            {subMenus && subMenus.length > 0 && (
              <CustomIcon icon='caret_down' />
            )}
          </MainItem>
          {subMenus && subMenus.length > 0 && isSubMenuOpen && (
            <SubMenu>
              {subMenus.map((submenu, index) => (
                <SubMenuItem href={submenu.href} key={index}>
                  &#8226; {submenu.label}
                </SubMenuItem>
              ))}
            </SubMenu>
          )}
        </MainItemContainer>
      </div>
    );
  }
}

const CustomLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

const Anchor = styled.a`
  text-decoration: none;
  color: inherit;
`;

const MainItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #C9D5C8;
`;

const MainItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: ${(p) => p.theme.typography.sizes.h1};
  color: ${(p) => p.theme.colors.creamWhite};

  &:hover {
    color: ${(p) => p.theme.colors.offWhite};
  }
`;

const SubMenu = styled.div`
  padding-left: 1em;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 1.5em;
`;

const CustomIcon = styled(Icon)`
  font-size: 0.625em;
  color: ${(p) => p.theme.colors.green.light};
`;

const SubMenuItem = styled.a`
  display: block;
  text-decoration: none;
  font-family: ${(p) => p.theme.typography.familyPoppins} !important;
  padding: 0.1em 0.5em !important;
  font-size: ${(p) => p.theme.typography.sizes.smaller} !important;
  color: ${(p) => p.theme.colors.creamWhite};

  &:hover {
    color: ${(p) => p.theme.colors.offWhite};
  }
`;

export default MobileNavItem;
