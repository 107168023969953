import { css } from 'styled-components';

const BREAKPOINTS = {
  xs: 449,  // 28.0625em
  sm: 767,  // 47.9375em
  md: 1079, // 67.4375em
  lg: 1279,  // 79.9375em
};

const emSize = (pixelValue) => `${pixelValue / 16}em`;

export const getSizeFromBreakpoint = (breakpoint) => {
  if (BREAKPOINTS[breakpoint]) {
    return emSize(BREAKPOINTS[breakpoint]);
  }

  breakpoint = parseInt(breakpoint, 10);
  if (isNaN(breakpoint)) {
    return emSize(breakpoint);
  }
  console.error('styled-media-query: No valid breakpoint or size specified for media.');
  return '0';
};

export const min = (breakpoint) => (...args) => css`
  @media (min-width: ${getSizeFromBreakpoint(breakpoint)}) {
    ${css(...args)};
  }
`;

export const max = (breakpoint) => (...args) => css`
  @media (max-width: ${getSizeFromBreakpoint(breakpoint)}) {
    ${css(...args)};
  }
`;

export const between = (minBreakpoint, maxBreakpoint) => (...args) => css`
  @media (min-width: ${getSizeFromBreakpoint(minBreakpoint)})
    and (max-width: ${getSizeFromBreakpoint(maxBreakpoint)}) {
    ${css(...args)};
  }
`;

export const media = { min, max, between };

export const isViewportAbove = (breakpoint) => window.innerWidth >= BREAKPOINTS[breakpoint];

export const isViewportBelow = (breakpoint) => window.innerWidth < BREAKPOINTS[breakpoint];
