import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from './Icon';

const InputMessage = styled.span`
  display: inline-block;
  margin-top: 0.5em;
  font-size: ${(p) => p.theme.typography.sizes.small};
  padding: 0.5em 1em;
  border-radius: 0.5em;
`;

const InputErrorWrapper = styled(InputMessage)`
  color: ${(p) => p.theme.colors.danger};
  background-color: #FCEFEF;
`;

const ErrorIcon = styled(Icon)`
  display: inline-block;
  position: relative;
  color: #FCEFEF;
  background-color: ${(p) => p.theme.colors.danger};
  margin-right: 0.5em;
  border-radius: 1em;
  font-size: 1em;
  height: 1em;
  width: 1em;

  :before {
    font-size: 1.1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const InputErrorMessage = (props) => {
  return (
    <InputErrorWrapper aria-live="polite" {...props}>
      <ErrorIcon icon='error' />
      {props.children}
    </InputErrorWrapper>
  );
};

InputErrorMessage.propTypes = {
  children: PropTypes.node,
};

export const InputSuccessMessage = styled(InputMessage)`
  color: ${(p) => p.theme.colors.success};
`;
