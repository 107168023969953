import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { lamePluralize } from 'core/ui/helpers';

import { Box, Text } from 'core/ui/atoms';

import InfoSection from 'dashboard/ui/components/ProductPage/ProductSpecs/InfoSection';
import NutritionFacts from 'dashboard/ui/components/ProductPage/ProductSpecs/NutritionFacts';
import IngredientsList from 'dashboard/ui/components/ProductPage/ProductSpecs/IngredientsList';
import AllergenWarning from 'dashboard/ui/components/ProductPage/ProductSpecs/AllergenWarning';
import Instructions from 'dashboard/ui/components/ProductPage/ProductSpecs/Instructions';

function ComponentSpecs(props) {
  const { nutrition, numServings, weightOunces, shelfLife, ingredients, allergenWarning, instructions } = props;

  return (
    <Fragment>
      { nutrition &&
        <InfoSection title='Nutrition per serving'>
          <Box md={10}>
            <NutritionFacts
              numServings={numServings}
              weightOunces={weightOunces}
              nutrition={nutrition}
            />
          </Box>
        </InfoSection>
      }
      { shelfLife > 0 &&
        <InfoSection>
          <Text no-margin text-weight='bold'>
            Eat within {shelfLife} {lamePluralize('day', shelfLife)} of delivery
          </Text>
        </InfoSection>
      }
      { (ingredients || []).length > 0 &&
        <InfoSection title='Ingredients'>
          <IngredientsList ingredients={ingredients} />
          { allergenWarning && <AllergenWarning noMargin bold/> }
        </InfoSection>
      }
      { instructions && instructions.length > 0 &&
        <InfoSection title='Instructions'>
          <Instructions instructions={instructions} />
        </InfoSection>
      }
    </Fragment>
  );
}

ComponentSpecs.propTypes = {
  nutrition: PropTypes.object,
  numServings: PropTypes.number,
  weightOunces: PropTypes.number,
  shelfLife: PropTypes.number,
  ingredients: PropTypes.arrayOf(PropTypes.object),
  allergenWarning: PropTypes.bool,
  instructions: PropTypes.string,
};

export default ComponentSpecs;
