import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import useDefaultVariant from 'dashboard/hooks/useDefaultVariant';

import { Table } from 'core/ui/atoms';
import { Drop, None } from 'core/ui/animations';
import { sortVariants } from 'dashboard/ui/helpers/ProductHelper';
import VariantOption from 'dashboard/ui/components/VariantOptions/VariantOption';

function VariantOptions(props) {
  const { deliveryDay, product, lineItems, autoAdd, disableAdd, disableRemove, animate, except = [], ...rest } = props;
  const defaultVariant = useDefaultVariant(product) || {};
  const Animation = animate ? Drop : None;

  if (!product) {
    return null;
  }

  if (product.variants.length <= 1) {
    except.push('name');
  }

  return (
    <Animation show={true} >
      <OptionsTable {...rest}>
        <tbody>
          {
            sortVariants(product).map((variant) => {
              const item = lineItems.find((lineItem) => !lineItem.gratis && lineItem.variant.id === variant.id);

              return (
                <VariantOption
                  key={`variant-option-${variant.id}`}
                  deliveryDay={deliveryDay}
                  product={product}
                  variant={variant}
                  quantity={item ? item.quantity : 0}
                  autoAdd={autoAdd && variant.id === defaultVariant.id}
                  disableAdd={disableAdd}
                  disableRemove={disableRemove}
                  except={except}
                />
              );
            })
          }
        </tbody>
      </OptionsTable>
    </Animation>
  );
}

VariantOptions.propTypes = {
  deliveryDay: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  autoAdd: PropTypes.bool,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.bool,
  animate: PropTypes.bool,
  except: PropTypes.arrayOf(PropTypes.string),
};

const OptionsTable = styled(Table)`
  border-collapse: unset;
  border-spacing: 0;

  tr {
    td {
      padding: 0.5em;
    }
  }
`;

export default VariantOptions;
