import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { RouterLink, Container, H1 } from 'core/ui/atoms';
import { rootPath } from 'core/api/actions/navigation';
import BACKGROUND_IMAGE from 'core/assets/images/bg404.png';

class NotFound extends PureComponent {
  render() {
    return (
      <Background>
        <ErrorContainer>
          <ErrorHeading>
            Oops... looks like you got lost.
          </ErrorHeading>
          <RouterLink btn-primary btn-lg to={rootPath()}>Take me back home</RouterLink>
        </ErrorContainer>
      </Background>
    );
  }
}

const ErrorContainer = styled(Container)`
  text-align: center;
  margin-top: 15vh;
  margin-bottom: 15vh;
  max-width: 550px;
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  min-height: calc(100vh - ${(p) => p.theme.layout.headerHeight});
  background-image: url(${BACKGROUND_IMAGE});
  background-size: cover;
  background-position: center;
  ${(p) => p.theme.max('md')`
    padding: 0;
  `}
`;

const ErrorHeading = styled(H1)`
  margin-top: 0.25em;
  margin-bottom: 0.5em;
  img {
    height: 70px;
  }
`;

export default NotFound;
