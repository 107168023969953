import isFunction from 'lodash/isFunction';
import { accessChecksDisabledVar } from 'core/api/apollo/cache';
import AccessControl from 'core/api/AccessControl';
import Account from 'core/api/Account';

class RouteAccessCheck {
  constructor({ check, pathname, state, name }) {
    this.check = (user, cache) => (accessChecksDisabledVar() || check(user, cache));
    this.pathname = pathname;
    this.state = state;
    this.name = name;
  }

  isAccessible = (...params) => this.check(...params);

  isBlocked = (...params) => !this.isAccessible(...params);

  getPathname = (props) => {
    if (isFunction(this.pathname)) {
      return this.pathname(props);
    }
    return this.pathname;
  }

  destinationPath = (props) => {
    return {
      pathname: this.getPathname(props),
      state: { ...(this.state ? this.state(props) : {}) },
    };
  }
}

export const userAuthenticated = new RouteAccessCheck({
  check: (user) => !!user,
  pathname: '/onramp/init-order',
  name: 'userAuthenticated',
  state: (props) => ({ destination: props.location }),
});

export const userUnauthenticated = new RouteAccessCheck({
  check: (user) => !user,
  pathname: (props) => {
    const { location = {} } = props;
    const { state = {} } = location || {};
    const { destination } = state || {};

    if (destination) {
      return `${destination.pathname}${destination.search}`;
    }

    return '/';
  },
  name: 'userUnauthenticated',
});

export const canOrder = new RouteAccessCheck({
  check: (user) => new AccessControl(user).placeOrder(),
  pathname: '/onramp/init-order',
  name: 'canOrder',
});

export const canManageSubscription = new RouteAccessCheck({
  check: (user) => new AccessControl(user).manageSubscription(),
  pathname: '/',
  name: 'canManageSubscription',
});

export const canManageDeliveryInfo = new RouteAccessCheck({
  check: (user) => new AccessControl(user).manageDeliveryInfo(),
  pathname: '/',
  name: 'canManageDeliveryInfo',
});

export const canManageBillingInfo = new RouteAccessCheck({
  check: (user) => new AccessControl(user).manageBillingInfo(),
  pathname: '/',
  name: 'canManageBillingInfo',
});

export const supportedAddress = new RouteAccessCheck({
  check: (user) => new Account(user).hasSupportedAddress(),
  pathname: '/out-of-delivery-zone',
  name: 'supportedAddress',
});

export const courierAddress = new RouteAccessCheck({
  check: (user) => new Account(user).hasCourierAddress(),
  pathname: '/',
  name: 'courierAddress',
});

export const shippingAddress = new RouteAccessCheck({
  check: (user) => new Account(user).hasShippingAddress(),
  pathname: '/',
  name: 'shippingAddress',
});

export const unsupportedAddress = new RouteAccessCheck({
  check: (user) => !supportedAddress.check(user),
  pathname: '/',
  name: 'unsupportedAddress',
});
export const preSubscribedAccountType = new RouteAccessCheck({
  check: (user) => new Account(user).hasPreSubscribedAccountType(),
  pathname: '/',
  name: 'preSubscribedAccountType',
});

export const shippingAccountType = new RouteAccessCheck({
  check: (user) => new Account(user).hasShippingAccountType(),
  pathname: '/',
  name: 'shippingAccountType',
});

export const emptyAccountType = new RouteAccessCheck({
  check: (user) => new Account(user).hasEmptyAccountType(),
  pathname: '/',
  name: 'emptyAccountType',
});
export default RouteAccessCheck;
