import moment from 'moment';

import useDeliveryDay from 'dashboard/hooks/useDeliveryDay';
import useOrder from 'dashboard/hooks/useOrder';

function useDeliveryDayStatus(deliveryDayId) {
  const { loading: deliveryDayLoading, deliveryDay: unsafeDeliveryDay } = useDeliveryDay(deliveryDayId);
  const deliveryDay = unsafeDeliveryDay || {};
  const { loading: orderLoading, order: unsafeOrder } = useOrder(deliveryDay.order && deliveryDay.order.id);
  const order = unsafeOrder || {};

  return {
    loading: deliveryDayLoading || orderLoading,
    deliveryDay: unsafeDeliveryDay,
    order: unsafeOrder,
    date: deliveryDay.date,
    available: !deliveryDay.closed && deliveryDay.menuPublished,
    closed: deliveryDay.closed,
    closureReason: deliveryDay.closureReason,
    assigned: deliveryDay.assigned,
    skipped: order.skipped || false,
    cutoff: deliveryDay.cutoffTime ? moment(deliveryDay.cutoffTime) : null,
    locked: deliveryDay.locked || order.locked || moment().isAfter(deliveryDay.cutoffTime),
    menuPublished: deliveryDay.menuPublished,
    subscribed: deliveryDay.subscribed,
    activeSubscription: deliveryDay.subscriptionStatus === 'ACTIVE',
    scheduled: deliveryDay.subscribed || !!order.id,
  };
}

export default useDeliveryDayStatus;
