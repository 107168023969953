import React from 'react';
import { useQuery } from '@apollo/client';

import { Text } from 'core/ui/atoms';
import Panel from 'settings/ui/components/Panel';
import Money from 'core/api/Money';
import { GET_BILLING_INFO } from 'core/api/apollo/queries';

function AvailableCredits() {
  const { loading, data: { getBillingInfo: billingInfo } = {} } = useQuery(GET_BILLING_INFO);

  const availableCredits = (billingInfo && billingInfo.availableCredits) || 0;

  return (
    <Panel title='Available Credits' loading={loading}>
      <Text text-size='huge' data-available-credits>{Money(availableCredits).toFormat()}</Text>
    </Panel>
  );
}

export default AvailableCredits;
