import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';

import { cartPath } from 'core/api/actions/navigation';
import Loading from 'core/ui/components/Loading';
import { Flex, Container, RouterLink } from 'core/ui/atoms';
import useDeliveryDayStatus from 'dashboard/hooks/useDeliveryDayStatus';
import OrderSummary from 'dashboard/ui/components/DeliveryDayHeader/OrderSummary';
import DeliveryDayToggle from 'dashboard/ui/components/DeliveryDayHeader/Toggle';
import DeliveryDayStatus from 'dashboard/ui/components/DeliveryDayHeader/Status';

function DeliveryDayHeader() {
  const { deliveryDayId } = useParams();
  const status = useDeliveryDayStatus(deliveryDayId);
  const { loading, deliveryDay, order } = status;

  return (
    <Fragment>
      <StatusRow>
        <Container>
          {!loading ? <DeliveryDayStatus status={status} /> : <Loading />}
        </Container>
      </StatusRow>
      <DeliveryDayHeaderCont data-delivery-day-header alignItems="center">
        <Container relative>
          <Flex alignItems='center'>
            <ToggleFlex divider>
              <DeliveryDayToggle deliveryDay={deliveryDay} />
            </ToggleFlex>

            <StatusColumn grow={1} shrink={0} alignItems="center" sm="50vw" hiddenXS divider>
              {!loading && <DeliveryDayStatus status={status} />}
            </StatusColumn>

            <OrderSummaryFlex data-header-order-summary>
              {!loading && (
                <OrderSummaryLink to={cartPath(deliveryDay.id)}>
                  <OrderSummary
                    order={order}
                    deliveryDay={deliveryDay}
                    status={status}
                  />
                </OrderSummaryLink>
              )}
            </OrderSummaryFlex>
          </Flex>
        </Container>
      </DeliveryDayHeaderCont>
    </Fragment>
  );
}

DeliveryDayHeader.propTypes = {
  deliveryDay: PropTypes.object.isRequired,
  order: PropTypes.object,
  status: PropTypes.object,
  loading: PropTypes.bool,
};

const DeliveryDayHeaderCont = styled(Flex)`
  width: 100%;
  min-height: 60px;
  position: sticky;
  top: ${(p) => p.theme.layout.headerHeight};
  background: white;
  z-index: ${(p) => p.theme.zIndex.headerFixed};
  border-bottom: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.grays.light};
  margin-bottom: -1px;
`;

const HeaderFlex = styled(Flex)`
  align-items: center;
  padding-right: 10px;
  margin-right: 30px;
  &:last-of-type {
    padding-right: 0;
    margin-right: 0;
  }
  ${(p) => p.theme.max('md')`
    margin-right: 10px;
  `}
  ${(p) => (p.divider && css`
    position: relative;
    ${(p) => p.theme.min('md')`
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 1px;
        right: -15px;
        height: 30px;
        background-color: ${(p) => p.theme.colors.grays.light};
      }
    `};
  `)}
`;

const ToggleFlex = styled(HeaderFlex)`
  flex-direction: column;
  align-items: flex-start;
`;

const OrderSummaryFlex = styled(HeaderFlex)`
  margin-left: auto;
  max-width: 34%;
`;

const OrderSummaryLink = styled(RouterLink)`
  display: flex;
  :hover {
    text-decoration: none;
  }
`;

const StatusColumn = styled(HeaderFlex)`
  ${(p) => p.theme.max('sm')`
    display: none;
  `}
`;

const StatusRow = styled.div`
  padding: 4px;
  background-color: ${(p) => p.theme.colors.grays.lighter};
  color: ${(p) => p.theme.colors.grays.darker };

  ${(p) => p.theme.min('sm')`
    display: none;
  `}

  p {
    margin: 0;
  }

  div {
    padding: 0;
    span {
      font-size: 14px;
      color: ${(p) => p.theme.colors.grays.light };
    }
  }
`;

export default DeliveryDayHeader;
