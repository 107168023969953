import { ApolloClient, from, HttpLink } from '@apollo/client';
import cache from './cache';
import errorLink from './errorLink';
import { graphqlHost, clientTimestamp } from 'reactAppConstants';


const httpLink = new HttpLink({
  uri: `${graphqlHost}/graphql`,
  credentials: 'include',
  headers: {
    'client-timestamp': clientTimestamp,
  },
});

const client = new ApolloClient({
  cache,
  link: from([
    errorLink,
    httpLink,
  ]),
});

export default client;
