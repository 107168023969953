import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { displayDate } from 'core/ui/dateHelpers';

import { Container, Flex, H4 } from 'core/ui/atoms';

function VisitorHeader(props) {
  const { deliveryDay } = props;

  return (
    <HeaderContainer alignItems='center'>
      <Container relative>
        <Flex alignItems='center' justifyContent='center'>
          <H4 no-margin>Week of {displayDate(moment(deliveryDay.date).startOf('week'), 'MMM Do')} Menu</H4>
        </Flex>
      </Container>
    </HeaderContainer>
  );
}

VisitorHeader.propTypes = {
  deliveryDay: PropTypes.object.isRequired,
};

const HeaderContainer = styled(Flex)`
  width: 100%;
  min-height: 60px;
  position: sticky;
  top: ${(p) => p.theme.layout.headerHeight};
  background: white;
  z-index: ${(p) => p.theme.zIndex.headerFixed};
  border-bottom: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.grays.light};
  margin-bottom: -1px;
`;

export default VisitorHeader;
