import styled, { css } from 'styled-components';
import { flexChildMixin, hiddenMixin, noMarginMixin, textMixin } from 'core/ui/mixins';

const List = styled.ul`
  ${(p) => p.horizontal && css`
    display: flex;
    flex-wrap: wrap;
  `}
  ${(p) => p.unstyled && css`
    margin: 0;
    padding: 0;
    list-style: none;
  `};
  ${(props) => props.centered && css`justify-content: center;`}
`;

List.Item = styled.li`
  display: block;
  ${textMixin}
  ${noMarginMixin}
  ${hiddenMixin}
  ${flexChildMixin}
`;

export default List;
