/* eslint-disable no-console */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { ApolloConsumer } from '@apollo/client';

import useLog from 'core/hooks/useLog';
import useUser from 'core/hooks/useUser';

import LoadingScreen from 'core/ui/components/LoadingScreen';
import InvalidBillingCheck from 'core/ui/components/InvalidBillingCheck';

const AppRoute = (props) => {
  const {
    component: Component,
    layout: Layout,
    layoutProps = {},
    accessChecks = [],
    requireValidBilling,
    onLoad,
    ...rest
  } = props;

  const log = useLog();
  const { currentUser, loading: userLoading } = useUser();

  return (
    <ApolloConsumer>
      { (client) => (
        <Route
          {...rest}
          render={(routeProps) => {

            if (userLoading) {
              return <LoadingScreen />;
            }

            log.debugGroupCollapsed('Route:', props.path);
            try {
              log.debug('CurrentUser:', currentUser);
              console.log('log CurrentUser:', currentUser);

              const accessCheck = accessChecks.find((check) => {
                log.debug('Checking:', check.name);

                return check.isBlocked(currentUser, client.cache);
              });


              if (accessCheck && props.path !== accessCheck.pathname) {
                log.debug('Redirecting to:', accessCheck.pathname);
                return <Redirect to={accessCheck.destinationPath(routeProps)} />;
              }
            } finally {
              log.debugGroupEnd();
            }

            if (onLoad) {
              onLoad(currentUser, client.cache);
            }

            const content = (
              <Fragment>
                <Component
                  log={log}
                  currentUser={currentUser}
                  cache={client.cache}
                  {...routeProps}
                />
                {requireValidBilling && <InvalidBillingCheck />}
              </Fragment>
            );

            return Layout ? <Layout {...layoutProps}>{content}</Layout> : content;
          }}
        />
      )}
    </ApolloConsumer>
  );
};

AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
  layout: PropTypes.func,
  layoutProps: PropTypes.object,
  accessChecks: PropTypes.arrayOf(PropTypes.object),
  requireValidBilling: PropTypes.bool,
  onLoad: PropTypes.func,
  path: PropTypes.string,
};

export default AppRoute;
