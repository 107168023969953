import styled, { css } from 'styled-components';

import { buttonMixin } from 'core/ui/mixins';

const Link = styled.a`
  text-decoration: underline;
  color: ${(props) => props.theme.typography.colors.primary};
  ${(p) => p.button && css`
    ${buttonMixin}
    text-decoration: none;
  `};
`;

export default Link;
