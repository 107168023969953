import { clientTimestamp } from 'reactAppConstants';

export const reportableError = (error) => {
  const { __typename: typename, type, message = '' } = error;

  return (
    typename !== 'UserError' &&
    type !== 'validation_error' &&
    !message.includes('Client is outdated')
  );
};

const IGNORED_OPERATION_ERRORS = [
  // JUSTIFICATION: if getting the split test assignments fail for any reason we don't want the site breaking.
  'GetSplitTestAssignments',
];

export const ignorableError = (operation) => {
  return IGNORED_OPERATION_ERRORS.includes(operation.operationName);
};

export const reportError = (error, info = {}) => {
  if (window && window.Rollbar && reportableError(error)) {
    info = { ...info, clientTimestamp };
    console.error('Report Error:', info, error);
    window.Rollbar.error(error, info, (err) => {
      if (err && err.message.indexOf('Rollbar is not enabled') === -1) {
        console.error('Error while reporting error to Rollbar: ', err);
      }
    });
  }
};
