import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';

import LineItemList from 'dashboard/ui/components/Cart/LineItemList';
import { EmptyOrderMessage, SkippedMessage } from 'dashboard/ui/components/DeliveryDayStatus';

function Body(props) {
  const { order = {}, status: { locked, skipped, menuPublished } } = props;
  const { lineItems: orderLineItems = [] } = order || {};

  const lineItems = sortBy(orderLineItems, (lineItem) => (
    [lineItem.product.line.subcategory.category.position, lineItem.product.name, lineItem.variant.size.position]
  ));

  if (skipped) {
    return (<SkippedMessage orderId={order.id} actionable={!locked && !menuPublished} />);
  }

  if (!lineItems.length) {
    return (<EmptyOrderMessage />);
  }

  return (
    <LineItemList
      lineItems={lineItems}
      order={order}
      editable={!locked}
    />
  );
}

Body.propTypes = {
  order: PropTypes.object,
  status: PropTypes.object.isRequired,
};

export default Body;
