import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { displayDate } from 'core/ui/dateHelpers';

import { ratingModalOrderIdVar }  from 'core/api/apollo/cache';

import {
  AspectRatio,
  Button,
  Flex,
  H5,
  Icon,
  Image,
} from 'core/ui/atoms';

function RateableOrder(props) {
  const openModal = () => ratingModalOrderIdVar(props.rateableOrder.id);

  const { rateableOrder: order } = props;

  return (
    <Wrapper onClick={openModal} data-rateable-order={order.id}>
      <DateHeader hiddenXS hiddenSM>{displayDate(order.date, 'dddd, MMMM Do')}</DateHeader>
      <DateHeader hiddenMD hiddenLG>{displayDate(order.date, 'MMM D')}</DateHeader>
      <RatingImageWrapper>
        <AspectRatio aspectHeight={100} aspectWidth={100}>
          <RatingImage src={order.image.smallUrl} rated={order.rated} />
        </AspectRatio>
        {order.rated && <RatedBadge />}
      </RatingImageWrapper>
      {
        order.rated ?
          <Button btn-default hiddenXS hiddenSM>Rated</Button>
          :
          <Button btn-secondary hiddenXS hiddenSM>Rate Order</Button>
      }
    </Wrapper>
  );
}

RateableOrder.propTypes = {
  rateableOrder: PropTypes.object.isRequired,
};

const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  border: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.grays.lighter};
  box-shadow: ${(p) => p.theme.shadows.card};
  padding: 20px;
  cursor: pointer;

  :active {
    box-shadow: none;
  }

  ${(p) => p.theme.max('md')`
    flex-direction: column-reverse;
    border: none;
    box-shadow: none;
  `}

  ${(p) => p.theme.max('xs')`
    padding-right: 0px;
    padding-left: 0px;
  `}
`;

const DateHeader = styled(H5)`
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: normal;

  ${(p) => p.theme.max('md')`
    font-size: 1em;
    font-weight: bold;
  `}
`;

const RatingImageWrapper = styled.div`
  position: relative;
  margin: auto;
  margin-bottom: 20px;

  width: 60%;
  ${(p) => p.theme.max('sm')`
    width: 90%;
  `}
`;

const RatedBadge = styled((props) => <Icon icon='check' {...props} />)`
  position: absolute;
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.primary};
  border-radius: 100%;
  padding: 0.1875em;
  top: 6%;
  right: 6%;

  ${(p) => p.theme.min('md')`
    font-size: 1.5em;
  `}

  ${(p) => p.theme.max('sm')`
    top: 0px;
    right: 0px;
  `}
`;

const RatingImage = styled(Image)`
  display: block;
  border-radius: 100%;
  border: 2px solid ${(p) => p.theme.colors.secondary};
  border-color: ${(p) => p.rated ? p.theme.colors.primary : (p) => p.theme.colors.secondary};
  height: 100%;
  width: 100%;
`;

export default RateableOrder;
