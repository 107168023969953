import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { buttonMixin } from 'core/ui/mixins';

import {
  Button,
  H6,
} from 'core/ui/atoms';
import { displayDiscount } from 'core/ui/discountHelpers';

function Actions(props) {
  const composeEmail = () => {
    const { referralCoupon } = props;

    const subject = 'Check out Methodology';
    const body = encodeURIComponent([
      'Hey,',
      "I started using Methodology and it's made eating healthy so fast and easy. Nourishing, ready-to-eat food delivered twice a week. Curated to my preferences. Plus they have a massive menu, over 80 things each week and it rotates each week, so there's always something new to satisfy my cravings. I also like that they try to put as much food into reusable glass jars as possible.",
      `If you want a ${displayDiscount(referralCoupon)} discount on your first order, use my link: ${referralCoupon.referralUrl}`,
    ].join('\n\n'));

    return `mailto:?subject=${subject}&body=${body}`;
  };

  const { referralCoupon } = props;

  return (
    <Fragment>
      <ReferralCoupon>
        <ReferralCodeHeading>Your referral code</ReferralCodeHeading>
        <ReferralCode>{referralCoupon.code}</ReferralCode>
      </ReferralCoupon>
      <EmailButton btn-primary fullWidth href={composeEmail()} target='_blank'>
        Give {displayDiscount(referralCoupon)} off
      </EmailButton>
      <CopyToClipboard text={referralCoupon.referralUrl}>
        <CopyButton btn-sm btn-default fullWidth>Or copy and paste your invitation link</CopyButton>
      </CopyToClipboard>
    </Fragment>
  );
}

Actions.propTypes = {
  referralCoupon: PropTypes.object.isRequired,
};

const ReferralCoupon = styled.div`
  margin: 20px 0px;
  text-align: center;
`;

const ReferralCodeHeading = styled(H6)`
  text-transform: uppercase;
  color: ${(p) => p.theme.typography.colors.muted} !important;
  margin: 0;
  font-size: 12px;
  font-weight: normal;
`;

const ReferralCode = styled.div`
  text-transform: uppercase;
  color: ${(p) => p.theme.typography.colors.primary};
  display: inline-block;
  padding: 10px;
  border: ${(p) => p.theme.borders.width} dashed ${(p) => p.theme.colors.primary};
  font-weight: bold;
  font-size: 17px;
`;

const EmailButton = styled.a`
  ${buttonMixin}
  display: block;
  text-align: center;
  padding: 1em 5.5em;
  font-weight: 500;
  ${(p) => p.theme.max('sm')`
    padding: 1em;
  `}
`;

const CopyButton = styled(Button)`
  font-size: ${(p) => p.theme.typography.sizes.small};
  padding: 0px;
  margin-block-start: 14px;
  margin-block-end: 14px;
`;

export default Actions;
