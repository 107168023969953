import React from 'react';
import styled from 'styled-components';

import { List as ListAtom, ListItem as ListItemAtom } from 'core/ui/atoms';

const List = styled((props) => <ListAtom unstyled {...props} />)`
`;

const ListItem = styled(ListItemAtom)`
  padding: 1em 0;
  border-bottom: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.grays.light};

  transition: border-color 0.25s ease-in-out;

  &:hover, &:active {
    border-bottom-color: ${(p) => p.theme.borders.color};
  }
`;

export { List, ListItem };
