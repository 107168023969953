import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, List } from 'core/ui/atoms';

function VariantNavBar(props) {
  const { selected, variants, onChange } = props;

  return (
    <Wrapper>
      <VariantList unstyled horizontal>
        {variants.map((variant) => (
          <List.Item key={variant.id}>
            <Button
              btn-primary={variant.id === selected.id}
              btn-outline={variant.id !== selected.id}
              onClick={() => onChange(variant)}
            >
              {variant.size.name}
            </Button>
          </List.Item>
        ))}
      </VariantList>
    </Wrapper>
  );
};

VariantNavBar.propTypes = {
  selected: PropTypes.object.isRequired,
  variants: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  width: 100%;
`;

const VariantList = styled(List)`
  overflow-x: scroll;
  flex-wrap: nowrap;
  justify-content: initial;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  li:not(:last-child) button {
    border-right: none;
  }
`;

export default VariantNavBar;
