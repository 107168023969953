import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _capitalize from 'lodash/capitalize';

import useFoodsToAvoid from 'dashboard/hooks/useFoodsToAvoid';
import { Icon, Text } from 'core/ui/atoms';

function FoodsToAvoidWarning(props) {
  const { product, ...rest } = props;
  const { warnings } = useFoodsToAvoid(product);

  return useMemo(() => {
    if (warnings.length < 1) {
      return null;
    }

    return (
      <Text text-color="danger" text-small {...rest}>
        <WarningIcon />
        {warnings.map((warning) => _capitalize(warning.name)).join(', ')}
      </Text>
    );
  }, [warnings, rest]);
}

FoodsToAvoidWarning.propTypes = {
  product: PropTypes.object.isRequired,
};

const WarningIcon = styled((p) => (<Icon icon='error' {...p} />))`
  display: inline-block;
  font-size: 1.2em;
  vertical-align: middle;
  margin-right: 0.5em;
`;

export default FoodsToAvoidWarning;
