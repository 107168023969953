import React, { Fragment, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  H2,
  Text,
} from 'core/ui/atoms';
import SplitPanelModal from 'core/ui/components/SplitPanelModal';
import { SKIP_ORDER, CREATE_ORDER } from 'core/api/apollo/mutations';
import BACKGROUND_IMG from 'dashboard/assets/images/skipModalBackground.png';
import { updateOrderCache, updateDeliveryDayCache } from 'core/api/cache/updaters';
import useErrorHandlers from 'core/hooks/useErrorHandlers';

function SkipOrderButton(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { text, orderId, deliveryDay, ...rest } = props;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  if (!deliveryDay) { return null; }

  return (
    <Fragment>
      <Button unstyled onClick={openModal} {...rest}>
        {text || 'Skip this order'}
      </Button>
      {isOpen && (
        <SkipOrderModal
          orderId={orderId}
          deliveryDay={deliveryDay}
          onClose={closeModal}
        />
      )}
    </Fragment>
  );
}

SkipOrderButton.propTypes = {
  orderId: PropTypes.string,
  deliveryDay: PropTypes.object.isRequired,
  text: PropTypes.string,
};

function SkipOrderModal(props) {
  const { handleGenericApolloError } = useErrorHandlers();
  const [skipOrder] = useMutation(SKIP_ORDER);
  const [createOrder] = useMutation(CREATE_ORDER, {
    update: (cache, { data: { createOrder: { order, errors } } }) => {
      updateOrderCache(cache, order, errors);
      const deliveryDay = { id: order.deliveryDay.id, order: { id: order.id, __typename: 'Order' } };
      updateDeliveryDayCache(cache, deliveryDay, errors);
    },
  });
  const [isSkipping, setIsSkipping] = useState(false);

  const onClose = () => {
    setIsSkipping(false);
    props.onClose();
  };

  const onSuccess = () => {
    props.onClose();
  };

  const onError = (error) => {
    setIsSkipping(false);
    handleGenericApolloError(error);
  };

  const onConfirm = (e) => {
    const { orderId, deliveryDay } = props;

    e.preventDefault();
    setIsSkipping(true);

    if (orderId) {
      skipOrder({ variables: { input: { orderId: orderId } } })
        .then(onSuccess)
        .catch(onError);
    } else {
      createOrder({ variables: { input: { deliveryDayId: deliveryDay.id, skipped: true } } })
        .then(onSuccess)
        .catch(onError);
    }
  };

  return (
    <SplitPanelModal onClose={onClose} image={BACKGROUND_IMG}>
      <ModalContent md={6}>
        <ModalTitle>Are you sure you want to skip this order?</ModalTitle>
        <ModalText>By clicking confirm, you will skip this order and not receive food.</ModalText>
        <ConfirmButton btn-primary btn-lg onClick={onConfirm} disabled={isSkipping}>Confirm</ConfirmButton>
        <CancelButton default onClick={onClose}>No thanks, keep it as is.</CancelButton>
      </ModalContent>
    </SplitPanelModal>
  );

}

SkipOrderModal.propTypes = {
  orderId: PropTypes.string,
  deliveryDay: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ModalContent = styled.div`
  padding: 75px 35px;
  ${(p) => p.theme.max('sm')`
    padding: 10px 35px;
  `}
`;

const ModalTitle = styled(H2)`
  ${(p) => p.theme.max('sm')`
    font-size: 1.375em;
  `}
`;

const ModalText = styled(Text)`
  margin: 20px 0 90px 0;
  font-size: 1em;
  ${(p) => p.theme.max('sm')`
    margin-bottom: 40px;
  `}
`;

const ConfirmButton = styled(Button)`
  margin-bottom: 30px;
`;

const CancelButton = styled(Button)`
  display: block;
  padding: 0;
  font-size: 1em;
  text-align: left;
`;

export default SkipOrderButton;
