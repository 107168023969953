import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Page } from 'onramp/ui/components/V2';
import useUser from 'core/hooks/useUser';

function SuccessLayout(props) {
  const { promoBannerEnabled, inverse, disableProgress, children } = props;
  const { currentUser } = useUser();

  const isReorder = currentUser && currentUser.reorder;

  return useMemo(() => {
    return (
      <Main>
        <MainContainer>
        <CustomPage backgroundColor="red">

          {children}
        </CustomPage>

        </MainContainer>
        {/* <Footer /> */}
      </Main>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoBannerEnabled, inverse, disableProgress, children, currentUser, isReorder]);
}

SuccessLayout.propTypes = {
  promoBannerEnabled: PropTypes.bool,
  inverse: PropTypes.bool,
  disableProgress: PropTypes.bool,
  children: PropTypes.node,
};

const Main = styled.main`
  margin: 0;
  min-height: 100vh;
  background: #BFD0CA; // FIXME: move to theme
`;

const MainContainer = styled.section`
  position: relative;
`;

const CustomPage = styled(Page)`
  min-height: 100vh;
  margin-top: 0;
  background-color: #C1D3C2;
  grid-template-columns: 1fr;
`;

export default SuccessLayout;
