import { InMemoryCache, makeVar } from '@apollo/client';

export const accessChecksDisabledVar = makeVar(false);
export const alertsVar = makeVar([]);
export const isMenuOpenVar = makeVar(false);
export const isReferralModalOpenVar = makeVar(false);
export const ratingModalOrderIdVar = makeVar(null);
export const videoIdVar = makeVar(null);
export const productFiltersVar = makeVar([]);
export const conversionPixelsVar = makeVar([]);
export const programBundleCartVar = makeVar({});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        accessChecksDisabled: {
          read() {
            return accessChecksDisabledVar();
          },
        },
        alerts: {
          read() {
            return alertsVar();
          },
        },
        isMenuOpen: {
          read() {
            return isMenuOpenVar();
          },
        },
        isReferralModalOpen: {
          read() {
            return isReferralModalOpenVar();
          },
        },
        ratingModalOrderId: {
          read() {
            return ratingModalOrderIdVar();
          },
        },
        videoId: {
          read() {
            return videoIdVar();
          },
        },
        productFilters: {
          read() {
            return productFiltersVar();
          },
        },
        conversionPixels: {
          read() {
            return conversionPixelsVar();
          },
        },
        programBundleCart: {
          read() {
            return programBundleCartVar();
          },
        },
      },
    },
    Order: {
      fields: {
        lineItems: {
          merge: false,
        },
      },
    },
  },
});

export default cache;
