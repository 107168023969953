import React, { useMemo } from 'react';
import styled from 'styled-components';

import useUser from 'core/hooks/useUser';

import { EXTERNAL_LINKS } from 'core/api/constants';
import { Flex, Icon, Link, RouterLink } from 'core/ui/atoms';
import LogoutButton from 'core/ui/components/LogoutButton';

function Footer(props) {
  const { userCan } = useUser();
  const canLogOut = userCan.logOut();

  return useMemo(() => {
    return (
      <StyledFooter {...props}>
        <LeftGutter />
        <Left>
          <Link href={EXTERNAL_LINKS.ourStory}>Our Story</Link>
          <Link href={EXTERNAL_LINKS.faq}>FAQ</Link>
          <Link href={EXTERNAL_LINKS.contact}>Contact Us</Link>
        </Left>
        <Right>
          <Link href={EXTERNAL_LINKS.privacyPolicy}>Privacy Policy</Link>
          <Link href={EXTERNAL_LINKS.termsAndConditions}>Terms & Conditions</Link>
          {
            canLogOut ?
              <LogoutButton reload={props.logoutReload || false} btn-unstyled key="nav-sign-out">Log out</LogoutButton>
              :
              <RouterLink to='/sign-in'>Log In</RouterLink>
          }
        </Right>
        <RightGutter>
          <Link href={EXTERNAL_LINKS.instagram} target='_blank' rel='noopener noreferrer' aria-label="Visit Methodology on Instagram. Opens in new window">
            <Icon icon='instagram' />
          </Link>
        </RightGutter>
      </StyledFooter>
    );
  }, [props, canLogOut]);
}

const StyledFooter = styled.footer`
  display: grid;
  grid-template-areas: 'left-gutter left right right-gutter';

  background: #BFD0CA;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  a, button {
    margin: 0.5em 1em;
    white-space: nowrap;
    background: transparent;
    color: ${(p) => p.theme.typography.colors.primary};
    font-size: ${(p) => p.theme.typography.sizes.smaller};
    line-height: ${(p) => p.theme.typography.lineHeight.base};
    border: none;
    padding: 0;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  ${(p) => p.theme.max('sm')`
    padding: 3vh 5vw;
    grid-template-areas:
      'left right'
      'right-gutter left-gutter';
  `}
`;

const Section = styled(Flex)`
  align-content: center;

  ${(p) => p.theme.max('sm')`
    flex-direction: column;
    flex-grow: 1;
  `}
`;

const LeftGutter = styled(Section)`
  grid-area: left-gutter;
`;

const Left = styled(Section)`
  grid-area: left;
  justify-content: flex-end;

  ${(p) => p.theme.max('sm')`
    align-items: flex-start;
  `}
`;

const Right = styled(Section)`
  grid-area: right;

  ${(p) => p.theme.max('sm')`
    align-items: flex-end;
  `}
`;

const RightGutter = styled(Section)`
  grid-area: right-gutter;
  padding-right: 3vw;
  ${(p) => p.theme.min('sm')`
    justify-content: flex-end;
  `}
`;

export default Footer;
