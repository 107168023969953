import React, { useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { Button } from 'core/ui/atoms';
import { UPDATE_LINE_ITEM } from 'core/api/apollo/mutations';
import { updateLineItemOptimisticResponse } from 'dashboard/ui/helpers/OrderHelper';

function RemoveFromOrderButton(props) {
  // See https://egghead.io/lessons/react-safely-setstate-on-a-mounted-react-component-through-the-useeffect-hook
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      isRemoving: false,
    },
  );
  const mounted = useRef(false);
  const [updateLineItem] = useMutation(UPDATE_LINE_ITEM);

  const { order, lineItem, children, ...rest } = props;
  const { isRemoving } = state;

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  const setStateSafely = (...args) => mounted.current && setState(...args);

  const removeItems = (e) => {
    e.preventDefault();

    setStateSafely({ isRemoving: true });

    const variables = { input: { lineItemId: lineItem.id, quantity: 0 } };
    const optimisticResponse = updateLineItemOptimisticResponse(order, lineItem, 0);
    updateLineItem({ variables, optimisticResponse })
      .finally(() => setStateSafely({ isRemoving: false }));
  };

  return (
    <Button {...rest} onClick={removeItems} disabled={isRemoving}>
      {children}
    </Button>
  );
}

RemoveFromOrderButton.propTypes = {
  order: PropTypes.object.isRequired,
  lineItem: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default RemoveFromOrderButton;
