import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import escapeRegExp from 'lodash/escapeRegExp';
import sortBy from 'lodash/sortBy';

import { Input as InputAtom, Icon } from 'core/ui/atoms';

class TagInput extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    variants: PropTypes.arrayOf(PropTypes.object).isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    placeholder: PropTypes.string,
    className: PropTypes.string,
  };

  state = {
    autosuggestVisible: false,
    searchTerm: '',
  };

  onChange = (item) => {
    this.props.onChange(item);
    this.setState({ searchTerm: '' });
  };

  showAutosuggest = () => this.setState({ autosuggestVisible: true });

  hideAutosuggest = () => setTimeout(this.setState({ autosuggestVisible: false }), 0);

  setSearchTerm = (e) => this.setState({ searchTerm: e.target.value });

  render() {
    const { variants, selected, placeholder } = this.props;
    const { searchTerm, autosuggestVisible } = this.state;
    const variantsToShow = searchTerm
      ? variants
          .filter((v) => !selected.includes(v.id))
          .filter((v) => v.name.match(new RegExp(escapeRegExp(searchTerm), 'gi')))
      : [];

    return (
      <TagInputCont className={this.props.className}>
        <Input
          type="text"
          placeholder={placeholder || 'ex. Apricots'}
          value={searchTerm}
          onFocus={this.showAutosuggest}
          onBlur={this.hideAutosuggest}
          onChange={this.setSearchTerm}
          name="addTag"
          autoComplete='off'
        />
        <TagIcon />
        <AutosuggestCont visible={autosuggestVisible && variantsToShow.length}>
          {sortBy(variantsToShow, 'name').slice(0, 7).map((item) => (
            <AutosuggestItem key={item.id} title={item.name} onClick={() => this.onChange(item)} data-suggested-tag-id={item.id}>
              {item.name}
            </AutosuggestItem>
          ))}
        </AutosuggestCont>
      </TagInputCont>
    );
  }
}

const TagInputCont = styled.div`
  position: relative;
  ${(p) => p.theme.max('md')`
    width: 100%;
  `}
`;

const TagIcon = styled((p) => <Icon icon="search" {...p} />)`
  font-size: 1em;
  position: absolute;
  top: 1em;
  right: 1em;
  color: #858C94; // FIXME: add to theme
`;

const AutosuggestCont = styled.ul`
  position: absolute;
  top: 100%;
  margin: 0;
  padding: 15px 30px;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  z-index: ${(p) => p.theme.zIndex.popover};
  width: 100%;
  background: ${(p) => p.theme.colors.grays.lighter};
  transition: all 0.3s ease;
  overflow-y: scroll;
  max-height: 200px;
  ${(p) => p.visible && css`
    visibility: visible;
    opacity: 1;
  `};
`;

const AutosuggestItem = styled.li`
  padding: 5px 7px;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.typography.colors.primary};
    font-weight: 500;
    text-decoration: underline;
  }
`;

const Input = styled(InputAtom)`
  width: 100%;
  margin-bottom: 3vh;
  padding-right: 3em;
  background-color: ${(p) => p.theme.colors.grays.lighter};
  border: none;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${(p) => p.theme.typography.colors.primary};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${(p) => p.theme.typography.colors.primary};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${(p) => p.theme.typography.colors.primary};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${(p) => p.theme.typography.colors.primary};
  }
`;

export default TagInput;
