import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { List, ListItem } from 'core/ui/atoms/index';

function IndicesList(props) {
  const { indices, ...rest } = props;

  return (
    <IndicesListCont unstyled horizontal {...rest}>
      {indices.map((index, i) => (
        <IndicesListItem key={`${index}-${i}`}>
          {index}
        </IndicesListItem>
      ))}
    </IndicesListCont>
  );
}

IndicesList.propTypes = {
  indices: PropTypes.arrayOf(PropTypes.string),
};

const IndicesListCont = styled(List)`
  text-align: left;
  display:flex;
  justify-content: left;
`;

const IndicesListItem = styled(ListItem)`
  position: relative;
  display:inline-block;
  margin: 0 10px;
  font-size: ${(p) => p.theme.typography.sizes.small};
  color: ${(p) => p.theme.typography.colors.muted};
  &:first-child {
    margin-left: 0;
  }
  &:not(:first-child) {
    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 50%;
      transform: translate(-12px, -50%);
      width: 3px;
      height: 3px;
      background: ${(p) => p.theme.colors.grays.regular};
      border-radius: 50%;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;

export default IndicesList;
