import { generatePath } from 'react-router';
import _compact from 'lodash/compact';
import qs from 'qs';
import { history } from 'core/api/history';

export const buildPath = (pattern, params = {}, query = {}) => {
  const path = generatePath(pattern, params);
  const queryString = qs.stringify(query);
  return _compact([path, queryString]).join('?');
};

export const rootPath = () => generatePath('/');
export const signInPath = () => generatePath('/sign-in');
export const signUpPath = () => generatePath('/sign-up');

export const cartPath = (deliveryDayId, query = {}) => buildPath('/cart/:deliveryDayId', { deliveryDayId }, query);

export const menuPath = (deliveryDayId, queryParams={}) => (
  deliveryDayId ? buildPath('/menu/:deliveryDayId', { deliveryDayId }, queryParams) : buildPath('/menu',{}, queryParams)
);

export const menuCategoryPath = (deliveryDayId, categoryId,queryParams={}) => buildPath('/menu/:deliveryDayId/category/:categoryId', { deliveryDayId, categoryId },queryParams);
export const menuFilterPath = (deliveryDayId, tagId) => generatePath('/menu/:deliveryDayId/filter/:tagId', { deliveryDayId, tagId: tagId });
export const menuReorderPath = (deliveryDayId) => generatePath('/menu/:deliveryDayId/reorder', { deliveryDayId });
export const productPath = (deliveryDayId, productId, queryParams={}) => buildPath('/menu/:deliveryDayId/product/:productId', { deliveryDayId, productId }, queryParams);

export const goTo = (path) => history.push(path);

export const goToRoot = () => goTo(rootPath());

export const goToSignIn = () => goTo(signInPath());

export const goToCart = (deliveryDayId, query = {}) => goTo(cartPath(deliveryDayId, query));

export const goToMenu = (deliveryDayId) => goTo(menuPath(deliveryDayId));

export const goToProduct = (deliveryDayId, productId) => goTo(productPath(deliveryDayId, productId));

export const goToBilling = () => goTo('/settings/billing');

export const goToInitOrder = () => {
  const currentPath = history.location.pathname;
  if (currentPath !== '/onramp/init-order') {
    goTo('/onramp/init-order');
  } else {
    history.go(0);
  }
};
