import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { generateRandomId } from 'core/ui/helpers';

class Radio extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func,
    label: PropTypes.node,
    className: PropTypes.string,
  };

  render() {
    const { checked = false, label, className = '', ...rest } = this.props;

    const id = `radio-${generateRandomId()}`;

    return (
      <RadioCont htmlFor={id} className={className}>
        <Input id={id} type='radio' checked={checked} {...rest} />
        <Facade className='radio' />
        {label}
      </RadioCont>
    );
  }
}

const RadioCont = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  &:span_2 {
  grid-column: span 2;
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  height: 1em;
  width: 1em;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;

  :checked ~ .radio {
    border-color: ${(p) => p.theme.colors.primary};

    :after {
      background: ${(p) => p.theme.colors.primary};
    }
  }
`;

const Facade = styled.span`
  position: relative;
  margin-right: 0.5em;
  height: 1em;
  width: 1em;
  min-width: 1em;
  background-color: transparent;
  transition: border-color 0.15s ease-in-out;
  border: 1px solid ${(p) => p.theme.colors.grays.regular};
  border-radius: 50%;

  :after {
    content: '';
    position: absolute;
    top: calc(0.25em / 2);
    left: calc(0.25em / 2);
    width: calc(100% - 0.25em);
    height: calc(100% - 0.25em);
    border-radius: 50%;
    transition: background 0.15s ease-in-out;
    background: transparent;
  }
`;

export default Radio;
