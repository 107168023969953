import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Image as ImageAtom, Text } from 'core/ui/atoms';

class InfoImage extends PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string,
    subtitle:  PropTypes.string,
  };

  render() {
    const { src, title, subtitle, ...rest } = this.props;

    return (
      <Wrapper {...rest} >
        <Image src={src} className='image' />
        <Caption className='image-caption'>
          {title && <Text no-margin text-bold text-size="small">{title}</Text>}
          <Subtitle no-margin text-size="smaller">{subtitle}</Subtitle>
        </Caption>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;

  ${(p) => p.theme.max('md')`
    transform: translateX(-35%);
  `}

  ${(p) => p.theme.max('sm')`
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    transform: translate(40%, -70%) rotate(55.65deg);
  `}
`;

const Image = styled(ImageAtom)`
  width: 100%;

  ${(p) => p.theme.max('sm')`
    width: 50vw;
  `}
`;

const Caption = styled.div`
  display: inline-block;
  margin-top: 0.25em;

  ${(p) => p.theme.max('md')`
    align-self: flex-end;
    padding-left: 35%;
  `}

  ${(p) => p.theme.max('sm')`
    display: none;
  `}
`;

const Subtitle = styled(Text)`
  max-width: 300px;
`;

export default InfoImage;
