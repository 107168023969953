import styled from 'styled-components';
import { flexChildMixin, hiddenMixin, noMarginMixin, textMixin } from 'core/ui/mixins';

// DEPRECATED use List.Item instead
const ListItem = styled.li`
  display: block;
  ${textMixin}
  ${noMarginMixin}
  ${hiddenMixin}
  ${flexChildMixin}
`;

export default ListItem;
