import moment from 'moment';
import sumBy from 'lodash/sumBy';

import Money from 'core/api/Money';

const orderHasItems = (order) => order && order.lineItems && order.lineItems.length;

export const deliveryFeeAmount = (order) => {
  return Money(orderHasItems(order) && order.deliveryFeeAmount);
};

export const serviceFeeAmount = (order) => {
  return Money(orderHasItems(order) && order.serviceFeeAmount);
};

export const totalAmount = (order) => {
  if (!order) { return Money(); }
  const amount = moment().isAfter(moment(order.billingTime)) ? order.billedAmount : order.estimatedAmount;

  return Money(amount);
};

export const subtotalAmount = (order) => {
  const feeAmount = deliveryFeeAmount(order).add(serviceFeeAmount(order));
  return Money.maximum([totalAmount(order).subtract(feeAmount), Money()]);
};

const buildLineItem = (order, product, variant, quantity) => {
  const price = { ...variant.price };
  price.amount = price.amount * quantity;

  return {
    optimistic: true,
    id: `${order.id}-${variant.id}`,
    __typename: 'OrderLineItem',
    quantity,
    order: {
      __typename: 'Order',
      id: order.id,
      skipped: false,
    },
    product: product || {},
    variant: variant,
    unitPrice: variant.price,
    price,
    gratis: false,
    message: null,
  };
};

const deliveryFee = (order, lineItems) => {
  if (lineItems.length < 1 || !order.deliveryFeeAmount) {
    return 0;
  }

  return order.deliveryFeeAmount.amount;
};

const estimatedAmount = (order, lineItems) => {
  const amount = { ...order.estimatedAmount };
  amount.amount = sumBy(lineItems, (item) => (item.price.amount)) + deliveryFee(order, lineItems);
  return amount;
};

export const upsertItemOnOrderOptimisticResponse = (deliveryDayId, order, product, variant, quantity) => {
  let lineItem = order.lineItems.find((item) => item.variant.id === variant.id);
  let lineItems = lineItem ? order.lineItems.filter((item) => item.id !== lineItem.id) : order.lineItems;
  lineItem = lineItem || buildLineItem(order, product, variant, quantity);
  lineItem = { ...lineItem, quantity };

  lineItems = [...lineItems, lineItem];

  return {
    __typename: 'Mutation',
    upsertItemOnOrder: {
      __typename: 'UpsertItemOnOrderPayload',
      errors: [],
      deliveryDay: {
        __typename: 'DeliveryDay',
        id: deliveryDayId,
        order: {
          __typename: 'Order',
          id: order.id,
        },
      },
      order: {
        __typename: 'Order',
        ...order,
        estimatedAmount: estimatedAmount(order, lineItems),
        lineItems,
      },
    },
  };
};

export const updateLineItemOptimisticResponse = (order, lineItem, quantity) => {
  let lineItems = order.lineItems.filter((item) => item.id !== lineItem.id);
  const price = { ...lineItem.unitPrice };
  price.amount = lineItem.unitPrice.amount * quantity;
  lineItem = { ...lineItem, quantity, price };
  lineItems = [...lineItems, lineItem];

  return {
    __typename: 'Mutation',
    updateLineItem: {
      __typename: 'UpdateLineItemPayload',
      errors: [],
      order: {
        __typename: 'Order',
        ...order,
        estimatedAmount: estimatedAmount(order, lineItems),
        lineItems,
      },
    },
  };
};
