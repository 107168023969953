import React, { PureComponent } from 'react';

import { ValidatorForm } from 'react-form-validator-core';
import { ERROR_MESSAGES } from 'core/api/constants';
import { Style, StyleGrid, StyleSection } from 'admin/ui/atoms';
import {
  Checkbox,
  FormGroup,
  InputGroupLabel,
  Label,
  Radio,
  Select,
  ValidatedInput,
  ValidatedTextarea,
} from 'core/ui/atoms';

import RadioV2 from 'core/ui/atoms/V2/Radio';
import CheckboxV2 from 'core/ui/atoms/V2/Checkbox';

class Forms extends PureComponent {
  state = {
    text: '',
    password: '',
    radio: '0',
    select: '',
    textarea: '',
    checkbox: true,
    uncheckedbox: false,
    checkboxV2: true,
    uncheckedboxV2: false,
    radioV2: '1',
  };

  handleChange = (update) => this.setState((st) => ({ ...st, ...update }));

  onChange = (e) => this.handleChange({ [e.target.name]: e.target.value });

  onSubmit = () => null;

  render() {
    return (
      <StyleSection title='Forms'>
        <ValidatorForm
          onSubmit={() => null}
          noValidate
          instantValidate={false}
        >
          <StyleGrid cols='repeat(3, 1fr)'>
            <Style name='Text'>
              <FormGroup>
                <Label required>Text Input</Label>
                <ValidatedInput
                  type="text"
                  name="text"
                  placeholder="placeholder text"
                  value={this.state.text}
                  onChange={this.onChange}
                  validators={['required']}
                  errorMessages={[ERROR_MESSAGES.required]}
                />
              </FormGroup>
            </Style>

            <Style name='Password'>
              <FormGroup>
                <Label required>Password Input</Label>
                <ValidatedInput
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  validators={['required']}
                  errorMessages={[ERROR_MESSAGES.required]}
                />
              </FormGroup>
            </Style>

            <Style name='Checkbox'>
              <FormGroup>
                <InputGroupLabel>Checkboxes</InputGroupLabel>
                <Checkbox
                  name='checkbox'
                  label='Checked'
                  checked={this.state.checkbox}
                  onChange={() => this.handleChange({ checkbox: !this.state.checkbox })}
                />
                <Checkbox
                  name='uncheckedbox'
                  label='Unchecked'
                  checked={this.state.uncheckedbox}
                  onChange={() => this.handleChange({ uncheckedbox: !this.state.uncheckedbox })}
                />
              </FormGroup>
            </Style>

            <Style name='CheckboxV2'>
              <FormGroup>
                <InputGroupLabel>CheckboxesV2</InputGroupLabel>
                <CheckboxV2
                  name='checkboxV2'
                  label='CheckedV2'
                  checked={this.state.checkboxV2}
                  onChange={() => this.handleChange({ checkboxV2: !this.state.checkboxV2 })}
                />
                <CheckboxV2
                  name='uncheckedboxV2'
                  label='UncheckedV2'
                  checked={this.state.uncheckedboxV2}
                  onChange={() => this.handleChange({ uncheckedboxV2: !this.state.uncheckedboxV2 })}
                />
              </FormGroup>
            </Style>

            <Style name='Radio'>
              <FormGroup>
                <InputGroupLabel>Radio Buttons</InputGroupLabel>
                <Radio
                  name='radio'
                  value='0'
                  label='Checked'
                  checked={this.state.radio === '0'}
                  onChange={this.onChange}
                />
                <Radio
                  name='radio'
                  value='1'
                  label='Unchecked'
                  checked={this.state.radio === '1'}
                  onChange={this.onChange}
                />
              </FormGroup>
            </Style>

            <Style name='RadioV2'>
              <FormGroup>
                <InputGroupLabel>RadioV2 Buttons</InputGroupLabel>
                <StyleGrid cols='1fr 1fr'>
                <RadioV2
                  name='radioV2'
                  value='0'
                  label='unChecked'
                  subLabel='unChecked description'
                  checked={this.state.radioV2 === '0'}
                  onChange={this.onChange}
                />
                <RadioV2
                  name='radioV2'
                  value='1'
                  subLabel='Checked description'
                  label='Checked'
                  checked={this.state.radioV2 === '1'}
                  onChange={this.onChange}
                />
                </StyleGrid>
                <RadioV2
                  name='radioV2'
                  value='2'
                  subLabel='unChecked description'
                  label='Unchecked'
                  checked={this.state.radioV2 === '2'}
                  onChange={this.onChange}
                />
                <RadioV2
                  name='radioV2'
                  value='3'
                  subLabel='unChecked description'
                  label='Unchecked'
                  checked={this.state.radioV2 === '3'}
                  onChange={this.onChange}
                />
              </FormGroup>
            </Style>

            <Style name='Select'>
              <FormGroup style={{ minWidth: '50%' }}>
                <Select
                  prompt='Select one'
                  options={[ { title: 'First', value: '1' }, { title: 'Second', value: '2' }, { title: 'Third', value: '3' } ]}
                  selected={this.state.select}
                  onChange={this.onChange}
                />
              </FormGroup>
            </Style>

            <Style name='Textarea'>
              <FormGroup>
                <Label required>Textarea</Label>
                <ValidatedTextarea
                  name="textarea"
                  value={this.state.textarea}
                  onChange={this.onChange}
                  rows={3}
                  placeholder="placeholder text"
                />
              </FormGroup>
            </Style>
          </StyleGrid>
        </ValidatorForm>
      </StyleSection>
    );
  }
}

export default Forms;
