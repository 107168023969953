import useAlerts from 'core/hooks/useAlerts';
import { ERROR_MESSAGES } from 'core/api/constants';
import { reportError } from 'core/api/errors';

function useErrorHandlers() {
  const alerts = useAlerts();

  const displayApolloResponseErrors = (errors, displayOnly = false) => {
    errors.forEach((error) => {
      alerts.show(error.message || error);
      if(!displayOnly) {
        reportError(error);
      }
    });
  };

  const handleGenericApolloError = (error) => {
    const { message = ERROR_MESSAGES.generic } = error || {};
    displayApolloResponseErrors([message]);
  };

  return {
    displayApolloResponseErrors,
    handleGenericApolloError,
  };
}

export default useErrorHandlers;
