import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import _orderBy from 'lodash/orderBy';
import _take from 'lodash/take';

import { Box, Button, Text } from 'core/ui/atoms';
import { List, ListItem } from 'settings/ui/atoms';
import Modal from 'settings/ui/components/Modal';
import Panel from 'settings/ui/components/Panel';
import Money from 'core/api/Money';
import { displayDate } from 'core/ui/dateHelpers';
import { LIST_INVOICES } from 'core/api/apollo/queries';

const NUM_PREVIEW = 4;

function Invoices() {
  const { loading, data: { listInvoices = [] } = {} } = useQuery(LIST_INVOICES);

  const invoices = _orderBy(listInvoices.filter((invoice) => !invoice.voided), 'date', 'desc');

  return (
    <Panel title='Invoices' loading={loading} data-invoices>
      <InvoiceList invoices={_take(invoices, NUM_PREVIEW)} />

      {(invoices.length > NUM_PREVIEW) && <ShowAllModal invoices={invoices} />}
    </Panel>
  );
}

function ShowAllModal(props) {
  const { invoices } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };

  return (
    <Panel.Footer>
      <ShowMoreButton unstyled onClick={openModal}>Show more</ShowMoreButton>
      { modalOpen && (
        <Modal title='Invoices' onClose={closeModal}>
          <ModalInvoiceList invoices={invoices} />
        </Modal>
      )}
    </Panel.Footer>
  );
}

ShowAllModal.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function InvoiceList(props) {
  const renderInvoiceStatus = (invoice) => {
    const statuses = [];

    if (invoice.refunded) {
      statuses.push('refund applied');
    }

    if (invoice.creditedAmount && invoice.creditedAmount.amount) {
      statuses.push('credits applied');
    }

    if (invoice.pastDue) {
      statuses.push('past due');
    }

    if (!statuses.length) {
      return null;
    }
    return `*${statuses.join(', ')}`;
  };

  const { invoices, ...rest } = props;

  if (!invoices.length) {
    return <Text no-margin text-color='muted'>None</Text>;
  }

  return (
    <List {...rest}>
      {
        invoices.map((invoice) => (
          <ListItem key={invoice.id} data-invoice-id={invoice.id}>
            <Download href={invoice.pdfUrl} target="_blank">
              <InvoiceDate md={4}>
                {displayDate(invoice.date, 'shortDate')}
              </InvoiceDate>
              <InvoiceStatus md={4}>
                {renderInvoiceStatus(invoice)}
              </InvoiceStatus>
              <InvoiceAmount md={4}>
                {Money(invoice.amount).toFormat()}
              </InvoiceAmount>
            </Download>
          </ListItem>
        ))
      }
    </List>
  );
}

InvoiceList.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const Download = styled.a`
  display: flex;
  cursor: pointer;
  font-weight: normal;
`;

const InvoiceDate = styled(Box)`
`;

const InvoiceStatus = styled(Box)`
  font-size: ${(p) => p.theme.typography.sizes.smallish};
  font-style: italic;
  margin-left: 7px;
`;

const InvoiceAmount = styled(Box)`
  text-align: right;
`;

const ShowMoreButton = styled(Button)`
  font-size: ${(p) => p.theme.typography.sizes.smaller};
  color: ${(p) => p.theme.typography.colors.muted};
`;

const ModalInvoiceList = styled(InvoiceList)`
  ${(p) => p.theme.min('sm')`
    max-height: 80vh;
    overflow-y: scroll;
  `}
`;

export default Invoices;
