import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Image as ImageAtom, H2 } from 'core/ui/atoms';
import DownArrow from 'core/assets/images/V2/DownArrow.svg';

class HeroImage extends PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string,
    alt: PropTypes.string.isRequired,
  };

  render() {
    const { src, title, alt, ...rest } = this.props;

    return (
      <Wrapper {...rest} >
        <Image src={src} className='image' alt={alt}/>
        <CustomHeader>
          {title}
          <ArrowImage src={DownArrow} alt=''/>
        </CustomHeader>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;

  ${(p) => p.theme.max('md')`
    transform: translateX(-35%);
  `}

  ${(p) => p.theme.max('sm')`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%, -70%) rotate(55.65deg);
  `}
`;

const Image = styled(ImageAtom)`
  width: 100%;
`;

const ArrowImage = styled(ImageAtom)`
  display: none;
  ${(p) => p.theme.max('sm')`
    display: block;
    width: 8px !important;
    height: 40px !important;
    min-height: 40px !important;
    margin: 0 auto;
  `}
`;



const CustomHeader = styled(H2)`
    display: none;
  ${(p) => p.theme.max('sm')`
    font-size: 36px;
    padding: 0 5vw;
    display: block;
    position: absolute;
    bottom: 0;
    text-align: center;
    color: ${(p) => p.theme.typography.colors.inverse};

  `}
`;

export default HeroImage;
