import React from 'react';
import styled from 'styled-components';

import { hiddenMixin, noMarginMixin, textMixin } from 'core/ui/mixins';

const Heading = styled.h4.attrs((props) => ({ as: `h${props.level || 4}` }))`
  color: ${(p) => p.theme.typography.colors.primary};
  ${noMarginMixin}
  ${hiddenMixin}
  font-family: ${(p) => p.theme.typography.familyText};
  font-weight: 500;
  font-size: ${(p) => p.theme.typography.sizes[`h${(p) => p.level || 4}`]};
  ${textMixin}
`;

export const H1 = styled((props) => <Heading level={1} {...props} />)`
  font-family: ${(p) => p.theme.typography.familyHeader};
`;

export const H2 = styled((props) => <Heading level={2} {...props} />)`
  font-family: ${(p) => p.theme.typography.familyHeader};
`;

export const H3 = (props) => <Heading level={3} {...props} />;

export const H4 = (props) => <Heading level={4} {...props} />;

export const H5 = (props) => <Heading level={5} {...props} />;

export const H6 = (props) => <Heading level={6} {...props} />;
