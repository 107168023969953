import React, { useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';

import { UPDATE_LINE_ITEM } from 'core/api/apollo/mutations';

import QuantitySelect from 'core/ui/components/QuantitySelect';

import { updateLineItemOptimisticResponse } from 'dashboard/ui/helpers/OrderHelper';

function LineItemQuantity(props) {
  // See https://egghead.io/lessons/react-safely-setstate-on-a-mounted-react-component-through-the-useeffect-hook
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      isSaving: false,
    },
  );
  const mounted = useRef(false);
  const [updateLineItem] = useMutation(UPDATE_LINE_ITEM);

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  const setStateSafely = (...args) => mounted.current && setState(...args);

  const saveQuantity = (quantity) => {
    const { order, lineItem } = props;
    setStateSafely({ isSaving: true });

    const variables = { input: { lineItemId: lineItem.id, quantity } };
    const optimisticResponse = updateLineItemOptimisticResponse(order, lineItem, quantity);
    updateLineItem({ variables, optimisticResponse })
      .finally(() => setStateSafely({ isSaving: false }));
  };

  const onChange = (e) => {
    const { editable, quantity: originalQuantity } = props;
    const { isSaving } = state;
    const quantity = isEmpty(e.target.value) ? null : toNumber(e.target.value);

    if (!editable || isSaving || quantity === originalQuantity) {
      return false;
    }

    saveQuantity(quantity);
  };

  const { editable, lineItem, quantity } = props;
  const { isSaving } = state;

  return (
    <QuantityInput
      name='quantity'
      selected={`${quantity}`}
      readOnly={!editable || isSaving}
      onChange={onChange}
      data-quantity={quantity}
      maxItems={lineItem.gratis ? lineItem.quantity : undefined}
    />
  );
}

LineItemQuantity.propTypes = {
  order: PropTypes.object.isRequired,
  lineItem: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
};

const QuantityInput = styled(QuantitySelect)`
  .select-selected {
    padding-right: 1em;
  }

  .select-icon {
    display: none;
  }
`;

export default LineItemQuantity;
