import styled from 'styled-components';
import { flexChildMixin, hiddenMixin, noMarginMixin, textMixin } from 'core/ui/mixins';

const Text = styled.p`
  font-weight: normal;
  font-size: ${(p) => p.theme.typography.sizes.base};
  ${noMarginMixin}
  ${flexChildMixin}
  ${hiddenMixin}
  ${textMixin}
`;

export default Text;
