import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from 'core/ui/theme';

function BoxIcon(props) {
  const { colors: { secondary: fill, black: stroke } } = theme;
  const height = 2.5 * 0.8; // downsize closed to match other boxes' heights

  return (
    <SVG
      className={props.className}
      height={`${height}em`}
      viewBox="0 0 35 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="LoggedIn-XP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Menus" transform="translate(-839.000000, -75.000000)">
          <g id="Menu-1" transform="translate(-1.000000, 60.000000)">
            <g id="Box-Full">
              <g transform="translate(841.000000, 16.000000)">
                <polygon id="Stroke-5" fill={fill} points="16.4805443 18.2306985 32.9612226 12.5596674 32.9612226 5.39411809 16.4805443 10.930326"></polygon>
                <polygon id="Stroke-11" fill={fill} points="16.4805443 18.2306985 -0.00013391304 12.5596674 -0.00013391304 5.22859075 16.4805443 10.8440129"></polygon>
                <polygon id="Stroke-8" fill={fill} points="8.02527478 8.01273191 23.9944052 2.51928511 16.506422 0.0748197115 0.689017918 5.10058594"></polygon>
                <polygon id="Stroke-8" fill={fill} points="8.02527478 8.01273191 23.9944052 2.51928511 32.6817043 5.26600851 16.5481063 10.8089694"></polygon>
                <polygon id="Stroke-1" fill={fill} stroke={stroke} strokeWidth="1.67999987" strokeLinecap="round" strokeLinejoin="round" points="32.9613565 24.2549538 16.4806783 29.9259849 16.4806783 10.9283548 32.9613565 5.25732373"></polygon>
                <path d="M8.02527478,8.01273191 L23.9944052,2.51928511" id="Stroke-8" stroke={stroke} strokeWidth="1.67999987" strokeLinecap="round" strokeLinejoin="round"></path>
                <polygon id="Stroke-9" fill={fill} stroke={stroke} strokeWidth="1.67999987" strokeLinecap="round" strokeLinejoin="round" points="16.4805443 10.927966 -0.00013391304 5.25693486 -0.00013391304 24.254565 16.4805443 29.9255961"></polygon>
                <polyline id="Stroke-13" stroke={stroke} strokeWidth="1.67999987" strokeLinecap="round" strokeLinejoin="round" points="0.000267826083 5.25693486 16.4809461 0.186060884 32.9616243 5.25693486"></polyline>
                <path d="M21.9416522,24.6055856 L29.7621739,21.9146003" id="Stroke-16" stroke={stroke} strokeWidth="1.67999987" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M23.9941374,20.7978933 L29.7631113,18.8133565" id="Stroke-17" stroke={stroke} strokeWidth="1.67999987" strokeLinecap="round" strokeLinejoin="round"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SVG>
  );
}

BoxIcon.propTypes = {
  className: PropTypes.string,
};

const SVG = styled.svg`
  margin-top: 0.5em;
`;

export default BoxIcon;
