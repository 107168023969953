import React, { PureComponent } from 'react';

import theme from 'core/ui/theme';
import { Style, StyleGrid, StyleSection } from 'admin/ui/atoms';
import { Logo, WordMark, LogoMark } from 'core/ui/atoms';

class Logos extends PureComponent {
  render() {
    return (
      <StyleSection title='Logos'>
        <StyleGrid cols='repeat(2, 1fr)'>
          <Style name='Logo'>
            <Logo style={{ fontSize: '2em' }} />
          </Style>
          <Style name='Logo' caption='inverse' style={{ backgroundColor: theme.colors.primary }}>
            <Logo inverse style={{ fontSize: '2em' }} />
          </Style>

          <Style name='WordMark'>
            <WordMark style={{ fontSize: '2em' }} />
          </Style>
          <Style name='WordMark' caption='inverse' style={{ backgroundColor: theme.colors.primary }}>
            <WordMark inverse style={{ fontSize: '2em' }} />
          </Style>

          <Style name='LogoMark'>
            <LogoMark style={{ fontSize: '2em' }} />
          </Style>
          <Style name='LogoMark' caption='inverse' style={{ backgroundColor: theme.colors.primary }}>
            <LogoMark inverse style={{ fontSize: '2em' }} />
          </Style>
        </StyleGrid>
      </StyleSection>
    );
  }
}

export default Logos;
