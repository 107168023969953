import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { compareDates, displayDate } from 'core/ui/dateHelpers';

import {
  Box,
  Flex,
} from 'core/ui/atoms';
import DeliveryDay from 'dashboard/ui/components/DeliveryDay';

function DeliveryPeriod(props) {
  const { deliveryPeriod } = props;

  const days = [...deliveryPeriod.days].sort((a,b) => compareDates(a.date, b.date)).filter((d,i)=>  !(d.closed && i!==0) );
  const cutoffTime = deliveryPeriod.cutoffTime;
  const locked = moment().isSameOrAfter(cutoffTime);

  return (
    <Wrapper data-delivery-period={deliveryPeriod.id}>
      <CutoffTime locked={locked}>
        Edit by {displayDate(cutoffTime, 'deliveryCutoffDate')}
      </CutoffTime>

      <DaysWrapper>
        {
          days.map((day) => (
            <DayBox key={day.id} xs={6} sm={5} md={4}>
              <DeliveryDay deliveryDay={day} />
            </DayBox>
          ))
        }
      </DaysWrapper>
    </Wrapper>
  );
}

DeliveryPeriod.propTypes = {
  deliveryPeriod: PropTypes.object.isRequired,
};

const Wrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const CutoffTime = styled.div`
  visibility: ${(p) => p.locked ? 'hidden' : 'visible'};
  font-size: 0.86em;
  padding: 10px 20px;
  background-color: ${(p) => p.theme.colors.primary};
  border-radius: 5em;
  color: ${(p) => p.theme.typography.colors.inverse};
  margin-bottom: 20px;
  display: ${(p) => p.locked ? 'none' : 'inherit'};
`;

const DaysWrapper = styled(Flex)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DayBox = styled(Box)`
  padding: 0 1vw;

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0px;
  }
`;

export default DeliveryPeriod;
