import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'core/ui/atoms/Icon';

function RatingStar(props) {
  return (
    <Star
      {...props}
      data-selected={props.selected}
      data-rating={props.rating}
    />
  );
}

RatingStar.propTypes = {
  selected: PropTypes.bool.isRequired,
  rating: PropTypes.number.isRequired,
};

const Star = styled((props) => <Icon icon="star" {...props} />)`
  color: ${(p) => p.selected ? p.theme.colors.yellow : p.theme.colors.grays.light };
  cursor: pointer;
`;

export default RatingStar;
