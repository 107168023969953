import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

import { Flex, H2, Image, Logo, Text } from 'core/ui/atoms';
import { BackgroundJobStatusCheck, BodyScrollManager } from 'core/ui/components';

import IMAGE from 'onramp/assets/images/Review/main.png';

class ProcessingPurchaseOverlay extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    backgroundJobId: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  render() {
    const { open, backgroundJobId, onSuccess, onError } = this.props;

    return (
      ReactDOM.createPortal(
        <Background open={open}>
          <BodyScrollManager disabled={open} />
          <CustomImage src={IMAGE} />
          <Content>
            <CustomLogo/>
            <CustomHeading>Processing</CustomHeading>
            <Text>Don't hit the back button on your browser!</Text>
          </Content>
          <BackgroundJobStatusCheck
            backgroundJobId={backgroundJobId}
            onSuccess={onSuccess}
            onError={onError}
          />
        </Background>,
      document.getElementById('modal-root'))
    );
  }
}

const Background = styled(Flex)`
  position: fixed;
  z-index: ${(p) => p.theme.zIndex.loadingModal};
  background-color: ${(p) => p.theme.modals.inverseBackdropColor};
  backdrop-filter: blur(4px);
  opacity: 0;
  top: -100vh;
  left: 0;
  width: 100vw;
  height: 100vh;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${(p) => p.theme.max('sm')`
    flex-direction: column;
  `}

  transition: opacity 0.25s ease-in-out;

  ${(p) => p.open && css`
    top: 0;
    opacity: 1;
  `};
`;

const Content = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CustomLogo = styled(Logo)`
  font-size: 1.75em;
`;

const CustomHeading = styled(H2)`
  font-size: 3em;
  margin: 10px 0;
`;

const CustomImage = styled(Image)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  bottom: 0;
`;

export default ProcessingPurchaseOverlay;
