// NOTE: inspired by https://czaplinski.io/blog/super-easy-animation-with-react-hooks/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

// TODO: replace with react-spring or similar library
const Fade = ({ show, duration = 0.25, children, ...rest }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) {
      setRender(true);
    }
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) {
      setRender(false);
    }
  };

  return (
    shouldRender && (
      <Faded
        animation={show ? fadeIn : fadeOut}
        duration={duration}
        onAnimationEnd={onAnimationEnd}
        {...rest}
      >
        {children}
      </Faded>
    )
  );
};

Fade.propTypes = {
  show: PropTypes.bool.isRequired,
  duration: PropTypes.number,
  children: PropTypes.node,
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Faded = styled.div`
  animation: ${(p) => css`${p.animation} ${p.duration}s ease-in-out`};
`;

export default Fade;
