import styled, { css } from 'styled-components';
import { textMixin } from 'core/ui/mixins';

const Label = styled.label`
  display: inline-block;
  font-size: ${(p) => p.theme.typography.sizes.small};
  font-weight: 500;
  padding: 0.5em 0 0.5em 1em;

  ${(p) => p.block && css`
    display: block !important;
  `}

  ${(p) => p.required && css`
    &:after {
      content: '*';
      color: ${(p) => p.theme.colors.danger};
    }
  `}

  > small {
    display: block;
    font-weight: 300;
  }

  ${textMixin}
`;

export const InputGroupLabel = styled(Label)`
  font-size: 1.15em;
  font-weight: 500;
  padding-bottom: 1em;
  padding-left: 0;
`;

export default Label;
