import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { Style, StyleGrid, StyleSection } from 'admin/ui/atoms';
import { Button, Flex, H2, LogoMark, Text } from 'core/ui/atoms';
import Modal from 'core/ui/components/Modal';
import AlertModal from 'core/ui/components/AlertModal';
import SplitPanelModal from 'core/ui/components/SplitPanelModal';
import SPLIT_BG from 'core/assets/images/bg1.jpg';

class Modals extends PureComponent {
  state = {
    modal: false,
    alertModal: false,
    splitModal: false,
    }

  toggleModal = () => this.setState((st) => ({ ...st, modal: !st.modal }));

  toggleAlertModal = () => this.setState((st) => ({ ...st, alertModal: !st.alertModal }));

  toggleSplitModal = () => this.setState((st) => ({ ...st, splitModal: !st.splitModal }));

  render() {
    return (
      <StyleSection title='Modals'>
        <StyleGrid cols='repeat(3, 1fr)'>
          <Style name='Modal'>
            <Button btn-primary onClick={this.toggleModal}>Open</Button>
            {
              this.state.modal && (
                <Modal onClose={this.toggleModal}>
                  <Content>
                    <Logo />
                    <H2>Hey there!</H2>
                    <Text>Some descriptive text</Text>
                    <Button onClick={this.toggleModal} btn-primary btn-lg>Gotcha</Button>
                  </Content>
                </Modal>
              )
            }
          </Style>

          <Style name='Modal' caption='alert'>
            <Button btn-primary onClick={this.toggleAlertModal}>Open</Button>
            {
              this.state.alertModal && (
                <AlertModal
                  heading='Houston, we have a problem!'
                  buttonText='Fix it!'
                  onClick={this.toggleAlertModal}
                >
                  <Text>
                    There's an issue with something
                  </Text>
                </AlertModal>
              )
            }
          </Style>

          <Style name='Modal' caption='split panel'>
            <Button btn-primary onClick={this.toggleSplitModal}>Open</Button>
            {
              this.state.splitModal && (
                <SplitPanelModal
                  image={SPLIT_BG}
                  onClose={this.toggleSplitModal}
                >
                  <Content>
                    <H2>Hey!!!</H2>
                    <Text>Some descriptive text</Text>
                    <Button onClick={this.toggleSplitModal} btn-primary btn-lg>Let's Go!</Button>
                  </Content>
                </SplitPanelModal>
              )
            }
          </Style>
        </StyleGrid>
      </StyleSection>
    );
  }
}

const Content = styled(Flex)`
  padding: 3vh 3vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled(LogoMark)`
  font-size: 2em;
`;

export default Modals;
