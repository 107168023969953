import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import styled from 'styled-components';

import { GET_SUBSCRIPTION_OPTIONS } from 'core/api/apollo/queries';
import { Text } from 'core/ui/atoms';

import { displayDate } from 'core/ui/dateHelpers';

@graphql(GET_SUBSCRIPTION_OPTIONS)
class EditReminder extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data: { getSubscriptionOptions = {}, loading } } = this.props;
    const { startPeriod } = getSubscriptionOptions;

    if (loading) {
      return null;
    }

    return (
      <Reminder text-size='smaller'>
        <strong>No surprises.</strong> We’ll remind you via email and SMS before each box ships. You can edit or pause your order up until {displayDate(startPeriod.cutoffTime, 'deliveryCutoffTime')}.
      </Reminder>
    );
  }
}

const Reminder = styled(Text)`
  margin-top: ${(p) => p.theme.layout.onramp.padding.vert};
`;

export default EditReminder;
