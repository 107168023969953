import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Page } from 'core/ui/atoms';

function SettingsPage(props) {
  const { children, ...rest } = props;

  return (
    <StyledPage {...rest}>
      {children}
    </StyledPage>
  );
};

SettingsPage.propTypes = {
  children: PropTypes.node,
};

const StyledPage = styled(Page)`
  padding-bottom: 100px;
  width: 100%;
`;

export default SettingsPage;
