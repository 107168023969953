import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Modal as ModalComp } from 'core/ui/components';
import Panel from 'settings/ui/components/Panel';

function Modal(props) {
  const { onClose, title, children, ...rest } = props;

  return (
    <SettingsModal onClose={onClose} {...rest}>
      <Panel title={title}>
        {children}
      </Panel>
    </SettingsModal>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

const SettingsModal = styled(ModalComp)`
  ${(p) => p.theme.min('md')`
    background-color: transparent;
  `}
`;

export default Modal;
