import { useQuery } from '@apollo/client';

import { GET_ORDER } from 'core/api/apollo/queries';

function useOrder(orderId, includeGiftItems: false) {
  const { loading, data: { getOrder: order } = {} } = useQuery(GET_ORDER, {
    variables: { orderId },
    skip: !orderId,
  });

  if (loading || !order) {
    return { loading, order };
  }

  const { lineItems, ...rest } = order;

  return {
    loading,
    order: {
      lineItems: lineItems.filter((item) => includeGiftItems || !item.gratis),
      ...rest,
    },
  };
}

export default useOrder;
