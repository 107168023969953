// FIXME: replace this with react-hook-form
import { useState } from 'react';
import _isEqual from 'lodash/isEqual';
import { ERROR_MESSAGE } from 'core/api/constants';

import useErrorHandlers from 'core/hooks/useErrorHandlers';

function useForm(mutationNames, options = {}) {
  const { initialData = {}, onSubmit, onSuccess, onError, validator } = options || {};
  const { displayApolloResponseErrors } = useErrorHandlers();

  const [isProcessing, setIsProcessing] = useState(false);
  const [submissionCount, setSubmissionCount] = useState(0);
  const [formData, setFormData] = useState({ ...initialData });

  const handleChange = (update) => setFormData({ ...formData, ...update });
  const onChange = (e) => handleChange({ [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e && e.preventDefault && e.preventDefault();
    setIsProcessing(true);

    if (!validator || validator(formData)) {
      setSubmissionCount(submissionCount + 1);

      const variables = { input: formData };

      // FIXME: find a better way to determine if there's a mutation error
      onSubmit({ variables }).then((data) => {
        const { data: response } = data;
        const errors = [];
        const mutations = (mutationNames && mutationNames.split(',')) || [];
        mutations.forEach((m) => response[m] && errors.push.apply(errors, response[m].errors));
        if (errors.length) {
          handleError(errors);
        } else {
          handleSuccess(data);
        }
      }, () => {
        handleError([{
          message: ERROR_MESSAGE,
        }], true);
      });
    } else {
      setIsProcessing(false);
    }
  };

  const handleError = (errors, displayOnly = false) => {
    setIsProcessing(false);
    (onError || displayApolloResponseErrors)(errors, displayOnly);
  };

  const handleSuccess = (data) => {
    setIsProcessing(false);
    (onSuccess && onSuccess(data));
  };

  // FIXME: This requires arrays to be in the same order
  const isDirty =  !_isEqual(formData, initialData);
  const isSubmitted = submissionCount > 0;

  return {
    handleChange,
    handleSubmit,
    onChange,
    isDirty,
    isProcessing,
    setIsProcessing,
    submissionCount,
    isSubmitted,
    formData,
  };
}

export default useForm;
