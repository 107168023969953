import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import { Select } from 'core/ui/atoms';

const MIN_ITEMS = 0;
const MAX_ITEMS = 20;

class QuantitySelect extends PureComponent {
  static propTypes = {
    ...Select.propTypes,
    minItems: PropTypes.number,
    maxItems: PropTypes.number,
  };

  selectOptions = () => {
    const { minItems = MIN_ITEMS, maxItems = MAX_ITEMS } = this.props;
    return range(minItems, maxItems + 1).map((value) => ({ title: `${value}`, value: `${value}` }));
  }

  render() {
    const { minItems, maxItem, ...rest } = this.props;

    return(
      <StyledSelect options={this.selectOptions()} {...rest} />
    );
  }
}

const StyledSelect = styled(Select)`
  .select-selected {
    border: 2px solid ${(p) => p.theme.colors.grays.light};
    border-radius: 2px;
    font-size: 0.875em;
    color: ${(p) => p.theme.typography.colors.primary};
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    margin: 0;
    text-align: center;
    font-weight: normal;
    &[readonly] {
      background-color: ${(p) => p.theme.colors.grays.light};
      color: ${(p) => p.theme.typography.colors.primary};
    }
  }
`;

export default QuantitySelect;
