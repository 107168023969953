import _kebabCase from 'lodash/kebabCase';
import { userHasShippingAddress } from 'core/api/Account';

export const ONRAMP_ROUTES_NAMESPACE = 'onramp';

export const onrampPath = (step) => `/${ONRAMP_ROUTES_NAMESPACE}/${_kebabCase(step)}`;

export const ROUTES = [
  {
    name: 'signIn', //This is used to sign in users who have not created any orders or passwords.
    isComplete: (user) => !!(user && user.email && user.primaryAddress && user.primaryAddress.zipCode),
  },
  {
    name: 'signUp',
    isComplete: (user) => !!(user && user.email && user.primaryAddress && user.primaryAddress.zipCode),
  },
  {
    name: 'initOrder',
    // eslint-disable-next-line no-unused-vars
    isComplete: (user) =>!! (user && user.email && user.primaryAddress && user.primaryAddress.zipCode) && (!!(user && user.nextOneTimeProgram) || !!(user && user.subscription)),
    // enable: (user) => user && !user.reorder,
  },
  {
    name: 'newBillingInfo',
    isComplete: (user) => !!(user && user.billingAccount && user.billingAccount.active),
  },
  {
    name: 'prepaidConfirmation',
    enabled: (user) => userHasShippingAddress(user) || !!user.nextOneTimeProgram,
  },
].map((route) => ({ path: onrampPath(route.name), ...route }));

const UNSEQUENCED_ROUTES = [
  {
    name: 'outOfDeliveryZone',
  },
].map((route) => ({ path: onrampPath(route.name), ...route }));

export const ROUTE_MAP = [...ROUTES, ...UNSEQUENCED_ROUTES].reduce((obj, route) => ({ ...obj, [route.name]: route }), {});

export const onrampStart = () => ROUTES[0].path;

export const onrampSequenceForUser = (user, cache) =>{
  return ROUTES.filter((route) => route.enabled ? route.enabled(user, cache): true);
};

// FIXME: this should use onrampSequenceForUser
export const stepNumber = (pathName) => ROUTES.findIndex((r) => r.path === pathName.replace(/\/+$/, '')) + 1;

// FIXME: this should use onrampSequenceForUser
export const stepCount = () => ROUTES.length;

export default ROUTES;
