import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Flex, Text } from 'core/ui/atoms';
import { displayWeight } from 'dashboard/ui/helpers';

function NutritionFacts(props) {
  const { numServings, weightOunces, nutrition } = props;

  return useMemo(() => {
    const servingSize = numServings > 1 ? `1/${numServings}` : numServings;
    const servingSizeText = `${servingSize} container (approx. ${displayWeight(weightOunces/numServings)})`;

    return (
      <Fragment>
        <PropsItem fullWidth>
          <Text text-transform="capitalize" no-margin grow={1}>
            Serving Size
          </Text>
          <Text no-margin text-right>
            {servingSizeText}
          </Text>
        </PropsItem>
        <NutritionFact
          title='Number of Servings'
          value={numServings}
          spaceBelow
        />
        <NutritionFact
          title='Calories'
          value={nutrition.calories}
        />
        <NutritionFact
          title='Total Fat'
          value={nutrition.fatGrams}
          unit='g'
        />
        <NutritionFact
          title='Saturated Fat'
          value={nutrition.saturatedFatGrams}
          unit='g'
          nested
          last
        />
        <NutritionFact
          title='Carbs'
          value={nutrition.carbsGrams}
          unit='g'
        />
        <NutritionFact
          title='Fiber'
          value={nutrition.fiberGrams}
          unit='g'
          nested
        />
        <NutritionFact
          title='Sugar'
          value={nutrition.sugarGrams}
          unit='g'
          nested
        />
        <NutritionFact
          title='Sugar Alcohol'
          value={nutrition.sugarAlcoholGrams}
          unit='g'
          nested
          last
        />
        <NutritionFact
          title='Protein'
          value={nutrition.proteinGrams}
          unit='g'
        />
      </Fragment>
    );

  }, [numServings, weightOunces, nutrition]);
}

NutritionFacts.propTypes = {
  numServings: PropTypes.number.isRequired,
  weightOunces: PropTypes.number.isRequired,
  nutrition: PropTypes.object.isRequired,
};

function NutritionFact(props) {
  const { title, unit, value, ...rest } = props;
  const formattedValue = Math.round(value || 0);

  return (
    <PropsItem fullWidth {...rest}>
      <Text text-transform="capitalize" no-margin grow={1}>
        {title}
      </Text>
      <Text no-margin text-right>
        {formattedValue}{unit}
      </Text>
    </PropsItem>
  );
}

NutritionFact.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  unit: PropTypes.string,
  nested: PropTypes.bool,
  last: PropTypes.bool,
  spaceBelow: PropTypes.bool,
};

const PropsItem = styled(Flex)`
  position: relative;
  margin-top: 10px;
  font-weight: 400;
  ${(p) => p.spaceBelow && css`
    margin-bottom: 20px;
  `}
  ${(p) => p.nested && css`
    padding-left: 10px;
    > p:first-child {
      font-weight: 200;
    }
  `}
  ${(p) => p.last && css`
    margin-bottom: 24px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -12px;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: ${(p) => p.theme.colors.grays.light};
    }
  `}
  > p {
    font-size: 16px;
  }
`;

export default NutritionFacts;
