import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, H6, Text } from 'core/ui/atoms';
import CartSummaryIcon from 'dashboard/ui/components/Cart/SummaryIcon';
import { deliveryFeeAmount, totalAmount } from 'dashboard/ui/helpers/OrderHelper';

function OrderSummary(props) {
  const { order, status } = props;

  const feeAmount = deliveryFeeAmount(order);
  const amount = totalAmount(order);

  return (
    <Fragment>
      <CartSummaryIcon
        order={order}
        status={status}
        showBadge={true}
      />
      <ShippingFee data-shipping-fee hiddenXS hiddenSM>
        <H6 no-margin text-color='muted' text-weight='normal'>Shipping</H6>
        <Text no-margin text-size='small'>{feeAmount ? feeAmount.toFormat() : 'N/A'}</Text>
      </ShippingFee>
      <Total data-total hiddenXS hiddenSM>
        <H6 no-margin text-color='muted' text-weight='normal'>Total</H6>
        <Text no-margin text-size='small'>{amount ? amount.toFormat() : 'N/A'}</Text>
      </Total>
    </Fragment>
  );
}

OrderSummary.propTypes = {
  order: PropTypes.object,
  deliveryDay: PropTypes.object,
  status: PropTypes.object.isRequired,
};

const ShippingFee = styled(Box)`
  position: relative;
  margin-right: 20px;
`;

const Total = styled(Box)`
  margin-right: 0;
`;

export default OrderSummary;
