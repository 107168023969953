import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { graphql } from '@apollo/client/react/hoc';

import { Box } from 'core/ui/atoms';
import { GET_MENU_OPENED } from 'core/api/apollo/queries';

@graphql(GET_MENU_OPENED, { name: 'isMenuOpenQuery' })
class LayoutBody extends PureComponent {
  static propTypes = {
    isMenuOpenQuery: PropTypes.object.isRequired,
    children: PropTypes.node,
  };

  render() {
    const { isMenuOpenQuery: { isMenuOpen } } = this.props;

    return (
      <Main isMenuOpen={isMenuOpen}>
        {this.props.children}
      </Main>
    );
  }
}

const Main = styled(Box)`
  margin-top: ${(p) => p.theme.layout.headerHeight};
  will-change: filter;
  min-height: calc(100vh - ${(p) => p.theme.layout.headerHeight});
  ${(props) =>
    props.isMenuOpen &&
    css`
      transition: 0.3s ease;
      filter: blur(10px);
    `};
`;

export default LayoutBody;
