import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Loading from 'core/ui/components/Loading';
import { SettingsHeading } from 'settings/ui/atoms';

function Panel(props) {
  const { title, loading = false, children, ...rest } = props;

  return (
    <Wrapper {...rest} >
      {title && <SettingsHeading>{title}</SettingsHeading>}
      {loading ? <Loading /> : children}
    </Wrapper>
  );
}

Panel.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 1em;
  padding: 3vh 3vw;

  ${(p) => p.theme.max('xs')`
    border-radius: 0;
  `}

  form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
`;

const Footer = styled.div`
  width: 100%;
  margin-top: auto;
  padding-top: 1em;
  text-align: right;
`;

Panel.Footer = Footer;

export default Panel;
