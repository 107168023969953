import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import { menuFilterPath } from 'core/api/actions/navigation';
import { GET_PRODUCT_TAG } from 'core/api/apollo/queries';
import useProductList from 'dashboard/hooks/useProductList';
import CatalogPage from 'dashboard/ui/pages/Catalog/Page';
import ProductList from 'dashboard/ui/components/CatalogPage/ProductList';
import ProductGroup from 'dashboard/ui/helpers/ProductGroup';

import {
  FriendsgivingHeroComponent,
  ImmunityHeroComponent,
  NewHeroComponent,
  PopularHeroComponent,
  ThanksgivingHeroComponent,
} from 'dashboard/ui/components/CatalogPage/FilterHeroes';

function FilterPage(props) {
  return (
    <CatalogPage>
      <Content {...props} />
    </CatalogPage>
  );
}

function Content(props) {
  const { match: { params: { deliveryDayId, tagId: productTagId } } } = props;

  const { loading: productsLoading, products } = useProductList({ variables: { deliveryDayId, productTagId } });
  const { loading: tagLoading, data: { getProductTag: tag = {} } = {} } = useQuery(GET_PRODUCT_TAG, { variables: { productTagId } });

  const FilterHeader = !tagLoading && HEROES[tag.zone];
  const groupHeader = !tagLoading && (FilterHeader ? null : tag.name);
  const productGroups = tag ? [new ProductGroup(groupHeader, 1, products)] : [];

  return (
    <Fragment>
      { FilterHeader && <FilterHeader path={menuFilterPath(deliveryDayId, tag.id)} /> }
      <ProductList
        key={`product-list-${tag && tag.id}`}
        loading={productsLoading || tagLoading}
        productGroups={productGroups}
        {...props}
        addEnabled={true}
      />
    </Fragment>
  );
}

Content.propTypes = {
  match: PropTypes.object.isRequired,
  deliveryDay: PropTypes.object,
  status: PropTypes.object,
  order: PropTypes.object,
};

const NewHero = styled(NewHeroComponent)`
  ${(p) => p.theme.min('lg')`
    background-position-y: 25%;
  `}
`;

const HEROES = {
  friendsgiving: FriendsgivingHeroComponent,
  immunity: ImmunityHeroComponent,
  new: NewHero,
  popular: PopularHeroComponent,
  thanksgiving: ThanksgivingHeroComponent,
};

export default FilterPage;
