import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { menuPath } from 'core/api/actions/navigation';

import { Box, Flex, H4, Icon, RouterLink } from 'core/ui/atoms';
import CartBody from 'dashboard/ui/components/CartPage/Body';
import SkipOrCancelButton from 'dashboard/ui/components/Cart/SkipOrCancelButton';
import CartBillingSummary from 'dashboard/ui/components/Cart/BillingSummary';
import CheckoutWarningComponent from 'dashboard/ui/components/Cart/CheckoutWarning';

function Cart(props) {
  const { status } = props;
  const { order, deliveryDay } = status;

  const canEditOrder = status.menuPublished && !status.locked;

  return (
    <Fragment>
      <ShelfLifeContainer>
        <Box sm={12} md={4} mdOffset={8}>
          <Icon icon='calendar' />
          Eat within 5 days of delivery
        </Box>
      </ShelfLifeContainer>
      <CartContainer data-cart>
        <LineItemListContainer sm={12} md={8}>
          <CartBody
            order={order}
            status={status}
          />
        </LineItemListContainer>

        <BillingSummaryContainer sm={12} md={4}>
          <BillingBackgroundWrapper>
            <SummaryHeading>Summary</SummaryHeading>
            <CartBillingSummary order={order} />

            {
              canEditOrder && (
                <EditOrderButton to={menuPath(deliveryDay.id)}>Edit Order</EditOrderButton>
              )
            }

            {
              !status.skipped && (
                <SkipOrCancel
                  status={status}
                  deliveryDay={deliveryDay}
                  order={order}
                />
              )
            }

            {
              status.locked && order && order.trackingUrl && (
                <TrackDeliveryButton href={order.trackingUrl} target='_blank' rel='noreferrer noopener'>Track Delivery</TrackDeliveryButton>
              )
            }
          </BillingBackgroundWrapper>

          {!status.locked && <CheckoutWarning status={status} />}
        </BillingSummaryContainer>

        {
          canEditOrder && (
            <EditOrderButton mobile to={menuPath(deliveryDay.id)}>Edit Order</EditOrderButton>
          )
        }
      </CartContainer>
    </Fragment>
  );
}

Cart.propTypes = {
  status: PropTypes.object.isRequired,
};

const ShelfLifeContainer = styled(Flex)`
  text-align: center;
  align-items: center;
  min-height: ${(p) => p.theme.layout.headerHeight};
  font-size: 0.75em;

  i {
    margin-right: 0.5em;
    font-size: 1.67em;
    position: relative;
    top: 0.2em;
  }

  ${(p) => p.theme.min('md')`
    position: sticky;
    top: calc(2 * ${(p) => p.theme.layout.headerHeight});
  `};

  ${(p) => p.theme.max('md')`
    padding-bottom: 0;
  `};
`;

const CartContainer = styled(Flex)`
  ${(p) => p.theme.max('md')`
    flex-direction: column;
  `}
`;

const LineItemListContainer = styled(Box)`
  ${(p) => p.theme.min('md')`
    padding-right: 3em;
  `}
`;

const BillingSummaryContainer = styled(Box)`
  ${(p) => p.theme.min('md')`
    position: sticky;
    top: calc(3 * ${(p) => p.theme.layout.headerHeight});
  `};

  ${(p) => p.theme.max('md')`
    display: flex;
    flex-direction: column-reverse;
  `};
`;

const BillingBackgroundWrapper = styled(Box)`
  background-color: ${(p) => p.theme.colors.grays.lighter};
  padding: 1em 20px;
`;

const SummaryHeading = styled(H4)`
  margin-top: 0;
`;

const CheckoutWarning = styled(CheckoutWarningComponent)`
  padding: 1em 0px;
  ${(p) => p.theme.max('sm')`
    padding: 1em 20px;
  `};
`;

const EditOrderButton = styled((props) => <RouterLink btn-primary {...props} />)`
  margin-top: 20px;
  padding: 1em;
  display: block;
  width: 100%;
  text-align: center;

  ${(p) => p.mobile && p.theme.min('md')`
    display: none;
  `}

  ${(p) => !p.mobile && p.theme.max('md')`
    display: none;
  `}

  ${(p) => p.mobile && p.theme.max('md')`
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 20;
    margin: 0;
    height: ${p.theme.layout.headerHeight};
    font-size: 1.125em;
  `}
`;

const SkipOrCancel = styled(SkipOrCancelButton)`
  margin-top: 20px;
  color: ${(p) => p.theme.typography.colors.muted};
`;

// FIXME: refactor into a button-styled anchor atom
const TrackDeliveryButton = styled.a`
  background: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  border: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.primary};
  width: ${(p) => (p.fullWidth ? '100%' : 'auto')};
  font-size: ${(p) => p.theme.typography.sizes.base};
  font-weight: 500;
  padding: 0.4em 1.7em;
  cursor: pointer;
  will-change: box-shadow;
  transition: box-shadow ${(p) => p.theme.transitions.fast};

  display: block;
  width: 100%;
  text-align: center;
  margin-top: 2em;
`;

export default Cart;
