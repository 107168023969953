import { alertsVar } from 'core/api/apollo/cache';

function useAlerts() {
  const show = (message, { autoClose = false } = {}) => {
    const id = `alert-${Math.random()}`;
    alertsVar([...alertsVar(), { id, message, autoClose }]);
  };

  const hide = (alert) => {
    alertsVar(alertsVar().filter((i) => i.id !== alert.id));
  };

  const clear = () => alertsVar([]);

  return {
    show,
    hide,
    clear,
  };
}

export default useAlerts;
