import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from 'core/ui/atoms';
import Money from 'core/api/Money';
import { deliveryFeeAmount, serviceFeeAmount, totalAmount, subtotalAmount } from 'dashboard/ui/helpers/OrderHelper';

function BillingSummary(props) {
  const { order, hideSubtotal, hideTax, hideDeliveryFee, hideServiceFee, hideFootnote, ...rest } = props;

  return (
    <BillingSummaryItems {...rest}>
      {!hideSubtotal && (
        <BillingSummaryRow justifyContent='flex-start' alignItems='center'>
          <SummaryLabel md={9} sm={8}>Subtotal</SummaryLabel>
          <SummaryAmount md={3} sm={4}>{subtotalAmount(order).toFormat()}</SummaryAmount>
        </BillingSummaryRow>
      )}
      {!hideTax && (
        <BillingSummaryRow justifyContent='flex-start' alignItems='center'>
          <SummaryLabel md={9} sm={8}>Tax</SummaryLabel>
          <SummaryAmount md={3} sm={4}>{Money().toFormat()}</SummaryAmount>
        </BillingSummaryRow>
      )}
      {!hideDeliveryFee && (
        <BillingSummaryRow justifyContent='flex-start' alignItems='center'>
          <SummaryLabel md={9} sm={8}>Delivery Fee</SummaryLabel>
          <SummaryAmount md={3} sm={4}>{deliveryFeeAmount(order).toFormat()}</SummaryAmount>
        </BillingSummaryRow>
      )}
      {!hideServiceFee && (
        <BillingSummaryRow justifyContent='flex-start' alignItems='center'>
          <SummaryLabel md={9} sm={8}>Service Fee</SummaryLabel>
          <SummaryAmount md={3} sm={4}>{serviceFeeAmount(order).toFormat()}</SummaryAmount>
        </BillingSummaryRow>
      )}
      <BillingSummaryRow justifyContent='flex-start' alignItems='center'>
        <SummaryLabel md={9} sm={8}>Total</SummaryLabel>
          <SummaryAmount md={3} sm={4}>
            {totalAmount(order).toFormat()}
            {!hideFootnote && '*'}
          </SummaryAmount>
      </BillingSummaryRow>

      {!hideFootnote && (
        <BillingFootnote md={12}>
          *Any applicable discounts or credits will be applied at billing.
        </BillingFootnote>
      )}
    </BillingSummaryItems>
  );
}

BillingSummary.propTypes = {
  order: PropTypes.object,
  hideSubtotal: PropTypes.bool,
  hideTax: PropTypes.bool,
  hideDeliveryFee: PropTypes.bool,
  hideServiceFee: PropTypes.bool,
  hideFootnote: PropTypes.bool,
};

const BillingSummaryItems = styled(Box)`
`;

const BillingSummaryRow = styled(Flex)`
  margin-bottom: 1em;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SummaryLabel = styled(Box)`
  color: ${(p) => p.theme.typography.colors.muted};
  font-size: 0.75em;
  text-align: left;
`;

const SummaryAmount = styled(Box)`
  text-align: right;
  font-weight: 500;
  font-size: 0.9em;
`;

const BillingFootnote = styled(Box)`
  margin-top: 40px;
  color: ${(p) => p.theme.typography.colors.primary};
  font-size: 0.75em;
`;

export default BillingSummary;
