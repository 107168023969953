import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDebounce, useUpdateEffect } from 'react-use';

import { Flex, IconButton } from 'core/ui/atoms';

const DELAY = 500;

function PaddleControl(props) {
  const { initialValue, onChange, isSaving, disableAdd, disableRemove, ...rest } = props;
  const [ value, setValue ] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useDebounce(() => setDebouncedValue(value), DELAY, [value]);
  useUpdateEffect(() => onChange(debouncedValue), [debouncedValue]);

  const incrValue = () => {
    if (disableAdd || isSaving) {
      return;
    }

    setValue(value + 1);
  };

  const decrValue = () => {
    if (disableRemove || isSaving) {
      return;
    }

    const newValue = value - 1;
    if (newValue >= 0) {
      setValue(newValue);
    }
  };

  return (
    <Container data-saving={isSaving} {...rest}>
      <Paddle
        onClick={decrValue}
        disabled={disableRemove || value <= 0}
        data-paddle-remove
        aria-label="Decrease Quantity"
      >
        <IconButton.Icon icon='minus' />
      </Paddle>
      <Value data-paddle-quantity data-saving={isSaving}>
        <span className="sr-only">Quantity for {isSaving ? 'Family' : 'Standard'} plan&nbsp;</span>
        {value}
      </Value>
      <Paddle
        onClick={incrValue}
        disabled={disableAdd}
        data-paddle-add
        aria-label="Increase Quantity"
      >
        <IconButton.Icon icon='plus' />
      </Paddle>
    </Container>
  );
};

PaddleControl.propTypes = {
  initialValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.bool,
  isSaving: PropTypes.bool,
};

const Container = styled(Flex)`
  display: inline-flex;
  &[data-saving=true] {
    cursor: wait;
  }
`;

const Paddle = styled((props) => <IconButton btn-primary {...props} />)`
  font-size: 1.5em;
  i {
    position: relative;
    top: 0.1em;
    color: ${(p) => p.theme.typography.colors.inverse};
  }
`;

const Value = styled.span`
  display: inline-block;
  margin: 0 1em;

  &[data-saving=true] {
    color: ${(p) => p.theme.typography.colors.muted};
  }

  ${(p) => p.theme.max('sm')`
    margin-right: 0.5em;
    margin-left: 0.5em;
  `}
`;

export default PaddleControl;
