import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import escapeRegExp from 'lodash/escapeRegExp';
import sortBy from 'lodash/sortBy';

import { Input as InputAtom } from 'core/ui/atoms';

const Icon = ( { ...rest }  ) => {
  return (
    <div {...rest}>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
      <path
        d="M20 20.5L15.514 16.006M18 10C18 12.2543 17.1045 14.4163 15.5104 16.0104C13.9163 17.6045 11.7543 18.5 9.5 18.5C7.24566 18.5 5.08365 17.6045 3.48959 16.0104C1.89553 14.4163 1 12.2543 1 10C1 7.74566 1.89553 5.58365 3.48959 3.98959C5.08365 2.39553 7.24566 1.5 9.5 1.5C11.7543 1.5 13.9163 2.39553 15.5104 3.98959C17.1045 5.58365 18 7.74566 18 10V10Z"
        stroke="#36443A"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
    </div>
  );
};

class TagInput extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    variants: PropTypes.arrayOf(PropTypes.object).isRequired,
    selected: PropTypes.arrayOf(PropTypes.string),
    placeholder: PropTypes.string,
    className: PropTypes.string,
  };

  state = {
    autosuggestVisible: false,
    searchTerm: '',
  };

  onChange = (item) => {
    this.props.onChange(item);
    this.setState({ searchTerm: '' });
  };

  showAutosuggest = () => this.setState({ autosuggestVisible: true });

  hideAutosuggest = () => setTimeout(this.setState({ autosuggestVisible: false }), 0);

  setSearchTerm = (e) => this.setState({ searchTerm: e.target.value });

  render() {
    const { variants, selected, placeholder } = this.props;
    const { searchTerm, autosuggestVisible } = this.state;
    const variantsToShow = searchTerm
      ? variants
          .filter((v) => !selected.includes(v.id))
          .filter((v) => v.name.match(new RegExp(escapeRegExp(searchTerm), 'gi')))
      : [];

    return (
      <TagInputCont className={this.props.className}>
        <Input
          type="text"
          placeholder={placeholder || 'ex. Apricots'}
          value={searchTerm}
          onFocus={this.showAutosuggest}
          onBlur={this.hideAutosuggest}
          onChange={this.setSearchTerm}
          name="addTag"
          autoComplete="off"
          aria-label="Add Tag"
        />
        <TagIcon />
        <AutosuggestCont visible={autosuggestVisible && variantsToShow.length}>
          {sortBy(variantsToShow, 'name')
            .slice(0, 7)
            .map((item) => (
              <AutosuggestItem
                key={item.id}
                title={item.name}
                onClick={() => this.onChange(item)}
                data-suggested-tag-id={item.id}
              >
                {item.name}
              </AutosuggestItem>
            ))}
        </AutosuggestCont>
      </TagInputCont>
    );
  }
}

const TagInputCont = styled.div`
  position: relative;
  ${(p) => p.theme.max('md')`
    width: 100%;
  `}
`;

const TagIcon = styled((p) => <Icon icon="search" {...p} />)`
  font-size: 1em;
  position: absolute;
  top: .8em;
  right: 1em;
  color: #36443A; // FIXME: add to theme
`;

const AutosuggestCont = styled.ul`
  position: absolute;
  top: 100%;
  margin: 0;
  padding: 15px 30px;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  z-index: ${(p) => p.theme.zIndex.popover};
  width: 100%;
  background: ${(p) => p.theme.colors.grays.lighter};
  border-radius: 15px;
  transition: all 0.3s ease;
  overflow-y: scroll;
  max-height: 200px;
  ${(p) =>
    p.visible &&
    css`
      visibility: visible;
      opacity: 1;
    `};
`;

const AutosuggestItem = styled.li`
  padding: 5px 7px;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.typography.colors.primary};
    font-weight: 500;
    text-decoration: underline;
  }
`;

const Input = styled(InputAtom)`
  width: 100%;
  margin-bottom: 3vh;
  padding-right: 3em;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #36443A; ;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: color: rgba(54, 68, 58, 0.75);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: color: rgba(54, 68, 58, 0.75);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: color: rgba(54, 68, 58, 0.75);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: color: rgba(54, 68, 58, 0.75);
  }
`;

export default TagInput;
