import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import useUser from 'core/hooks/useUser';

import { GET_SUBSCRIPTION_OPTIONS } from 'core/api/apollo/queries';
import { H3, H4, H5, RouterLink, Text } from 'core/ui/atoms';
import { Loading } from 'core/ui/components';

import { ROUTE_MAP } from 'onramp/api/routeSequence';
import { numDaysLabel, productSizeLabel, selectedFoodTypesList } from 'onramp/helpers';

function SubscriptionSummary(props) {
  const { returnTo } = props;
  const { currentUser: { primaryAddress: { deliveryOption: { name, deliveryMethod } } } } = useUser();
  const { loading, data: { getSubscriptionOptions } = {} } = useQuery(GET_SUBSCRIPTION_OPTIONS);

  if (loading || !getSubscriptionOptions) {
    return <Loading />;
  }

  const { productSize, numDays, subscribedDays } = getSubscriptionOptions;
  const foodForLabel = numDaysLabel(numDays, deliveryMethod);
  const deliveryDays = subscribedDays.map((day) => `${moment().day(day).format('dddd')}s`);

  const { breakfast, lunch, dinner, snacks } = getSubscriptionOptions;
  const mealTypes = selectedFoodTypesList({ breakfast, lunch, dinner, snacks }).join(', ');

  return (
    <Wrapper data-subscription-summary>
      <H4 text-center>My Plan</H4>

      <Section>
        <H3 no-margin data-subscription-meal-types>{mealTypes}</H3>
        <Text no-margin data-subscription-quantity>
          {foodForLabel.label} | {foodForLabel.range} Weekly
        </Text>
        <Text no-margin data-subscription-size>
          {productSizeLabel(productSize).label}
        </Text>
      </Section>

      <div>
        <H5 no-margin>Weekly Deliveries</H5>
        <Text no-margin data-subscription-schedule>
          {name} on {deliveryDays.join(' and ')}
        </Text>
      </div>

      <EditLink to={`${ROUTE_MAP.initOrder.path}?next=${returnTo}`}>Edit plan</EditLink>
    </Wrapper>
  );
}

SubscriptionSummary.propTypes = {
  returnTo: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 3vh;
`;

const Section = styled.div`
  margin-bottom: 2em;
`;

const EditLink = styled(RouterLink)`
  padding: 0;
  font-weight: normal;
  color: ${(p) => p.theme.typography.colors.muted};
  text-align: right;
  font-size: ${(p) => p.theme.typography.sizes.small};
  text-decoration: underline;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export default SubscriptionSummary;
