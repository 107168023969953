import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button as ButtonAtom,
  H2,
  LogoMark,
} from 'core/ui/atoms';
import ModalComponent from 'core/ui/components/Modal';

class AlertModal extends PureComponent {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    onClose: PropTypes.func,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  render() {
    const { heading, buttonText, onClick, onClose } = this.props;

    return (
      <Modal onClose={onClose}>
        <Logo />
        <H2>{heading}</H2>
        {this.props.children}
        <Button btn-primary fullWidth onClick={onClick || onClose}>{buttonText || 'OK'}</Button>
      </Modal>
    );
  }
}

const Modal = styled(ModalComponent)`
  padding: 30px 40px;
  width: 350px;
  > p {
    font-size: 20px;
  }
`;

const Logo = styled(LogoMark)`
  font-size: 2em;
`;

const Button = styled(ButtonAtom)`
  padding: 12px;
  margin-top: 40px;
  font-size: 20px;
`;

export default AlertModal;
