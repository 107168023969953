import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconAtom from './Icon';
import { formInputMixin } from 'core/ui/mixins';

class Select extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    readOnly: PropTypes.bool,
    prompt: PropTypes.string,
  };

  state = {
    selected: undefined,
  };

  componentDidMount() {
    const { selected } = this.props;
    this.setState({ selected });
  }

  componentDidUpdate() {
    if (this.props.selected !== this.state.selected) {
      this.setState({ selected: this.props.selected });
    }
  }

  onChange = (e) => {
    this.setState({ selected: e.target.value });
    this.props.onChange && this.props.onChange(e);
  };

  render() {
    const { name, options, readOnly, onChange, prompt, ...rest } = this.props;
    const { selected } = this.state;
    const selectedOption = options.find((o) => o.value === selected) || {};

    return (
      <SelectCont {...rest}>
        <Selected className='select-selected' readOnly={readOnly}>
          {selectedOption.title || prompt}
          <Icon icon='caret_down' className='select-icon' />
        </Selected>
        <Input name={name} onChange={this.onChange} value={selected} disabled={readOnly}>
          {prompt && <option key={`${name}-${prompt}`} value="">{prompt}</option>}
          {options.map(({ value, title, disabled=false }) => (
            <option key={`${name}-${value}`} value={value} disabled={disabled}>
              {title}
            </option>
          ))}
        </Input>
      </SelectCont>
    );
  }
}

const SelectCont = styled.div`
  position: relative;
`;

const Selected = styled.p`
  &.select-selected {
    ${formInputMixin}

    padding-right: 3em;
  }
`;

const Icon = styled(IconAtom)`
  &.select-icon {
    position: absolute;
    font-size: 0.625em;
    top: 50%;
    right: 1em;
    transform: translateY(-25%);
  }
`;

const Input = styled.select`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
`;

export default Select;
