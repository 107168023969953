import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button as ButtonAtom, Icon } from 'core/ui/atoms';

class CloseButton extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  }

  render() {
    const { onClose, ...rest } = this.props;

    return (
      <Button unstyled onClick={onClose} aria-label="Close" {...rest} >
        <Icon icon='close'/>
      </Button>
    );
  }
}

const Button = styled(ButtonAtom)`
  position:absolute;
  top: 15px;
  right: 20px;
  font-size: 20px;
  color: ${(p) => p.theme.typography.colors.primary};
  z-index: 1;
`;

export default CloseButton;
