import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ModalComponent from '../Modal';
import { H2, Image } from 'core/ui/atoms';
import DownArrow from 'core/assets/images/V2/DownArrow.svg';


class SplitPanelModal extends PureComponent {
  static propTypes = {
    image: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    imageTitle: PropTypes.string,
  }

  render() {
    const { onClose, children, image, imageTitle } = this.props;

    return (
      <Modal onClose={onClose}>
        <ModalContentContainer>
          <ModalImage image={image} />
          {imageTitle && 
            <CustomHeader>
              {imageTitle}
              <CustomImage src={DownArrow} />
            </CustomHeader>}
          <ModalContent>
            {children}
          </ModalContent>
        </ModalContentContainer>
      </Modal>
    );
  }
}

const Modal = styled(ModalComponent)`
  width: 90%;
  ${(p) => p.theme.max('sm')`
    width: 100%;
  `}
`;

const ModalContentContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
`;

const ModalContent = styled.div`
  margin-left: 55%;
  height: 80vh;
  ${(p) => p.theme.max('sm')`
    margin-left: 0;
    margin-top: 75vh;
    height: 25vh;
  `}
`;

const ModalImage = styled.section`
  position: absolute;
  height: 100%;
  width: 55%;
  top: 0;
  left: 0;
  background-image: url(${(p) => p.image});
  background-size: cover;
  background-position: center center;
  ${(p) => p.theme.max('sm')`
    height: 75vh;
    width: 100%;
  `}
`;

const CustomHeader = styled(H2)`
  display: none;
  ${(p) => p.theme.max('sm')`
    font-size: 36px;
    display: block;
    position: absolute;
    bottom: 25vh;
    text-align: center;
    width:100%;
    color: ${(p) => p.theme.typography.colors.inverse};
    padding: 0 5vw;
    margin-bottom: 1.5rem;
  `}
`;

const CustomImage = styled(Image)`
  margin: 0 auto;
`;

export default SplitPanelModal;
