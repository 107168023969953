// NOTE: inspired by https://czaplinski.io/blog/super-easy-animation-with-react-hooks/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

// TODO: replace with react-spring or similar library
const Drop = ({ show, duration = 0.25, children, ...rest }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) {
      setRender(true);
    }
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) {
      setRender(false);
    }
  };

  return (
    shouldRender && (
      <Dropped
        animation={show ? dropIn : dropOut}
        duration={duration}
        onAnimationEnd={onAnimationEnd}
        {...rest}
      >
        {children}
      </Dropped>
    )
  );
};

Drop.propTypes = {
  show: PropTypes.bool.isRequired,
  duration: PropTypes.number,
  children: PropTypes.node,
};

const dropIn = keyframes`
  0% {
    transform: translate(0, -50%);
  }
  100% {
    transform: translate(0, 0%);
  }
`;

const dropOut = keyframes`
  0% {
    transform: translate(0, 0%);
  }
  100% {
    transform: translate(0, -50%);
  }
`;

const Dropped = styled.div`
  animation: ${(p) => css`${p.animation} ${p.duration}s ease-in-out`};
`;

export default Drop;
