import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _isEmpty from 'lodash/isEmpty';

import useUser from 'core/hooks/useUser';
import useFoodsToAvoid from 'dashboard/hooks/useFoodsToAvoid';

import { H2, H6, RouterLink, SanitizedHTML, Text } from 'core/ui/atoms';
import { signUpPath } from 'core/api/actions/navigation';
import VariantOptions from 'dashboard/ui/components/VariantOptions';
import DietTagList from 'dashboard/ui/components/ProductPage/DietTagList';
import FoodsToAvoidWarningComponent from 'dashboard/ui/components/ProductPage/FoodsToAvoidWarning';

import useInventoryStatus from 'dashboard/hooks/useInventoryStatus';

function ProductSummary(props) {
  const { status, product, addDisabled } = props;
  const { name, subtitle, tagLine, description, dietTags, line: { subcategory: { category } } } = product;
  const { warnings: ftaWarnings } = useFoodsToAvoid(product);
  const { soldOut } = useInventoryStatus(status.deliveryDay.id, product.id);
  const disableAdd = addDisabled || soldOut;
  const disableRemove = addDisabled;

  return (
    <Fragment>
      <H6 text-spaced text-color='secondary' text-size='smallish' text-transform='uppercase'> {category.name}</H6>
      <ItemHeading>{name}</ItemHeading>
      <ProductStatus status={status} soldOut={soldOut} />
      <VariantOptionsSection>
        <VariantOptions
          deliveryDay={status.deliveryDay}
          product={product}
          lineItems={(status.order && status.order.lineItems) || []}
          disableAdd={disableAdd}
          disableRemove={disableRemove}
        />
        <CTAButton />
      </VariantOptionsSection>
      <Section hide={_isEmpty(subtitle)}>
        <Subtitle>{subtitle}</Subtitle>
      </Section>
      <Section hide={_isEmpty(tagLine) && _isEmpty(dietTags)}>
        <TagLine>{tagLine}</TagLine>
        <DietTags dietTags={dietTags} />
      </Section>
      <Section hide={_isEmpty(description)}>
        <Text no-margin><SanitizedHTML rawHTML={description} /></Text>
      </Section>
      <Section hide={_isEmpty(ftaWarnings)}>
        <FoodsToAvoidWarning product={product} />
      </Section>
    </Fragment>
  );
}

ProductSummary.propTypes = {
  status: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  addDisabled: PropTypes.bool,
};

function ProductStatus(props) {
  const { status, soldOut } = props;
  const { locked, available } = status;

  let notice = '';
  if (locked) {
    notice = 'The deadline to edit this order has passed.';
  } else if (soldOut) {
    notice = 'This item is sold out.';
  } else if (!available) {
    notice = 'This item is not available.';
  }

  if (notice.length === 0) {
    return null;
  }

  return (
    <UnavailableNotice text-color='muted'>
      {notice}
    </UnavailableNotice>
  );
}

ProductStatus.propTypes = {
  status: PropTypes.object.isRequired,
  soldOut: PropTypes.bool.isRequired,
};

function CTAButton() {
  const { userCan } = useUser();

  if (!userCan.placeOrder()) {
    return (<GetStartedButton btn-primary btn-lg to={signUpPath()}>Get Started</GetStartedButton>);
  }

  return null;
}

function Section(props) {
  const { hide, children, ...rest } = props;

  if (hide) { return null; }

  return (
    <StyledSection {...rest}>
      {children}
    </StyledSection>
  );
}

Section.propTypes = {
  hide: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const StyledSection = styled.section`
  width: 100%;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.borders.color};

  &:last-child {
    border-bottom: none;
  }
`;

const VariantOptionsSection = styled(Section)`
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 30px;
`;

const ItemHeading = styled(H2)`
  margin-bottom: 0;
  line-height: 1em;
  font-size: 3em;
  margin: 0 0 0.75em 0;
`;

const Subtitle = styled(Text)`
  color: ${(p) => p.theme.typography.colors.muted};
  font-size: ${(p) => p.theme.typography.sizes.small};
  margin: 0;
`;

const TagLine = styled(Text)`
  font-weight: bold;
  font-size: ${(p) => p.theme.typography.sizes.small};
  margin-top: 0;
`;

const DietTags = styled(DietTagList)`
  font-size: ${(p) => p.theme.typography.sizes.smaller};
`;

const FoodsToAvoidWarning = styled(FoodsToAvoidWarningComponent)`
  font-size: ${(p) => p.theme.typography.sizes.smaller};
`;

const UnavailableNotice = styled(Text)`
  margin-top: 0;
`;

const GetStartedButton = styled(RouterLink)`
  display: inline-block;
  margin-top: 40px;
  margin-bottom: 10px;
`;

export default ProductSummary;
