import React, { PureComponent } from 'react';

import { Text } from 'core/ui/atoms';

class LoadingError extends PureComponent {
  render() {
    return (
      <div>
        <Text>There was a problem loading the Stripe payment form.</Text>
        <Text>Please turn off ad blockers and check your browser's security settings.</Text>
      </div>
    );
  }
}

export default LoadingError;
