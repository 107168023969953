// Here we are using table cell behavior, to x/y center modal if content is less then viewport,
// and make modal scrollable inside the parent if content exceeds the viewport height

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { Backdrop } from 'core/ui/atoms';
import BodyScrollManager from 'core/ui/components/BodyScrollManager';
import CloseButton from 'core/ui/components/CloseButton';

class Modal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.node,
    inverse: PropTypes.bool,
    className: PropTypes.string,
  };

  render() {
    const { inverse, onClose } = this.props;

    return ReactDOM.createPortal(
      <ModalWrap>
        <ModalTable>
          <ModalCell>
            <BodyScrollManager disabled={true} />
            <Backdrop $inverse={inverse} onClick={onClose} />
            <CSSTransition
              appear
              in
              classNames="modal"
              timeout={600}
            >
              <ModalContainer className={this.props.className}>
                {onClose && <Close className='modal-close' onClose={onClose} />}
                {this.props.children}
              </ModalContainer>
            </CSSTransition>
          </ModalCell>
        </ModalTable>
      </ModalWrap>,
      document.getElementById('modal-root'),
    );
  }
}

const ModalWrap = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: ${(p) => p.theme.zIndex.modal};
`;

const ModalTable = styled.div`
  display: table;
  width: 100vw;
  height: 100%;
  vertical-align: middle;
`;

const ModalCell = styled.div`
  position: relative;
  display: table-cell;
  vertical-align: middle;
  ${(p) => p.theme.min('md')`
    padding: 40px;
  `}
`;

const ModalContainer = styled.div`
  position: relative;
  display:flex;
  flex-direction: column;
  width: 450px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  margin-left: auto;
  margin-right: auto;
  z-index: 2;

  &.modal-appear {
    transform: translate(0, -50%);
  }
  &.modal-appear-active {
    transform: translate(0, 0);
    transition: all 300ms ease-out;
  }
  &.modal-exit {
    transform: translate(0, 0);
  }
  &.modal-exit-active {
    transform: translate(0, -50%);
    transition: all 300ms ease-in;
  }

  ${(p) => p.theme.max('sm')`
    width: 100%;
    min-height: 100vh;
  `}
`;

const Close = styled((p) => <CloseButton {...p} />)`
  position:absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  font-size: 20px;
  z-index: 3;
`;

export default Modal;
