import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Icon } from 'core/ui/atoms';

const NavItem = ({ to, href, children, subMenus, ...props }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const navItemRef = useRef(null);

  const handleToggleSubMenu = (e) => {
    e.stopPropagation();
    setShowSubMenu((prevShowSubMenu) => !prevShowSubMenu);
  };

  const handleClickOutside = (e) => {
    if (navItemRef.current && !navItemRef.current.contains(e.target)) {
      setShowSubMenu(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => handleClickOutside(event);
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  let Comp;
  if (to) {
    Comp = Link;
  } else if (href) {
    Comp = Anchor;
  } else {
    Comp = Span;
  }

  return (
    <NavItemWrapper ref={navItemRef}>
      <Comp {...(to ? { to } : href ? { href } : { onClick: handleToggleSubMenu })} {...props}>
        {children}
        {subMenus && <IconAtom icon='caret_down' className='select-icon' />}
      </Comp>
      {subMenus && showSubMenu && (
        <DropdownMenu>
          {subMenus.map((subMenu, index) => (
            <DropdownItem key={index} href={subMenu.href}>
              {subMenu.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </NavItemWrapper>
  );
};

NavItem.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
  subMenus: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ),
};

const NavItemWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Link = styled(NavLink)`
  text-decoration: none;
  padding: 0.25em 0.5em;
  color: ${(p) => p.theme.typography.colors.primary};
  display: flex;
  align-items: center;
  font-weight: normal;
  &.active {
    border-bottom: 2px solid ${(p) => p.theme.colors.primary};
  }
  ${(p) => p.theme.min('md')`
    font-size: ${(p) => p.theme.typography.sizes.base};
  `};
`;

const Anchor = styled.a`
  text-decoration: none;
  padding: 0.25em 0.5em;
  color: ${(p) => p.theme.typography.colors.primary};
  font-weight: normal;
  display: flex;
  align-items: center;
  &.active {
    border-bottom: 2px solid ${(p) => p.theme.colors.primary};
  }

  ${(p) => p.theme.min('md')`
    font-size: ${(p) => p.theme.typography.sizes.base};
  `};
`;

const Span = styled.span`
  text-decoration: none;
  padding: 0.25em 0.5em;
  color: ${(p) => p.theme.typography.colors.primary};
  display: flex;
  align-items: center;
  cursor: pointer;

  ${(p) => p.theme.min('md')`
    font-size: ${(p) => p.theme.typography.sizes.base};
  `};
`;

const IconAtom = styled(Icon)`
  &.select-icon {
    position: absolute;
    font-size: 0.625em;
    top: 45%;
    right: -0.7em;
    transform: translateY(-25%);
  }
`;

const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const DropdownItem = styled.a`
  padding: 0.5em 1em;
  text-decoration: none;
  color: ${(p) => p.theme.typography.colors.primary};
  white-space: nowrap;
  &:hover {
    background-color: ${(p) => p.theme.colors.secondary};
  }
`;

export default NavItem;
