import { css } from 'styled-components';

import BLACK from 'core/assets/fonts/Albra/Albra-Black.otf';
import BOLD from 'core/assets/fonts/Albra/Albra-Bold.otf';
import SEMI from 'core/assets/fonts/Albra/Albra-Semi.otf';
import MEDIUM from 'core/assets/fonts/Albra/Albra-Medium.otf';
import REGULAR from 'core/assets/fonts/Albra/Albra-Regular.otf';
import LIGHT from 'core/assets/fonts/Albra/Albra-Light.otf';


export default css`
  @font-face {
    font-family: 'Albra';
    src: url(${BLACK});
    font-weight: 800 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Albra';
    src: url(${BOLD});
    font-weight: bold 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Albra';
    src: url(${SEMI});
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Albra';
    src: url(${MEDIUM});
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Albra';
    src: url(${REGULAR});
    font-weight: normal 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Albra';
    src: url(${LIGHT});
    font-weight: 300 200 100;
    font-style: normal;
    font-display: swap;
  }
`;
