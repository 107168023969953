import React from 'react';

import Message from './Message';

function NotPublishedMessage(props) {
  return (
    <Message title="This menu hasn't been published yet." {...props} />
  );
}

export default NotPublishedMessage;
