import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

class NavItem extends PureComponent {
  static propTypes = {
    to: PropTypes.string,
    children: PropTypes.node,
  };

  render() {
    const { to } = this.props;
    const Comp = to ? Link : Anchor;

    return <Comp {...this.props}>{this.props.children}</Comp>;
  }
}

const Link = styled(NavLink)`
  text-decoration: none;
  padding: 0.25em 0.5em;
  color: ${(p) => p.theme.typography.colors.primary};
  &.active {
    border-bottom: 2px solid ${(p) => p.theme.colors.primary};
  }
`;

const Anchor = styled.a`
  text-decoration: none;
  padding: 0.25em 0.5em;
  color: ${(p) => p.theme.typography.colors.primary};
  &.active {
    border-bottom: 2px solid ${(p) => p.theme.colors.primary};
  }
`;

export default NavItem;
