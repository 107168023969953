import { debugTrackingEnabled, shareASaleMerchantId } from 'reactAppConstants';
import { conversionPixelsVar } from 'core/api/apollo/cache';

const logEvent = (data = {}) => {
  try {
    if (debugTrackingEnabled()) {
      console.debug(Tracker.name, Tracker.enabled ? 'enabled' : 'disabled', data);
    }
  } catch(e) {
    console.error(Tracker.name, 'Error while logging', e, data);
  }
};

// NOTE: This vendor uses an actual <img> element to track conversions

const pixelUrl = (orderId, orderSubtotal) => {
  return `https://www.shareasale.com/sale.cfm?tracking=${orderId}&amount=${orderSubtotal.toRoundedUnit(2)}&merchantID=${shareASaleMerchantId}&transtype=sale`;
};

const firePixel = (url) => {
  const pixelData = { vendor: 'shareasale', url: url };
  logEvent('Firing conversion pixel', pixelData);
  Tracker.enabled && conversionPixelsVar([...conversionPixelsVar(), pixelData]);
};

const Tracker = {
  name: 'ShareASale Pixel',
  enabled: !!shareASaleMerchantId,
  events: {
    pageView: () => {},
    lead: () => {},
    registration: () => {},
    purchase: ({ transactionId, purchaseAmount }) => {
      const url = pixelUrl(transactionId, purchaseAmount);
      firePixel(url);
    },
    addToCart: () => {},
    applyPromoCode: () => {},
    checkoutProgress: () => {},
    error: () => {},
  },
};

export default Tracker;
