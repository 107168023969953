import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as LogoMarkSVG } from 'core/assets/images/LogoMark.svg';
import { ReactComponent as WordMarkSVG } from 'core/assets/images/WordMark.svg';

export const LogoMark = styled(({ inverse, ...rest }) => <LogoMarkSVG {...rest} />)`
  path {
    fill: ${(p) => p.inverse ? p.theme.colors.white : p.theme.colors.primary};
  }
`;

export const WordMark = styled(({ inverse, ...rest }) => <WordMarkSVG {...rest} />)`
  path {
    fill: ${(p) => p.inverse ? p.theme.colors.white : p.theme.colors.primary};
  }
`;

function Logo(props) {
  const { inverse, ...rest } = props;

  return (
    <Wrapper {...rest} >
      <WrappedLogoMark inverse={inverse} />
      <WordMark inverse={inverse} />
    </Wrapper>
  );
}

Logo.propTypes = {
  inverse: PropTypes.bool,
};

const Wrapper = styled.span`
  display: flex;
  align-items: flex-start;
`;

const WrappedLogoMark = styled(LogoMark)`
  font-size: 0.8em;
  margin-right: 0.25em;
`;

export default Logo;
