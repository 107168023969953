/* eslint-disable react/prop-types */
import React, { useState, Fragment, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import ContainerPage from './ContainerPage';
import useErrorHandlers from 'core/hooks/useErrorHandlers';
import { accessChecksDisabledVar } from 'core/api/apollo/cache';
import { COMPLETE_PREPAID_PURCHASE, ACTIVATE_SUBSCRIPTION, SIGN_OUT } from 'core/api/apollo/mutations';
import ProcessingPurchaseOverlay from 'onramp/ui/components/ReviewPage/ProcessingPurchaseOverlay';
import client from 'core/api/apollo/apolloClient';
import { goToCart } from 'core/api/actions/navigation';
import { onrampNext } from 'onramp/api/navigation';
import { getQueryParams } from 'core/api/utils';
import useUser from 'core/hooks/useUser';



// @graphql(ACTIVATE_SUBSCRIPTION)
const NewBillingInfoPage = ({ currentUser, cache }) => {
  const [backgroundJobId, setBackgroundJobId] = useState(null);
  const { displayApolloResponseErrors } = useErrorHandlers();
  const { isSubscribed } = getQueryParams();
  const { refetchUser } = useUser();

  const [activeSubscription] = useMutation(ACTIVATE_SUBSCRIPTION);
  const [completePrepaidPurchase] = useMutation(COMPLETE_PREPAID_PURCHASE);
  const [isProcessing, setIsProcessing] = useState(false);
  const [purchase, setPurchase] = useState(null);
  const [userValidateEmail, setUserValidateEmail] = useState(null);

  const [logout] = useMutation(SIGN_OUT, {
    variables: { input: {} },
    // onCompleted: onComplete,
    // onError: onComplete,
  });

  useEffect(() => {

    return () => {
      if(currentUser && currentUser.email && !currentUser.email.includes('@guest.com')){
        // logout();
      }
    };
  }, [currentUser, logout]);


  const onSuccess = () => {
    const isShippingFlow =!!currentUser.nextOneTimeProgram;
    accessChecksDisabledVar(true);
    client.stop();
    client.resetStore().then(() => {
      if(!isShippingFlow){
        goToCart(purchase.startDay.id, { welcome: true });
      } else {
        onrampNext(currentUser, cache);
      }
      accessChecksDisabledVar(false);
    });
  };

  const onComplete = () => {
    setIsProcessing(true);
    const { nextOneTimeProgram } = currentUser;
    const isShippingFlow =!!nextOneTimeProgram;

    if (isShippingFlow) {
      return completePrepaidPurchase({ variables: { input: {} } }).then(({ data }) => {
        const {
          completePrepaidPurchase: { backgroundJob, purchase },
        } = data;
        setBackgroundJobId(backgroundJob.id);
        setPurchase(purchase);
      });
    } else {

      return activeSubscription({ variables: { input: {} } }).then(({ data }) => {
        const {
          activateSubscription: { backgroundJob, purchase },
        } = data;
        setBackgroundJobId(backgroundJob.id);
        setPurchase(purchase);
      });
    }
  };

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    primaryAddress,
    notificationSettings: { receiveMenuReminderSms: receiveReminders = false } = {},
  } = currentUser || {};

  const initialData = { ...primaryAddress, phoneNumber, receiveReminders, email, firstName, lastName };

  const onError = (errors) => {
    setIsProcessing(false);
    setBackgroundJobId(null);
    displayApolloResponseErrors(errors);
  };

  return (
    <Fragment>
      <ContainerPage
        currentUser={currentUser}
        cache={cache}
        initialData={initialData}
        onComplete={onComplete}
        setUserValidateEmail={setUserValidateEmail}
        userValidateEmail={userValidateEmail}
        setIsProcessing={setIsProcessing}
        isSubscribed={isSubscribed}
        refetchUser={refetchUser}
      />
      {(isProcessing || backgroundJobId) &&
        (<ProcessingPurchaseOverlay
          open={isProcessing || backgroundJobId}
          backgroundJobId={backgroundJobId}
          onSuccess={onSuccess}
          onError={onError}
        />)
      }
    </Fragment>
  );
};

export default NewBillingInfoPage;
