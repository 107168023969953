import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import {
  H4,
  List,
  ListItem as ListItemComponent,
} from 'core/ui/atoms';
import LineItem from 'dashboard/ui/components/Cart/LineItem';

function LineItemList(props) {
  const { lineItems, order, editable, ...rest } = props;

  const categories = uniqBy(lineItems.map((lineItem) => lineItem.product.line.subcategory.category), 'id');
  const lineItemsByCategory = groupBy(lineItems, 'product.line.subcategory.category.id');

  return (
    <List unstyled {...rest}>
      {
        categories.map((category) => (
          <LineItemCategory
            key={`cart-category-${category.id}`}
            category={category}
            lineItems={lineItemsByCategory[category.id]}
            order={order}
            editable={editable}
          />
        ))
      }
    </List>
  );
}

LineItemList.propTypes = {
  order: PropTypes.object.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  editable: PropTypes.bool.isRequired,
};

function LineItemCategory(props) {
  const { category, lineItems, order, editable } = props;

  return (
    <ListItem>
      <CategoryHeading>{category.name}</CategoryHeading>
      <List unstyled>
        {
          sortBy(lineItems, ['product.line.subcategory.position', 'product.line.position']).map((lineItem) => (
            <CartListItem key={`cart-item-${lineItem.id}`}>
              <LineItem
                lineItem={lineItem}
                order={order}
                editable={editable}
              />
            </CartListItem>
          ))
        }
      </List>
    </ListItem>
  );
}

LineItemCategory.propTypes = {
  category: PropTypes.object.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  order: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
};

const ListItem = styled(ListItemComponent)`
  margin-top: 1.5em;

  &:first-child {
    margin-top: 0;
  }

  ${(p) => p.theme.min('md')`
    &:first-child {
      margin-top: 0.625em;
    }

    &:last-child {
      margin-bottom: 1.5em;
    }
  `};
`;

const CategoryHeading = styled(H4)`
  padding: 0 20px;
  font-size: 1.25em;
  margin-top: 0;
`;

const CartListItem = styled(ListItem)`
  padding: 20px;
  border-bottom: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.grays.lighter};

  ${(p) => p.theme.max('md')`
    margin-top: 0;

    &:nth-child(odd) {
      background-color: ${(p) => p.theme.colors.grays.lighter};
    }
  `}
`;

export default LineItemList;
