import styled from 'styled-components';
import theme from 'core/ui/theme';

const Header = styled.h2`
  font-family: ${theme.typography.familyHeader};
  font-weight: normal;
  font-size: 38px;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 10px;
  ${(p) => p.theme.max('md')`
    font-size: 30px;
    margin-bottom: calc(${p.theme.layout.mobilePadding} / 2);
  `}
`;

export default Header;
