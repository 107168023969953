import React from 'react';
import styled from 'styled-components';

import useUser from 'core/hooks/useUser';

import InviteFriends from 'dashboard/ui/components/InviteFriends';
import Money from 'core/api/Money';

function ReferralAd(props) {
  const { currentUser: { referralCoupon } } = useUser();

  if (!referralCoupon) {
    return null;
  }

  return (
    <Wrapper {...props}>
      <InviteFriends default label={'Refer friends'} />
      and get a {Money(referralCoupon.creditAmount).toFormat('$0,0')} credit
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: #9CA3BC;
  padding: 10px 0;

  button {
    color: #9CA3BC;
    font-size: 1.1em;
    font-weight: bold;
    text-decoration: underline;
    padding: 0 0.4em 0 0;
  }
`;

export default ReferralAd;
