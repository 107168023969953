import React from 'react';
import styled from 'styled-components';
import { ValidatorForm } from 'react-form-validator-core';

const ContentForm = styled((props) => <ValidatorForm noValidate instantValidate={false} {...props} />)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default ContentForm;
