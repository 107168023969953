import styled from 'styled-components';

import { FormGroup as FormGroupAtom } from 'core/ui/atoms';

const RadioFormGroup = styled(FormGroupAtom)`
  label {
    padding: 1em 0;
    border-bottom: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.grays.light};

    transition: border-color 0.25s ease-in-out;

    &:hover, &:active {
      border-bottom-color: ${(p) => p.theme.borders.color};
    }
  }
`;

export default RadioFormGroup;
