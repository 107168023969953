import get from 'lodash/get';
import { combineMixins, deriveValueFromProp } from './utils';

export const textSizeMixin = (props) => {
  const { 'text-size': textSize  } = props;
  if (!textSize) {
    return null;
  }

  return `font-size: ${props.theme.typography.sizes[textSize]};`;
};

export const textColorMixin = (props) => {
  const { 'text-color': textColor } = props;
  const color = get(props.theme.typography.colors, textColor) || get(props.theme.colors, textColor, textColor);
  if (!color) { return null; }

  return `color: ${color};`;
};

export const textAccentMixin = (props) => {
  const { 'text-accent': textAccent } = props;
  if (!textAccent) { return null; }

  return `
    font-family: ${props.theme.typography.familyAccent};
    font-style: italic;
    font-weight: 400;
  `;
};

export const textSpacedMixin = (props) => {
  const { 'text-spaced': textSpaced } = props;
  if (!textSpaced) { return null; }

  const spacing = deriveValueFromProp(textSpaced, 'accent');
  return `letter-spacing: ${get(props.theme.typography.letterSpacing, spacing, 'normal')};`;
};

export const textTransformMixin = (props) => {
  const { 'text-transform': textTransform } = props;
  if (!textTransform) { return null; }

  return `text-transform: ${textTransform};`;
};

export const textWeightMixin = (props) => {
  const { 'text-weight': textWeight, 'text-bold': textBold } = props;
  const weight = textBold ? 'bold' : textWeight;
  if (!weight) { return null; }

  return `font-weight: ${weight};`;
};

export const textStyleMixin = (props) => {
  const { 'text-style': textStyle, 'text-italic': textItalic } = props;
  const style = textItalic ? 'italic' : textStyle;
  if (!style) { return null; }

  return `font-style: ${style};`;
};

export const textAlignMixin = (props) => {
  const {
    'text-align': textAlign,
    'text-left': textLeft = false,
    'text-center': textCenter = false,
    'text-right': textRight = false,
  } = props;

  let alignment = null;
  if (textAlign) {
    alignment = textAlign;
  } else if (textLeft) {
    alignment = 'left';
  } else if (textCenter) {
    alignment = 'center';
  } else if (textRight) {
    alignment = 'right';
  } else {
    return null;
  }

  return `text-align: ${alignment};`;
};

export const textMixin = combineMixins([
  textSizeMixin,
  textColorMixin,
  textAccentMixin,
  textSpacedMixin,
  textTransformMixin,
  textWeightMixin,
  textStyleMixin,
  textAlignMixin,
]);
