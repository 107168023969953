import moment from 'moment';
import zipcodeData from './zipcodes.json';
import { NUM_DAY_OPTIONS_V2, NUM_DAY_OFFSETS, FOOD_TYPES } from 'onramp/constants';
// import { ReactComponent as BurstSVG } from 'onramp/assets/uszips.csv';

export const productSizeLabel = (size) => {
  // eslint-disable-next-line no-console
  if (size.serves !== 1) {
    return { label: size.name, subLabel: size.description };
  }

  return { label: size.name, subLabel: size.description };
};

export const numDaysLabel = (numDays, deliveryMethod) => {
  const option = NUM_DAY_OPTIONS_V2.find((o) => o.value === numDays);
  const deliveryOffset = NUM_DAY_OFFSETS[deliveryMethod] || 0;
  const min = moment().day(option.min).add(deliveryOffset, 'days').format('ddd');
  const max = moment().day(option.max).add(deliveryOffset, 'days').format('ddd');

  return {
    label: `${option.value} day${option.value === 1 ? '' : 's'}`,
    range: `${min} - ${max}`,
  };
};

export const selectedFoodTypesList = (selections) =>
  FOOD_TYPES.map((type) => selections[type.value] && type.name).filter((x) => x);

// TODO: remove once sure it will be not required
// const CONSTANT_CALORIES_CHART = {
//   '5 Days': {
//     Standard: 1200,
//     Large: 1600,
//   },
//   '4 Days': {
//     Standard: 1600,
//     Large: 2000,
//   },
//   '3 Days': {
//     Standard: 1600,
//     Large: 2000,
//   },
//   '2 Days': {
//     Standard: 1600,
//     Large: 2000,
//   },
//   '1 Day': {
//     Standard: 1600,
//     Large: 2000,
//   },
// };

export const displaySizeLabel = (size) => {
  // const result =
  //   days &&
  //   days.name &&
  //   CONSTANT_CALORIES_CHART[days.name] &&
  //   CONSTANT_CALORIES_CHART[days.name][size.name];
  return `${size.name} (~${size.calories} calories / meal)`;
};

export const displayScheduleLabel = (schedule) => {
  const daysOfWeek = schedule.daysOfWeek;
  const ds = [...daysOfWeek].sort((a, b) => a.index - b.index);

  const startDay = ds[0].name.slice(0, 3); // Get the first 3 letters of the start day
  const endDay = ds[ds.length - 1].name.slice(0, 3);
  // TODO: fix in BE value hardcoded on demand
    if (startDay === endDay){
    return `${schedule.name} | ${startDay}`;
  }
  // if (schedule.name === '4 Days'){
    // return `${schedule.name} | Tue - Fri`;
  // }
  return `${schedule.name} | ${startDay} - ${endDay}`;
};


export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

export const stringWithoutTags = (description) => {
  if (description) {
    return description.replace(/<\/?p>/g, '');
  }
};

export const isGuestEmail = (email) => {
  return email.includes('@guest.com');
};

export const getCityFromZipcode = (zipcode) => {
  const entry = zipcodeData[zipcode];
  return entry ? entry.city : '';
};
