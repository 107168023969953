import React from 'react';
import PropTypes from 'prop-types';

import useUser from 'core/hooks/useUser';

import AuthenticatedLayout from 'core/ui/layouts/AuthenticatedLayout';
import VisitorLayout from 'core/ui/layouts/VisitorLayout';

function DefaultLayout(props) {
  const { children } = props;
  const { currentUser } = useUser();

  const Layout = currentUser ? AuthenticatedLayout : VisitorLayout;

  return <Layout>{children}</Layout>;
}

DefaultLayout.propTypes = {
  children: PropTypes.node,
};

export default DefaultLayout;
