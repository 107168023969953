import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';

import { theme } from './theme';

import fonts from './fonts';

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}
  ${fonts}

  * {
    box-sizing: border-box;
    outline: none !important;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  body {
    font-family: ${theme.typography.familyText};
    font-size: ${(props) => props.theme.typography.sizes.base};
    font-weight: normal;
    line-height: ${theme.typography.lineHeight.base};
    letter-spacing: ${theme.typography.letterSpacing.base};
    color: ${(props) => props.theme.typography.colors.primary};
  }

  .no-scroll {
    overflow: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${theme.typography.colors.primary};
  }

  h1 {
    font-size: ${theme.typography.sizes.h1};
  }

  h2 {
    font-size: ${theme.typography.sizes.h2};
  }

  h3 {
    font-size: ${theme.typography.sizes.h3};
  }

  h4 {
    font-size: ${theme.typography.sizes.h4};
  }

  h5 {
    font-size: ${theme.typography.sizes.h5};
  }

  h6 {
    font-size: ${theme.typography.sizes.h6};
  }

  p {
    color: ${(props) => props.theme.typography.colors.primary};
  }

  a, button {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
  }

  input, select {
    font-size: 1em;
  }

  .cached-image {
    position: absolute;
    left: -1000vw;
    top: -1000vw;
  }

  *:focus-visible, a:focus-visible, button:focus-visible {
    -webkit-text-decoration: underline;
    text-decoration: underline !important;
    border: 2px solid blue !important;
    }

  .sr-only {
    position: absolute;
    left: -10000px;
  }
`;

export default GlobalStyles;
