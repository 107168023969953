import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { menuReorderPath } from 'core/api/actions/navigation';
import CatalogPage from 'dashboard/ui/pages/Catalog/Page';
import useProductList from 'dashboard/hooks/useProductList';
import ProductList from 'dashboard/ui/components/CatalogPage/ProductList';
import ProductGroup from 'dashboard/ui/helpers/ProductGroup';

import { ReorderHeroComponent } from 'dashboard/ui/components/CatalogPage/FilterHeroes';

function ReorderPage(props) {
  return (
    <CatalogPage>
      <Content {...props} />
    </CatalogPage>
  );
}

function Content(props) {
  const { match: { params: { deliveryDayId } }, ...rest } = props;

  const { loading, products } = useProductList({ variables: { deliveryDayId, ordered: true } });

  const productGroups = [new ProductGroup(null, 1, products)];

  return (
    <Fragment>
      <ReorderHeroComponent path={menuReorderPath(deliveryDayId)} />
      <ProductList
        key={'product-list-reorder'}
        loading={loading}
        productGroups={productGroups}
        {...rest}
        addEnabled={true}
      />
    </Fragment>
  );
}

Content.propTypes = {
  match: PropTypes.object.isRequired,
  deliveryDay: PropTypes.object,
  status: PropTypes.object,
  order: PropTypes.object,
};

export default ReorderPage;
