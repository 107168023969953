import styled, { css } from 'styled-components';

const Section = styled.div`
  padding-top: 0;
  padding-right: calc(${(p) => p.theme.layout.onramp.padding.horiz} / 2);
  padding-left: calc(${(p) => p.theme.layout.onramp.padding.horiz} / 2);
  margin-right: calc(${(p) => p.theme.layout.onramp.padding.horiz} / -2);
  margin-left: calc(${(p) => p.theme.layout.onramp.padding.horiz} / -2);

  ${(p) => p.spaced && css`
    padding-bottom: calc(${p.theme.layout.onramp.padding.vert} / 2);
    margin-bottom: ${p.theme.layout.onramp.padding.vert};
  `};

  background-color: ${(p) => p.highlight ? '#F3F3F3' : 'transparent'}; // FIXME: move to theme
`;

export default Section;
