import React, { PureComponent } from 'react';

import { Style, StyleSection, StyleGrid } from 'admin/ui/atoms';
import Alert from 'core/ui/components/Alert';
import AlertV2 from 'core/ui/components/V2/Alert';

const COPY = 'We promptly judged antique ivory buckles for the next prize.';

class Alerts extends PureComponent {
  render() {
    return (
      <StyleSection title='Alerts'>
        <StyleGrid cols='repeat(2, 1fr)'>
          <Style name='Alert'>
            <Alert alert={{ message: COPY }} />
          </Style>
          <Style name='AlertV2'>
            <AlertV2 alert={{ message: COPY }} />
          </Style>
        </StyleGrid>
      </StyleSection>
    );
  }
}

export default Alerts;
