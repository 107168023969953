import { useQuery } from '@apollo/client';

import { LIST_PRODUCT_TAGS } from 'core/api/apollo/queries';

function useProductTags(deliveryDayId) {
  const { loading, data: { listProductTags: productTags = [] } = {} } = useQuery(LIST_PRODUCT_TAGS, {
    variables: { deliveryDayId },
  });

  return { loading, productTags };
}

export default useProductTags;
