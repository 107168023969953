import React from 'react';

import { Page } from 'core/ui/atoms';

import Hero from 'dashboard/ui/components/OrdersPage/Hero';
import DeliveryPeriods from 'dashboard/ui/components/OrdersPage/DeliveryPeriods';

function OrdersPage() {
  return (
    <Page scrollTop>
      <Hero />
      <DeliveryPeriods />
    </Page>
  );
}

export default OrdersPage;
