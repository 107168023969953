import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/client';
import { ValidatorForm } from 'react-form-validator-core';

import { GET_BILLING_INFO } from 'core/api/apollo/queries';
import { SET_BILLING_INFO } from 'core/api/apollo/mutations';
import { updateBillingInfoQuery, updateUserQuery } from 'core/api/cache/updaters';

import useAlerts from 'core/hooks/useAlerts';
import useErrorHandlers from 'core/hooks/useErrorHandlers';

import { ControlsGrid, FormGroup, Label, StripeCardInput, StripeExpiryInput, StripeCVCInput } from 'core/ui/atoms';
import StripeContext from 'core/ui/components/Stripe/Context';

import { EditButton, SubmitButton } from 'settings/ui/atoms';
import Modal from 'settings/ui/components/Modal';
import Panel from 'settings/ui/components/Panel';
import PaymentCard from 'settings/ui/components/BillingInfo/PaymentCard';

function PaymentInfo() {
  const { loading, data: { getBillingInfo: billingInfo } = {} } = useQuery(GET_BILLING_INFO);
  const [modalOpen, setModalOpen] = useState(false);
  const alerts = useAlerts();

  const openModal = () => setModalOpen(true);

  const closeModal = () => setModalOpen(false);

  const onSuccess = () => {
    closeModal();
    alerts.show('Your changes have been saved!', { autoClose: true });
  };

  return (
    <Panel title='Credit Card' loading={loading} data-payment-info>
      <PaymentCard billingInfo={billingInfo} />

      <Panel.Footer>
        <EditButton onClick={openModal} />
        { modalOpen && (
          <Modal title='Credit Card' onClose={closeModal}>
            <StripeContext>
              <PaymentInfoForm
                data-payment-info-form
                onSuccess={onSuccess}
              />
            </StripeContext>
          </Modal>
        )}
      </Panel.Footer>
    </Panel>
  );
}

function PaymentInfoForm(props) {
  const [setBillingInfo] = useMutation(SET_BILLING_INFO, {
    update: (cache, { data: { setBillingInfo: { billingAccount, errors } } }) => {
      // NOTE: these are required to handle the case where the user didn't have a billing account
      //       when the app loaded
      updateUserQuery(cache, { user: { billingAccount } }, errors);
      updateBillingInfoQuery(cache, billingAccount, errors);
    },
  });
  const { displayApolloResponseErrors } = useErrorHandlers();
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [ready, setReady] = useState(false);

  const saveBillingInfo = (token) => {
    const { onSuccess } = props;

    setBillingInfo({ variables: { input: { stripeToken: token.id } } })
      .then(({ data: { setBillingInfo: { errors } } }) => {
        errors.length ? onServerError(errors) : onSuccess();
      })
      .catch(({ error }) => {
        onServerError(error);
      });
  };

  const onSubmit = (e) => {
    const { stripe, elements, getCardElement } = props;

    e.preventDefault();

    setLoading(true);
    setIsSubmitted(true);
    if (!stripe || !elements || !ready) {
      onError();
      return;
    }

    stripe
      .createToken(getCardElement())
      .then(({ error, token }) => {
        error ? onServerError(error) : saveBillingInfo(token);
      });
  };

  const onReady = () => setReady(true);

  const onError = () => {
    setLoading(false);
  };

  const onServerError = (error) => {
    onError();

    // NOTE: errors may contain either a single Stripe API error or an array of GraphQL API errors
    const errors = [error].flatten();
    displayApolloResponseErrors(errors);
  };

  return (
    <ValidatorForm
      onSubmit={onSubmit}
      onError={onError}
      noValidate
      instantValidate={false}
    >
      <FormGroup>
        <Label required>Card Number</Label>
        <StripeCardInput
          placeholder="ex. 4111 1111 1111 1111"
          onReady={onReady}
          showInvalidState={isSubmitted}
        />
      </FormGroup>
      <CustomControlsGrid>
        <FormGroup>
          <Label required>Exp date</Label>
          <StripeExpiryInput
            placeholder="ex. 11/23"
            showInvalidState={isSubmitted}
          />
        </FormGroup>
        <FormGroup>
          <Label required>CVC</Label>
          <StripeCVCInput
            placeholder="ex. 123"
            showInvalidState={isSubmitted}
          />
        </FormGroup>
      </CustomControlsGrid>

      <Panel.Footer>
        <SubmitButton disabled={loading}>Apply Changes</SubmitButton>
      </Panel.Footer>
    </ValidatorForm>
  );
}

PaymentInfoForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  stripe: PropTypes.object,
  elements: PropTypes.object,
  getCardElement: PropTypes.func,
  stripeHelpers: PropTypes.object,
};

const CustomControlsGrid = styled(ControlsGrid)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 28px;

  ${(p) => p.theme.max('sm')`
    grid-template-columns: 1fr;
    grid-gap: 0px;
  `};
`;

export default PaymentInfo;
