import React from 'react';
import PropTypes from 'prop-types';

import { isReferralModalOpenVar } from 'core/api/apollo/cache';
import useUser from 'core/hooks/useUser';

import { Button } from 'core/ui/atoms';

function InviteFriends(props) {
  const { label = 'Invite Friends', ...rest } = props;
  const { userCan } = useUser();

  if (!userCan.inviteFriends()) {
    return null;
  }

  return (
    <Button
      onClick={() => isReferralModalOpenVar(true)}
      {...rest}
    >
      {label}
    </Button>
  );
}

InviteFriends.propTypes = {
  label: PropTypes.string,
};

export default InviteFriends;
