import styled from 'styled-components';

// NOTE: https://css-tricks.com/aspect-ratio-boxes/#article-header-id-6
const AspectRatio = styled.div`
  position: relative;

  > :first-child {
    position: absolute;
    height: 100%;
  }

  :before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(${(p) => p.aspectHeight / p.aspectWidth} * 100%);
  }

  :after {
    content: "";
    display: table;
    clear: both;
  }
`;

export default AspectRatio;
