import React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import { useParams, useLocation } from 'react-router-dom';

import useDeliveryDayStatus from 'dashboard/hooks/useDeliveryDayStatus';
import useProduct from 'dashboard/hooks/useProduct';

import { Grid, Image, Page } from 'core/ui/atoms';
import LoadingScreen from 'core/ui/components/LoadingScreen';
import ProductSummary from 'dashboard/ui/components/ProductPage/ProductSummary';
import ImageGallery from 'dashboard/ui/components/ProductPage/ImageGallery';
import ProductNotAvailable from 'dashboard/ui/components/ProductPage/ProductNotAvailable';
import ProductSpecs from 'dashboard/ui/components/ProductPage/ProductSpecs';

function ProductPage() {
  const { deliveryDayId, productId } = useParams();
  const status = useDeliveryDayStatus(deliveryDayId);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const zipcode = searchParams.get('zipcode');
  const { product, loading } = useProduct(deliveryDayId, productId, zipcode);



  if (loading || status.loading) {
    return <LoadingScreen />;
  }

  if (!product) {
    return <ProductNotAvailable deliveryDay={status.deliveryDay} />;
  }

  return (
    <Page scrollTop key={`product-page-${status.deliveryDay.id}-${product.id}`}>
      <LayoutGrid>
        <Summary data-product-summary>
          <ProductSummary
            deliveryDayId={status.deliveryDay.id}
            order={status.order}
            status={status}
            product={product}
            addDisabled={status.loading || status.locked}
          />
        </Summary>
        <Gallery>
          <ImageGallery images={product.carouselImages}/>
        </Gallery>
        <Specs>
          <ProductSpecs product={product} />
        </Specs>
        {product.secondaryImage && (
          <SecondaryImage>
            <LazyLoad once>
              <ImageHuge src={product.secondaryImage.largeUrl}/>
            </LazyLoad>
          </SecondaryImage>
        )}
      </LayoutGrid>
    </Page>
  );
}

const BREAKER = 'md';

const LayoutGrid = styled(Grid)`
  grid-gap: ${(p) => p.theme.layout.padding.vert} ${(p) => p.theme.layout.padding.horiz};
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'summary gallery'
    'specs image';

  ${(p) => p.theme.max(BREAKER)`
    grid-template-columns: 1fr;
    grid-template-areas:
      'gallery'
      'summary'
      'specs'
      'image';
  `}
`;

const Summary = styled.div`
  grid-area: summary;
  padding-top: ${(p) => p.theme.layout.padding.vert};
  padding-left: ${(p) => p.theme.layout.padding.horiz};

  ${(p) => p.theme.max(BREAKER)`
    padding-right: ${(p) => p.theme.layout.padding.horiz};
    padding-left: ${(p) => p.theme.layout.padding.horiz};
  `}
`;

const Gallery = styled.div`
  grid-area: gallery;
`;

const Specs = styled.div`
  grid-area: specs;
  padding-left: ${(p) => p.theme.layout.padding.horiz};

  ${(p) => p.theme.max(BREAKER)`
    padding-right: ${(p) => p.theme.layout.padding.horiz};
    padding-left: ${(p) => p.theme.layout.padding.horiz};
  `}
`;

const SecondaryImage = styled.div`
  grid-area: image;
`;

const ImageHuge = styled(Image)`
  width: 100%;
`;

export default ProductPage;
