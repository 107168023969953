import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from 'core/ui/atoms';

function StatusIcon(props) {
  const { status, ...rest } = props;
  const { closed, skipped, menuPublished, locked } = status;

  return useMemo(() => {
    if (closed || skipped || !menuPublished) {
      return <DisabledIcon icon='alert' {...rest} />;
    }

    if (locked) {
      return <Icon icon='locked' {...rest} />;
    }

    return null;
  }, [closed, skipped, menuPublished, locked, rest]);
}

StatusIcon.propTypes = {
  status: PropTypes.object.isRequired,
};

const DisabledIcon = styled(Icon)`
  color: #FF3232;
`;

export default StatusIcon;
