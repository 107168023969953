import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import _uniq from 'lodash/uniq';
import _intersectionBy from 'lodash/intersectionBy';
import _reduce from 'lodash/reduce';

import useUser from 'core/hooks/useUser';
import { LIST_GLOBAL_FOODS_TO_AVOID } from 'core/api/apollo/queries';

function useFoodsToAvoid(product) {
  const { currentUser } = useUser();
  const { data: { listGlobalFoodsToAvoid: globalFoodsToAvoid = [] } = {} } = useQuery(LIST_GLOBAL_FOODS_TO_AVOID);

  return useMemo(() => {
    const userFoodsToAvoid = (currentUser || {}).foodsToAvoid || [];
    const { variants } = product;
    const productFoodsToAvoid = _reduce(variants, (ftas, variant) => (
      _uniq(ftas.concat(variant.ingredientTags || []))
    ), []);

    return {
      global: globalFoodsToAvoid,
      user: userFoodsToAvoid,
      product: productFoodsToAvoid,
      warnings: _intersectionBy(productFoodsToAvoid, userFoodsToAvoid.concat(globalFoodsToAvoid), 'id'),
    };
  }, [product, globalFoodsToAvoid, currentUser]);
};

export default useFoodsToAvoid;
