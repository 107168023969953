import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DashboardHeader from 'dashboard/ui/components/DashboardHeader';
import LayoutBody from 'core/ui/components/LayoutBody';
import Footer from 'core/ui/components/Footer';
import InviteFriendsModal from 'dashboard/ui/components/InviteFriends/Modal';
import RateFood from 'dashboard/ui/components/RateFood';
import CutoffModal from 'dashboard/ui/components/Cutoff/Modal';
import VideoModal from 'dashboard/ui/components/Video/Modal';

class AuthenticatedLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <main>
        <DashboardHeader />
        <LayoutBody>
          {this.props.children}
          <Footer />
        </LayoutBody>
        <InviteFriendsModal />
        <RateFood />
        <CutoffModal />
        <VideoModal />
      </main>
    );
  }
}

export default AuthenticatedLayout;
