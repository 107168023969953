import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import { LIST_RATEABLE_ORDERS } from 'core/api/apollo/queries';

import { isLoading } from 'core/api/loadingHelpers';
import { compareDates, displayDate } from 'core/ui/dateHelpers';

import {
  Box,
  Flex,
  H4,
  Text,
} from 'core/ui/atoms';
import RateableOrder from 'dashboard/ui/components/RateFood/RateableOrder';
import Loading from 'core/ui/components/Loading';

function RateFood() {
  const variables = {
    startDate: displayDate(moment().subtract(4, 'week'), 'isoShort'),
    endDate: displayDate(moment(), 'isoShort'),
  };
  const {
    loading, error, data: { listRateableOrders = [] } = {},
  } = useQuery(LIST_RATEABLE_ORDERS, { variables });

  if (isLoading(loading, listRateableOrders, error)) {
    return <Loading />;
  }

  const rateableOrders = [...listRateableOrders].sort((a, b) => compareDates(b.date, a.date));
  const orders = rateableOrders.slice(0, 4);

  if (orders.length === 0) {
    return (
      <NoOrders>
        <NoOrdersHeading>No deliveries to rate</NoOrdersHeading>
        <NoOrdersText>This is where you'll rate past deliveries</NoOrdersText>
      </NoOrders>
    );
  }

  return (
    <Wrapper>
      {
        orders.map((order) => (
          <RateFoodBox key={`rate-food-box-${order.id}`} xs={3} sm={3} md={3} lg={3}>
            <RateableOrder rateableOrder={order} />
          </RateFoodBox>
        ))
      }
    </Wrapper>
  );
}

const Wrapper = styled(Flex)`
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

const RateFoodBox = styled(Box)`
  margin-right: 3%;
  :last-child {
    margin-right: 0;
  }
`;

const NoOrders = styled.div`
  border: 2px dashed ${(p) => p.theme.colors.grays.light};
  border-radius: 10px;
  text-align: center;
  margin: 0;
  color: ${(p) => p.theme.typography.colors.muted};
  padding: 25px 20px;

  ${(p) => p.theme.max('md')`
    margin: 0 20px;
  `};
`;

const NoOrdersHeading = styled(H4)`
  color: ${(p) => p.theme.typography.colors.muted} !important;
  margin: 0;
`;

const NoOrdersText = styled(Text)`
  color: ${(p) => p.theme.typography.colors.muted} !important;
  margin: 0px;
`;

export default RateFood;
