/* eslint-disable no-console */
import { useQuery } from '@apollo/client';
import _sortBy from 'lodash/sortBy';

import { LIST_DIETS } from 'core/api/apollo/queries';

function useDietLists() {
  const { loading, data: { listDiets } = {} } = useQuery(LIST_DIETS);


  const diets = _sortBy(listDiets, 'position');

  return { loading, diets };
}

export default useDietLists;
