import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class ProgressRing extends PureComponent {
  static propTypes = {
    progress: PropTypes.number,
    height: PropTypes.number.isRequired,
    stroke: PropTypes.number.isRequired,
  }

  state = {
    radius: 0,
    normalizedRadius: 0,
    circumference: 0,
  };

  componentDidMount() {
    const { height, stroke } = this.props;
    const radius = height / 2;
    const normalizedRadius =  radius - Math.round(stroke / 2);
    const circumference = normalizedRadius * 2 * Math.PI;
    this.setState({ radius, normalizedRadius, circumference });
  }

  render() {
    const { progress = 0, stroke, ...rest } = this.props;
    const { radius, normalizedRadius, circumference } = this.state;

    const strokeDashoffset = circumference - progress / 100 * circumference;

    return (
      <svg
        height={radius * 2}
        width={radius * 2}
        viewBox={`0 0 ${radius * 2} ${radius * 2}`}
        fill="none"
        {...rest}
       >
        <circle
          stroke="#123035"
          strokeOpacity="0.2"
          fill="transparent"
          strokeWidth={ stroke }
          r={ normalizedRadius }
          cx={ radius }
          cy={ radius }
        />
        <Circle
          stroke="#123035"
          fill="transparent"
          strokeWidth={ stroke }
          strokeDasharray={ circumference + ' ' + circumference }
          style={ { strokeDashoffset } }
          r={ normalizedRadius }
          cx={ radius }
          cy={ radius }
         />
      </svg>
    );
  }
}

const Circle = styled.circle`
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

export default ProgressRing;
