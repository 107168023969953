import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';

import { useHistory } from 'react-router-dom';
import { SIGN_OUT } from 'core/api/apollo/mutations';
import useUser from 'core/hooks/useUser';
import { H3, H5, RouterLink, Text } from 'core/ui/atoms';
import BorderedTitle from 'onramp/ui/atoms/BorderedTitle';
import { Loading } from 'core/ui/components';

import { ROUTE_MAP } from 'onramp/api/routeSequence';
import { GET_SUBSCRIPTION_OPTIONS } from 'core/api/apollo/queries';

import {
  displayScheduleLabel,
  numDaysLabel,
  productSizeLabel,
  selectedFoodTypesList,
} from 'onramp/helpers';
import { session } from 'core/api/storage';
import client from 'core/api/apollo/apolloClient';
import { userHasCourierAddress } from 'core/api/Account';

const ProgramSummary = ({ returnTo, isShipping, getPrepaidPurchase }) => {
  const { currentUser, loading: userLoading } = useUser();

  const onLogoutComplete = () => {
    if (currentUser && currentUser.email && !currentUser.email.includes('@guest.com')) {
      session.clear();
      client.resetStore();
    }
  };

  const [logout] = useMutation(SIGN_OUT, {
    variables: { input: {} },
    onCompleted: onLogoutComplete,
    onError: onLogoutComplete,
  });

  if (userLoading) {
    return <Loading />;
  }

  if (isShipping) {
    return (
      <OneTimeProgramSummary
        getPrepaidPurchase={getPrepaidPurchase}
        currentUser={currentUser}
        returnTo={returnTo}
        logout={logout}
      />
    );
  } else {
    return (
      <SubscriptionSummary
        getPrepaidPurchase={getPrepaidPurchase}
        currentUser={currentUser}
        returnTo={returnTo}
        logout={logout}
      />
    );
  }
};

const OneTimeProgramSummary = ({ currentUser, getPrepaidPurchase }) => {
  const { nextOneTimeProgram } = currentUser;
  const deliveryPeriod = getPrepaidPurchase && getPrepaidPurchase.deliveryPeriods[0];
  const billableItems = getPrepaidPurchase && getPrepaidPurchase.billableItems;
  const bundleName = getPrepaidPurchase && getPrepaidPurchase.bundleName;
  const otpSubscribed = getPrepaidPurchase && getPrepaidPurchase.otpSubscribed;
  const getBundleReservations = getPrepaidPurchase && getPrepaidPurchase.bundleReservations;
  const isCourierAddress = userHasCourierAddress(currentUser);

  const {
    programBundle: { prepaidProgram: program, schedule, size },
    bundleReservations,
  } = nextOneTimeProgram;

  const numWeeks = bundleReservations && bundleReservations.length;

  const history = useHistory();

  const handleEditProgram = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    history.push(ROUTE_MAP.initOrder.path);
  };

  const shippingMessage = isCourierAddress ? 'Delivery by 6 AM' : 'Delivery via FedEx by 8 PM on Mondays.';
  const firstDeliveryDate = deliveryPeriod && moment(deliveryPeriod.startDate).format('MMMM D, YYYY');
  const dayOfWeek = deliveryPeriod && moment(deliveryPeriod.startDate).format('dddd');

  const isTransformationTribe = billableItems && billableItems.length > 0;
  const isBundleReservationsEmpty = getBundleReservations && getBundleReservations.length === 0;
  const isDigitalOnly = isTransformationTribe && isBundleReservationsEmpty;

  const programTitle = () => {
    if (isTransformationTribe) {
      if (isBundleReservationsEmpty) {
        return 'Transformation Tribe: Digital Only';
      }
      return `Transformation Tribe: ${program.name} - ${size.name} Meals`;
    } else if (bundleName === 'Haute Mom') {
      return otpSubscribed ?
        `Weekly Subscription :- ${program.name} - ${size.name} Meals`
        : `One Time Program :- ${program.name} - ${size.name} Meals`;
    } else if (bundleName !== 'Haute Mom') {
      return otpSubscribed ?
        `Weekly Subscription :- Signature Program: ${program.name} - ${size.name} Meals`
        : `One Time Program :- Signature Program: ${program.name} - ${size.name} Meals`;
    } else {
      return `${program.name} - ${size.name} Meals`;
    }
  };

  return (
    <Wrapper data-program-summary>
      <CustomBorderedTitle>My Plan</CustomBorderedTitle>
      <Section>
        <ProgramName no-margin data-program-name>
          {programTitle()}
        </ProgramName>
        <Text no-margin data-program-schedule>
          {!isDigitalOnly && displayScheduleLabel(schedule)}
        </Text>
        {numWeeks > 0 && (
          <Text no-margin data-program-length>
            {numWeeks} Week{numWeeks > 1 ? 's' : ''}
          </Text>
        )}
        {!isDigitalOnly && (
          <div>
            <hr />
            <div>
              <H5 no-margin>Weekly Deliveries</H5>
              <Text data-subscription-schedule text-size="small">
                {dayOfWeek && `First Delivery Day: ${dayOfWeek}, ${firstDeliveryDate}`}
              </Text>
              {otpSubscribed && <Text no-margin text-size="small">
                Cancel anytime by emailing <a href='mailto:help@gomethodology.com'>help@gomethodology.com</a>
              </Text>}
              <br/>
              <Text no-margin text-size="small">
                {shippingMessage}
              </Text>
            </div>
          </div>
        )}
      </Section>
      <EditLink to={`${ROUTE_MAP.initOrder.path}`} onClick={handleEditProgram}>
        Edit program
      </EditLink>
    </Wrapper>
  );
};

OneTimeProgramSummary.propTypes = {
  currentUser: PropTypes.object.isRequired,
  getPrepaidPurchase: PropTypes.object,
  logout: PropTypes.func,
};

const SubscriptionSummary = ({ currentUser, getPrepaidPurchase }) => {
  const { loading, data } = useQuery(GET_SUBSCRIPTION_OPTIONS);
  const history = useHistory();

  const handleEditProgram = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    history.push(ROUTE_MAP.initOrder.path);
  };

  const isCourierAddress = userHasCourierAddress(currentUser);

  if (loading) {
    return <Loading />;
  }

  const { getSubscriptionOptions } = data;
  const {
    primaryAddress: {
      deliveryOption: { deliveryMethod },
    },
  } = currentUser;
  const deliveryPeriod = getPrepaidPurchase && getPrepaidPurchase.deliveryPeriods[0];

  const { productSize, numDays, startDay } = getSubscriptionOptions;
  const foodForLabel = numDaysLabel(numDays, deliveryMethod);
  const dayOfWeek = deliveryPeriod && moment(deliveryPeriod.startDate).format('dddd');

  const { breakfast, lunch, dinner, snacks } = getSubscriptionOptions;
  const mealTypes = selectedFoodTypesList({ breakfast, lunch, dinner, snacks }).join(', ');
  const firstDeliveryDate = moment(startDay.date).format('MMMM D, YYYY');
  const shippingMessage = isCourierAddress ? 'Delivery by 6 AM' : 'Delivery via FedEx by 8 PM on Mondays.';

  return (
    <Wrapper data-subscription-summary>
      <CustomBorderedTitle>My Plan</CustomBorderedTitle>
      <Section>
        <ProgramName no-margin data-program-name>
          {mealTypes}
        </ProgramName>
        <Text no-margin data-program-schedule>
          {foodForLabel.label} | {foodForLabel.range} Weekly
        </Text>
        <Text no-margin data-program-length>
          {productSize && productSizeLabel(productSize).label}
        </Text>
        <hr />
        <div>
          <H5 no-margin>Weekly Deliveries</H5>
          <Text data-subscription-schedule text-size="small">
            {dayOfWeek && `First Delivery Day: ${dayOfWeek}, ${firstDeliveryDate}`}
          </Text>
          <Text no-margin text-size="small">
            Cancel anytime by emailing <a href='mailto:help@gomethodology.com'>help@gomethodology.com</a>
          </Text>
          <Text no-margin text-size="small">
            {shippingMessage}
          </Text>
        </div>
      </Section>
      <EditLink to={`${ROUTE_MAP.initOrder.path}`} onClick={handleEditProgram}>
        Edit program
      </EditLink>
    </Wrapper>
  );
};

SubscriptionSummary.propTypes = {
  currentUser: PropTypes.object.isRequired,
  getPrepaidPurchase: PropTypes.object,
  logout: PropTypes.func,
};

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 2vh;
`;

const Section = styled.div`
  background-color: #cfddcf;
  border: 1px solid ${(p) => p.theme.typography.colors.black};
  padding: 16px; /* padding inside the section */
  margin-bottom: 16px; /* margin between sections */
`;

const CustomBorderedTitle = styled(BorderedTitle)`
  ${(p) => p.theme.min('sm')`
    display: none;
  `};
`;

const EditLink = styled(RouterLink)`
  padding: 0;
  font-weight: normal;
  color: ${(p) => p.theme.typography.colors.black};
  text-align: right;
  font-size: ${(p) => p.theme.typography.sizes.small};
  text-decoration: underline;
  position: absolute;
  bottom: 0;
  right: 0;
  ${(p) => p.theme.max('sm')`
    font-size: ${(p) => p.theme.typography.sizes.smaller};
  `}
`;

ProgramSummary.propTypes = {
  returnTo: PropTypes.string.isRequired,
  isShipping: PropTypes.bool.isRequired,
};

const ProgramName = styled(H3)`
  font-size: ${(p) => p.theme.typography.sizes.h2};
  font-family: ${(p) => p.theme.typography.familyLarken};
  ${(p) => p.theme.max('sm')`
    font-size: 24px;
  `}
`;

export default ProgramSummary;
