import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Header from 'core/ui/components/VisitorHeader';
import LayoutBody from 'core/ui/components/LayoutBody';
import Footer from 'core/ui/components/Footer';
import VideoModal from 'dashboard/ui/components/Video/Modal';

class VisitorLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <main>
        <Header />
        <LayoutBody>
          {this.props.children}
          <Footer />
        </LayoutBody>
        <VideoModal />
      </main>
    );
  }
}

export default VisitorLayout;
