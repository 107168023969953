import styled from 'styled-components';

import { H5 } from 'core/ui/atoms';

const SettingsHeading = styled(H5)`
  display: inline-block;
  width: max-content;
  background-color: ${(p) => p.theme.colors.grays.lighter};
  padding: 0.25em 1em;
  border-radius: 0.5em;
  margin-top: 0;
`;

export default SettingsHeading;
