import React, { PureComponent } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LIST_FOODS_TO_AVOID } from 'core/api/apollo/queries';

import TagInput from 'core/ui/atoms/TagInput';
import TagsList from 'core/ui/atoms/TagsList';
import Tag from 'core/ui/atoms/Tag';
import Loading from 'core/ui/components/Loading';

import { FOODS_TO_AVOID_NUM_TAGS_FOR_WARNING } from 'onramp/constants';
import FoodsToAvoidWarning from 'onramp/ui/components/DietPage/FoodsToAvoidWarning';

class FoodsToAvoidInput extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    placeholder: PropTypes.string,
    initialFoodsToAvoidIds: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  state = {
    foodsToAvoidIds: [],
  };

  componentDidMount() {
    this.updateTags(this.props.initialFoodsToAvoidIds);
  }

  updateTags = (tagIds) => {
    const { onChange } = this.props;
    this.setState(() => ({ foodsToAvoidIds: tagIds }), () => onChange(this.state.foodsToAvoidIds));
  }

  addTag = (tag) => this.updateTags([...this.state.foodsToAvoidIds, tag.id]);

  removeTag = (tagId) => this.updateTags(this.state.foodsToAvoidIds.filter((id) => id !== tagId));

  render() {
    const { data: { listFoodsToAvoid: foodsToAvoid, error, loading }, placeholder } = this.props;

    if (error) console.error('err', error);
    if (loading) return <Loading />;

    const { foodsToAvoidIds: selectedFoodsToAvoidIds } = this.state;
    const isEmpty = selectedFoodsToAvoidIds.length < 1;
    const displayFTAWarning = selectedFoodsToAvoidIds.length >= FOODS_TO_AVOID_NUM_TAGS_FOR_WARNING;

    return (
      <Wrapper className={this.props.className}>
        {displayFTAWarning && <FoodsToAvoidWarning />}
        <CustomTagInput
          className='foods-to-avoid-input-input'
          placeholder={placeholder}
          variants={foodsToAvoid.filter((fta) => fta.userFacing)}
          selected={selectedFoodsToAvoidIds}
          onChange={this.addTag}
        />
        <TagsList empty={isEmpty} className='foods-to-avoid-input-tag-list'>
          {
            selectedFoodsToAvoidIds.map((tagId) => {
              const tag = foodsToAvoid.find((item) => item.id === tagId);
              return (
                <Tag
                  className='foods-to-avoid-input-tag'
                  key={`foods-to-avoid-input-tag-${tag.id}`}
                  tagId={tag.id}
                  title={tag.name}
                  onRemove={this.removeTag}
                />
              );
            })
          }
        </TagsList>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin-bottom: 3vh;
`;

const CustomTagInput = styled(TagInput)`
  margin-bottom: 10px;
`;

export default graphql(LIST_FOODS_TO_AVOID)(FoodsToAvoidInput);
