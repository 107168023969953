import React from 'react';
import PropTypes from 'prop-types';

import { theme } from 'core/ui/theme';

function BoxOpenedIcon(props) {
  const { colors: { secondary: fill, black: stroke } } = theme;

  return (
    <svg
      height="2.5em"
      className={props.className}
      viewBox="0 0 44 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="LoggedIn-XP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Edit-Menu" transform="translate(-1217.000000, -63.000000)">
          <g id="Cart-Expanded" transform="translate(0.000000, 61.000000)">
            <g id="Box-Info" transform="translate(948.000000, 3.000000)">
              <g id="Cart">
                <g id="Box-Open" transform="translate(270.000000, 0.000000)">
                  <polygon id="Stroke-4" fill={fill} points="36.8559187 13.3071051 20.7029812 18.9773723 20.7029812 26.2135021 36.8559187 20.5044233"></polygon>
                  <polygon id="Stroke-5" fill={fill} points="36.85605 13.1540006 41.9656125 5.13649646 25.8113625 10.8455753 20.7031125 18.8617745"></polygon>
                  <polygon id="Stroke-12" fill={fill} points="4.75587565 13.1411311 0.000131250006 5.10504759 16.1530688 0.000152393178 20.7035063 8.04897488"></polygon>
                  <polygon id="Stroke-10" fill={fill} points="4.55004375 13.2396746 20.7029813 18.7877525 20.7029813 26.2135021 4.55004375 20.5044233"></polygon>
                  <polygon id="Stroke-1" fill={fill} stroke={stroke} strokeWidth="1.68" strokeLinecap="round" strokeLinejoin="round" points="36.85605 32.27948 20.7031125 37.9872539 20.7031125 18.862166 36.85605 13.154392"></polygon>
                  <polyline id="Stroke-5" stroke={stroke} strokeWidth="1.68" strokeLinecap="round" strokeLinejoin="round" points="36.85605 13.1540006 41.9656125 5.13649646 25.8113625 10.8455753 20.7031125 18.8617745"></polyline>
                  <polygon id="Stroke-7" fill={fill} stroke={stroke} strokeWidth="1.68" strokeLinecap="round" strokeLinejoin="round" points="20.7029813 18.8617745 4.55004375 13.1540006 4.55004375 32.2790885 20.7029813 37.9868624"></polygon>
                  <polyline id="Stroke-11" stroke={stroke} strokeWidth="1.68" strokeLinecap="round" strokeLinejoin="round" points="4.55056875 13.1540006 20.7035062 8.04910537 27.5731312 10.2192078"></polyline>
                  <polyline id="Stroke-12" stroke={stroke} strokeWidth="1.68" strokeLinecap="round" strokeLinejoin="round" points="4.55056875 12.8902737 0.000131250004 5.10504759 16.1530688 0.000152393178 20.7035063 8.04897488 20.7035063 18.2020353"></polyline>
                  <path d="M26.0554875,32.6311592 L33.7204875,29.9234247" id="Stroke-14" stroke={stroke} strokeWidth="1.68" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M28.0672875,28.7979206 L33.7215375,26.8000692" id="Stroke-15" stroke={stroke} strokeWidth="1.68" strokeLinecap="round" strokeLinejoin="round"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

BoxOpenedIcon.propTypes = {
  className: PropTypes.string,
};

export default BoxOpenedIcon;
