import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _get from 'lodash/get';
import _parseInt from 'lodash/parseInt';
import reject from 'lodash/reject';
import _sortBy from 'lodash/sortBy';
import _sumBy from 'lodash/sumBy';
import { useMutation, useQuery } from '@apollo/client';

import useAlerts from 'core/hooks/useAlerts';
import useForm from 'core/hooks/useForm';
import useUser from 'core/hooks/useUser';

import { LIST_SUBSCRIPTION_QUANTITY_OPTIONS } from 'core/api/apollo/queries';
import { SET_SUBSCRIPTION_QUANTITIES } from 'core/api/apollo/mutations';

import { Form, Table, Text } from 'core/ui/atoms';
import QuantitySelectComp from 'core/ui/components/QuantitySelect';

import { SettingsHeading, SubmitButton } from 'settings/ui/atoms';
import Panel from 'settings/ui/components/Panel';

const MINIMUM_SUBSCRIBED_ITEMS = 1;
const MAXIMUM_SUBSCRIBED_ITEMS = 100;

const mapQuantities = (subscription) => {
  const map = {};
  const { subscriptionQuantities = [] } = subscription || {};

  subscriptionQuantities.forEach((subQty) => {
    map[subQty.dayOfWeek] = map[subQty.dayOfWeek] || {};
    map[subQty.dayOfWeek][subQty.subscriptionCategory.id] = subQty.quantity;
  });
  return map;
};

const normalizeInitialData = (subscription, days, categories) => {
  const quantityMap = mapQuantities(subscription);

  const quantities = days.flatMap((day) => (
    categories.map((category) => (
      {
        dayOfWeek: day.index,
        categoryId: category.id,
        quantity: _get(quantityMap, [day.index, category.id], 0),
      }
    ))
  ));

  return { quantities: _sortBy(quantities, ['dayOfWeek', 'categoryId']) };
};

function SubscriptionSettings(props) {
  const { account, currentUser: { subscription } } = useUser();
  const { loading, data: { listSubscriptionDays = [], listSubscriptionCategories = [] } = {} } = useQuery(LIST_SUBSCRIPTION_QUANTITY_OPTIONS);
  const alerts = useAlerts();

  const onSuccess = () => {
    let message = 'Your changes have been saved!';
    if (account.subscription.isActive()) {
      message += ' Note: this will not apply to existing orders';
    }
    alerts.show(message, { autoClose: true });
  };

  const days = _sortBy(listSubscriptionDays, 'index').filter((d) => d.index <= 1);
  const categories = _sortBy(listSubscriptionCategories, 'position');

  return (
    <Panel title='Subscription Settings' loading={loading || !subscription} data-subscription-settings {...props}>
      <Text no-margin text-italic text-size="small">Food is typically good for 5 days after delivery</Text>
      <QuantitiesForm
        categories={categories}
        days={days}
        onSuccess={onSuccess}
        initialData={normalizeInitialData(subscription, days, categories)}
      />
    </Panel>
  );
};

function formIsValid({ quantities }) {
  const total = _sumBy(quantities, 'quantity');
  return total >= MINIMUM_SUBSCRIBED_ITEMS && total <= MAXIMUM_SUBSCRIBED_ITEMS;
};

function QuantitiesForm(props) {
  const { initialData, onSuccess, days, categories } = props;
  const alerts = useAlerts();
  const [setSubscriptionQuantities] = useMutation(SET_SUBSCRIPTION_QUANTITIES);
  const { handleSubmit, formData, handleChange, isDirty, isProcessing } = useForm('setSubscriptionQuantities', {
    initialData,
    onSubmit: setSubscriptionQuantities,
    onSuccess,
    validator: formIsValid,
  });
  const { quantities } = formData;
  const isValid = formIsValid(formData);

  const onChange = (quantity) => {
    const current = reject(quantities, (item) => item.dayOfWeek === quantity.dayOfWeek && item.categoryId === quantity.categoryId);
    const updatedQuantities = _sortBy([...current, quantity], ['dayOfWeek', 'categoryId']);

    handleChange({ quantities: updatedQuantities });
  };

  useEffect(() => {
    if (!isValid) {
      alerts.show(`Your subscription must be between ${MINIMUM_SUBSCRIBED_ITEMS} and ${MAXIMUM_SUBSCRIBED_ITEMS} items.`, { autoClose: true });
    }
  }, [isValid, alerts]);

  return (
    <Form onSubmit={handleSubmit} noValidate instantValidate={false}>
      <Table>
        <thead>
          <Table.Row>
            <Table.Header width='50%'></Table.Header>
            {
              days.map((day) => (
                <Table.Header key={`day-${day.index}`} width='25%' text-align='center'>
                  <SettingsHeading no-margin>{day.name}s</SettingsHeading>
                </Table.Header>
              ))
            }
          </Table.Row>
        </thead>
        <tbody>
          {
            categories.map((category) => {
              const key = `subscription-quantities-${category.id}`;
              const initialData = quantities.filter((quantity) => quantity.categoryId === category.id);
              return (
                <Row
                  key={key}
                  days={days}
                  category={category}
                  initialData={initialData}
                  onChange={onChange}
                />
              );
            })
          }
        </tbody>
      </Table>

      <Panel.Footer>
        <SubmitButton disabled={!isValid || isProcessing || !isDirty}>
          Apply Changes
        </SubmitButton>
      </Panel.Footer>
    </Form>
  );
}

QuantitiesForm.propTypes = {
  initialData: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  days: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function Row(props) {
  const { onChange: handleChange, category, days, initialData } = props;

  const onChange = (day, quantity) => {
    handleChange({ dayOfWeek: day.index, categoryId: category.id, quantity });
  };

  return (
    <Table.Row data-quantities-category={category.id}>
      <Table.Cell text-align='left'>{category.name}</Table.Cell>
      {
        days.map((day) => {
          const initialValue = initialData.find((quantity) => quantity.dayOfWeek === day.index);
          const name = `quantities[${day.index}][${category.id}]`;
          return (
            <Table.Cell text-align='center' key={name}>
              <QuantitySelect
                selected={`${initialValue ? initialValue.quantity : 0}`}
                onChange={(e) => onChange(day, _parseInt(e.target.value))}
                name={name}
              />
            </Table.Cell>
          );
        })
      }
    </Table.Row>
  );
}

Row.propTypes = {
  days: PropTypes.arrayOf(PropTypes.object).isRequired,
  category: PropTypes.object.isRequired,
  initialData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

const QuantitySelect = styled(QuantitySelectComp)`
  display: inline-block;
  font-size: ${(p) => p.theme.typography.sizes.medium};

  .select-selected {
    background: ${(p) => p.theme.colors.grays.lighter};
    border-color: ${(p) => p.theme.colors.grays.lighter};
    border-radius: 0.5em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
  }
`;

export default SubscriptionSettings;
