import {
  userAuthenticated,
  canOrder,
  supportedAddress,
} from 'core/api/routeAccessCheck';

import DefaultLayout from 'core/ui/layouts/DefaultLayout';
import CatalogLayout from 'dashboard/ui/layouts/CatalogLayout';

import DashboardPage from 'dashboard/ui/pages/DashboardPage';
import OrdersPage from 'dashboard/ui/pages/OrdersPage';
import ProductPage from 'dashboard/ui/pages/ProductPage';
import CartPage from 'dashboard/ui/pages/CartPage';
import NextMenuPage from 'dashboard/ui/pages/NextMenuPage';
import CatalogHomePage from 'dashboard/ui/pages/Catalog/HomePage';
import CatalogCategoryPage from 'dashboard/ui/pages/Catalog/CategoryPage';
import CatalogFilterPage from 'dashboard/ui/pages/Catalog/FilterPage';
import CatalogReorderPage from 'dashboard/ui/pages/Catalog/ReorderPage';

export const ROUTES = [
  {
    name: 'root',
    path: '/',
    component: DashboardPage,
    layout: DefaultLayout,
    accessChecks: [userAuthenticated, canOrder],
  },
  {
    name: 'orders',
    path: '/orders',
    component: OrdersPage,
    layout: DefaultLayout,
    accessChecks: [userAuthenticated, canOrder],
  },
  {
    name: 'cart',
    path: '/cart/:deliveryDayId',
    component: CartPage,
    layout: CatalogLayout,
    accessChecks: [userAuthenticated, canOrder],
    requireValidBilling: true,
  },
  {
    name: 'defaultMenu',
    path: '/menu',
    component: NextMenuPage,
    layout: DefaultLayout,
  },
  {
    name: 'menu',
    path: '/menu/:deliveryDayId',
    component: CatalogHomePage,
    layout: CatalogLayout,
    requireValidBilling: true,
  },
  {
    name: 'menuCategory',
    path: '/menu/:deliveryDayId/category/:categoryId',
    component: CatalogCategoryPage,
    layout: CatalogLayout,
    requireValidBilling: true,
  },
  {
    name: 'menuFilter',
    path: '/menu/:deliveryDayId/filter/:tagId',
    component: CatalogFilterPage,
    layout: CatalogLayout,
    requireValidBilling: true,
  },
  {
    name: 'menuReorder',
    path: '/menu/:deliveryDayId/reorder',
    component: CatalogReorderPage,
    layout: CatalogLayout,
    accessChecks: [userAuthenticated, canOrder, supportedAddress],
    requireValidBilling: true,
  },
  {
    name: 'menuProduct',
    path: '/menu/:deliveryDayId/product/:productId',
    component: ProductPage,
    layout: CatalogLayout,
    requireValidBilling: true,
  },
];

const ROUTE_MAP = ROUTES.reduce((obj, route) => ({ ...obj, [route.name]: route }), {});

export default ROUTE_MAP;
