import { useQuery } from '@apollo/client';

import { GET_PRODUCT_FILTERS } from 'core/api/apollo/queries';
import { productFiltersVar } from 'core/api/apollo/cache';

function useProductFilters() {
  const { data: { productFilters: active = [] } } = useQuery(GET_PRODUCT_FILTERS);

  const isActive = (filterId) => active.includes(filterId);

  const filterProducts = (products) => {
    return products.filter((product) => {
      const ids = product.tags.map((p) => p.id);
      return active.every((id) => ids.includes(id));
    });
  };

  const toggleFilter = (productFilterId) => {
    const productFilters = productFiltersVar();
    let filters;
    if (productFilters.includes(productFilterId)) {
      filters = productFilters.filter((id) => id !== productFilterId);
    } else {
      filters = [...productFilters, productFilterId];
    }
    productFiltersVar(filters);
  };

  return {
    active,
    isActive,
    filterProducts,
    toggleFilter,
  };
}

export default useProductFilters;
