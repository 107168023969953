import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { textMixin } from 'core/ui/mixins';

function Tooltip(props) {
  const { tipText } = props;

  return (
    <TooltipContainer data-text={tipText}>
      <TooltipControl text-bold />
    </TooltipContainer>
  );
};

Tooltip.propTypes = {
  tipText: PropTypes.string.isRequired,
};

const TooltipContainer = styled.span`
  position: relative;
  cursor: pointer;

  &:before {
    content: attr(data-text);
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    margin-left: 15px;

    width: 200px;
    padding: 10px;
    border-radius: 10px;
    background: ${(p) => p.theme.colors.black};
    color: ${(p) => p.theme.colors.white};
    text-align: left;
    font-size: ${(p) => p.theme.typography.sizes.medium};

    display:none;
  }

  &:after {
    content: "";
    position: absolute;

    left: 100%;
    margin-left: -5px;

    top: 50%;
    transform: translateY(-50%);

    border: 10px solid ${(p) => p.theme.colors.black};
    border-color: transparent ${(p) => p.theme.colors.black} transparent transparent;

    display: none;
  }

  &:hover:before, &:hover:after {
    display: block;
  }
`;

const TooltipControl = styled.span`
  &:after {
    content: '?';
  }

  ${textMixin}
`;

export default Tooltip;
