import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import DEFAULT_BACKGROUND from 'core/assets/images/bg1.jpg';

import { Page } from 'core/ui/atoms';

class SplitPageContainer extends PureComponent {
  static propTypes = {
    image: PropTypes.string,
    children: PropTypes.node,
  };

  render() {
    const { image } = this.props;
    return (
      <Page scrollTop>
        <Background {...this.props}>
          <Image image={image} />
          {this.props.children}
        </Background>
      </Page>
    );
  }
}

const Background = styled.div`
  position: relative;
  width: 100%;
  ${(p) => p.theme.min('md')`
    min-height: calc(100vh - ${(p) => p.theme.layout.headerHeight});
  `}
  ${(p) => !p.noPadding && css`
    padding: 40px 0;
  `}
`;

const Image = styled.section`
  position: absolute;
  height: 100%;
  width: calc(50% - (${(p) => p.theme.layout.containerWidth.md} / 24));
  top: 0;
  right: 0;
  background-image: url(${(p) => p.image || DEFAULT_BACKGROUND});
  background-size: cover;
  background-position: center;
  min-height: calc(100vh - ${(p) => p.theme.layout.headerHeight});
  ${(p) => p.theme.max('md')`
    display: none;
  `}
`;

export default SplitPageContainer;
