import styled from 'styled-components';

const BorderedTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #3e4c43;
    margin: 0 10px;
  }

  span {
    font-size: 1.2em;
    color: #3e4c43;
    white-space: nowrap;
  }

  ${(p) => p.theme.max('md')`
    margin: 10px 0;

    span {
      font-size: 1em;
    }

    &::before,
    &::after {
      margin: 0 5px;
    }
  `}

  ${(p) => p.theme.max('sm')`
    margin: 0 0 5px;
    span {
      font-size: 0.9em;
    }

    &::before,
    &::after {
      margin: 0 3px;
    }
  `}
`;

export default BorderedTitle;
