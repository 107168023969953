import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import _sortBy from 'lodash/sortBy';

import { Icon , Button } from 'core/ui/atoms';

import ProductImage from 'dashboard/ui/components/ProductPage/ProductImage';

function ImageGallery(props) {
  const { images = [] } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  return useMemo(() => {
    const currentImage = _sortBy(images, 'position')[currentIndex];

    const next = () => {
      const index = currentIndex + 1;
      setCurrentIndex(index >= images.length ? 0 : index);
    };

    const prev = () => {
      const index = currentIndex - 1;
      setCurrentIndex(index < 0 ? images.length - 1 : index);
    };

    return (
      <GalleryCont>
        {images.length > 1 && (
          <Fragment>
            <Arrow left unstyled onClick={prev}>
              <Icon icon="caret_l" />
            </Arrow>
            <Arrow right unstyled onClick={next}>
              <Icon icon="caret_r" />
            </Arrow>
          </Fragment>
        )}
        <ProductImage image={currentImage} />
      </GalleryCont>
    );
  }, [images, currentIndex]);
}

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const GalleryCont = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Arrow = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 100%;
  color: ${(p) => p.theme.typography.colors.primary};
  font-size: 20px;
  text-align: ${(p) => p.right ? 'right' : 'left'};
  padding: ${(p) => p.theme.layout.mobilePadding};
  z-index: ${(p) => p.theme.zIndex.galleryPaddle};
  ${(p) =>
    p.right &&
    css`
      left: inherit;
      right: 0;
    `}
  &:hover {
    color: ${(p) => p.theme.typography.colors.primary};
    background-image: linear-gradient(to ${(p) =>
      p.right ? 'right' : 'left'}, rgba(0, 0, 0, 0) , rgba(0, 0, 0, .1));
  }
`;

export default ImageGallery;
