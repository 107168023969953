import { googleAdManagerId, debugTrackingEnabled } from 'reactAppConstants';

const loadGTag = (tag_id) => {
  const script = document.createElement('script');

  script.src = `https://www.googletagmanager.com/gtag/js?id=${tag_id}`;
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => initializeGTag(tag_id);
};

const initializeGTag = (tag_id) => {
  window.dataLayer = window.dataLayer || [];

  window.gtag = function (...data) {
    const args = data.length === 1 ? data[0] : data;
    window.dataLayer.push(args);
  };

  window.gtag('js', new Date());
  window.gtag('config', tag_id);
};

if (googleAdManagerId) {
  loadGTag(googleAdManagerId);
}

const logEvent = (data = {}) => {
  try {
    if (debugTrackingEnabled()) {
      console.debug(Tracker.name, Tracker.enabled ? 'enabled' : 'disabled', data);
    }
  } catch (e) {
    console.error(Tracker.name, 'Error while logging', e, data);
  }
};

const pushGAMData = (data) => {
  const gtag = window.gtag || function () {};
  Tracker.enabled && gtag(data);
  logEvent(data);
};

const Tracker = {
  name: 'Google Ads Manager',
  enabled: !!googleAdManagerId,
  events: {
    pageView: () => {},
    lead: () => {},
    addToCart: () => {},
    registration: () => {},
    applyPromoCode: () => {},
    checkoutProgress: () => {},
    purchase: ({ transactionId, purchaseAmount }) => {
      pushGAMData('event', 'conversion', {
        send_to: `${googleAdManagerId}/xncgCMqrtNMYEPuZlIsq`,
        value: purchaseAmount.toRoundedUnit(2),
        currency: 'USD',
        transaction_id: transactionId,
        event_callback: () => {},
      });
    },
    error: () => {},
  },
};

export default Tracker;
