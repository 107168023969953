import isNil from 'lodash/isNil';
import round from 'lodash/round';
import sumBy from 'lodash/sumBy';
import { displayNumber } from 'core/api/utils';

export const displayNumItems = (order) => {
  if (!order) { return 0; }

  return displayNumber(sumBy(order.lineItems || [], 'quantity'));
};

const WEIGHT_ADJUSTMENT = 0.9;
export const displayWeight = (weight, unit = 'oz') => {
  if (isNil(weight)) {
    return null;
  }

  const adjustedWeight = (weight || 0) * WEIGHT_ADJUSTMENT;
  return `${round(adjustedWeight, 1)} ${unit}`;
};
