import React, { useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import SplitPanelModal from 'core/ui/components/SplitPanelModal';
import ModalCard from 'dashboard/ui/components/RateFood/ModalCard';
import INTRO_IMAGE from 'dashboard/assets/images/RateFood_Intro.png';
import OUTRO_IMAGE from 'dashboard/assets/images/RateFood_Outro.png';
import { LIST_RATEABLE_PRODUCT_VARIANTS } from 'core/api/apollo/queries';

function RateFoodModal(props) {
  const { orderId } = props;

  const reducer = (state, action) => {
    if (action.type === 'next') {
      return { pageNum: state.pageNum + 1 };
    } else {
      throw new Error(`Unhandled action ${action.type}`);
    }
  };

  const [state, setState] = useReducer(reducer, { pageNum: 0 });
  const mounted = useRef(false);
  const {
    loading, data: { listRateableProductVariants: variants = [] } = {},
  } = useQuery(LIST_RATEABLE_PRODUCT_VARIANTS, { variables: { orderId } });

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  const setStateSafely = (...args) => mounted.current && setState(...args);
  const onClose = () => props.onClose();
  const next = () => setStateSafely({ type: 'next' });

  if (loading) { return null; }

  const showIntro = state.pageNum === 0;
  const variant = variants[state.pageNum - 1];

  let image = INTRO_IMAGE;
  if (variant) {
    image = variant.product.primaryImage.smallUrl;
  } else if (!showIntro) {
    image = OUTRO_IMAGE;
  }

  return (
    <SplitPanelModal
      onClose={onClose}
      image={image}
    >
      <ModalContent>
        <ModalCard
          next={variant || showIntro ? next : null}
          variant={variant}
          orderId={orderId}
        />
      </ModalContent>
    </SplitPanelModal>
  );
}

RateFoodModal.propTypes = {
  orderId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ModalContent = styled.div`
  padding: 30px 35px 75px 35px;
  min-height: 450px;
  ${(p) => p.theme.max('sm')`
    padding-bottom: 0;
    min-height: 100%;
    margin-left: 0;
  `}
`;

export default RateFoodModal;
