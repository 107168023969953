import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { generatePath } from 'react-router';
// import { useRouteMatch } from 'react-router-dom';

import { compareDates, displayDate } from 'core/ui/dateHelpers';

function DeliveryDayToggle(props) {
  // const { path, params } = useRouteMatch();

  // const targetPath = (deliveryDayId) => {
    // const targetParams = { ...params, deliveryDayId };

    // return generatePath(path, targetParams);
  // };

  const { deliveryDay } = props;
  const { period: { days } } = deliveryDay;

  const firstDelivery = [...days].sort((a, b) => compareDates(a.date, b.date))[0];

  return (
    <ToggleList>
      {/* CHANGES BEFORE THURSDAY DELIERY */}
      {/* {
        [...days].sort((a, b) => compareDates(a.date, b.date) ).map((day) => (
          <ToggleListItem key={`day-toggle-${day.id}`} >
            <Link to={targetPath(day.id)}>{displayDate(day.date, 'deliveryDate')}</Link>
          </ToggleListItem>
        ))
      } */}
      <ToggleListItem key={`day-toggle-${firstDelivery.id}`} >
            <ToggleContainer>{displayDate(firstDelivery.date, 'deliveryDate')}</ToggleContainer>
          </ToggleListItem>

    </ToggleList>
  );
}

DeliveryDayToggle.propTypes = {
  deliveryDay: PropTypes.object.isRequired,
};

const ToggleList = styled.ul`
  margin: 0;
  padding: 0;
  border-radius: 5em;
  border: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.grays.light};
  white-space: nowrap;
`;

const ToggleListItem = styled.li`
  display: inline-block;
`;

const ToggleContainer = styled.div`
  display: block;
  color: ${(p) => p.theme.colors.grays.primary};
  background: ${(p) => p.theme.colors.secondary};
  font-weight: 500;
  border-radius: 5em;
  padding: 8px 15px;

  &.active {
    font-weight: 500;
    color: ${(p) => p.theme.typography.colors.primary};
    background: ${(p) => p.theme.colors.secondary};
  }

  ${(p) => p.theme.max('xs')`
    font-size: 0.8125em;
    padding: 10px;
  `}
`;

export default DeliveryDayToggle;
