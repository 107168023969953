import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import useUser from 'core/hooks/useUser';
import useDeliveryDay from 'dashboard/hooks/useDeliveryDay';

import DefaultLayout from 'core/ui/layouts/DefaultLayout';
import DeliveryDayHeader from 'dashboard/ui/components/DeliveryDayHeader';
import VisitorHeader from 'dashboard/ui/components/DeliveryDayHeader/VisitorHeader';
import CategoryNav from 'dashboard/ui/components/CatalogPage/ProductList/NavBar';
import LoadingScreen from 'core/ui/components/LoadingScreen';
import NotFound from 'core/ui/pages/NotFound';

function CatalogLayout({ children }) {
  const { deliveryDayId } = useParams();
  const { deliveryDay, loading } = useDeliveryDay(deliveryDayId);

  if (!deliveryDay) {
    return (
      <DefaultLayout>
        {loading ? <LoadingScreen /> : <NotFound />}
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <HeaderContent deliveryDay={deliveryDay} />
      {children}
    </DefaultLayout>
  );
}

CatalogLayout.propTypes = {
  children: PropTypes.node,
};

function HeaderContent(props) {
  const { deliveryDay } = props;
  const { userCan } = useUser();

  return (
    <Fragment>
      {userCan.placeOrder() ? (
          <DeliveryDayHeader deliveryDay={deliveryDay} />
        ) : (
          <VisitorHeader deliveryDay={deliveryDay} />
        )
      }
      <CategoryNav deliveryDay={deliveryDay} />
    </Fragment>
  );
}

HeaderContent.propTypes = {
  deliveryDay: PropTypes.object.isRequired,
};

export default CatalogLayout;
