import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { buttonMixin } from 'core/ui/mixins';

const IGNORED_PROPS = /^btn-|^mobile$/;

const RouterLink = styled(Link).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !prop.match(IGNORED_PROPS) && defaultValidatorFn(prop),
})`
  ${buttonMixin}
`;

export default RouterLink;
