import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { init, updatePersonInfo } from 'core/api/rollbar';

class RollbarComponent extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
  };

  componentDidMount() {
    init();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.user && this.props.user.id) !== (prevProps.user && prevProps.user.id)) {
      updatePersonInfo(this.props.user);
    }
  }

  render() {
    return null;
  }
}

export default RollbarComponent;
