import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';

import { Image, Link, Text } from 'core/ui/atoms';
import { EXTERNAL_LINKS } from 'core/api/constants';
import SIGNATURE_IMG from 'core/assets/images/Julie_Nguyen.png';

import { InfoHeader, InfoImage, PageContent, PageInfo, Page } from 'onramp/ui/components';
import ZipCodeForm from 'onramp/ui/components/OutOfZonePage/ZipCodeForm';

import PageImage from 'onramp/assets/images/OutOfZone/main.png';

function OutOfZonePage(props) {
  const { currentUser } = props;

  if (!currentUser) {
    return null;
  }

  const { firstName, primaryAddress: { zipCode } } = currentUser;

  return (
    <Page backgroundColor="#FFF8F0">
      <PageInfo>
        <InfoHeader>Nuts, we don’t deliver to you yet!</InfoHeader>
        <CustomInfoImage src={PageImage} />
      </PageInfo>
      <PageContent>
        <Text text-bold>Sorry, {capitalize(firstName)}. It looks like we don’t deliver to your neck of the woods yet.</Text>

        <FormWrapper>
          <Text no-margin>Try another zip code</Text>
          <Form zipCode={zipCode} />
        </FormWrapper>

        <Text>
          The good news is you can shop our pantry items and other artisanal goods from our <Link href={EXTERNAL_LINKS.shop} rel='noreferrer' target='_blank'>Methodology Shop</Link>.
          We deliver anywhere in the US except Alaska and Hawaii (our delivery guy can't walk that far).
        </Text>

        <ShopNowLink button btn-primary btn-lg href={EXTERNAL_LINKS.shop} rel='noreferrer' target='_blank'>
          Shop Now
        </ShopNowLink>

        <CEO>
          <Image src={SIGNATURE_IMG} />
          <Text>Founder, CEO</Text>
        </CEO>
      </PageContent>
    </Page>
  );
}

OutOfZonePage.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

const CustomInfoImage = styled(InfoImage)`
  ${(p) => p.theme.max('sm')`
    display: none;
  `}
`;

const FormWrapper = styled.div`
  margin-top: 3vh;
`;

const Form = styled(ZipCodeForm)`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 3vh 2vw;
`;

const ShopNowLink = styled(Link)`
  width: fit-content;
  display: inline-block;
  border-radius: 3em;
  padding-right: 1.5em;
  padding-left: 1.5em;

  margin: 3vh 0;
`;

const CEO = styled.div`
  width: 120px;
  margin-top: 40px;
  margin-bottom: 40px;
  > img {
    width: 100%;
  }
  > p {
    margin-top: 3px;
  }
`;

export default OutOfZonePage;
