import React from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import Message from './Message';

function ClosedMessage(props) {
  const { reason, ...rest } = props;
  const title = compact(['No deliveries.', reason]).join(' ');

  return (
    <Message title={title} {...rest} />
  );
}

ClosedMessage.propTypes = {
  reason: PropTypes.string,
};

export default ClosedMessage;
