import { shopMyEnabled } from 'reactAppConstants';

const shopMyURL = 'https://api.shopmy.us/api/order_confirmation';


const requestOrderConfirmation = ({ orderInfo }) => {
  const { query= '', content_id, price } = orderInfo;
  const cookies = document.cookie.split(';').reduce((res, cookie) => ({ ...res, [(cookie.split('=')[0] || '').trim()]: (cookie.split('=')[1] || '').trim() }), {});

  const { sms_click_id } = cookies;
  const code = query;
    if (sms_click_id || code) {
      fetch(shopMyURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          orderAmount: price,
          orderId: content_id,
          clickId: sms_click_id,
          currency: 'USD',
          page_url: window.location.href,
          code,
        }),
      }).then((r) => r.json())
        // eslint-disable-next-line no-unused-vars
        .then((result) => {
          const now = new Date();
          document.cookie = `sms_click_id=;expires=${now.toUTCString()};path=/;`;
          document.cookie = `sms_click_time=;expires=${now.toUTCString()};path=/;`;
        });
    };
};


const Tracker = {
  name: 'Shop My Shelf',
  enabled: !!shopMyEnabled,
  events: {
    subscription: () => {},
    pageView: () => {}, //BE code only
    purchase: (params) => {requestOrderConfirmation(params);},
    lead: () => {},
    addToCart: () => {},
    registration: () => {},
    applyPromoCode: () => {},
    checkoutProgress: () => {},
    error: () => {},
  },
};

export default Tracker;
