import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import _isNumber from 'lodash/isNumber';

import { Container } from 'core/ui/atoms';
import { withAlerts } from 'core/ui/components';
import CloseButton from 'core/ui/components/CloseButton';

const TIMEOUT = 300;
const DELAY = 5000;

@withAlerts
class Alert extends PureComponent {
  static propTypes = {
    alert: PropTypes.object.isRequired,
    alerts: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { alert: { autoClose } } = this.props;

    if (autoClose) {
      const delay = _isNumber(autoClose) ? autoClose : DELAY;
      this.closeTimeout = setTimeout(this.close, delay);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.closeTimeout);
  }

  close = () => {
    const { alert, alerts } = this.props;
    alerts.hide(alert);
    clearTimeout(this.closeTimeout);
  };

  render() {
    const { alert: { message } } = this.props;
    if (!message) return null;

    return (
      <CSSTransition
        appear
        in
        classNames="alert"
        timeout={TIMEOUT}
        unmountOnExit
      >
        <AlertWrapper>
          <AlertContainer>
            <AlertText role="alert">{message}</AlertText>
            <Close onClose={this.close} />
          </AlertContainer>
        </AlertWrapper>
      </CSSTransition>
    );
  }
}

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  will-change: transform;
  padding: 10px;
  min-height: ${(p) => p.theme.layout.headerHeight};
  background: ${(p) => p.theme.colors.secondaryV2};

  &.alert-appear {
    transform: translate(0, -100%);
  }
  &.alert-appear-active {
    transform: translate(0, 0);
    transition: all ${TIMEOUT}ms ease-out;
  }
  &.alert-exit {
    transform: translate(0, 0);
  }
  &.alert-exit-active {
    transform: translate(0, -100%);
    transition: all ${TIMEOUT}ms ease-in;
  }
`;

const AlertContainer = styled(Container)`
  display: flex;
  justify-items: flex-start;
  align-items: center;
`;

const AlertText = styled.span`
  display: flex;
  font-size: 18px;
  flex-grow: 1;
  margin-right: 5vw;
`;

const Close = styled(CloseButton)`
  position: static;
  display: flex;
  margin-left: auto;
`;

export default Alert;
