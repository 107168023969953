import { tiktokPixelEnabled } from 'reactAppConstants';

const trackSubscription = (email, phoneNumber, params) => {
  if (Tracker.enabled) {
    if (phoneNumber) {
      window.ttq.identify({ email, phoneNumber });
    }
    window.ttq.track('Subscribe', params);
  }
};

const trackPageView = () => {
  if (Tracker.enabled) {
    window.ttq.page();
  }
};

const trackAddToCart = (name, variant, price, email, phoneNumber) => {
  if (Tracker.enabled) {
    const tiktokParams = {
      contents: [{
          content_name: name,
          product: 'Onramp Plan',
          content_id: variant,
          price: price,
          quantity: 1,
        }],
      currency: 'USD',
      content_type: 'Onramp Plan',
    };

    if (phoneNumber) {
      window.ttq.identify({ email, phoneNumber });
    }
    window.ttq.track('AddToCart', tiktokParams);
  }
};

const InitiateCheckout = (name, variant, price, step) => {
  if (Tracker.enabled) {
    const tiktokParams = {
      event: 'checkoutProgress',
      currencyCode: 'USD',
      checkout: {
        actionField: { step },
        products: [{
            name,
            product: 'Onramp Plan',
            variant,
            price,
            quantity: 1,
          }],
      },
    };

    window.ttq.track('InitiateCheckout', tiktokParams);
  }
};

const formatPhoneToE164 = (phoneNumber) => phoneNumber ? '+1' + phoneNumber.split('-').join('') : null;

const Tracker = {
  name: 'TikTok Pixel',
  enabled: !!tiktokPixelEnabled,
  events: {
    subscription: (email, phoneNumber, params) => trackSubscription(email, formatPhoneToE164(phoneNumber), params),
    pageView: () => {trackPageView();}, //BE code only works on first load
    purchase: () => {}, //calling subscription manually instead of purchase.
    lead: () => {},
    addToCart: ({ name, variant, price, email, phoneNumber }) => {
      trackAddToCart(name, variant, price, email, formatPhoneToE164(phoneNumber));
    },
    registration: () => {},
    applyPromoCode: () => {},
    checkoutProgress: ({ name, variant, price, step }) => {
      InitiateCheckout(name, variant, price, step);
    },
    error: () => {},
  },
};

export default Tracker;
