import React, { Fragment, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import uniq from 'lodash/uniq';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import useDietLists from 'core/hooks/useDietLists';
import FoodsToAvoidInput from 'core/ui/components/V2/FoodsToAvoidInput';
import { Loading } from 'core/ui/components';
import {  FormGroup, Text, Grid } from 'core/ui/atoms';
import {  Radio, InputGroupLabel } from 'core/ui/atoms/V2';

function DietAndAllergens({ currentUser, updateDietAndAllergiens }) {
  const { loading, diets } = useDietLists();
  const [dietId, setDietId] = useState(currentUser.diet && currentUser.diet.id);
  const [allergyIds, setAllergyIds] = useState((currentUser.allergyTags || []).map((tag) => tag.id));


  useEffect(() => {
    if (isEmpty(dietId) && !isEmpty(diets)) {
      setDietId(first(diets).id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diets]);

  const onFTAChanged = useCallback((allergyId = []) => {
      if (!allergyId) return;
      const foodsToAvoidIds = uniq([...allergyId]);
      setAllergyIds(foodsToAvoidIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allergyIds]);

    useEffect(() => {
      updateDietAndAllergiens(dietId, allergyIds, diets);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dietId, allergyIds, updateDietAndAllergiens]);



  if (loading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <DietTypeRadios selected={dietId} onChange={setDietId} loading={loading} diets={diets} />

      <AllergiesSection allergyIds={allergyIds} onChange={onFTAChanged} />
    </Fragment>
  );
}

DietAndAllergens.propTypes = {
  currentUser: PropTypes.object.isRequired,
  updateDietAndAllergiens: PropTypes.func.isRequired,
};

export default DietAndAllergens;

function DietTypeRadios(props) {
  const { selected, onChange, diets } = props;



  return (
    <CustomFormGroup role="radiogroup" aria-labelledby="whichFood">
      <InputGroupLabel id='whichFood'>Which Food</InputGroupLabel>
      <Grid cols={'1fr 1fr'} gap={'1.2em'}>

      {diets.map((diet) => (
        <CustomRadio
        key={diet.id}
        name="dietId"
        label={diet.name}
        subLabel={diet.description}
        value={diet.id || 0}
        checked={selected === diet.id}
        onChange={() => onChange(diet.id)}
        />
      ))}
      </Grid>
    </CustomFormGroup>
  );
}

DietTypeRadios.propTypes = {
  loading: PropTypes.bool,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  diets: PropTypes.array.isRequired,
};

function AllergiesSection(props) {
  const { allergyIds = [], onChange } = props;

  return (
    <CustomFormGroup role='text' aria-labelledby='allergies'>
      <InputGroupLabel id='allergies'>Any Allergies?</InputGroupLabel>
      <FTASectionDescription>
        We'll make sure your meals don't contain ingredients you're allergic to.
      </FTASectionDescription>
      <CustomFTAInput
        initialFoodsToAvoidIds={allergyIds}
        placeholder="ex. Apricots"
        onChange={onChange}
      />
    </CustomFormGroup>
  );
}

AllergiesSection.propTypes = {
  allergyIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

const FTASectionDescription = styled(Text)`
  text-align: left;
  margin-top: 6px;
`;

const CustomFTAInput = styled(FoodsToAvoidInput)`
`;

const CustomFormGroup = styled(FormGroup)`
  margin-bottom: 1.5em;
`;

const CustomRadio = styled(Radio)`
  margin-bottom: 1em;
`;
