import isEmpty from 'lodash/isEmpty';
import { updateFragment, updateQueryCache } from 'core/api/cache/helpers';
import {
  DeliveryDayFragment,
  OrderFragment,
  SubscriptionFragment,
  UserFragment,
} from 'core/api/apollo/fragments';
import { reportError } from 'core/api/errors';
import { CURRENT_USER, GET_BILLING_INFO } from 'core/api/apollo/queries';

const payloadHasErrors = (key, data) => {
  if (!data) { return false; }
  return !isEmpty(data.errors) || !isEmpty(data[key] && data[key].errors);
};

const cacheUpdater = (key, success) => {
  return (cache, { data }) => {
    try {
      if (!payloadHasErrors(key, data)) {
        success(cache, (data && data[key]) || {});
      }
    } catch(ex) {
      reportError(ex);
    }
  };
};

export const updateUserCache = (cache, { user }, errors = null) => {
  if (errors && errors.length) { return; }
  updateFragment(cache, {
    fragment: UserFragment,
    fragmentName: 'UserFragment',
    id: user.id,
    typename: 'User',
    data: user,
  });
};

export const updateUserQuery = (cache, { user }, errors = null) => {
  if (errors && errors.length) { return; }
  updateQueryCache(cache, {
    query: CURRENT_USER,
    variables: {
      id: 'CURRENT_USER',
    },
    data: { currentUser: { ...user } },
  });
};

export const updateOrderCache = (cache, order, errors) => {
  if (errors.length) { return; }

  updateFragment(cache, {
    fragment: OrderFragment,
    fragmentName: 'OrderFragment',
    id: order.id,
    typename: 'Order',
    data: order,
  });
};

export const updateDeliveryDayCache = (cache, deliveryDay, errors) => {
  if (errors.length) { return; }

  updateFragment(cache, {
    fragment: DeliveryDayFragment,
    fragmentName: 'DeliveryDayFragment',
    id: deliveryDay.id,
    typename: 'DeliveryDay',
    data: deliveryDay,
  });
};

export const updateSubscriptionCache = (cache, subscription, errors) => {
  if (errors.length) { return; }

  updateFragment(cache, {
    fragment: SubscriptionFragment,
    fragmentName: 'SubscriptionFragment',
    id: subscription.id,
    typename: 'Subscription',
    data: subscription,
  });
};

export const updateBillingInfoQuery = (cache, billingAccount, errors = null) => {
  if (errors && errors.length) { return; }
  updateQueryCache(cache, {
    query: GET_BILLING_INFO,
    data: { getBillingInfo: { ...billingAccount } },
  });
};

export default cacheUpdater;
