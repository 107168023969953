import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { ValidatorForm } from 'react-form-validator-core';

import useAlerts from 'core/hooks/useAlerts';
import useForm from 'core/hooks/useForm';
import useUser from 'core/hooks/useUser';

import { SET_DELIVERY_INSTRUCTIONS } from 'core/api/apollo/mutations';

import { FormGroup, Text, ValidatedTextarea } from 'core/ui/atoms';
import { SubmitButton } from 'settings/ui/atoms';
import Panel from 'settings/ui/components/Panel';


function DeliveryInstructions(props) {
  const { currentUser } = useUser();
  const alerts = useAlerts();

  const onSuccess = () => {
    alerts.show('Your changes have been saved! Note: this will not apply to existing orders', { autoClose: true });
  };

  return (
    <Panel title='Delivery Instructions' data-delivery-instructions {...props}>
      <CurrentInstructions>{currentUser.primaryAddress.instructions}</CurrentInstructions>
      <DeliveryInstructionsForm
        onSuccess={onSuccess}
      />
    </Panel>
  );
}


function DeliveryInstructionsForm(props) {
  const { onSuccess } = props;
  const [setDeliveryInstructions] = useMutation(SET_DELIVERY_INSTRUCTIONS);
  const { handleSubmit, formData, onChange, isDirty, isProcessing } = useForm('setDeliveryInstructions', {
    initialData: { instructions: '' },
    onSubmit: setDeliveryInstructions,
    onSuccess,
  });

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      noValidate
      instantValidate={false}
    >
      <FormGroup>
        <ValidatedTextarea
          name="instructions"
          value={formData.instructions}
          onChange={onChange}
          rows={5}
          placeholder="Need to make changes? Enter new delivery instructions here."
        />
      </FormGroup>

      <Panel.Footer>
        <SubmitButton disabled={!isDirty || isProcessing}>
          Apply Changes
        </SubmitButton>
      </Panel.Footer>
    </ValidatorForm>
  );
}

DeliveryInstructionsForm.propTypes = {
  onSuccess: PropTypes.func,
};

const CurrentInstructions = styled(Text)`
  margin-top: 0;
`;

export default DeliveryInstructions;
