import Money from 'core/api/Money';

export const displayDiscount = (coupon) => {
  if (coupon.discountType === 'FIXED_AMOUNT') {
    return Money(coupon.discountAmount).toFormat('$0,0');
  }

  return `${coupon.discountPercentage || 10}%`;
};

