import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useLocation } from 'react-router-dom';
import { GET_PRODUCT_VARIANT } from 'core/api/apollo/queries';

import useDefaultVariant from 'dashboard/hooks/useDefaultVariant';

import { Loading } from 'core/ui/components';
import VariantButtons from 'dashboard/ui/components/ProductPage/ProductSpecs/VariantNavBar';
import ComponentSpecs from 'dashboard/ui/components/ProductPage/ProductSpecs/ComponentSpecs';
import BundleSpecs from 'dashboard/ui/components/ProductPage/ProductSpecs/BundleSpecs';

import { sortVariants } from 'dashboard/ui/helpers/ProductHelper';

function ProductSpecs(props) {
  const { product } = props;
  const variant = useDefaultVariant(product);
  const variants = sortVariants(product);
  const [selected, setSelected] = useState(variant);

  return (
    <Fragment>
      { (variants || []).length > 1 &&
        <VariantButtons selected={selected} variants={variants} onChange={setSelected} />
      }
      <VariantSpecs variantId={selected.id} product={product} />
    </Fragment>
  );
}

ProductSpecs.propTypes = {
  product: PropTypes.object.isRequired,
};

function VariantSpecs(props) {
  const { variantId, product } = props;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const zipcode = searchParams.get('zipcode');
  const { loading, data: { getProductVariant: variant } = {} } = useQuery(GET_PRODUCT_VARIANT, {
    variables: { productVariantId: variantId, zipcode: zipcode },
  });

  if (loading || !variant) {
    return <VariantLoading encircled />;
  }

  return (
    <Fragment>
      {
        variant.components.length > 1 ? (
          <BundleSpecs variant={variant} instructions={product.instructions} />
        ) : (
          <ComponentSpecs
            nutrition={variant.nutrition}
            weightOunces={variant.weightOunces}
            numServings={variant.numServings}
            shelfLife={product.shelfLife}
            ingredients={variant.ingredients}
            allergenWarning={product.allergenWarning}
            instructions={product.instructions}
          />
        )
      }
    </Fragment>
  );
}

VariantSpecs.propTypes = {
  variantId: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
};

const VariantLoading = styled(Loading)`
  margin-top: 10vh;
`;

export default ProductSpecs;
