import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { videoIdVar } from 'core/api/apollo/cache';
import { Button, Flex, Icon, Image } from 'core/ui/atoms';
import PLACEHOLDER_IMG from 'core/assets/images/food_no_img.jpg';

function ProductImage(props) {
  const { image = {} } = props;
  const { largeUrl = PLACEHOLDER_IMG, externalVideoUrl, videoId } = image;

  const openVideo = () => {
    if (externalVideoUrl) {
      window.open(externalVideoUrl, '_blank');
    } else if (videoId) {
      videoIdVar(videoId);
    }
  };

  return (
    <Fragment>
      <StyledImage src={largeUrl} />
      {(externalVideoUrl || videoId) && (
        <PlayButtonFlex>
          <PlayButton unstyled onClick={openVideo}>
            <PlayIcon icon='play3' />
          </PlayButton>
        </PlayButtonFlex>
      )}
    </Fragment>
  );
}

ProductImage.propTypes = {
  image: PropTypes.object.isRequired,
};

const StyledImage = styled(Image)`
  width: 100%;
`;

const PlayButtonFlex = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const PlayButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vw;
  width: 20vw;
  ${(p) => p.theme.min('lg')`
    height: 10vw;
    width: 10vw;
  `};
  background-color: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  border-radius: 50%;
`;

const PlayIcon = styled(Icon)`
  padding-left: 1vw;
  font-size: 7.5vw;
  ${(p) => p.theme.min('lg')`
    font-size: 3.75vw;
  `};
`;

export default ProductImage;
