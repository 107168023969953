import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import replace from 'lodash/replace';
import split from 'lodash/split';

import { Text } from 'core/ui/atoms';

function Instructions(props) {
  const { instructions } = props;

  return useMemo(() => {
    const formattedInstructions = replace(instructions, /STOVETOP|MICROWAVE/g, (match) => `\n${match}`);
    const paragraphs = split(formattedInstructions, '\n').map((paragraph, i) => (
      <Text key={`prep-p-${i}`}>{paragraph}</Text>
    ));

    return (
      <Fragment>
        {paragraphs}
      </Fragment>
    );
  }, [instructions]);
}

Instructions.propTypes = {
  instructions: PropTypes.string.isRequired,
};

export default Instructions;
