import React from 'react';

import { useLocation } from 'react-router-dom';
import Header from 'core/ui/components/Header/Header';
import { EXTERNAL_LINKS } from 'core/api/constants';
import { menuPath } from 'core/api/actions/navigation';
import { NavItem, MobileNavItem } from 'core/ui/atoms';

function VisitorHeader() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isShipping = searchParams.get('delivery');
  const zipcode = searchParams.get('zipcode');
  const queryParams = isShipping? { delivery: isShipping, zipcode  }: {};

  const leftNavItems = () => {
    return [
      <NavItem href={EXTERNAL_LINKS.home} key="nav-home">Home</NavItem>,
      <NavItem to={menuPath(null,queryParams)} key="nav-menu">Sample Menu</NavItem>,
    ];
  };

  const rightNavItems = () => {
    return [
      leftNavItems(),
      <NavItem to="/sign-in" activeClassName='hidden' key="nav-sign-in">Log In</NavItem>,
    ];
  };

  const mobileNavItems = () => {
    return [
      <MobileNavItem href={EXTERNAL_LINKS.home} key="nav-home">Home</MobileNavItem>,
      <MobileNavItem to={menuPath(null,queryParams)} key="nav-menu">Sample Menu</MobileNavItem>,
      <MobileNavItem to="/sign-in" key="nav-sign-in">Log In</MobileNavItem>,
    ];
  };

  return (
    <Header rightNavItems={rightNavItems} mobileNavItems={mobileNavItems} />
  );
}

export default VisitorHeader;
