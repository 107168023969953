import React, { PureComponent } from 'react';

import { Style, StyleGrid, StyleSection } from 'admin/ui/atoms';
import { Text } from 'core/ui/atoms';

const COPY = 'We promptly judged antique ivory buckles for the next prize.';

class Texts extends PureComponent {
  render() {
    return (
      <StyleSection title='Text'>
        <StyleGrid cols='repeat(3, 1fr)'>
          <Style name='P'>
            <Text>{COPY}</Text>
          </Style>

          <Style name='P' caption='bold'>
            <Text text-bold>{COPY}</Text>
          </Style>

          <Style name='P' caption='accent'>
            <Text text-accent>{COPY}</Text>
          </Style>

          <Style name='P' caption='italic'>
            <Text text-italic>{COPY}</Text>
          </Style>

          <Style name='P' caption='muted'>
            <Text text-color='muted'>{COPY}</Text>
          </Style>

          <Style name='P' caption='spaced'>
            <Text text-spaced>{COPY}</Text>
          </Style>
        </StyleGrid>
      </StyleSection>
    );
  }
}

export default Texts;
