import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { scrollTop } from 'core/api/actions/scrolling';

import withAlerts from 'core/ui/components/withAlerts';

@withAlerts
class Page extends Component {
  static propTypes = {
    alerts: PropTypes.object.isRequired,
    scrollTop: PropTypes.bool,
    children: PropTypes.node,
  };

  componentDidMount() {
    this.props.alerts.clear();
    if (this.props.scrollTop) {
      scrollTop();
    }
  }

  render() {
    return <PageCont {...this.props}>{this.props.children}</PageCont>;
  }
}

const PageCont = styled.section`
  display: block;
  min-height: calc(100vh - ${(p) => p.theme.layout.headerHeight});
`;

export default Page;
