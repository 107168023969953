// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import hoistNonReactStatics from 'hoist-non-react-statics';

// core
import withAlerts from 'core/ui/components/withAlerts';
import { ERROR_MESSAGES } from 'core/api/constants';
import { reportError } from 'core/api/errors';

function handlesErrors(WrappedComponent) {

  hoistNonReactStatics(Component, WrappedComponent);

  return compose(withAlerts)(class extends Component {
    static displayName = 'handlesErrorsHOC';

    static propTypes = {
      alerts: PropTypes.object.isRequired,
    };

    displayApolloResponseErrors = (errors, displayOnly = false) => {
      const { alerts: { show: showAlert }  } = this.props;
      errors.forEach((error) => {
        showAlert(error.message || error);
        if(!displayOnly) {
          reportError(error);
        }
      });
    };

    handleGenericApolloError = (error) => {
      if (error && error.message) {
        this.displayApolloResponseErrors([error.message]);
      } else {
        this.displayApolloResponseErrors([ERROR_MESSAGES.generic]);
      }
    };

    render() {
      const { alerts, ...rest } = this.props;

      return (
        <WrappedComponent
          {...rest}
          displayApolloResponseErrors={this.displayApolloResponseErrors}
          handleGenericApolloError={this.handleGenericApolloError}
        />
      );
    }
  });
}

export default handlesErrors;
