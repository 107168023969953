import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';

import { GET_BACKGROUND_JOB } from 'core/api/apollo/queries';

const POLL_INTERVAL = 500;

@graphql(GET_BACKGROUND_JOB, {
  skip: (props) => !props.backgroundJobId,
  options: (props) => ({
    variables: { backgroundJobId: props.backgroundJobId },
    pollInterval: POLL_INTERVAL,
  }),
})
class BackgroundJobStatusCheck extends PureComponent {
  static propTypes = {
    backgroundJobId: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    data: PropTypes.object,
  }

  componentDidUpdate() {
    const { onSuccess, onError, data = {} } = this.props;
    const { loading, getBackgroundJob = {}, error, stopPolling } = data;

    if (loading) { return null; }

    const { status, errors: jobErrors = [] } = getBackgroundJob;
    const errors = _compact([error, ...jobErrors]);

    if (status === 'COMPLETE' && _isEmpty(errors)) {
      stopPolling();
      return onSuccess();
    }

    if (status === 'FAILED' || !_isEmpty(errors)) {
      stopPolling();
      return onError(errors);
    }

    return null;
  }

  render() {
    return null;
  }
}

export default BackgroundJobStatusCheck;
