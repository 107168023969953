import { theme } from 'core/ui/theme';

export const toggleBodyScrolling = (disabled) => {
  // can't use toggle because of IE 10
  if (disabled) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
};

export const getStripeInputStyleProps = () => ({
  style: {
    base: {
      fontSize: theme.typography.sizes.base, // NOTE: Stripe wants a size in pixels
      color: theme.typography.colors.primary,
      fontFamily: theme.typography.familyText,
      '::placeholder': {
        color: theme.typography.colors.muted,
      },
    },
    invalid: {
      color: theme.typography.colors.primary,
    },
  },
});

export const generateRandomId = () => Math.random().toString(12).substring(2,10);

export const lamePluralize = (word, count, plural = null) => {
  if (count === 1) {
    return word;
  }
  return plural ? plural : `${word}s`;
};
