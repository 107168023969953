import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import CatalogPage from 'dashboard/ui/pages/Catalog/Page';
import ProductList from 'dashboard/ui/components/CatalogPage/ProductList';
import ProductGroup from 'dashboard/ui/helpers/ProductGroup';
import useProductList from 'dashboard/hooks/useProductList';

function CategoryPage(props) {
  //TODO: remove once sure its no longer required
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);

  // const isShipping = searchParams.get('delivery');
  // const onlyReset = !!isShipping;

  return (
    <CatalogPage>
      <Content {...props} onlyReset={false} />
    </CatalogPage>
  );
}

function Content(props) {
  const { match: { params: { deliveryDayId, categoryId: productCategoryId } }, ...rest } = props;

  const { loading, products } = useProductList({ variables: { deliveryDayId, productCategoryId, onlyReset: props.onlyReset } });

  const subcategories = uniqBy(products.map(({ line: { subcategory } }) => subcategory), 'id');
  const groupedProducts = groupBy(products, 'line.subcategory.id');
  const productGroups = subcategories.map((subcategory) => (new ProductGroup(subcategory.name, subcategory.position, groupedProducts[subcategory.id])));

  return (
    <ProductList
      key={`product-list-${productCategoryId}`}
      loading={loading}
      productGroups={productGroups}
      {...rest}
      addEnabled={true}
    />
  );
}

Content.propTypes = {
  match: PropTypes.object.isRequired,
  deliveryDay: PropTypes.object,
  status: PropTypes.object,
  order: PropTypes.object,
  onlyReset: PropTypes.bool,
};

export default CategoryPage;
