import React from 'react';
import PropTypes from 'prop-types';

import { IndicesList } from 'dashboard/ui/atoms';

function DietTagList(props) {
  const { dietTags: tags, ...rest } = props;
  const dietTags = tags.filter((f) => f.userFacing);

  return <IndicesList indices={dietTags.map((f) => f.name)} {...rest} />;
}

DietTagList.propTypes = {
  dietTags: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DietTagList;
