import React from 'react';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';

import useUser from 'core/hooks/useUser';
import useProductTags from 'dashboard/hooks/useProductTags';

import { menuFilterPath, menuReorderPath, signUpPath } from 'core/api/actions/navigation';
import { Container, Grid } from 'core/ui/atoms';
import Loading from 'core/ui/components/Loading';
import CatalogPage from 'dashboard/ui/pages/Catalog/Page';
import {
  FriendsgivingHeroComponent,
  ThanksgivingHeroComponent,
  ImmunityHeroComponent,
  NewHeroComponent,
  PopularHeroComponent,
  PromoHeroComponent,
  ReorderHeroComponent,
  GetStartedHeroComponent,
} from 'dashboard/ui/components/CatalogPage/FilterHeroes';

function HomePage() {
  const { deliveryDayId } = useParams();
  const { productTags: tags, loading } = useProductTags(deliveryDayId);
  const { userCan } = useUser();

  if (loading) {
    return <CatalogPage><Loading /></CatalogPage>;
  }

  const friendsgivingTag = tags.find((t) => t.zone === 'friendsgiving');
  const thanksgivingTag = tags.find((t) => t.zone === 'thanksgiving');
  const immunityTag = tags.find((t) => t.zone === 'immunity');
  const newTag = tags.find((t) => t.zone === 'new');
  const popularTag = tags.find((t) => t.zone === 'popular');

  return (
    <CatalogPage>
      <HomeContainer>
        {friendsgivingTag && <FriendsgivingHero path={menuFilterPath(deliveryDayId, friendsgivingTag.id)} />}
        {thanksgivingTag && <ThanksgivingHero path={menuFilterPath(deliveryDayId, thanksgivingTag.id)} />}
        {immunityTag && <ImmunityHero path={menuFilterPath(deliveryDayId, immunityTag.id)} />}
        {newTag && <NewHero path={menuFilterPath(deliveryDayId, newTag.id)} />}
        <HeroGrid>
          {popularTag && <PopularHero path={menuFilterPath(deliveryDayId, popularTag.id)} />}
          <PromoHero />
          { userCan.placeOrder() ?
              <ReorderHero path={menuReorderPath(deliveryDayId)} />
            :
              <GetStartedHero path={signUpPath()} />
          }
        </HeroGrid>
      </HomeContainer>
    </CatalogPage>
  );
}

const HomeContainer = styled(Container)`
  padding-top: 3vh;
  padding-bottom: 3vh;
`;

const HeroGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 40vh 10vh;
  grid-gap: calc(${(p) => p.theme.layout.gridGap} * 2);

  ${(p) => p.theme.max('sm')`
    grid-template-columns: 1fr;
    grid-template-rows: 40vh 40vh 10vh;
  `}
`;

const HeroStyles = css`
  a div {
    max-width: 350px;
  }
`;

const FriendsgivingHero = styled(FriendsgivingHeroComponent)`
  margin-bottom: calc(${(p) => p.theme.layout.gridGap} * 2);
  ${HeroStyles}
`;

const ThanksgivingHero = styled(ThanksgivingHeroComponent)`
  margin-bottom: calc(${(p) => p.theme.layout.gridGap} * 2);
  ${HeroStyles}
`;

const ImmunityHero = styled(ImmunityHeroComponent)`
  margin-bottom: calc(${(p) => p.theme.layout.gridGap} * 2);
  ${HeroStyles}
`;

const NewHero = styled(NewHeroComponent)`
  margin-bottom: calc(${(p) => p.theme.layout.gridGap} * 2);
  ${HeroStyles}
`;

const PopularHero = styled(PopularHeroComponent)`
  grid-row: 1;
  ${HeroStyles}
`;

const PromoHero = styled(PromoHeroComponent)`
  grid-row: 2;

  ${(p) => p.theme.max('sm')`
    grid-row: 3;
  `}
`;

const ReorderHero = styled(ReorderHeroComponent)`
  grid-row: span 2;

  ${(p) => p.theme.max('sm')`
    grid-row: 2;
  `}
  ${HeroStyles}
`;

const GetStartedHero = styled(GetStartedHeroComponent)`
  grid-row: span 2;

  h1 {
    font-size: 48px;
    ${(p) => p.theme.max('md')`
      font-size: ${(p) => p.theme.typography.sizes.h1};
    `}
    ${(p) => p.theme.max('xs')`
      font-size: ${(p) => p.theme.typography.sizes.h2};
    `}
  }

  ${(p) => p.theme.max('sm')`
    grid-row: 2;
  `}
  ${HeroStyles}
`;

export default HomePage;
