import moment from 'moment';

export const FORMATS = {
  longDate: 'dddd, MMM D, Y',
  shortDate: 'MM/DD/YYYY',
  tinyDate: 'M/D',
  dayOfWeek: 'dddd',
  deliveryDate: 'ddd, MMM Do',
  deliveryCutoffDate: 'h:mm A dddd, MMM Do',
  deliveryCutoffTime: 'h:mm A dddd',
  deliveryDateUpdated: 'dddd, MMMM Do',
  isoShort: 'YYYY-MM-DD',
};

export const compareDates = (a, b) => moment(a).diff(moment(b));

export const displayDate = (date, format) => moment(date).format(FORMATS[format] || format);
