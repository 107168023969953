// FIXME: refactor scrolling navbar into a component so this and the menu nav bar can share it
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { NavLink, useRouteMatch } from 'react-router-dom';

import useUser from 'core/hooks/useUser';

import { Flex, Container, List, ListItem } from 'core/ui/atoms';

const LINKS = [
  { name: 'Account Info', path: '/settings/account-info', isVisible: () => true },
  { name: 'Subscription', path: '/settings/subscription', isVisible: (userCan) => userCan.manageSubscription() },
  { name: 'Food Preferences', path: '/settings/food-preferences', isVisible: () => true },
  { name: 'Billing', path: '/settings/billing', isVisible: (userCan) => userCan.manageBillingInfo() },
  { name: 'Contact Us', path: '/settings/contact', isVisible: () => true },
];

function NavBar() {
  const { path: pageId } = useRouteMatch();
  const { userCan } = useUser();

  const visibleLinks = LINKS.filter((link) => link.isVisible(userCan));

  return useMemo(() => {
    return (
      <NavWrapper>
        <NavContainer>
          <NavBarList unstyled horizontal centered >
            {
              visibleLinks.map(({ name, path }) => (
                <NavBarItem key={`settings-nav-${pageId}-${name}`}>
                  <NavBarLink to={path} exact>
                    {name}
                  </NavBarLink>
                </NavBarItem>
              ))
            }
          </NavBarList>
        </NavContainer>
      </NavWrapper>
    );
  }, [pageId, visibleLinks]);
}

const NavWrapper = styled(Flex)`
  position: sticky;
  background: ${(p) => p.theme.colors.grays.lightest};
  z-index: ${(p) => p.theme.zIndex.headerFixed - 1};
  top: calc(${(p) => p.theme.layout.headerHeight} - 1px);
  width: 100%;
  align-items: center;
  min-height: ${(p) => p.theme.layout.headerHeight};
`;

const BookendStyles = css`
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 5vw;
  ${(p) => p.theme.min('md')`
    width: 3vw;
  `};
  z-index: 1000;
`;

const NavContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;

  position: relative;
  &:before {
    ${BookendStyles}
    left: 0;
    background: linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 50%, rgba(247,247,247,0) 100%);
  }

  &:after {
    ${BookendStyles}
    right: 0;
    background: linear-gradient(90deg, rgba(247,247,247,0) 0%, rgba(247,247,247,1) 50%, rgba(247,247,247,1) 100%);
  }
`;

const NavBarList = styled(List)`
  overflow: auto;
  flex-wrap: nowrap;
  justify-content: initial;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

const NavBarItem = styled(ListItem)`
  white-space: nowrap;
  padding: 10px 20px 0 20px;
  ${(p) => p.theme.max('md')`
    padding-right: 10px;
    padding-left: 10px;
  `};

  &:first-child {
    padding-left: 5vw;
    ${(p) => p.theme.min('md')`
      padding-left: 3vw;
    `};
  }

  &:last-child {
    padding-right: 5vw;
    ${(p) => p.theme.min('md')`
      padding-right: 3vw;
    `};
  }
`;

const NavBarLink = styled(NavLink)`
  position: relative;
  font-size: ${(p) => p.theme.typography.sizes.h6};
  text-decoration: none;
  display: block;
  color: ${(p) => p.theme.typography.colors.primary};

  &.active, &:hover {
    &:after {
      position: absolute;
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      left: 0;
      bottom: 0;
      background: ${(p) => p.theme.colors.primary};
    }
  }
`;

export default NavBar;
