import Money from 'core/api/Money';

export const APPVERSION = 2;

export const FOOD_TYPES = [
  { name: 'Breakfast', value: 'breakfast' },
  { name: 'Lunch', value: 'lunch' },
  { name: 'Dinner', value: 'dinner' },
  { name: 'Snacks', value: 'snacks' },
];

export const NUM_DAY_OPTIONS = [
  { value: 2, min: 'Mon', max: 'Tue' },
  { value: 3, min: 'Mon', max: 'Wed' },
  { value: 5, min: 'Mon', max: 'Fri' },
  // { value: 7, min: 'Mon', max: 'Sun' },
];

export const NUM_DAY_OFFSETS = {
  CARRIER: 0,
  SHIPPING: 0,
};

export const DAY_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const PROGRAM_FOOD_OPTIONS = [
  {
    id: '1',
    name: 'Lunch, Dinner, Snack, Probiotic Tonics, Ceremonial Matcha (No Breakfast)',
    default: true,
    position: 1,
    tags: ['5 Days'],
  },
  {
    id: '2',
    name: 'Breakfast, Lunch, Dinner, Snack, Probiotic Tonics, Ceremonial Matcha',
    position: 2,
    tags: ['4 Days', '3 Days', '2 Days', '1 Day'],
  },
];

const save10 = ' - save 10%';

export const PROGRAM_LENGTHS = [
  { name: '1 Week', value: '1', default: false },
  { name: '2 Weeks', value: '2', default: false },
  { name: '3 Weeks' + save10, value: '3', default: true },
  { name: '4 Weeks' + save10, value: '4', default: false },
  { name: '6 Weeks' + save10, value: '6', default: false },
  { name: '8 Weeks' + save10, value: '8', default: false },
];

export const FOODS_TO_AVOID_NUM_TAGS_FOR_WARNING = 4;

export const ESTIMATE_MIN_AMOUNT = 100;
export const ESTIMATE_ERROR_AMOUNT = 2000;

// export const DEFAULT_PROMO_CODE = { code: 'WELCOME10', amount: 10, unit: '%' }; // FIXME: Temp Disabled default promo code
export const DEFAULT_PROMO_CODE = {};

export const PROGRAM_SCHEDULE_VALUES = {
  COURIER: 'courier',
  SHIPPING: 'shipping',
  PRE_SUBSCRIBED: 'pre_subscribed',
};

// =============V2
export const PROGRAM_TYPE_VALUES = {
  COURIER: 'courier_program',
  SHIPPING: 'signature_program',
  TRANSFORMATION_TRIBE: 'transformation_tribe',
  PRE_SUBSCRIBED: 'pre_subscribed',
  POST_PARTUM_HOTMOM: 'post_partium_hotmom',
};



export const NUM_DAY_OPTIONS_V2 = [
  { value: 2, min: 'Mon', max: 'Tue', description: 'Mon & Tue' },
  { value: 3, min: 'Mon', max: 'Wed', description: 'Mon - Tue - Wed' },
  { value: 5, min: 'Mon', max: 'Fri', description: 'Mon - Fri' },
];

// -----------

// const a = {
//   signature_program: {

//   transformation_tribe: {
//     meals: [
//       {
//         name: 'Breakfast | Lunch | Dinner',
//         days: [1,2,3,4,5],
//       },
//       {
//         name: 'Lunch | Dinner',
//         days: [1,2,3,4,5],
//       },
//       {
//         name: 'Digital Education only',
//         days: [],
//       },
//     ],
//     size: [
//       'Standard', 'Large',
//     ],
//     weeks_of_food: [4,8],
//   },
// } };

export const PROGRAM_SCHEDULES_KEYS = {
  WEEKLY_SUBSCRIPTION: 'weekly_subscription',
  ONE_TIME_PURCHASE: 'one_time_program',
};
const PROGRAM_SCHEDULE_OPTIONS =  {
  weekly_subscription:{
  label: 'Weekly Subscription',
  value: PROGRAM_SCHEDULES_KEYS.WEEKLY_SUBSCRIPTION,
  description: 'Eat healthy more consistently, save up to 15%, fully customize your order, access full menu, cancel anytime',
  },
  one_time_program: {
  label: 'One-Time Program',
  value: PROGRAM_SCHEDULES_KEYS.ONE_TIME_PURCHASE,
  description: 'Curated programs for optimal results',
},
};

export const PROGRAM_TYPE_OPTIONS = { courier_program:
  {
    label: 'Build Your Own Box',
    value: PROGRAM_TYPE_VALUES.COURIER,
    description: 'Save up to 15%, swap meals access full menu',
    success: true,
    disable: false,
    schedule: [ {
      ...PROGRAM_SCHEDULE_OPTIONS.weekly_subscription,
    },{
      ...PROGRAM_SCHEDULE_OPTIONS.one_time_program,
      disable: true,
      // hidden: true,
    },
  ],
  },
  signature_program:{
    label: 'Signature Program',
    value: PROGRAM_TYPE_VALUES.SHIPPING,
    description:
      'Avg 30-50g protein per meal, calorie-controlled', // FIXME: swap
    disable: false,
    schedule: [ {
      ...PROGRAM_SCHEDULE_OPTIONS.weekly_subscription,
      description: 'Eat healthy more consistently, save 15%, cancel anytime',
      // disable: true,
    },{
      ...PROGRAM_SCHEDULE_OPTIONS.one_time_program,
    },
  ],
},

transformation_tribe:  {
    label: 'Transformation Tribe',
    value: PROGRAM_TYPE_VALUES.TRANSFORMATION_TRIBE,
    description:
      '8-week weight loss group coaching program',
    disable: false,
    schedule: [{
      ...PROGRAM_SCHEDULE_OPTIONS.one_time_program,
    },
  ],
  },
  pre_subscribed: {
    label: 'HIDDEN Signature Program',
    value: PROGRAM_TYPE_VALUES.PRE_SUBSCRIBED,
    description:
      'Nourishing, oven-ready dinners that serve four. Free protein cookie dough in every order.',
    // disable: true,
    hidden: true,
    schedule: [{
      ...PROGRAM_SCHEDULE_OPTIONS.one_time_program,
    }],
  },
  post_partium_hotmom: {
    label: 'Haute Mom',
    value: PROGRAM_TYPE_VALUES.POST_PARTUM_HOTMOM,
    description:
      'Postpartum nourishment to help new moms and newborns feel their best.',
    disable: false,
    // hidden: false,
    schedule: [
    {
      ...PROGRAM_SCHEDULE_OPTIONS.weekly_subscription,
      description: 'Eat healthy more consistently, save 15%, cancel anytime',
      // disable: true,
    },
    {
      ...PROGRAM_SCHEDULE_OPTIONS.one_time_program,
    },
  ],
  },
};


// export const priceLabelWithDiscount = (price, numWeek) => {
//   const weeks = Number(numWeek); // Convert numWeek to a number

//   let total = Money(price).multiply(weeks);

//   if (weeks > 3) {
//     total = total.percentage(90); // Apply 10% discount
//   }

//   return total.toFormat();
// };

export const priceLabel = (price, numWeek, isDigitalOnly=false, isWeeklyProgram = false) => {
  const weeks = Number(numWeek); // Convert numWeek to a number

  let total = Money(price).multiply(weeks);
  if(isDigitalOnly || isWeeklyProgram) {
    total = Money(price);
  }

  return total.toFormat();
};
