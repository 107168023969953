import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _kebabCase from 'lodash/kebabCase';
import _sortBy from 'lodash/sortBy';

import { H4 } from 'core/ui/atoms';
import ProductItem from 'dashboard/ui/components/CatalogPage/ProductList/Item';

function ProductSection(props) {
  const { title, products = [], deliveryDay, status, order, addEnabled } = props;
  const lineItems = (order && order.lineItems) || [];

  if (!products.length) {
    return null;
  }

  const sortedProducts = _sortBy(products, ['line.subcategory.category.position', 'line.subcategory.position', 'line.position']);

  return (
    <ProductSectionWrapper key={`product-list-${title}`}>
      {title && <H4>{title}</H4>}
      <ProductsGrid>
        {
          sortedProducts.map((product) => (
            <ProductItem
              key={`${_kebabCase(title)}-product-${product.id}`}
              deliveryDay={deliveryDay}
              product={product}
              order={order}
              lineItems={lineItems.filter((item) => item.product.id === product.id)}
              addEnabled={!status.locked && addEnabled}
            />
          ))
        }
      </ProductsGrid>
    </ProductSectionWrapper>
  );
}

ProductSection.propTypes = {
  title: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  deliveryDay: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  order: PropTypes.object,
  addEnabled: PropTypes.bool,
};

const ProductSectionWrapper = styled.div`
  margin-bottom: 4em;
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  grid-gap: calc(${(p) => p.theme.layout.gridGap} * 2);

  ${(p) => p.theme.max('sm')`
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  `};
`;

export default ProductSection;
