import styled from 'styled-components';

const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${(p) => p.$inverse ? p.theme.modals.inverseBackdropColor : p.theme.modals.backdropColor};
  backdrop-filter: blur(4px);
`;

export default Backdrop;
