import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useQuery } from '@apollo/client';
import { useRouteMatch, NavLink, useLocation, Redirect } from 'react-router-dom';
import sortBy from 'lodash/sortBy';

import { menuPath, menuCategoryPath, menuFilterPath } from 'core/api/actions/navigation';
import { LIST_PRODUCT_CATEGORIES, LIST_PRODUCT_TAGS } from 'core/api/apollo/queries';
import { Flex, Container, List, ListItem } from 'core/ui/atoms';

function ProductListNavBar(props) {
  const { deliveryDay } = props;

  const location = useLocation();
  const match = useRouteMatch();
  const { params: { categoryId: pageId } } = match;
  const searchParams = new URLSearchParams(location.search);

  const isShipping = searchParams.get('delivery');

  const {
    data: { listProductCategories: categories = [] } = {},
  } = useQuery(LIST_PRODUCT_CATEGORIES, { variables: { deliveryDayId: deliveryDay.id } });
  const {
    data: { listProductTags: filters = [] } = {},
  } = useQuery(LIST_PRODUCT_TAGS, { variables: { deliveryDayId: deliveryDay.id } });

  const promoFilters = sortBy(filters.filter((filter) => filter.promo), ['tagGroup.position', 'position']);

  const programCategory = categories.find((c) =>{
    return c.name.toLowerCase().includes('programs');
  });

  if(isShipping && programCategory) {
  const zipcode = searchParams.get('zipcode');
  const queryParams = isShipping? { delivery: isShipping, zipcode  }: {};

    return (
      <Redirect to={menuCategoryPath(deliveryDay.id, programCategory.id, queryParams)}/>
    );
  }

  return (
    <NavWrapper>
      <NavContainer>
        <CategoriesList unstyled horizontal centered >
          <CategoryItem key={`category-nav-${pageId}`}>
            <CategoryLink to={menuPath(deliveryDay.id)} exact>
              Our Picks
            </CategoryLink>
          </CategoryItem>

          {promoFilters.map((filter) => (
            <CategoryItem key={`filter-nav-${pageId}-${filter.id}`}>
              <CategoryLink to={menuFilterPath(deliveryDay.id, filter.id)}>{filter.name}</CategoryLink>
            </CategoryItem>
          ))}

          {categories.map((category) => (
            <CategoryItem key={`category-nav-${pageId}-${category.id}`}>
              <CategoryLink to={menuCategoryPath(deliveryDay.id, category.id)}>{category.name}</CategoryLink>
            </CategoryItem>
          ))}
        </CategoriesList>
      </NavContainer>
    </NavWrapper>
  );
}

ProductListNavBar.propTypes = {
  deliveryDay: PropTypes.object.isRequired,
};

const NavWrapper = styled(Flex)`
  position: sticky;
  background: white;
  z-index: ${(p) => p.theme.zIndex.headerFixed - 1};
  top: calc(${(p) => p.theme.layout.headerHeight} * 2 - 1px);
  width: 100%;
  align-items: center;
  min-height: ${(p) => p.theme.layout.headerHeight};
  border-bottom: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.grays.light};
`;

const BookendStyles = css`
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 5vw;
  ${(p) => p.theme.min('md')`
    width: 3vw;
  `};
  z-index: 1000;
`;

const NavContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding: 0 !important;

  position: relative;
  &:before {
    ${BookendStyles}
    left: 0;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
  }

  &:after {
    ${BookendStyles}
    right: 0;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
  }
`;

const CategoriesList = styled(List)`
  overflow: auto;
  flex-wrap: nowrap;
  justify-content: initial;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

const CategoryItem = styled(ListItem)`
  white-space: nowrap;
  padding: 10px 20px 0 20px;
  ${(p) => p.theme.max('md')`
    padding-right: 10px;
    padding-left: 10px;
  `};

  &:first-child {
    padding-left: 5vw;
    ${(p) => p.theme.min('md')`
      padding-left: 3vw;
    `};
  }

  &:last-child {
    padding-right: 5vw;
    ${(p) => p.theme.min('md')`
      padding-right: 3vw;
    `};
  }
`;

const CategoryLink = styled(NavLink)`
  position: relative;
  font-size: ${(p) => p.theme.typography.sizes.h5};
  font-weight: bold;
  text-decoration: none;
  display: block;
  color: ${(p) => p.theme.typography.colors.primary};

  &.active, &:hover {
    &:after {
      position: absolute;
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      left: 0;
      bottom: 0;
      background: ${(p) => p.theme.colors.primary};
    }
  }
`;

export default ProductListNavBar;
