import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Drop } from 'core/ui/animations';

import { H3, Icon, List, Text } from 'core/ui/atoms';

import InfoSection from 'dashboard/ui/components/ProductPage/ProductSpecs/InfoSection';
import ComponentSpecs from 'dashboard/ui/components/ProductPage/ProductSpecs/ComponentSpecs';
import AllergenWarning from 'dashboard/ui/components/ProductPage/ProductSpecs/AllergenWarning';
import Instructions from 'dashboard/ui/components/ProductPage/ProductSpecs/Instructions';

function BundleSpecs(props) {
  const { variant, instructions } = props;
  const { allergenWarning } = variant;

  return (
    <Fragment>
      <InfoSection title='Includes'>
        <List unstyled>
          {variant.components.map((component) => (
            <BundleComponent
              key={component.id}
              component={component}
            />
          ))}
        </List>
      </InfoSection>

      { allergenWarning && <AllergenWarning noMargin bold/> }

      { instructions && instructions.length > 0 &&
        <InfoSection title='Instructions'>
          <Instructions instructions={instructions} />
        </InfoSection>
      }
    </Fragment>
  );
}

BundleSpecs.propTypes = {
  variant: PropTypes.object.isRequired,
  instructions: PropTypes.string,
};

function BundleComponent(props) {
  const { component, ...rest } = props;
  const [open, setOpen] = useState(false);

  return (
    <ListItem data-bundle-component-id={component.id} {...rest}>
      <Header onClick={() => setOpen(!open)} role="button" tabIndex={0} aria-expanded={open}>
        <Quantity data-bundle-component-qty={component.quantity}>{component.quantity} x</Quantity>
        <div>
          <Title>{component.name}</Title>
          <Subtitle>{component.subtitle}</Subtitle>
        </div>
        <CaretColumn>
          <Caret icon='caret_down' $open={open} />
        </CaretColumn>
      </Header>
      <Content show={open}>
        <ComponentSpecs
          nutrition={component.nutrition}
          numServings={component.numServings}
          weightOunces={component.weightOunces}
          shelfLife={component.shelfLife}
          ingredients={component.ingredients}
          instructions={component.instructions}
        />
      </Content>
      <span className='sr-only'>&nbsp;click for nutritional information</span>
    </ListItem>
  );
}

BundleComponent.propTypes = {
  component: PropTypes.object.isRequired,
};

const ListItem = styled(List.Item)`
  position: relative;
  overflow: hidden;
  border-bottom: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.borders.colorLight};
  padding-bottom: 1vh;
`;

const Header = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  cursor: pointer;
  position: relative;
  padding: 3vh 0 0 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
`;

const Quantity = styled(Text)`
  margin: 0 1em 0 0;
  color: ${(p) => p.theme.typography.colors.muted};
  font-weight: bold;
  line-height: 1.5em;
  min-width: 35px;
`;

const CaretColumn = styled.div`
  margin-left: auto;
`;

const Caret = styled(Icon)`
  display: inline-block;
  transition: transform 0.25s ease-in-out;
  transform-style: preserve-3d;
  transform: rotateX(${(p) => p.$open ? '180deg' : '0deg' });
`;

const Content = styled(Drop)`
  z-index: -1;
`;

const Title = styled(H3)`
  line-height: 1em;
  margin: 0;
`;

const Subtitle = styled(Text)`
  color: ${(p) => p.theme.typography.colors.muted};
  font-size: ${(p) => p.theme.typography.sizes.small};
  margin: 0;
`;

export default BundleSpecs;
