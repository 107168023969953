import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { graphql } from '@apollo/client/react/hoc';
import isEmpty from 'lodash/isEmpty';

import Alert from 'core/ui/components/Alert';
import { GET_ALERTS } from 'core/api/apollo/queries';

@graphql(GET_ALERTS)
class Alerts extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      alerts: PropTypes.arrayOf(PropTypes.object),
    }),
  };

  render() {
    const { data: { alerts } } = this.props;

    if (isEmpty(alerts)) { return null; }

    return ReactDOM.createPortal(
      (<AlertsContainer>
        {alerts.map((alert) => <Alert key={alert.id} alert={alert} />)}
      </AlertsContainer>),
      document.getElementById('alert-root'));
  }
}


const AlertsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${(p) => p.theme.zIndex.alert};
`;

export default Alerts;
