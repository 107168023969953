import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from 'core/ui/atoms';

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="8" fill="#36443A" />
    <path d="M5 5L11 11" stroke="#BBD7A7" strokeLinecap="round" />
    <path d="M11 5L5 11" stroke="#BBD7A7" strokeLinecap="round" />
  </svg>
);

class Tag extends PureComponent {
  static propTypes = {
    onRemove: PropTypes.func.isRequired,
    tagId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  onRemove = () => this.props.onRemove(this.props.tagId);

  render() {
    return (
      <TagCont data-tag-id={this.props.tagId}>
        <Title>{this.props.title}</Title>
        <RemoveButton btn-outline onClick={this.onRemove} data-tag-remove-button>
          <RemoveIcon icon="close" />
        </RemoveButton>
      </TagCont>
    );
  }
}

const TagCont = styled.li`
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #bbd7a7;
  padding: 0.6em 1em;
  margin: 0 0.9em 0.9em 0;
  position: relative;
`;

const Title = styled.span`
  margin: 0 0.5em;
`;

const RemoveButton = styled(Button)`
  position: absolute;
  padding: 0;
  border: none;
  padding: 0;
    top: 0;
    right: 0;
    transform: translate(50%, -40%);

    &:hover {
    background: transparent;
    }
`;

const RemoveIcon = styled(Icon)`
  position: absolute;
  font-size: 0.7em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default Tag;
