import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import styled, { css } from 'styled-components';

import { isGuestEmail } from 'onramp/helpers';
import { ReactComponent as UserSVG } from 'core/assets/images/V2/icons/User.svg';
import { GET_MENU_OPENED } from 'core/api/apollo/queries';
import { Box, Container, Flex, WordMark, RouterLink, NavItem } from 'core/ui/atoms';
import MobileNavClose from 'core/ui/components/Header/MobileNavClose';
import useUser from 'core/hooks/useUser';
import Nav from 'core/ui/components/Header/Nav';
import MobileNav from 'core/ui/components/Header/MobileNav';
import LogoutButton from 'core/ui/components/LogoutButton';

function Header(props) {
  const { leftNavItems = (() => ([])), rightNavItems = (() => ([])), mobileNavItems = (() => ([])) } = props;
  const { data: { isMenuOpen } = {} } = useQuery(GET_MENU_OPENED);
  const { currentUser } = useUser();

  const renderLeftNav = (currentUser) => (<Nav navItems={leftNavItems(currentUser)} />);

  const renderRightNav = (currentUser) => (<Nav navItems={rightNavItems(currentUser)} />);

  const renderMobileNav = (currentUser, opened) => {
    return <MobileNav navItems={mobileNavItems(currentUser)} opened={opened} />;
  };

  const LoginButton = () => {
    if (currentUser && !isGuestEmail(currentUser.email)) {
      return (
        <BoxCenteredY hiddenMinSM>
          <LogoutButton reload={true} key="nav-sign-in">Edit Zipcode</LogoutButton>
        </BoxCenteredY>
      );
    } else {
      return (
        <BoxCenteredY hiddenMinSM>
          <CustomLink to="/sign-in" activeClassName='hidden' key="nav-sign-in">
            <BoxCentered hiddenMinSM>
              Log In
              <UserSVG />
            </BoxCentered>
          </CustomLink>
        </BoxCenteredY>
      );
    }
  };

  return (
    <HeaderCont isMenuOpen={isMenuOpen}>
      <SkipLink href="#main-content">Skip to Content</SkipLink>
      <Container relative>
        <Flex justifyContent="space-between">
          <BoxCenteredY grow={1} shrink={0} hiddenXS hiddenSM>
            {renderLeftNav(currentUser)}
          </BoxCenteredY>
          {mobileNavItems(currentUser).length > 0 && (
            <Fragment>
              <BoxCenteredY hiddenMinSM>
                <MobileNavClose opened={isMenuOpen} />
              </BoxCenteredY>
              <LoginButton />
            </Fragment>
          )}
          <BoxCenteredY grow={1} shrink={0} hiddenXS hiddenSM alignRight >
            {renderRightNav(currentUser)}
          </BoxCenteredY>
        </Flex>
        <LogoCont>
          <RouterLink to='/' tabIndex={-1} aria-hidden={true}>
            <WordMark />
          </RouterLink>
        </LogoCont>
      </Container>
      {renderMobileNav(currentUser, isMenuOpen)}
      <SkipTarget id="main-content"></SkipTarget>
    </HeaderCont>
  );
}

Header.propTypes = {
  leftNavItems: PropTypes.func,
  rightNavItems: PropTypes.func,
  mobileNavItems: PropTypes.func,
};

const HeaderCont = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${(p) => p.theme.colors.creamWhite};
  height: ${(p) => p.theme.layout.headerHeight};
  z-index: ${(p) => p.theme.zIndex.headerFixed};
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: ${(p) => p.theme.colors.black};
  }
  ${(p) => p.isMenuOpen && css`
    &:after {
      transition: 0.3s ease;
      filter: blur(10px);
    }
  `};
`;

const BoxCenteredY = styled(Box)`
  display: flex;
  height: ${(p) => p.theme.layout.headerHeight};
  align-items: center;
  z-index: 5;
  ${(p) => p.alignRight && css`
    justify-content: flex-end;
  `}
`;

const BoxCentered = styled(Box)`
  display: flex;
  height: ${(p) => p.theme.layout.headerHeight};
  align-items: center;
  z-index: 5;
  gap: 5px;
  ${(p) => p.alignRight && css`
    justify-content: flex-end;
  `}
`;

const CustomLink = styled(NavItem)`
  margin-right: 0.4rem;
  ${(p) => p.theme.max('sm')`
    font-size: 12px;
    margin-right: 0rem;
    padding: 0em;
    text-decoration: underline;
  `}
`;

const LogoCont = styled(Box)`
  position: absolute;
  left: 0;
  top: 25%;
  font-size: 2em;
  ${(p) => p.theme.max('sm')`
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
  `}
`;

const SkipLink = styled.a`
  position: absolute;
  left: -10000px;
  z-index: 999999;
  color: black;
  background-color: white;
  padding: 12px;
  &:focus-visible {
    left: 0px;
  }
`;

const SkipTarget = styled.div`
  width: 0px;
  height: 0px;
  overflow: hidden;
`;

export default Header;
