import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import styled, { css } from 'styled-components';

import {
  H4,
  List,
  ListItem,
} from 'core/ui/atoms';
import Loading from 'core/ui/components/Loading';

import { LIST_MARKETING_NOTICES } from 'core/api/apollo/queries';

function Notices(props) {
  const { loading, data: { listMarketingNotices = [] } = {} } = useQuery(LIST_MARKETING_NOTICES);
  const { priority, ...rest } = props;

  if (loading) {
    return <Loading />;
  }

  const notices = listMarketingNotices.filter((notice) => notice.priority === priority);

  if (!notices.length) {
    return null;
  }

  return (
    <List unstyled className='notices' {...rest}>
      {notices.map((notice) => <Notice key={notice.id} notice={notice} />)}
    </List>
  );
}

Notices.propTypes = {
  priority: PropTypes.string.isRequired,
};

function Notice(props) {
  const { notice: { headline, url, body, priority } } = props;

  return (
    <NoticeItem priority={priority} className='notices-notice'>
      <Heading>
        <NoticeAnchor href={url} target='_blank' rel='noopener noreferrer'>{headline}</NoticeAnchor>
      </Heading>
      {` ${body}`}
    </NoticeItem>
  );
}

Notice.propTypes = {
  notice: PropTypes.object.isRequired,
};

const Heading = styled(H4)`
  display: inline;
`;

const NoticeItem = styled(ListItem)`
  margin-bottom: 0.5em;
  &:last-child {
    margin-bottom: 0;
  }

  min-width: 30vw;
  padding: 0.5em 0;

  ${(p) => p.priority === 'HIGH' && css`
    &:first-child {
      background-color: ${(p) => p.theme.colors.secondary};
      color: ${(p) => p.theme.typography.colors.primary};
      padding: 0.5em 1em;

      a {
        color: ${(p) => p.theme.typography.colors.primary};
      }
    }
  `};
`;

const NoticeAnchor = styled.a`
  font-size: 1.1em;
  text-decoration: underline;
  &[href=''] {
    pointer-events: none !important;
    text-decoration: none;
  }
`;

export default Notices;
