import { css } from 'styled-components';

import BLACK from 'core/assets/fonts/Poppins/Poppins-Black.ttf';
import BLACK_ITALIC from 'core/assets/fonts/Poppins/Poppins-BlackItalic.ttf';
import EXTRA_BOLD from 'core/assets/fonts/Poppins/Poppins-ExtraBold.ttf';
import EXTRA_BOLD_ITALIC from 'core/assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf';
import BOLD from 'core/assets/fonts/Poppins/Poppins-Bold.ttf';
import BOLD_ITALIC from 'core/assets/fonts/Poppins/Poppins-BoldItalic.ttf';
import SEMI_BOLD from 'core/assets/fonts/Poppins/Poppins-SemiBold.ttf';
import SEMI_BOLD_ITALIC from 'core/assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf';
import MEDIUM from 'core/assets/fonts/Poppins/Poppins-Medium.ttf';
import MEDIUM_ITALIC from 'core/assets/fonts/Poppins/Poppins-MediumItalic.ttf';
import REGULAR from 'core/assets/fonts/Poppins/Poppins-Regular.ttf';
import REGULAR_ITALIC from 'core/assets/fonts/Poppins/Poppins-Italic.ttf';
import LIGHT from 'core/assets/fonts/Poppins/Poppins-Light.ttf';
import LIGHT_ITALIC from 'core/assets/fonts/Poppins/Poppins-LightItalic.ttf';
import EXTRA_LIGHT from 'core/assets/fonts/Poppins/Poppins-ExtraLight.ttf';
import EXTRA_LIGHT_ITALIC from 'core/assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf';
import THIN from 'core/assets/fonts/Poppins/Poppins-Thin.ttf';
import THIN_ITALIC from 'core/assets/fonts/Poppins/Poppins-ThinItalic.ttf';

export default css`
  @font-face {
    font-family: 'Poppins';
    src: url(${BLACK});
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${BLACK_ITALIC});
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${EXTRA_BOLD});
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${EXTRA_BOLD_ITALIC});
    font-weight: 800;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${BOLD});
    font-weight: bold 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${BOLD_ITALIC});
    font-weight: bold 700;
    font-style: italic;
    font-display: swap;
  }
    @font-face {
    font-family: 'Poppins';
    src: url(${SEMI_BOLD});
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${SEMI_BOLD_ITALIC});
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${MEDIUM});
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${MEDIUM_ITALIC});
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${REGULAR});
    font-weight: normal 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${REGULAR_ITALIC});
    font-weight: normal 400;
    font-style: italic;
    font-display: swap;
  }
    @font-face {
    font-family: 'Poppins';
    src: url(${LIGHT});
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${LIGHT_ITALIC});
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }
    @font-face {
    font-family: 'Poppins';
    src: url(${EXTRA_LIGHT});
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${EXTRA_LIGHT_ITALIC});
    font-weight: 200;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${THIN});
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url(${THIN_ITALIC});
    font-weight: 100;
    font-style: italic;
    font-display: swap;
  }
`;
