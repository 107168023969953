import styled from 'styled-components';

const ControlsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${(p) => p.size || 3}, 1fr);

  row-gap: 1em;
  column-gap: 3em;

  ${(p) => p.theme.max('sm')`
    grid-template-columns: 1fr;
  `}
`;

export default ControlsGrid;
