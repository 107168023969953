import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import toInteger from 'lodash/parseInt';

import {
  H4,
  Text,
} from 'core/ui/atoms';
import { RatingStar as RatingStarAtom } from 'dashboard/ui/atoms';
import { RATE_PRODUCT_COMPONENT } from 'core/api/apollo/mutations';
import ComponentCommentInput from 'dashboard/ui/components/RateFood/ComponentCommentInput';

const RATINGS = {
  1: 'HATED IT',
  2: "DIDN'T LIKE IT",
  3: 'IT WAS OKAY',
  4: 'LIKED IT',
  5: 'LOVED IT',
};

function ComponentRatingInput(props) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      currentRating: 0,
      currentComment: '',
      hoverRating: null,
    },
  );
  const [rateProductComponent] = useMutation(RATE_PRODUCT_COMPONENT);

  const rate = (rating, comment) => {
    const { orderId, productComponent } = props;
    const variables = { input: { orderId, productComponentId: productComponent.id, rating, comment } };
    rateProductComponent({ variables })
      .catch(() => setState({ currentRating: 0, currentComment: '' }));
  };

  const submitRating = (rating) => {
    const { currentComment } = state;
    setState({ currentRating: rating });
    rate(rating, currentComment);
  };

  const submitComment = (comment) => {
    const { currentRating } = state;
    setState({ currentComment: comment });
    rate(currentRating, comment);
  };

  const onMouseOver = (rating) => {
    setState({ hoverRating: rating });
  };

  const onMouseOut = () => {
    setState({ hoverRating: null });
  };

  const { productComponent } = props;
  const { hoverRating } = state;

  const currentRating = hoverRating || state.currentRating;

  return (
    <Rating data-component-id={productComponent.id}>
      <ItemName>
        {productComponent.name}
        <Subtitle>{productComponent.subtitle}</Subtitle>
      </ItemName>

      <RatingLabel>{RATINGS[`${currentRating}`]}</RatingLabel>
      {
        Object.keys(RATINGS).map(toInteger).map((rating) => (
          <RatingStar
            key={`star-${productComponent.id}-${rating}`}
            rating={rating}
            selected={rating <= currentRating}
            onClick={() => submitRating(rating)}
            onMouseOver={() => onMouseOver(rating)}
            onMouseOut={onMouseOut}
          />
        ))
      }

      <ComponentCommentInput
        onBlur={submitComment}
        disabled={state.currentRating < 1}
      />
    </Rating>
  );
}

ComponentRatingInput.propTypes = {
  productComponent: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
};

const Rating = styled.div`
  text-align: center;
  margin: 15px 0;
  &:not(:last-child) {
    border-bottom: ${(p) => p.theme.borders.width} solid ${(p) => p.theme.colors.grays.light};
  }
`;

const ItemName = styled(H4)`
  margin: 2em 0;
`;

const Subtitle = styled.small`
  display: block;
  font-weight: normal;
`;

const RatingLabel = styled(Text)`
  min-height: 1.5em;
  color: ${(p) => p.theme.colors.yellow};
  margin-bottom: 5px;
  font-size: 16px;
`;

const RatingStar = styled(RatingStarAtom)`
  font-size: 2em;
  letter-spacing: 4px;
`;

export default ComponentRatingInput;
