import StyleGuide from 'admin/ui/pages/StyleGuide';

export const ROUTES = [
  {
    name: 'styleGuide',
    path: '/developer/style-guide',
    component: StyleGuide,
  },
];

const ROUTE_MAP = ROUTES.reduce((obj, route) => ({ ...obj, [route.name]: route }), {});

export default ROUTE_MAP;
