import { useMutation } from '@apollo/client';

import { CREATE_ORDER, UPSERT_ITEM_ON_ORDER } from 'core/api/apollo/mutations';
import { upsertItemOnOrderOptimisticResponse } from 'dashboard/ui/helpers/OrderHelper';

function useUpsertItemOnOrderMutation(deliveryDayId, product, variant, existingOrder) {
  const [createOrder, { loading: orderLoading }] = useMutation(CREATE_ORDER);
  const [upsertItemOnOrder, { loading: itemLoading }] = useMutation(UPSERT_ITEM_ON_ORDER);

  const upsertItem = (order, quantity) => {
    const optimisticResponse = upsertItemOnOrderOptimisticResponse(deliveryDayId, order, product, variant, quantity);

    return upsertItemOnOrder({
      variables: { input: { orderId: order.id, variantId: variant.id, quantity } },
      optimisticResponse,
    });
  };

  const upsert = (quantity) => {
    if (existingOrder) {
      upsertItem(existingOrder, quantity);
    } else {
      createOrder({
        variables: { input: { deliveryDayId: deliveryDayId } },
      }).then(({ data: { createOrder: { order } } }) => upsertItem(order, quantity));
    }
  };

  return [upsert, { loading: orderLoading || itemLoading }];
}

export default useUpsertItemOnOrderMutation;
