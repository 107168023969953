import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { getQueryParams } from 'core/api/utils';

import { GET_RATING_MODAL_ORDER_ID } from 'core/api/apollo/queries';
import { ratingModalOrderIdVar } from 'core/api/apollo/cache';
import RateFoodModal from 'dashboard/ui/components/RateFood/Modal';

function RateFood() {
  const { loading, data: { ratingModalOrderId: orderId } = {} } = useQuery(GET_RATING_MODAL_ORDER_ID);

  useEffect(() => {
    const orderId = getQueryParams().rate;

    if (orderId) {
      ratingModalOrderIdVar(orderId);
    }
  }, []);

  const close = () => ratingModalOrderIdVar(null);

  if (loading || !orderId) { return null; }

  return (
    <RateFoodModal orderId={orderId} onClose={close} />
  );
}

export default RateFood;
