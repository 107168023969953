import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useMount } from 'react-use';

import useUser from 'core/hooks/useUser';
import useOrder from 'dashboard/hooks/useOrder';
import useUpsertItemOnOrderMutation from 'dashboard/hooks/useUpsertItemOnOrderMutation';
import Money from 'core/api/Money';
import { displayWeight } from 'dashboard/ui/helpers';
import { Table } from 'core/ui/atoms';
import PaddleControl from 'core/ui/components/PaddleControl';

// FIXME: replace except array with an object with the cell name mapped to an array of callbacks
function VariantOption(props) {
  const { deliveryDay, product, variant, quantity, autoAdd, disableAdd, disableRemove, except = [] } = props;
  const { userCan } = useUser();
  const { order: existingOrder } = useOrder(deliveryDay.order && deliveryDay.order.id);
  const [upsertItem, { loading: isSaving }] = useUpsertItemOnOrderMutation(deliveryDay.id, product, variant, existingOrder);

  useMount(() => {
    if (autoAdd && !disableAdd) {
      upsertItem(1);
    }
  });

  if (!variant.weightOunces) {
    except.push('weight');
  }

  if (!userCan.placeOrder()) {
    except.push('controls');
  }

  const showCell = (cell) => !except.includes(cell);

  const amountWithZipcode = (variant.price && variant.price.amount) ? variant.price.amount + product.zipcodeCharges : 0;
  const priceWithZipcode = { ...variant.price, amount: amountWithZipcode };

  return (
    <Container data-variant-option={variant.id}>
      {showCell('name') && <Name>{variant.size.name}</Name>}
      {showCell('weight') && <Weight>{displayWeight(variant.weightOunces)}</Weight>}
      {showCell('price') && <Price>{Money(priceWithZipcode).toFormat()}</Price>}
      {showCell('controls') && (
        <Cell text-align='center'>
          <PaddleControl
            key={`paddle-control-${variant.id}-${quantity}`}
            initialValue={quantity}
            onChange={upsertItem}
            disableAdd={disableAdd}
            disableRemove={disableRemove}
            isSaving={isSaving}
          />
        </Cell>
      )}
    </Container>
  );
}

VariantOption.propTypes = {
  deliveryDay: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  variant: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  autoAdd: PropTypes.bool,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.bool,
  except: PropTypes.arrayOf(PropTypes.string),
};

const Container = styled(Table.Row)`
  background-color: ${(p) => p.theme.colors.white};
  align-items: center;
  margin-bottom: 1em;
`;

const Cell = styled(Table.Cell)`
  ${(p) => p.theme.max('sm')`
    font-size: ${p.theme.typography.sizes.small};
    padding-right: 0.3em;
    padding-left: 0.3em;
  `}
`;

const Name = styled(Cell)`
  font-weight: 500;
`;

const Weight = styled(Cell)`
  font-size: ${(p) => p.theme.typography.sizes.small};
  ${(p) => p.theme.max('sm')`
    font-size: ${p.theme.typography.sizes.smaller};
  `}
`;

const Price = styled(Table.Cell)`
  font-weight: 500;
`;

export default VariantOption;
