import { debugTrackingEnabled, nextdoorPixelEnabled } from 'reactAppConstants';

const logEvent = (data = {}) => {
  try {
    if (debugTrackingEnabled()) {
      console.debug(Tracker.name, Tracker.enabled ? 'enabled' : 'disabled', data);
    }
  } catch(e) {
    console.error(Tracker.name, 'Error while logging', e, data);
  }
};

const trackEvent = (event) => {
  Tracker.enabled && window.ndp('track', event);
  logEvent(event);
};

const Tracker = {
  name: 'Nextdoor Pixel',
  enabled: !!nextdoorPixelEnabled,
  events: {
    pageView: () => trackEvent('PAGE_VIEW'),
    lead: () => trackEvent('LEAD'),
    registration: () => trackEvent('SIGN UP'),
    purchase: () => {
      trackEvent('CONVERSION');
      trackEvent('PURCHASE');
    },
    addToCart: () => {},
    applyPromoCode: () => {},
    checkoutProgress: () => {},
    error: () => {},
  },
};

export default Tracker;
