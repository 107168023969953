import { css } from 'styled-components';

import BOLD from 'core/assets/fonts/HelveticaNeue/HelveticaNeue-Bold.ttf';
import BOLD_ITALIC from 'core/assets/fonts/HelveticaNeue/HelveticaNeue-BoldItalic.ttf';
import MEDIUM from 'core/assets/fonts/HelveticaNeue/HelveticaNeue-Medium.ttf';
import MEDIUM_ITALIC from 'core/assets/fonts/HelveticaNeue/HelveticaNeue-MediumItalic.ttf';
import REGULAR from 'core/assets/fonts/HelveticaNeue/HelveticaNeue.ttf';
import REGULAR_ITALIC from 'core/assets/fonts/HelveticaNeue/HelveticaNeue-Italic.ttf';
import LIGHT from 'core/assets/fonts/HelveticaNeue/HelveticaNeue-Light.ttf';
import LIGHT_ITALIC from 'core/assets/fonts/HelveticaNeue/HelveticaNeue-LightItalic.ttf';

export default css`
  @font-face {
    font-family: 'Helvetica Neue';
    src: url(${BOLD});
    font-weight: bold 700 800 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url(${BOLD_ITALIC});
    font-weight: bold 700 800 900;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url(${MEDIUM});
    font-weight: 500 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url(${MEDIUM_ITALIC});
    font-weight: 500 600;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url(${REGULAR});
    font-weight: normal 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url(${REGULAR_ITALIC});
    font-weight: normal 400;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url(${LIGHT});
    font-weight: 300 200 100;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url(${LIGHT_ITALIC});
    font-weight: 300 200 100;
    font-style: italic;
    font-display: swap;
  }
`;
