import React from 'react';
import PropTypes from 'prop-types';

import SkipOrderButton from 'dashboard/ui/components/SkipOrderButton';
import CancelOrderButton from 'dashboard/ui/components/CancelOrderButton';

function SkipOrCancelButton(props) {
  const { status, order, deliveryDay, ...rest } = props;

  if (status.locked) {
    return null;
  }

  if (status.activeSubscription) {
    return (
      <SkipOrderButton
        {...rest}
        deliveryDay={deliveryDay}
        orderId={order && order.id}
        unstyled
      />
    );
  }

  return (
    <CancelOrderButton
      {...rest}
      orderId={order && order.id}
      unstyled
    />
  );
}

SkipOrCancelButton.propTypes = {
  status: PropTypes.object.isRequired,
  deliveryDay: PropTypes.object.isRequired,
  order: PropTypes.object,
};

export default SkipOrCancelButton;
