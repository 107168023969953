import React from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';

import useUser from 'core/hooks/useUser';

import { previousStep } from 'onramp/api/navigation';
import { RouterLink } from 'core/ui/atoms';

function BackButton(props) {
  const { cache } = useApolloClient();
  const { currentUser } = useUser();
  const route = previousStep(currentUser, cache);

  if (!route || !route.path) {
    return null;
  }

  return (
    <Button btn-lg btn-outline to={route.path} {...props} />
  );
}

const Button = styled(RouterLink)`
  font-weight: 500;
  background-color: ${(p) => p.theme.colors.white};
  border: none !important;
  border-radius: 3em;
  padding-right: 1.5em;
  padding-left: 1.5em;
  :before {
    content: 'Go Back';

    ${(p) => p.theme.max('xs')`
      content: "Back";
    `}
  }
`;

export default BackButton;
