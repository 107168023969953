import { useContext } from 'react';
import AccountContext from 'core/api/accountContext';
import Account from 'core/api/Account';
import AccessControl from 'core/api/AccessControl';

function useUser() {
  const { currentUser, userLoading, refetchUser } = useContext(AccountContext);

  return {
    currentUser,
    refetchUser,
    loading: userLoading,
    account: new Account(currentUser),
    userCan: new AccessControl(currentUser),
  };
}

export default useUser;
