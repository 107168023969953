import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { GET_VIDEO_ID } from 'core/api/apollo/queries';
import { videoIdVar } from 'core/api/apollo/cache';
import ModalComponent from 'core/ui/components/Modal';
import { VimeoEmbed } from 'dashboard/ui/atoms';

function Modal() {
  const { loading, data: { videoId } = {} } = useQuery(GET_VIDEO_ID);
  const close = () => videoIdVar(null);

  if (loading || !videoId) { return null; }

  return (
    <StyledModal onClose={close}>
      <VimeoEmbed srcKey={videoId} autoplay={true} fullscreen={false} />
    </StyledModal>
  );
}

const StyledModal = styled(ModalComponent)`
  background-color: transparent;
  box-shadow: none;
  width: 75vw;
  padding-top: 25px;
  padding-bottom: 25px;
  ${(p) => p.theme.max('sm')`
    width: 100vw;
    min-height: 100%;
    display: table-cell;
  `}
  @media (max-height: 450px) {
    padding: 0px 30px;
  }

  button.modal-close {
    top: 0px;
    right: -30px;
    ${(p) => p.theme.max('sm')`
      top: 0;
      right: 5px;
    `}
    @media (max-height: 450px) {
      top: 0;
      right: 5px;
      padding-top: 5px;
    }
    color: ${(p) => p.theme.colors.white};
    z-index: ${(p) => p.theme.zIndex.popover};
  }

  .vimeo-aspect-ratio {
    @media (max-height: 450px) {
      height: 100vh;
    }
  }
`;

export default Modal;
