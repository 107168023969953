import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Logo as LogoAtom } from 'core/ui/atoms';
import { Squiggle as SquiggleAtom } from 'onramp/ui/atoms';
import ProgressRing from 'onramp/ui/components/Page/ProgressRing';
import { stepNumber, stepCount } from 'onramp/api/routeSequence';

@withRouter
class OnrampHeader extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    inverse: PropTypes.bool,
    disableProgress: PropTypes.bool,
  };

  progress = () => {
    const { location: { pathname } } = this.props;
    return stepNumber(pathname) / stepCount() * 100;
  }

  render() {
    const { inverse, disableProgress } = this.props;

    return (
      <HeaderCont {...this.props}>
        <Logo inverse={inverse} />
        {!disableProgress && <Progress progress={this.progress()} height={62} stroke={5} />}
        <Squiggle $inverse={inverse} />
      </HeaderCont>
    );
  }
}

const HeaderCont = styled.header`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "logo progress";
  ${(p) => p.theme.max('sm')`
    grid-template-areas: "logo logo" "progress progress";
  `}
  padding: calc(${(p) => p.theme.layout.onramp.headerHeight} / 4) ${(p) => p.theme.layout.onramp.padding.horiz} 0 ${(p) => p.theme.layout.onramp.padding.horiz};
`;

const Logo = styled(LogoAtom)`
  grid-area: logo;
  font-size: 1.5em;

  ${(p) => p.theme.max('sm')`
    font-size: 2.5em;
    justify-self: center;
  `}
`;

const Squiggle = styled(SquiggleAtom)`
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, 0%);
`;

const Progress = styled(ProgressRing)`
  grid-area: progress;
  align-self: start;
  justify-self: end;

  ${(p) => p.theme.max('sm')`
    align-self: center;
    height: 2em;
  `}
`;

export default OnrampHeader;
