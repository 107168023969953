import styled from 'styled-components';

import { ReactComponent as SquiggleSVG } from 'onramp/assets/images/Squiggle.svg';

const Squiggle = styled(SquiggleSVG)`
  height: 0.5em;
  path {
    stroke: ${(p) => p.$inverse ? p.theme.colors.white : p.theme.colors.primary };
  }
`;

export default Squiggle;
