import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from './Icon';
import { generateRandomId } from 'core/ui/helpers';


class Checkbox extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.node,
    className: PropTypes.string,
  };

  render() {
    const { checked = false, label, className = '', ...rest } = this.props;

    const id = `checkbox-${generateRandomId()}`;

    return (
      <CheckboxCont htmlFor={id} className={className} >
        <Input id={id} type="checkbox" checked={checked} {...rest} />
        <Facade className='checkbox' />
        <span className="checkboxLabel">{label}</span>
      </CheckboxCont>
    );
  }
}

const CheckboxCont = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;
  height: 1em;
  width: 1em;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;

  :checked ~ .checkbox {
    color: ${(p) => p.theme.typography.colors.inverse};
    background-color: ${(p) => p.theme.typography.colors.primary};
  }
`;

const Facade = styled((props) => <Icon icon='check' {...props} />)`
  position: relative;
  display: inline-table;
  margin-right: 0.5em;
  height: 1em;
  width: 1em;
  min-width: 1em;
  background-color: transparent;
  border: 1px solid ${(p) => p.theme.typography.colors.primary};
  border-radius: 0.15em;
  color: transparent;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  :before {
    position: absolute;
    font-size: 0.85em;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
  }
`;

export default Checkbox;
