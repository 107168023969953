import React from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import firstItem from 'lodash/first';

import { isLoading } from 'core/api/loadingHelpers';
import { compareDates, displayDate } from 'core/ui/dateHelpers';

import {
  Flex,
} from 'core/ui/atoms';
import Loading from 'core/ui/components/Loading';
import DeliveryPeriod from 'dashboard/ui/components/OrdersPage/DeliveryPeriod';

import { LIST_DELIVERY_PERIODS } from 'core/api/apollo/queries';

function Orders() {
  const variables = {
    startDate: displayDate(moment().startOf('isoWeek'), 'isoShort'),
    endDate: displayDate(moment().add(2, 'weeks'), 'isoShort'),
  };
  const {
    loading, error, data: { listDeliveryPeriods = [] } = {},
  } = useQuery(LIST_DELIVERY_PERIODS, { variables });

  if (isLoading(loading, listDeliveryPeriods, error)) {
    return <Loading />;
  }

  const periods = [...listDeliveryPeriods].sort((a,b) => compareDates(a.startDate, b.startDate));
  const period = firstItem(periods.filter((period) => !period.locked));

  if (!period) {
    return null;
  }

  return (
    <Flex direction='column' alignItems='center'>
      <DeliveryPeriod deliveryPeriod={period} />
    </Flex>
  );
}

export default Orders;
