import React, { useEffect, Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { ReferralCodeContext } from '../../../../core/api/referralCodeContext';
import { ReactComponent as Tick } from 'onramp/assets/images/Tick.svg';
import { Container, H1, Text, Image, Link } from 'core/ui/atoms';
import Bento from 'core/assets/images/V2/Bento.jpeg';

import Tracker from 'core/api/tracking/tracker';
import Money from 'core/api/Money';
import { EXTERNAL_LINKS, HELP_EMAIL } from 'core/api/constants';
import { GET_PREPAID_PURCHASE, CURRENT_USER } from 'core/api/apollo/queries';
import { compareDates, displayDate } from 'core/ui/dateHelpers';

import { initOrderPath } from 'onramp/api/navigation';
import { userHasCourierAddress } from 'core/api/Account';

function ProgramConfirmationPage() {
  return (
    <ConfirmationModal />
  );
}

function ConfirmationModal() {
  const { loading: purchaseLoading, data: { getPrepaidPurchase: purchase = {} } = {} } = useQuery(
    GET_PREPAID_PURCHASE,
    { fetchPolicy: 'network-only' },
  );

  const { loading: userLoading, data: { currentUser } = {}  } = useQuery(
    CURRENT_USER,
    { fetchPolicy: 'network-only' },
  );
  const history = useHistory();
  const referralCodeContext = useContext(ReferralCodeContext);

  useEffect(() => {
    referralCodeContext.clearReferralCode();
  }, [referralCodeContext]);

  useEffect(() => {
    if (userLoading) return;
    const { nextOneTimeProgram = {} } = currentUser ;
    if (!(nextOneTimeProgram && nextOneTimeProgram.invoiced)) {
        history.push(initOrderPath());
    }
  }, [userLoading, history, currentUser]);

  if (userLoading || purchaseLoading || !purchase) {
    return null;
  }

  const { nextOneTimeProgram ={} } = currentUser ;

  const {
    programBundle: { prepaidProgram: program = {}, size } = {},
    bundleReservations = {},
  } = nextOneTimeProgram;

  const numWeeks = bundleReservations.length;

  const isCourierAddress = userHasCourierAddress(currentUser);

  const startDates = bundleReservations
    .map((r) => r.deliveryPeriod.startDate)
    .sort((a, b) => compareDates(a.startDate, b.startDate));

    const otpSubscribed = purchase && purchase.otpSubscribed;
    const billableItems = purchase && purchase.billableItems;
    const isTransformationTribe = billableItems && billableItems.length > 0;
    const getBundleReservations = purchase && purchase.bundleReservations;
    const isBundleReservationsEmpty = getBundleReservations && getBundleReservations.length === 0;

    const programTitle = () => {
      const bundleName = purchase && purchase.bundleName;

      if (isTransformationTribe) {
        if (isBundleReservationsEmpty) {
          return 'Transformation Tribe Digital only experience';
        }
        return `Transformation Tribe ${program.name}`;
      } else if (bundleName !== 'Haute Mom') {
        return `Signature Program: ${program.name}`;
      } else {
        return `${program.name}`;
      }
    };

    const shippingMessage = isCourierAddress ? 'on Monday by 6 AM' : 'via FedEx on Monday by 8 PM.';

    const weeklyMessage = () => {
      if (isTransformationTribe) {
        if (isBundleReservationsEmpty) {
          return "You'll get our";
        }
      } else {
        if (otpSubscribed) {
          return "You're subscribed to receive our";
        } else {
          return `You'll get ${numWeeks} week${numWeeks > 1 ? 's' : ''} of our`;
        }
      }
    };

    const deliveryArrivalMessage = () => {
      const billableItemDate = billableItems[0].deliveryPeriod.startDate;
      return `Your Transformation Tribe workbook will arrive by ${displayDate(billableItemDate, 'MMMM Do')}`;
    };

    if (purchase) {
      Tracker.trackMetaPurchase({
        customData: {
          currency: 'USD',
          value: purchase.total.amount * 0.01,
        },
        userData: currentUser,
        eventTime: Math.floor(Date.now() / 1000),
        actionSource: 'website',
        data_processing_options: [],
      });
    }

    return (
      <Fragment backgroundColor="red">
        <CustomContainer >
        {/* <BodyScrollManager disabled={true} /> */}
        <ModalContent>

          <Tick />
          <H1>Order Confirmed!</H1>
          <div>
            <Text  text-size="medium">
              Your credit card has been charged <strong>{Money(purchase.total).toFormat()}</strong>.
              {' '}{weeklyMessage()}{' '}
              <strong>{programTitle()}{' '}</strong>
              {!isTransformationTribe && !isBundleReservationsEmpty && `with ${size.name} size meals`}.
            </Text>

            {isTransformationTribe && !isBundleReservationsEmpty &&
              <Text text-size="medium">
                {`You'll get ${numWeeks} week${numWeeks > 1 ? 's' : ''}
                  of ${size.name} size meals in our 8-Week Transformation Tribe.`
                }
              </Text>
            }

            {!isTransformationTribe && !isBundleReservationsEmpty &&
              <Text text-size="medium">
                Your first delivery arrives the week of {' '}
                <strong>{displayDate(startDates[0], 'MMMM Do')}{' '}</strong>
                {shippingMessage}
              </Text>
            }

            {isTransformationTribe && isBundleReservationsEmpty &&
              <Text text-size="medium">
                {deliveryArrivalMessage()}
              </Text>
            }

            {!otpSubscribed && numWeeks > 0 &&
              <Text text-size="medium">
                Your last delivery arrives on {' '}
                <strong>{displayDate(startDates[numWeeks - 1], 'dddd, MMMM Do')}</strong>
              </Text>
            }

            {otpSubscribed && <Text text-size="medium">
              You'll get weekly deliveries thereafter unless you email {' '}
                <Link href={EXTERNAL_LINKS.contactMail} target="_blank">
                  {HELP_EMAIL}
              </Link> {' '}
              at least 8 days in advance of your Monday delivery day to pause that delivery.</Text>
            }

            <Text text-size="medium">
              We'll also send a confirmation email to {' '}
              <strong>{currentUser.email}</strong>, so keep an eye out for it!
            </Text>

            <Text  text-size="medium">
              If you need anything, we're here. Email {' '}
              <Link href={EXTERNAL_LINKS.contactMail} target="_blank">
                {HELP_EMAIL}
              </Link>
              .
            </Text>
          </div>
          <CustomButton href='/onramp/init-order'>
            BACK TO HOME
          </CustomButton>
          </ModalContent>
        </CustomContainer>
        <CustomImage src={Bento}/>
      </Fragment>);
}


const CustomButton = styled(Link)`
  display: block;
  margin: 1.5rem auto;
  max-width: 200px;
  border-radius: 5px;
  color: ${(p) => p.theme.typography.colors.primary};
  background: ${(p) => p.theme.colors.white};
  padding: 12px 35px;
  font-size: 15px;
  margin-top: 10px;
  text-decoration: auto;
  font-family: ${({ theme }) => theme.typography.familyPoppins};
`;

const CustomContainer = styled(Container)`
  text-align: center;
  margin-top: 5vh;
  z-index: 2;
`;

const CustomImage = styled(Image)`
  position: absolute;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 40%;
  object-position: center 25%;
  ${(p) => p.theme.max('sm')`
    position: relative;
    bottom: 0;
    height: 30vh;
    transform: scale(1.5);
    object-position: 25% center;
  `}
`;

const ModalContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 630px;
  min-height: 325px;
  margin: 1vh auto 0 auto;

  ${(p) => p.theme.max('sm')`
    padding: 0 5vw;
  `}
`;

export default ProgramConfirmationPage;
