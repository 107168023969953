import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { displayDate } from 'core/ui/dateHelpers';
import { cartPath } from 'core/api/actions/navigation';

import useDeliveryDayStatus from 'dashboard/hooks/useDeliveryDayStatus';

import { RouterLink } from 'core/ui/atoms';
import StatusHeaderComponent from 'dashboard/ui/components/DeliveryDay/StatusHeader';
import StatusIconComponent from 'dashboard/ui/components/DeliveryDay/StatusIcon';
import SummaryIconComponent from 'dashboard/ui/components/Cart/SummaryIcon';

function DeliveryDay(props) {
  const { deliveryDay } = props;
  const status = useDeliveryDayStatus(deliveryDay.id);

  return useMemo(() => {
    const date = {
      dayAndMonth: displayDate(deliveryDay.date, 'dddd, MMMM'),
      day: displayDate(deliveryDay.date, 'D'),
      ordinal: displayDate(deliveryDay.date, 'Do').replace(/[0-9]/g, ''),
    };

    return (
      <Wrapper
        data-delivery-day={deliveryDay.id}
        to={cartPath(deliveryDay.id)}
        disabled={status.locked || status.closed}
      >
        <StatusIcon status={status} />
        <StatusHeader status={status} />
        <DateWrapper>
          <DayMonth>{date.dayAndMonth}</DayMonth>
          <Day>
            {date.day}<small>{date.ordinal}</small>
          </Day>
        </DateWrapper>
        <OrderSummaryWrapper>
          <SummaryIcon
            order={status.order}
            status={status}
            showBadge={true}
          />
        </OrderSummaryWrapper>
      </Wrapper>
    );
  }, [deliveryDay, status]);
}

DeliveryDay.propTypes = {
  deliveryDay: PropTypes.object.isRequired,
};

const Wrapper = styled(RouterLink)`
  display: flex;
  position: relative;
  font-weight: normal;
  background-color: ${(p) => p.disabled ? '#ECECEC' : p.theme.colors.white};
  border: ${(p) => p.theme.borders.width} solid #ECECEC;
  box-shadow: ${(p) => p.theme.shadows.level1};
  border-radius: 6px;
  padding: 10px 1vw 20px 1vw;
  margin: 0;
  flex-direction: column;
  align-items: center;
  &:active {
    box-shadow: none;
  }
`;

const StatusHeader = styled(StatusHeaderComponent)`
  font-size: 0.75em;
  min-height: 0.75em;
  margin-bottom: 10px;
  letter-spacing: 2.4px;

  ${(p) => p.theme.max('md')`
    font-size: 0.5em;
    min-height: 0.5em;
    letter-spacing: 1.6px;
  `}
`;

const StatusIcon = styled(StatusIconComponent)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 0.5em;
`;

const DateWrapper = styled.div``;

const DayMonth = styled.div`
  text-align: center;

  ${(p) => p.theme.max('md')`
    font-size: 0.75em;
  `}
`;

const Day = styled.div`
  text-align: center;
  font-size: 5.25em;
  font-weight: bold;
  small {
    font-size: 0.33em;
  }

  ${(p) => p.theme.max('md')`
    font-size: 3.75em;
  `}
`;

const OrderSummaryWrapper = styled.div`
  border-top: ${(p) => p.theme.borders.width} solid #D8D8D8;
  padding: 10px 5px 0 0;
`;

const SummaryIcon = styled(SummaryIconComponent)`
  margin: 0;
  font-size: 1.1em;
`;

export default DeliveryDay;
