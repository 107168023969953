import React from 'react';

import { Text } from 'core/ui/atoms';
import CutoffModal from 'core/ui/components/Cutoff/Modal';

function Modal() {
  return (
    <CutoffModal>
      <Text>It looks like the deadline to edit this order has passed.</Text>
    </CutoffModal>
  );
}

export default Modal;
