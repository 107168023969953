import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ValidatorForm } from 'react-form-validator-core';

import useAlerts from 'core/hooks/useAlerts';
import useForm from 'core/hooks/useForm';

import { ERROR_MESSAGES } from 'core/api/constants';
import { SET_PASSWORD } from 'core/api/apollo/mutations';

import { Flex, FormGroup, H6, Hint, Label, Text, ValidatedInput } from 'core/ui/atoms';
import { EditButton, SubmitButton } from 'settings/ui/atoms';
import Modal from 'settings/ui/components/Modal';
import Panel from 'settings/ui/components/Panel';


function Password(props) {
  const alerts = useAlerts();
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);

  const closeModal = () => setModalOpen(false);

  const onSuccess = () => {
    closeModal();
    alerts.show('Your changes have been saved!', { autoClose: true });
  };

  return (
    <Flex data-password {...props}>
      <div>
        <H6 text-color='muted' no-margin>Password</H6>
        <Text no-margin>••••••••••••</Text>
      </div>

      <EditButton onClick={openModal} />
      { modalOpen && (
        <Modal title='Change Password' data-password-modal onClose={closeModal}>
          <PasswordForm
            onSuccess={onSuccess}
          />
        </Modal>
      )}
    </Flex>
  );
}

function PasswordForm(props) {
  const minPwdLength = 8;
  const maxPwdLength = 128;

  const { onSuccess } = props;
  const [setPassword] = useMutation(SET_PASSWORD);
  const { handleSubmit, formData, onChange, isDirty, isProcessing } = useForm('setPassword', {
    initialData: { oldPassword: '', password: '', passwordConfirmation: '' },
    onSubmit: setPassword,
    onSuccess,
    validator: ({ oldPassword, password, passwordConfirmation }) => (
      oldPassword &&
        password.length >= minPwdLength &&
        password.length <= maxPwdLength &&
        password === passwordConfirmation
    ),
  });

  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      noValidate
      instantValidate={false}
    >
      <FormGroup>
        <Label required>Old Password</Label>
        <ValidatedInput
          type="password"
          name="oldPassword"
          placeholder="Enter current password"
          value={formData.oldPassword}
          onChange={onChange}
          validators={['required']}
          errorMessages={[ERROR_MESSAGES.required]}
        />
      </FormGroup>

      <FormGroup>
        <Label required>New Password</Label>
        <ValidatedInput
          type="password"
          name="password"
          placeholder="Enter new password"
          value={formData.password}
          onChange={onChange}
          validators={['required',
            `minStringLength:${minPwdLength}`,
            `maxStringLength:${maxPwdLength}`,
          ]}
          errorMessages={[
            ERROR_MESSAGES.required,
            ERROR_MESSAGES.passwordLength,
            ERROR_MESSAGES.passwordLength,
          ]}
        />
        <Hint>At least 8 characters</Hint>
      </FormGroup>

      <FormGroup>
        <Label required>Confirm New Password</Label>
        <ValidatedInput
          type="password"
          placeholder="Enter new password"
          name="passwordConfirmation"
          value={formData.passwordConfirmation}
          onChange={onChange}
          validators={['required',
            `matchRegexp:${formData.password}`,
          ]}
          errorMessages={[
            ERROR_MESSAGES.required,
            ERROR_MESSAGES.passwordMatch,
          ]}
        />
      </FormGroup>

      <Panel.Footer>
        <SubmitButton disabled={!isDirty || isProcessing}>
          Apply Changes
        </SubmitButton>
      </Panel.Footer>
    </ValidatorForm>
  );
}

PasswordForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default Password;
