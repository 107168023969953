import React, { PureComponent } from 'react';

import { Style, StyleSection } from 'admin/ui/atoms';
import { H1, H2, H3, H4, H5, H6 } from 'core/ui/atoms';

const COPY = 'We promptly judged antique ivory buckles for the next prize.';

class Headings extends PureComponent {
  render() {
    return (
      <StyleSection title='Headings'>
        <Style name='H1'>
          <H1>{COPY}</H1>
        </Style>
        <Style name='H2'>
          <H2>{COPY}</H2>
        </Style>
        <Style name='H3'>
          <H3>{COPY}</H3>
        </Style>
        <Style name='H4'>
          <H4>{COPY}</H4>
        </Style>
        <Style name='H5'>
          <H5>{COPY}</H5>
        </Style>
        <Style name='H6'>
          <H6>{COPY}</H6>
        </Style>
      </StyleSection>
    );
  }
}

export default Headings;
