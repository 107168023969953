// Stripe card input should be wrapped in <Elements> element from '@stripe/react-stripe-js'.
// It has a built in validation. Validation technique is different from the regular input validation

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CardExpiryElement } from '@stripe/react-stripe-js';

import { formInputMixin } from 'core/ui/mixins';
import { getStripeInputStyleProps } from 'core/ui/helpers';
import { InputErrorMessage } from 'core/ui/atoms/InputMessage';
import { ERROR_MESSAGES } from 'core/api/constants';

const stripeStyleOptions = getStripeInputStyleProps();

class StripeExpiryInput extends PureComponent {
  static propTypes = {
    showInvalidState: PropTypes.bool,   // Determines whether the invalid state should be shown
    placeholder: PropTypes.string,
    onReady: PropTypes.func,
  };

  state = {
    isValid: false,
    error: '',
  };

  onChange = (cardData) => {
    this.setState({
      isValid: cardData.complete,
      error: cardData.empty ? ERROR_MESSAGES.required : (cardData.error || {}).message,
    });
  };

  render() {
    const { showInvalidState, placeholder, onReady } = this.props;
    const { isValid, error } = this.state;
    return (
      <CardInputWrap>
        <CardExpiry
          onChange={this.onChange}
          onReady={onReady}
          placeholder={placeholder}
          error={!isValid && showInvalidState}
          options={stripeStyleOptions}
        />
        {!isValid && showInvalidState && (
          <InputErrorMessage>{error}</InputErrorMessage>
        )}
      </CardInputWrap>
    );
  }
}

const CardExpiry = styled(({ error, ...rest }) => <CardExpiryElement {...rest} />)`
  ${formInputMixin}
  min-height: 48px;
`;

const CardInputWrap = styled.div`
  position: relative;
`;

export default StripeExpiryInput;
