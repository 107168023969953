import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from 'core/ui/atoms';
import { BoxIcon, BoxCrossedIcon, BoxOpenedIcon } from 'dashboard/ui/atoms';

import { displayNumItems } from 'dashboard/ui/helpers';

function OrderSummaryIcon(props) {

  const renderBoxIcon = () => {
    const { status } = props;

    if (status.closed) {
      return <BoxCrossedIcon />;
    } else if (!status.locked) {
      return <BoxOpened  />;
    }

    return <BoxIcon />;
  };

  const { order, status, showBadge, ...rest } = props;

  return (
    <OrderSummaryCont data-box-icon {...rest} >
      {renderBoxIcon()}
      {showBadge && <ItemCountBadge>{displayNumItems(order)}<span className="sr-only">&nbsp;Items in cart</span></ItemCountBadge>}
    </OrderSummaryCont>
  );
}

OrderSummaryIcon.propTypes = {
  order: PropTypes.object,
  status: PropTypes.object.isRequired,
  showBadge: PropTypes.bool,
};

const OrderSummaryCont = styled(Box)`
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 20px;
  svg {
    position: relative;
    top: 2px;
  }
`;

const ItemCountBadge = styled(Box)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  top: -0.25em;
  left: -1.5em;
  border-radius: 50%;
  color: ${(p) => p.theme.colors.white};
  background: ${(p) => p.theme.colors.primary};
  font-size: ${(p) => p.theme.typography.sizes.smaller};
`;

const BoxOpened = styled(BoxOpenedIcon)`
  position: relative;
  top: 3px;
`;

export default OrderSummaryIcon;
