import React, { PureComponent } from 'react';

import { Style, StyleGrid, StyleSection } from 'admin/ui/atoms';
import { Button } from 'core/ui/atoms';

class Buttons extends PureComponent {
  render() {
    return (
      <StyleSection title='Buttons'>
        <StyleGrid cols='repeat(3, 1fr)'>
          <Style name='Default' caption='large'>
            <Button btn-default btn-lg>Large</Button>
          </Style>
          <Style name='Default' caption='regular'>
            <Button btn-default>Regular</Button>
          </Style>
          <Style name='Default' caption='small'>
            <Button btn-default btn-sm>Small</Button>
          </Style>
        </StyleGrid>

        <StyleGrid cols='repeat(3, 1fr)'>
          <Style name='Primary' caption='large'>
            <Button btn-primary btn-lg>Large</Button>
          </Style>
          <Style name='Primary' caption='regular'>
            <Button btn-primary>Regular</Button>
          </Style>
          <Style name='Primary' caption='small'>
            <Button btn-primary btn-sm>Small</Button>
          </Style>
        </StyleGrid>

        <StyleGrid cols='repeat(3, 1fr)'>
          <Style name='Secondary' caption='large'>
            <Button btn-secondary btn-lg>Large</Button>
          </Style>
          <Style name='Secondary' caption='regular'>
            <Button btn-secondary>Regular</Button>
          </Style>
          <Style name='Secondary' caption='small'>
            <Button btn-secondary btn-sm>Small</Button>
          </Style>
        </StyleGrid>

        <StyleGrid cols='repeat(3, 1fr)'>
          <Style name='Unstyled' caption='large'>
            <Button btn-unstyled btn-lg>Large</Button>
          </Style>
          <Style name='Unstyled' caption='regular'>
            <Button btn-unstyled>Regular</Button>
          </Style>
          <Style name='Unstyled' caption='small'>
            <Button btn-unstyled btn-sm>Small</Button>
          </Style>
        </StyleGrid>

        <StyleGrid cols='repeat(3, 1fr)'>
          <Style name='Link' caption='large'>
            <Button btn-link btn-lg>Large</Button>
          </Style>
          <Style name='Link' caption='regular'>
            <Button btn-link>Regular</Button>
          </Style>
          <Style name='Link' caption='small'>
            <Button btn-link btn-sm>Small</Button>
          </Style>
        </StyleGrid>

        <StyleGrid cols='repeat(3, 1fr)'>
          <Style name='Outline' caption='large'>
            <Button btn-outline btn-lg>Large</Button>
          </Style>
          <Style name='Outline' caption='regular'>
            <Button btn-outline>Regular</Button>
          </Style>
          <Style name='Outline' caption='small'>
            <Button btn-outline btn-sm>Small</Button>
          </Style>
        </StyleGrid>

        <StyleGrid cols='repeat(3, 1fr)'>
          <Style name='Disabled' caption='large'>
            <Button disabled btn-lg>Large</Button>
          </Style>
          <Style name='Disabled' caption='regular'>
            <Button disabled>Regular</Button>
          </Style>
          <Style name='Disabled' caption='small'>
            <Button disabled btn-sm>Small</Button>
          </Style>
        </StyleGrid>
      </StyleSection>
    );
  }
}

export default Buttons;
