import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import { Text } from 'core/ui/atoms';

function IngredientsList(props) {
  const { ingredients } = props;

  return useMemo(() => (
    <Text>
      {capitalize([...ingredients].sort((i1,i2) => i2.grams - i1.grams).map((i) => i.displayName).join(', '))}
    </Text>
  ), [ingredients]);
}

IngredientsList.propTypes = {
  ingredients: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default IngredientsList;
