import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Container, Page } from 'core/ui/atoms';
import DefaultLayout from 'core/ui/layouts/DefaultLayout';
import NavBar from 'settings/ui/components/NavBar';

function SettingsLayout(props) {
  return (
    <DefaultLayout>
      <SettingsPage>
        <NavBar />
        <SettingsContainer>
          {props.children}
        </SettingsContainer>
      </SettingsPage>
    </DefaultLayout>
  );
}

SettingsLayout.propTypes = {
  children: PropTypes.node,
};

const SettingsPage = styled(Page)`
  background-color: ${(p) => p.theme.colors.grays.lightest};
`;

const SettingsContainer = styled(Container)`
  ${(p) => p.theme.max('xs')`
    padding-right: 0;
    padding-left: 0;
  `}
`;

export default SettingsLayout;
