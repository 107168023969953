import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

import GlobalStyles from 'core/ui/globalStyles';
import theme from 'core/ui/theme';
import { scrollTop } from 'core/api/actions/scrolling';
import { Button, LogoMark, Text } from 'core/ui/atoms';
import { reloadPage } from 'core/api/utils';
import { HELP_EMAIL, EXTERNAL_LINKS } from 'core/api/constants';

class ErrorOccurred extends PureComponent {
  static propTypes = {
    error: PropTypes.object.isRequired,
  };

  componentDidMount() {
    scrollTop();
  }

  getNotification({ networkError }) {
    const status = networkError ? networkError.statusCode : -1;
    switch (status) {
      case 409:
        return <UpgradeNoticeMessage />;
      case 503:
        return <MaintenanceMessage />;
      default:
        return <GenericError />;
    }
  }

  render() {
    const { error = {} } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <main>
          <ErrorMessage>
            <Logo />
            {this.getNotification(error)}
            <ReloadButton btn-primary btn-lg onClick={reloadPage}>OK</ReloadButton>
          </ErrorMessage>
        </main>
        <GlobalStyles />
      </ThemeProvider>
    );
  }
}

class MaintenanceMessage extends PureComponent {
  render() {
    return (
      <Fragment>
        <h2>
          Hang in there...<br/>
          We'll be back in a moment!
        </h2>
        <Text>
          We're doing some routine maintenance on the site to make your experience
          even better. Please check back in a few minutes and we should be finished.
        </Text>
        <Text>
          If you need to contact support in the meantime, please send us an email:<br/>
          <a href={EXTERNAL_LINKS.contactMail}>{HELP_EMAIL}</a>
        </Text>
      </Fragment>
    );
  }
}


class UpgradeNoticeMessage extends PureComponent {
  render() {
    return (
      <Fragment>
        <h2>
          Good News!
        </h2>
        <Text>
          Our engineers just upgraded the website.
        </Text>
        <Text>
          Refresh this page to use the latest version.
        </Text>
      </Fragment>
    );
  }
}

class GenericError extends PureComponent {
  render() {
    return (
      <Fragment>
        <h2>
          Uh ohhhh!<br/>
          An unexpected error occurred.
        </h2>
        <Text>
          We have already been notified and will solve the issue as soon as possible,
          but if you have something to add please contact the support team here:<br/>
          <a href={EXTERNAL_LINKS.contactMail}>{HELP_EMAIL}</a>
        </Text>
      </Fragment>
    );
  }
}

const ErrorMessage = styled.div`
  display: block;
  text-align: center;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 200px;
  width: 540px;
  ${(p) => p.theme.max('xs')`
    width: 260px;
    margin-top: 50px;
    margin-bottom: 100px;
  `}
`;

const ReloadButton = styled(Button)`
  margin: 30px auto 10px auto;
  width: 250px;
`;

const Logo = styled(LogoMark)`
  margin: auto;
  font-size: 3em;
`;

export default ErrorOccurred;
