import { gql } from '@apollo/client';

import {
  BillingAccountFullFragment,
  CouponFragment,
  DeliveryDayFragment,
  DeliveryOptionFragment,
  DeliveryPeriodFragment,
  DietFragment,
  ErrorFragment,
  InvoiceBasicFragment,
  MotivationFragment,
  IngredientTagFragment,
  MarketingNoticeFragment,
  MarketingVideoFragment,
  NotificationPreferencesFragment,
  OrderFragment,
  PrepaidProgramFragment,
  PrepaidPurchaseFragment,
  ProgramBundleFragment,
  ProgramBundleReservationFragment,
  PriceFragment,
  ProductCategoryFragment,
  ProductFullFragment,
  ProductImageFragment,
  ProductDetailFragment,
  ProductPreviewFragment,
  ProductSizeFragment,
  ProductSubcategoryFragment,
  ProductTagFragment,
  ProgramScheduleFragment,
  ProgramSizeFragment,
  RateableOrderFragment,
  SplitTestAssignmentFragment,
  SubscriptionFragment,
  SubscriptionCategoryFragment,
  SubscriptionOptionsFragment,
  TastePreferencesFragment,
  UserFragment,
  VariantFullFragment,
  ProgramMealTypeFragment,
} from './fragments';

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const GET_ALERTS = gql`
  query GetAlerts {
    alerts @client {
      id
      message
      autoClose
    }
  }
`;

export const GET_BACKGROUND_JOB = gql`
  query GetBackgroundJob($backgroundJobId: ID!) {
    getBackgroundJob(backgroundJobId: $backgroundJobId) {
      id
      status
      updateTime
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragment}
`;

export const GET_BILLING_INFO = gql`
  query GetBillingInfo {
    getBillingInfo {
      ...BillingAccountFullFragment
    }
  }
  ${BillingAccountFullFragment}
`;

export const GET_DELIVERY_DAY = gql`
  query GetDeliveryDay($deliveryDayId: ID!) {
    getDeliveryDay(deliveryDayId: $deliveryDayId) {
      ...DeliveryDayFragment
    }
  }
  ${DeliveryDayFragment}
`;

export const GET_FIRST_ORDER = gql`
  query {
    getFirstOrder {
      ...OrderFragment
      deliveryDay {
        id
      }
    }
  }
  ${OrderFragment}
`;

export const GET_REFERRAL_MODAL_OPENED = gql`
  query {
    isReferralModalOpen @client
  }
`;

export const GET_MENU_OPENED = gql`
  query {
    isMenuOpen @client
  }
`;

export const GET_NOTIFICATION_PREFERENCES = gql`
  query GetNotificationPreferences {
    getNotificationPreferences {
      ...NotificationPreferencesFragment
    }
  }
  ${NotificationPreferencesFragment}
`;

export const GET_ONE_TIME_PROGRAM = gql`
  query GetOneTimeProgram {
    getOneTimeProgram {
      programBundle {
        ...ProgramBundleFragment
      }
      bundleReservations {
        ...ProgramBundleReservationFragment
      }
    }
  }
  ${ProgramBundleFragment}
  ${ProgramBundleReservationFragment}
`;

export const GET_ORDER = gql`
  query GetOrder($orderId: ID!) {
    getOrder(orderId: $orderId) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

export const GET_ORDER_DATE = gql`
  query GetOrderDate($orderId: ID!) {
    getOrder(orderId: $orderId) {
      id
      date
    }
  }
`;

export const GET_PREPAID_PURCHASE = gql`
  query GetPrepaidPurchase {
    getPrepaidPurchase {
      ...PrepaidPurchaseFragment
    }
  }
  ${PrepaidPurchaseFragment}
`;

export const GET_PRODUCT = gql`
  query GetProduct($productId: ID!, $deliveryDayId: ID!, $zipcode: String) {
    getProduct(productId: $productId, deliveryDayId: $deliveryDayId, zipcode: $zipcode) {
      ...ProductFullFragment
    }
  }
  ${ProductFullFragment}
`;

export const GET_PRODUCT_FILTERS = gql`
  query GetProductFilters {
    productFilters @client
  }
`;

export const GET_PRODUCT_TAG = gql`
  query GetProductTag($productTagId: ID!) {
    getProductTag(productTagId: $productTagId) {
      ...ProductTagFragment
    }
  }
  ${ProductTagFragment}
`;

export const GET_PRODUCT_VARIANT = gql`
  query GetProductVariant($productVariantId: ID!, $zipcode: String) {
    getProductVariant(productVariantId: $productVariantId, zipcode: $zipcode) {
      ...VariantFullFragment
    }
  }
  ${VariantFullFragment}
`;

export const GET_PROGRAM_BUNDLE = gql`
  query GetProgramBundle($programId: ID!, $sizeId: ID!, $scheduleId: ID!, $mealTypeId: ID!, $numWeeks: Int = 1, $otpSubscribed: Boolean) {
    getProgramBundle(programId: $programId, sizeId: $sizeId, scheduleId: $scheduleId, mealTypeId: $mealTypeId, numWeeks: $numWeeks, otpSubscribed: $otpSubscribed) {
      ...ProgramBundleFragment
    }
  }
  ${ProgramBundleFragment}
`;

export const LIST_DELIVERY_DAYS_FOR_DIGITAL_ONLY = gql`
query ListDeliveryDaysForDigitalOnly {
  listDeliveryDaysForDigitalOnly {
    ...DeliveryDayFragment
    dateWithoutOffset
  }
}
${DeliveryDayFragment}
`;

export const GET_RATING_MODAL_ORDER_ID = gql`
  query {
    ratingModalOrderId @client
  }
`;

export const GET_SPLIT_TEST_ASSIGNMENTS = gql`
  query GetSplitTestAssignments($testNames: [String!]!, $readonly: Boolean) {
    getSplitTestAssignments(testNames: $testNames, readonly: $readonly) {
      ...SplitTestAssignmentFragment
    }
  }
  ${SplitTestAssignmentFragment}
`;

export const GET_SUBSCRIPTION = gql`
  query GetSubscription {
    getSubscription {
      ...SubscriptionFragment
    }
  }
  ${SubscriptionFragment}
`;

export const GET_SUBSCRIPTION_OPTIONS = gql`
  query GetSubscriptionOptions {
    getSubscriptionOptions {
      ...SubscriptionOptionsFragment
    }
  }
  ${SubscriptionOptionsFragment}
`;

export const GET_NEXT_CUTOFF_TIME = gql`
  query GetNextCutoffTime {
    getNextCutoffTime
  }
`;

export const GET_SUBSCRIPTION_PURCHASE = gql`
  query GetSubscriptionPurchase {
    getSubscriptionPurchase {
      numItems
      deliveryFee {
        ...PriceFragment
      }
      serviceFee {
        ...PriceFragment
      }
      subtotal {
        ...PriceFragment
      }
      coupon {
        ...CouponFragment
      }
      discount {
        ...PriceFragment
      }
      total {
        ...PriceFragment
      }
      orders {
        id
        date
        deliveryDay {
          id
        }
      }
      startDay {
        id
        date
      }
    }
  }
  ${PriceFragment}
  ${CouponFragment}
`;

export const GET_TASTE_PREFERENCES = gql`
  query GetTastePreferences {
    listFoodsToAvoid {
      ...IngredientTagFragment
    }
    currentUser {
      ...TastePreferencesFragment
    }
  }
  ${IngredientTagFragment}
  ${TastePreferencesFragment}
`;

export const GET_VIDEO_ID = gql`
  query {
    videoId @client
  }
`;

export const GET_PROMOCODE_INFO = `
  query GetPromocodeInfo($promocode: String!) {
    getPromocodeInfo(promocode: $promocode) {
      code
      discountPercentage
      discountType
      durationType
      applicableOnWeek
      discountAmount {
        amount
      }
    }
  }
`;

export const LIST_DELIVERY_PERIODS = gql`
  query ListDeliveryPeriods($startDate: Iso8601Date!, $endDate: Iso8601Date!) {
    listDeliveryPeriods(startDate: $startDate, endDate: $endDate) {
      ...DeliveryPeriodFragment
    }
  }
  ${DeliveryPeriodFragment}
`;

export const LIST_DIETS = gql`
  query ListDiets {
    listDiets {
      ...DietFragment
    }
  }
  ${DietFragment}
`;

export const LIST_FIRST_WEEK_ORDERS = gql`
  query GetSubscriptionPurchaseOrders {
    getSubscriptionPurchase {
      confirmedOrders {
        id
        date
        cutoffTime
        numItems
        billedAmount {
          ...PriceFragment
        }
        deliveryOption {
          ...DeliveryOptionFragment
        }
      }
    }
  }
  ${PriceFragment}
  ${DeliveryOptionFragment}
`;

export const LIST_FOODS_TO_AVOID = gql`
  query ListFoodsToAvoid {
    listFoodsToAvoid {
      ...IngredientTagFragment
    }
  }
  ${IngredientTagFragment}
`;

export const LIST_GLOBAL_FOODS_TO_AVOID = gql`
  query ListGlobalFoodsToAvoid {
    listGlobalFoodsToAvoid {
      ...IngredientTagFragment
    }
  }
  ${IngredientTagFragment}
`;

export const LIST_INVOICES = gql`
  query ListInvoices {
    listInvoices {
      ...InvoiceBasicFragment
    }
  }
  ${InvoiceBasicFragment}
`;

export const LIST_MARKETING_NOTICES = gql`
  query ListMarketingNotices {
    listMarketingNotices {
      ...MarketingNoticeFragment
    }
  }
  ${MarketingNoticeFragment}
`;

export const LIST_MARKETING_VIDEOS = gql`
  query ListMarketingVideos {
    listMarketingVideos {
      ...MarketingVideoFragment
    }
  }
  ${MarketingVideoFragment}
`;

export const LIST_PRODUCT_SIZES = gql`
  query ListProductSizes {
    listProductSizes {
      ...ProductSizeFragment
    }
  }
  ${ProductSizeFragment}
`;

export const LIST_MOTIVATIONS = gql`
  query ListMotivations {
    listMotivations {
      ...MotivationFragment
    }
  }
  ${MotivationFragment}
`;

export const LIST_PRODUCT_CATEGORIES = gql`
  query ListProductCategories($deliveryDayId: ID!) {
    listProductCategories(deliveryDayId: $deliveryDayId) {
      ...ProductCategoryFragment
      subcategories {
        ...ProductSubcategoryFragment
      }
    }
  }
  ${ProductCategoryFragment}
  ${ProductSubcategoryFragment}
`;

export const LIST_PRODUCT_INVENTORY_STATUSES = gql`
  query ListProductInventoryStatuses($deliveryDayId: ID!) {
    listProductInventoryStatuses(deliveryDayId: $deliveryDayId) {
      id
      soldOut
      product {
        id
      }
    }
  }
`;

export const LIST_PRODUCT_TAGS = gql`
  query ListProductTags($deliveryDayId: ID!) {
    listProductTags(deliveryDayId: $deliveryDayId) {
      ...ProductTagFragment
    }
  }
  ${ProductTagFragment}
`;

export const LIST_PRODUCT_PREVIEWS = gql`
  query ListProducts($deliveryDayId: ID!, $productCategoryId: ID, $productTagId: ID, $ordered: Boolean, $featured: Boolean, $onlyReset: Boolean) {
    listProducts(
      deliveryDayId: $deliveryDayId,
      productCategoryId: $productCategoryId,
      productTagId: $productTagId,
      ordered: $ordered,
      featured: $featured,
      onlyReset: $onlyReset
    ) {
      ...ProductPreviewFragment
    }
  }
  ${ProductPreviewFragment}
`;

export const LIST_PRODUCT_DETAILS = gql`
  query ListProducts($deliveryDayId: ID!, $productCategoryId: ID, $productTagId: ID, $ordered: Boolean, $featured: Boolean) {
    listProducts(
      deliveryDayId: $deliveryDayId,
      productCategoryId: $productCategoryId,
      productTagId: $productTagId,
      ordered: $ordered,
      featured: $featured
    ) {
      ...ProductDetailFragment
    }
  }
  ${ProductDetailFragment}
`;

export const LIST_PROGRAM_OPTIONS = gql`
  query ListProgramOptions {
    listPrepaidPrograms {
      ...PrepaidProgramFragment
    }
    listProgramSizes {
      ...ProgramSizeFragment
    }
    listProgramSchedules {
      ...ProgramScheduleFragment
    }
          listProgramMealTypes {
      ...ProgramMealTypeFragment
    }
  }
  ${PrepaidProgramFragment}
  ${ProgramSizeFragment}
  ${ProgramScheduleFragment}
  ${ProgramMealTypeFragment}
`;

export const LIST_RATEABLE_ORDERS = gql`
  query ListRateableOrders($startDate: Iso8601Date!, $endDate: Iso8601Date!) {
    listRateableOrders(startDate: $startDate, endDate: $endDate) {
      ...RateableOrderFragment
    }
  }
  ${RateableOrderFragment}
`;

export const LIST_RATEABLE_PRODUCT_VARIANTS = gql`
  query ListRateableProductVariants($orderId: ID!) {
    listRateableProductVariants(orderId: $orderId) {
      id
      product {
        name
        primaryImage {
          ...ProductImageFragment
        }
      }
      components {
        id
        name
        subtitle
        tagLine
        parentSku
      }
    }
  }
  ${ProductImageFragment}
`;

export const LIST_SUBSCRIPTION_QUANTITY_OPTIONS = gql`
  query ListSubscriptionQuantityOptions {
    listSubscriptionDays {
      index
      name
    }
    listSubscriptionCategories {
      ...SubscriptionCategoryFragment
    }
  }
  ${SubscriptionCategoryFragment}
`;
