import { useQuery } from '@apollo/client';
import _sortBy from 'lodash/sortBy';

import { LIST_PRODUCT_SIZES } from 'core/api/apollo/queries';

function useProductSizes() {
  const { loading, data: { listProductSizes } = {} } = useQuery(LIST_PRODUCT_SIZES);

  const productSizes = _sortBy(listProductSizes, 'position').filter((size) => size.subscribable);

  return { loading, productSizes };
}

export default useProductSizes;
