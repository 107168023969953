import { reportError } from 'core/api/errors';

// FIXME: we shouldn't need to check if the payload esxists, but sometimes it is undefined when loading is false
// https://app.clubhouse.io/methodology/story/8020/ai-typeerror-cannot-get-property-sort-of-undefined-or-null
export const isLoading = (loading, payload, error) => {
  if (error) {
    reportError(error);
  }

  return loading || !payload || error;
};
