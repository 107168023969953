import _find from 'lodash/find';
import _first from 'lodash/first';
import _get from 'lodash/get';

import useUser from 'core/hooks/useUser';
import { sortVariants } from 'dashboard/ui/helpers/ProductHelper';

function useDefaultVariant(product) {
  const { variants = [] } = product || {};
  const { currentUser } = useUser();
  const sizeId = _get(currentUser, 'subscription.productSize.id');

  return _find(variants, (v) => v.size.id === sizeId) || _first(sortVariants(product));
}

export default useDefaultVariant;
