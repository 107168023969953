import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

import useUser from 'core/hooks/useUser';

import { H3, H4, H5, RouterLink, Text } from 'core/ui/atoms';
import { Loading } from 'core/ui/components';

import { ROUTE_MAP } from 'onramp/api/routeSequence';
import { NUM_DAY_OFFSETS } from 'onramp/constants';
import { displayScheduleLabel } from 'onramp/helpers';

function OneTimeProgramSummary(props) {
  const { returnTo } = props;
  const {
    currentUser: { nextOneTimeProgram, primaryAddress },
    loading: userLoading,
  } = useUser();
  const { programBundle: { prepaidProgram: program, schedule, size }, bundleReservations } = nextOneTimeProgram;
  const { deliveryOption: { name: deliveryWindow, deliveryMethod } } = primaryAddress;

  const numWeeks = bundleReservations && bundleReservations.length;
  const deliveryOffset = NUM_DAY_OFFSETS[deliveryMethod];
  const deliveryDays = schedule.deliveryDays.map((day) => moment().day(day.index).add(deliveryOffset, 'days').format('ddd'));

  if (userLoading) { return <Loading />; }

  return (
    <Wrapper data-program-summary>
      <H4 text-center>My Program</H4>

      <Section>
        <H3 no-margin data-program-name>{program.name} - {size.name} Meals</H3>
        <Text no-margin data-program-schedule>
          {displayScheduleLabel(schedule)}
        </Text>
        <Text no-margin data-program-length>
          {numWeeks} Week{numWeeks > 1 ? 's' : ''}
        </Text>
      </Section>

      <div>
        <H5 no-margin>Weekly Deliveries</H5>
        <Text no-margin data-subscription-schedule>
          {deliveryWindow} on {deliveryDays.join(' and ')}
        </Text>
        Delivery via FedEx by 12 PM on Mondays.
      </div>

      <EditLink to={`${ROUTE_MAP.initOrder.path}?next=${returnTo}`}>Edit program</EditLink>
    </Wrapper>
  );
}

OneTimeProgramSummary.propTypes = {
  returnTo: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 3vh;
`;

const Section = styled.div`
  margin-bottom: 2em;
`;

const EditLink = styled(RouterLink)`
  padding: 0;
  font-weight: normal;
  color: ${(p) => p.theme.typography.colors.muted};
  text-align: right;
  font-size: ${(p) => p.theme.typography.sizes.small};
  text-decoration: underline;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export default OneTimeProgramSummary;
