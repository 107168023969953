import styled from 'styled-components';

import { Box } from 'core/ui/atoms';

const PageBox = styled(Box)`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: ${(p) => p.theme.layout.onramp.headerHeight};
  padding-right: ${(p) => p.theme.layout.onramp.padding.horiz};
  padding-bottom: ${(p) => p.theme.layout.onramp.padding.vert};
  padding-left: ${(p) => p.theme.layout.onramp.padding.horiz};
`;

export default PageBox;
