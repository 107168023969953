import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import uniqBy from 'lodash/uniqBy';

import useUser from 'core/hooks/useUser';

import { displayDate } from 'core/ui/dateHelpers';
import Money from 'core/api/Money';
import { displayDiscount } from 'core/ui/discountHelpers';

import { GET_ORDER_DATE } from 'core/api/apollo/queries';

import { Button, H1, H3, H6, Text as TextAtom } from 'core/ui/atoms';
import Loading from 'core/ui/components/Loading';
import ReferralActions from 'dashboard/ui/components/InviteFriends/Actions';
import ComponentRatingInput from 'dashboard/ui/components/RateFood/ComponentRatingInput';

function ModalCard(props) {
  const { next, variant, orderId } = props;

  if (next && !variant) {
    return <IntroCard next={next} />;
  }

  if (!next) {
    return <OutroCard />;
  }

  return (
    <RatingCard
      key={`variant-rating-${variant.id}`}
      orderId={orderId}
      variant={variant}
      next={next}
    />
  );
}

ModalCard.propTypes = {
  next: PropTypes.func,
  variant: PropTypes.object,
  orderId: PropTypes.string,
};

function IntroCard(props) {
  const { next } = props;

  return (
    <IntroCardContainer>
      <IntroCardHeading>Rate your food</IntroCardHeading>
      <Text>Rate food to improve your personalized menus</Text>
      <ConfirmButton btn-primary btn-lg fullWidth onClick={next}>Let's Go!</ConfirmButton>
    </IntroCardContainer>
  );
}

IntroCard.propTypes = {
  next: PropTypes.func.isRequired,
};

function OutroCard() {
  const { currentUser: { referralCoupon }, userCan } = useUser();

  if (!userCan.inviteFriends()) {
    return <CardHeading>Thanks!</CardHeading>;
  }

  return (
    <Fragment>
      <CardHeading>Loving Methodology?</CardHeading>
      <Text>
        Gift your friends <strong>{displayDiscount(referralCoupon)} off</strong> and
        get a {Money(referralCoupon.creditAmount).toFormat('$0,0')} credit each time your personal referral code is redeemed.
      </Text>
      <ReferralActions referralCoupon={referralCoupon} />
    </Fragment>
  );
}

function RatingCard(props) {
  const { orderId, variant, next } = props;
  const { loading, data: { getOrder: order } = {} } = useQuery(GET_ORDER_DATE, { variables: { orderId } });

  if (loading) {
    return <Loading />;
  }

  const rateableComponents = uniqBy(variant.components, 'parentSku');

  const isBundle = rateableComponents.length > 1;

  return (
    <Fragment>
      <CardHeading>
        <span>Rate your food</span>
        <small>Order {displayDate(order.date, 'tinyDate')}</small>
      </CardHeading>
      <RatingCardContainer>
        {isBundle && <BundleName>{variant.product.name}</BundleName>}
        <div>
          {
            rateableComponents.map((component) => (
              <ComponentRatingInput
                key={`component-rating-${component.id}`}
                orderId={orderId}
                productComponent={component}
              />
            ))
          }
        </div>
        <ConfirmButton btn-primary btn-lg fullWidth onClick={next}>Next</ConfirmButton>
      </RatingCardContainer>
    </Fragment>
  );
}

RatingCard.propTypes = {
  next: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  variant: PropTypes.object.isRequired,
};

const Text = styled(TextAtom)`
  font-size: 16px;
`;

const ConfirmButton = styled(Button)`
  display: block;
  margin-bottom: 30px;
`;

const IntroCardContainer = styled.div`
  margin-top: 60px;
  p {
    margin-bottom: 40px;
  }
  ${(p) => p.theme.max('sm')`
    margin-top: 10px;
  `}
`;

const IntroCardHeading = styled(H1)`
  margin-top: 0px;
  font-size: 3.25em;
  ${(p) => p.theme.max('sm')`
    font-size: 1.375em;
  `}
`;

const RatingCardContainer = styled.div`
  text-align: center;
  ${(p) => p.theme.min('sm')`
    max-height: 450px;
    overflow-y: auto;
  `}
`;

const CardHeading = styled(H3)`
  margin-top: 0;
  margin-bottom: 45px;
  text-align: center;
  line-height: 1;

  ${(p) => p.theme.max('sm')`
    margin-bottom: 20px;
    span {
      display: none;
    }
  `}

  small {
    font-weight: normal;
    font-size: 12px;
    color: ${(p) => p.theme.typography.colors.muted};
    display: block;
    margin-top: 10px;
  }
`;

const BundleName = styled(H6)`
  background-color: ${(p) => p.theme.colors.grays.light};
  border-radius: 1em;
  display: inline-block;
  padding: 5px 2em;
  margin: 0;
`;

export default ModalCard;
