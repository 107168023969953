import { min, max, between  } from 'core/ui/breakpoints';

const SHADOW_COLOR = 'rgba(30, 30, 30, 0.07)';
const FONT_BASE = 16;

const colors = {
  primary: '#36443A', // primary button color
  secondary: '#EF9281', // secondary button color
  black: '#000000',
  white: '#ffffff',
  offWhite: '#FFF9F3',
  creamWhite: '#FFFBF5',
  yellow: '#F8B631',
  green: {
    light: '#C9D5C8',
    lighter: '#C1D3C1',
    lightest: '#CFDDCF',
  },
  grays: {
    darker: '#444444',
    dark: '#555555',
    regular: '#8E8E8E',
    light: '#D9D9D9',
    lighter: '#F2F2F2',
    lightest: '#F7F7F7',
  },
  danger: '#C70018',
  success: '#04B120',
  secondaryV2: '#BBD7A7',
  primaryV2:'#36443A',
};

const layout = {
  gridSize: 12,
  gridGap: '12px',
  headerHeight: '60px',
  containerWidth: {
    md: '1000px',
    lg: '1150px',
  },
  mobilePadding: '5vw',
  promoBannerHeight: '60px',
  padding: {
    horiz: '5vw',
    vert: '5vh',
  },
  onramp: {
    headerHeight: '150px',
    padding: {
      horiz: '5vw',
      vert: '5vh',
    },
  },
};

const transitions = {
  fast: '.2s ease',
  medium: '.5s ease',
  slow: '.8s ease',
};

const zIndex = {
  galleryPaddle: 990,
  header: 1000,
  headerFixed: 1030,
  alert: 9998,
  modalBg: 2001,
  modal: 2002,
  popover: 1060,
  tooltip: 1070,
  loadingModal: 2000,
  promoBanner: 1020,
};

const shadows = {
  level1: `0 7px 12px ${SHADOW_COLOR}`,
  level2: `0 3px 40px ${SHADOW_COLOR}`,
  level3: `0 27px 45px ${SHADOW_COLOR}`,
  card: '0 2px 20px rgba(0, 0, 0, 0.09)',
};

// FIXME: convert heading sizes to ems
const typography = {
  sizes: {
    base: `${FONT_BASE}px`,
    h1: '34px',
    h2: '28px',
    h3: '22px',
    h4: '18px',
    h5: '16px',
    h6: '14px',
    huge: `${32/FONT_BASE}em`,
    large: `${25/FONT_BASE}em`,
    big: `${21/FONT_BASE}em`,
    medium: `${18/FONT_BASE}em`,
    regular: '1em',
    small: `${14/FONT_BASE}em`,
    smaller: `${12/FONT_BASE}em`,
    smallish: `${10/FONT_BASE}em`, // FIXME: rename
    smallest: `${8/FONT_BASE}em`,
  },
  colors: {
    primary: colors.primary,
    muted: colors.grays.regular,
    inverse: colors.white,
    danger: colors.danger,
    success: colors.success,
  },
  lineHeight: {
    base: 1.38,
    reduced: 1.2,
  },
  letterSpacing: {
    base: 'normal',
    accent: '0.2em',
  },
  familyText: 'Poppins, Helvetica Neue, Helvetica, sans-serif',
  familyHeader: 'Larken',
  familyAccent: 'Poppins, Helvetica Neue, Helvetica, sans-serif',
  familyLarken: 'Larken, Albra',
  familyPoppins: 'Poppins, Helvetica Neue, Helvetica, sans-serif',
};

const borders = {
  width: '1px',
  radius: '5px',
  color: colors.grays.regular,
  colorLight: colors.grays.light,
};

const modals = {
  backdropColor: 'rgba(0, 0, 0, .8)',
  inverseBackdropColor: 'rgba(256, 256, 256, .58)',
};

const overlays = {
  backdropColor: 'rgba(30, 30, 30, .85)',
};

export const theme = {
  colors,
  layout,
  transitions,
  zIndex,
  shadows,
  typography,
  borders,
  modals,
  overlays,
  min,
  max,
  between,
};

export default theme;
