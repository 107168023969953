import React from 'react';
import PropTypes from 'prop-types';
import createDOMPurify from 'dompurify';

const DOMPurify = createDOMPurify(window);

const SanitizedHTML = (props) => (
  <div>
    { <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.rawHTML) }} /> }
  </div>
);

SanitizedHTML.propTypes = {
  rawHTML: PropTypes.string,
};

export default SanitizedHTML;
