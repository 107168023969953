import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BackButton from 'onramp/ui/components/Page/Info/BackButton';
import PageBox from 'onramp/ui/components/V2/Page/Box';

class PageInfo extends PureComponent {
  static propTypes = {
    hideBackButton: PropTypes.bool,
  };

  render() {
    const { hideBackButton = false, ...rest } = this.props;

    return (
      <PageBox {...rest}>
        {rest.children}
        {!hideBackButton && <CustomBackButton />}
      </PageBox>
    );
  }
}

const CustomBackButton = styled(BackButton)`
  position: relative;
  z-index: 10;
  display: inline-block;
  align-self: flex-start;
  margin-top: auto;

  ${(p) => p.theme.max('sm')`
    position: absolute;
    bottom: ${(p) => p.theme.layout.onramp.padding.vert};
  `}
`;

export default PageInfo;
