import React from 'react';

import { Text } from 'core/ui/atoms';

function AllergenWarning(props) {
  return (
    <Text {...props}>
      Produced in a facility that processes wheat, soy, peanuts, dairy, and tree nuts.
    </Text>
  );
}

export default AllergenWarning;
