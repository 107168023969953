import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { menuPath } from 'core/api/actions/navigation';
import { FORMATS, displayDate } from 'core/ui/dateHelpers';
import { Container, Flex, H3, RouterLink } from 'core/ui/atoms';

import BACKGROUND_IMAGE from 'dashboard/assets/images/productNotAvailableBackground.jpg';

function ProductNotAvailable(props) {
  const { deliveryDay } = props;

  return useMemo(() => {
    const dayName = displayDate(deliveryDay.date, FORMATS.dayOfWeek);

    return(
      <BackgroundFlex direction='column'>
        <NotAvailableContainer>
          <Heading>This item isn't available for the {dayName} delivery.</Heading>
          <RouterLink btn-primary btn-lg to={menuPath(deliveryDay.id)}>Browse {dayName} Menu</RouterLink>
        </NotAvailableContainer>
      </BackgroundFlex>
    );
  }, [deliveryDay]);
}

ProductNotAvailable.propTypes = {
  deliveryDay: PropTypes.object.isRequired,
};

const BackgroundFlex = styled(Flex)`
  align-items: center;
  width: 100%;
  min-height: calc(100vh - (${(p) => p.theme.layout.headerHeight} * 3));
  background-image: url(${BACKGROUND_IMAGE});
  background-size: cover;
  background-position: top 50% left 52.5%;
  background-repeat: no-repeat;
`;

const NotAvailableContainer = styled(Container)`
  text-align: center;
  padding: 2vh;
  max-width: 550px;
`;

const Heading = styled(H3)`
  margin-top: 5vh;
  margin-bottom: 5vh;
`;

export default ProductNotAvailable;
