import React from 'react';
import styled from 'styled-components';

import { Icon, Text } from 'core/ui/atoms';

function FoodsToAvoidWarning(props) {
  return (
    <FTAWarning>
      <Text text-color="danger" text-size="small" {...props}>
        <WarningIcon icon='error' />
        Heads up - if you enter too many foods to avoid we may not be able to design a full menu for you
      </Text>
    </FTAWarning>
  );
}

const WarningIcon = styled(Icon)`
  display: inline-block;
  margin-right: 5px;
`;

const FTAWarning = styled.div`
  padding-left: 5px;
  padding-right: 5vw;
`;

export default FoodsToAvoidWarning;
